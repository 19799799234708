import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Location } from '#mrktbox/types';

import { Theme } from '#types';

import LocationCard from '#components/orders/LocationCard';

interface Style { theme? : Theme; }

const LocationSelectView = styled.div<Style>`
  position: relative;
  padding: ${(props) => props.theme.layout.spacing.small} 0;
  flex-grow: 1;
  background-color: ${(props) => props.theme.palette.background.fill};
`;

const LocationsSelectList = styled.ul<Style>`
  margin: 0 0 ${(props) => props.theme.layout.spacing.large};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    margin: 0 0 ${(props) => props.theme.layout.spacing.medium};
  }

  & > li {
    margin: ${(props) => props.theme.layout.spacing.medium} 0 0;

    @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
      margin: ${(props) => props.theme.layout.spacing.small} 0 0;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }
`;

interface RevenueCentersSelectProps {
  locations : Location[];
  selectLocation : (location : Location) => void;
  disabled? : boolean;
}

function Locations({
  locations,
  disabled,
  selectLocation,
} : RevenueCentersSelectProps) {
  const handleSelect = useCallback((location : Location) => () => {
    selectLocation(location);
  }, [selectLocation]);

  return (
    <LocationSelectView>
      <LocationsSelectList>
        { locations.map((location) => (
          <li
            id={`location-${location.id}`}
            key={location.id}
          >
            <LocationCard
              location={location}
              showImage={true}
              disabled={disabled}
              onSelect={handleSelect(location)}
            />
          </li>
        ))}
      </LocationsSelectList>
    </LocationSelectView>
  );
}

export default Locations;
