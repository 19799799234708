import { useCallback } from 'react';

import {
  Integration,
  Image,
  Product,
  ProductIntegration,
  ExternalProduct,
  ServiceChannel,
} from '#mrktbox/clerk/types';

import {
  createProduct as createProductAPI,
  retrieveProducts as retrieveProductsAPI,
  retrieveProductsBulk as retrieveProductsBulkAPI,
  retrieveProduct as retrieveProductAPI,
  updateProduct as updateProductAPI,
  deleteProduct as deleteProductAPI,
  retrieveExternalProducts as retrieveExternalProductsAPI,
  importProduct as importProductAPI,
  createProductIntegration as createProductIntegrationAPI,
  deleteProductIntegration as deleteProductIntegrationAPI,
  syncProductIntegration as syncProductIntegrationAPI,
  pushProductIntegration as pushProductIntegrationAPI,
  pullProductIntegration as pullProductIntegrationAPI,
  createProductImageUpload as createProductImageUploadAPI,
  pullProductImage as pullProductImageAPI,
  removeImageFromProduct as removeImageFromProductAPI,
  addServiceChannelToProduct as addServiceChannelToProductAPI,
  removeServiceChannelFromProduct as removeServiceChannelFromProductAPI,
} from '#mrktbox/clerk/api/mrktbox/products';

import useAPI from '#mrktbox/clerk/hooks/api/useAPI';

function useProductsAPI() {
  const { processRequest } = useAPI();

  const createProduct = useCallback(async (product : Product) => {
    return await processRequest(
      createProductAPI,
      { product },
    )
  }, [processRequest]);

  const retrieveProducts = useCallback(async (
    options? : { since? : Date }
  ) => {
    return await processRequest(
      retrieveProductsAPI,
      { since : options?.since },
    )
  }, [processRequest])

  const retrieveProductsBulk = useCallback(async (
    productIds : number[],
    options? : { withIntegrations? : boolean },
  ) => {
    return await processRequest(
      retrieveProductsBulkAPI,
      { productIds },
    )
  }, [processRequest]);

  const retrieveProduct = useCallback(
    async (
      productId : number,
      options? : { withIntegrations? : boolean },
    ) => {
      return await processRequest(
        retrieveProductAPI,
        { productId, withIntegrations : options?.withIntegrations ?? false },
      )
    },
    [processRequest],
  );

  const updateProduct = useCallback(async (product : Product) => {
    return await processRequest(
      updateProductAPI,
      { product },
    )
  }, [processRequest]);

  const deleteProduct = useCallback(async (product : Product) => {
    return await processRequest(
      deleteProductAPI,
      { product },
    )
  }, [processRequest]);

  const retrieveExternalProducts = useCallback(async (query? : string) => {
    return await processRequest(
      retrieveExternalProductsAPI,
      { query },
    )
  }, [processRequest]);

  const importProduct = useCallback(
    async (integration : Integration, externalProduct : ExternalProduct) => {
      return await processRequest(
        importProductAPI,
        { integration, externalProduct },
      )
    },
    [processRequest],
  );

  const createProductIntegration = useCallback(
    async (product : Product, externalProduct : ExternalProduct) => {
      return await processRequest(
        createProductIntegrationAPI,
        { product, externalProduct },
      )
    },
    [processRequest],
  );

  const deleteProductIntegration = useCallback(
    async (productIntegration : ProductIntegration) => {
      return await processRequest(
        deleteProductIntegrationAPI,
        { productIntegration },
      )
    },
    [processRequest],
  );

  const syncProductIntegration = useCallback(
    async (productIntegration : ProductIntegration) => {
      return await processRequest(
        syncProductIntegrationAPI,
        { productIntegration },
      )
    },
    [processRequest],
  );

  const pushProductIntegration = useCallback(
    async (productIntegration : ProductIntegration) => {
      return await processRequest(
        pushProductIntegrationAPI,
        { productIntegration },
      )
    },
    [processRequest],
  );

  const pullProductIntegration = useCallback(
    async (productIntegration : ProductIntegration) => {
      return await processRequest(
        pullProductIntegrationAPI,
        { productIntegration },
      )
    },
    [processRequest],
  );

  const createProductImageUpload = useCallback(
    async (product : Product) => {
      return await processRequest(
        createProductImageUploadAPI,
        { product },
      )
    },
    [processRequest],
  );

  const pullProductImage = useCallback(
    async (product : Product, image : Image) => {
      return await processRequest(
        pullProductImageAPI,
        { product, image },
      )
    },
    [processRequest],
  );

  const removeImageFromProduct = useCallback(
    async (product : Product, image : Image) => {
      return await processRequest(
        removeImageFromProductAPI,
        { product, image },
      )
    },
    [processRequest],
  );

  const addServiceChannelToProduct = useCallback(
    async (product : Product, serviceChannel : ServiceChannel) => {
      return await processRequest(
        addServiceChannelToProductAPI,
        { product, serviceChannel },
      )
    },
    [processRequest],
  );

  const removeServiceChannelFromProduct = useCallback(
    async (product : Product, serviceChannel : ServiceChannel) => {
      return await processRequest(
        removeServiceChannelFromProductAPI,
        { product, serviceChannel },
      )
    },
    [processRequest]
  );

  return {
    createProduct,
    retrieveProducts,
    retrieveProductsBulk,
    retrieveProduct,
    updateProduct,
    deleteProduct,
    retrieveExternalProducts,
    importProduct,
    createProductIntegration,
    deleteProductIntegration,
    syncProductIntegration,
    pushProductIntegration,
    pullProductIntegration,
    createProductImageUpload,
    pullProductImage,
    removeImageFromProduct,
    addServiceChannelToProduct,
    removeServiceChannelFromProduct,
  };
}

export default useProductsAPI;
