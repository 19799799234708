import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useNavigation, useAuth } from '#mrktbox';

import useModal from '#hooks/useModal';

import ButtonLink from '#materials/ButtonLink';

import Registration from '#slices/Registration';

import Content from '#components/page/Content';
import Main from '#components/page/Main';
import PageContainer from '#components/page/PageContainer';
import PageTitle from '#components/page/PageTitle';
import Login from '#components/auth/Login';

function SignUpPage() {
  const { navigate } = useNavigation();
  const { checkAuth } = useAuth();
  const { openModal } = useModal();

  const handleLogin = useCallback(() => {
    openModal(<Login />);
  }, [openModal]);

  useEffect(() => {
    if (checkAuth({ redirect : null })) navigate('/');
  }, [checkAuth, navigate]);

  return (
    <>
      <Helmet>
        <title>{(`Sign Up`)}</title>
      </Helmet>
      <Content>
        <Main>
          <PageContainer style={{ maxWidth: '72rem' }}>
            <PageTitle
              title="Create An Account"
              subtitle={'Order history, saved favorites & allergens, saved '
                + 'credit cards, and much more. Signing up takes two seconds '
                + '- start reaping the benefits today!'}
            >
              <p style={{ margin: '2rem 0' }}>
                  Already have an account?{' '}
                  <ButtonLink onClick={handleLogin}>
                    Click here to log in.
                  </ButtonLink>
              </p>
            </PageTitle>
            <Registration />
          </PageContainer>
        </Main>
      </Content>
    </>
  );
}

export default SignUpPage;
