import {
  Adjustment,
  isAdjustment,
  Condition,
  isCondition,
  Product,
  Service,
  Tax,
} from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

const ADJUSTMENTS_PATH = 'adjustments/';
const CONDITIONS_PATH = 'conditions/';
const PRODUCTS_PATH = 'products/';
const SERVICES_PATH = 'services/';
const TAXES_PATH = 'taxes/';

function parseAdjustment(adjustment : any) {
  if (!isAdjustment(adjustment))
    throw new TypeError('Adjustment is not an adjustment');
  return adjustment;
}

function parseAdjustments(adjustments : any) {
  const parsedAdjustments : { [id : number] : Adjustment } = {};
  for (const adjustmentId in adjustments) {
    if (typeof adjustmentId !== 'string')
      throw new TypeError('Adjustment id is not a string');

    const id = parseInt(adjustmentId);
    if (isNaN(id)) throw new TypeError('Adjustment id is not a number');

    parsedAdjustments[id] = parseAdjustment(adjustments[id]);
  }

  return parsedAdjustments;
}

function parseCondition(condition : any) {
  if (!isCondition(condition))
    throw new TypeError('Condition is not a condition');
  return condition;
}

function parseConditions(conditions : any) {
  const parsedConditions : { [id : number] : Condition } = {};
  for (const conditionId in conditions) {
    if (typeof conditionId !== 'string')
      throw new TypeError('Condition id is not a string');

    const id = parseInt(conditionId);
    if (isNaN(id)) throw new TypeError('Condition id is not a number');

    parsedConditions[id] = parseCondition(conditions[id]);
  }

  return parsedConditions;
}

export async function createAdjustment(
  { adjustment } : { adjustment : Adjustment },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(ADJUSTMENTS_PATH),
    methods.post,
    { adjustment },
    options,
  );

  try {
    return parseAdjustment(response.adjustment);
  } catch {
    throw new DeserializationError(
      'Could not deserialize adjustment',
      response,
    );
  }
}

export async function retrieveAdjustments(
  input : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(ADJUSTMENTS_PATH),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseAdjustments(response.adjustments);
  } catch {
    throw new DeserializationError(
      'Could not deserialize adjustments',
      response,
    );
  }
}

export async function retrieveAdjustment(
  { adjustmentId } : { adjustmentId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${ADJUSTMENTS_PATH}${adjustmentId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseAdjustment(response.adjustment);
  } catch {
    throw new DeserializationError(
      'Could not deserialize adjustment',
      response,
    );
  }
}

export async function updateAdjustment(
  { adjustment } : { adjustment : Adjustment },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${ADJUSTMENTS_PATH}${adjustment.id}`),
    methods.put,
    { adjustment },
    options,
  );

  try {
    return parseAdjustment(response.adjustment);
  } catch {
    throw new DeserializationError(
      'Could not deserialize adjustment',
      response,
    );
  }
}

export async function deleteAdjustment(
  { adjustment } : { adjustment : Adjustment },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${ADJUSTMENTS_PATH}${adjustment.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseAdjustment(response.adjustment);
  } catch {
    throw new DeserializationError(
      'Could not deserialize adjustment',
      response,
    );
  }
}

export async function createCondition({
  condition,
  adjustments,
  products,
  services
} : {
  condition : Condition,
  adjustments? : Adjustment[] | number[],
  products? : Product[] | number[],
  services? : Service[] | number[]
}, options? : RequestOptions) {
  const adjustmentIds = adjustments?.map((a) => {
    return typeof a === 'number' ? a : a.id
  }) ?? []

  const productIds = products?.map((p) => {
    return typeof p === 'number' ? p : p.id
  }) ?? []

  const serviceIds = services?.map((s) => {
    return typeof s === 'number' ? s : s.id
  }) ?? []

  const response = await request(
    getUrl(`${ADJUSTMENTS_PATH}${CONDITIONS_PATH}`),
    methods.post,
    {
      condition,
      adjustmentIds,
    },
    options,
  );

  let newCondition : Condition;
  let newAdjustments : { [id : number] : Adjustment };

  try {
    newCondition = parseCondition(response.condition);
  } catch (e) {
    throw new DeserializationError(
      'Could not deserialize condition',
      response,
    );
  }
  try {
    newAdjustments = parseAdjustments(response.adjustments);
  } catch {
    throw new DeserializationError(
      'Could not deserialize adjustment',
      response,
    );
  }

  for (const productId of productIds) {
    if(!productId) continue;
      newCondition = await addProductToCondition({
      condition: newCondition,
      product: productId,
    }, options);
  }

  for (const serviceId of serviceIds) {
    if(!serviceId) continue;
    newCondition = await addServiceToCondition({
      condition: newCondition,
      service: serviceId,
    }, options);
  }

  return {
    condition : newCondition,
    adjustments : newAdjustments,
  }
}

export async function retrieveConditions(
  input : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${ADJUSTMENTS_PATH}${CONDITIONS_PATH}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseConditions(response.conditions);
  } catch {
    throw new DeserializationError(
      'Could not deserialize conditions',
      response,
    );
  }
}

export async function retrieveCondition(
  { conditionId } : { conditionId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${ADJUSTMENTS_PATH}${CONDITIONS_PATH}${conditionId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseCondition(response.condition);
  } catch {
    throw new DeserializationError(
      'Could not deserialize condition',
      response,
    );
  }
}

export async function updateCondition({
  condition,
  product,
  service,
} : {
  condition : Condition,
  product? : Product | number,
  service? : Service | number,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${ADJUSTMENTS_PATH}${CONDITIONS_PATH}${condition.id}`),
    methods.put,
    { condition },
    options,
  );

  let updatedCondition : Condition;
  try {
    updatedCondition = parseCondition(response.condition);
  } catch {
    throw new DeserializationError(
      'Could not deserialize condition',
      response,
    );
  }

  const productId = (typeof product === 'number') ? product : product?.id;
  if (productId && !updatedCondition.productIds.includes(productId)) {
    updatedCondition = await addProductToCondition({
      condition : updatedCondition,
      product : productId,
    }, options);
  }
  for (const existingProductId of updatedCondition.productIds) {
    if (existingProductId === productId) continue;
    updatedCondition = await removeProductFromCondition({
      condition : updatedCondition,
      product : existingProductId,
    }, options);
  }

  const serviceId = (typeof service === 'number') ? service : service?.id;
  if (serviceId && !updatedCondition.serviceIds.includes(serviceId)) {
    updatedCondition = await addServiceToCondition({
      condition : updatedCondition,
      service : serviceId,
    }, options);
  }
  for (const existingServiceId of updatedCondition.serviceIds) {
    if (existingServiceId === serviceId) continue;
    updatedCondition = await removeServiceFromCondition({
      condition : updatedCondition,
      service : existingServiceId,
    }, options);
  }

  return updatedCondition;
}

export async function deleteCondition(
  { condition } : { condition : Condition },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${ADJUSTMENTS_PATH}${CONDITIONS_PATH}${condition.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseCondition(response.condition);
  } catch {
    throw new DeserializationError(
      'Could not deserialize condition',
      response,
    );
  }
}

export async function addConditionToAdjustment({
  adjustment,
  condition
} : {
  adjustment : Adjustment,
  condition : Condition
}, options? : RequestOptions) {
  const response = await request(
    getUrl(
      `${ADJUSTMENTS_PATH}${adjustment.id}/${CONDITIONS_PATH}${condition.id}`
    ),
    methods.post,
    {},
    options,
  );

  try {
    return parseAdjustment(response.adjustment);
  } catch {
    throw new DeserializationError(
      'Could not deserialize adjustment',
      response,
    );
  }
}

export async function removeConditionFromAdjustment({
  adjustment,
  condition
} : {
  adjustment : Adjustment,
  condition : Condition
}, options? : RequestOptions) {
  const response = await request(
    getUrl(
      `${ADJUSTMENTS_PATH}${adjustment.id}/${CONDITIONS_PATH}${condition.id}`
    ),
    methods.delete,
    {},
    options,
  );

  try {
    return parseAdjustment(response.adjustment);
  } catch {
    throw new DeserializationError(
      'Could not deserialize adjustment',
      response,
    );
  }
}

export async function addProductToCondition({
  condition,
  product,
} : {
  condition : Condition,
  product : Product | number
}, options? : RequestOptions) {
  const productId = (typeof product === 'number') ? product : product.id;
  const response = await request(
    getUrl(
      `${ADJUSTMENTS_PATH}${CONDITIONS_PATH}${condition.id}/`
        + `${PRODUCTS_PATH}${productId}`
    ),
    methods.post,
    {},
    options,
  );

  try {
    return parseCondition(response.condition);
  } catch {
    throw new DeserializationError(
      'Could not deserialize condition',
      response,
    );
  }
}

export async function removeProductFromCondition({
  condition,
  product,
} : {
  condition : Condition,
  product : Product | number,
}, options? : RequestOptions) {
  const productId = (typeof product === 'number') ? product : product.id;
  const response = await request(
    getUrl(
      `${ADJUSTMENTS_PATH}${CONDITIONS_PATH}${condition.id}/`
        + `${PRODUCTS_PATH}${productId}`
    ),
    methods.delete,
    {},
    options,
  );

  try {
    return parseCondition(response.condition);
  } catch {
    throw new DeserializationError(
      'Could not deserialize condition',
      response,
    );
  }
}

export async function addServiceToCondition({
  condition,
  service,
} : {
  condition : Condition,
  service : Service | number
}, options? : RequestOptions) {
  const serviceId = (typeof service === 'number') ? service : service.id;
  const response = await request(
    getUrl(
      `${ADJUSTMENTS_PATH}${CONDITIONS_PATH}${condition.id}/`
        + `${SERVICES_PATH}${serviceId}`
    ),
    methods.post,
    {},
    options,
  );

  try {
    return parseCondition(response.condition);
  } catch {
    throw new DeserializationError(
      'Could not deserialize condition',
      response,
    );
  }
}

export async function removeServiceFromCondition({
  condition,
  service
} : {
  condition : Condition,
  service : Service | number
}, options? : RequestOptions) {
  const serviceId = (typeof service === 'number') ? service : service.id;
  const response = await request(
    getUrl(
      `${ADJUSTMENTS_PATH}${CONDITIONS_PATH}${condition.id}/` +
        `${SERVICES_PATH}${serviceId}`
    ),
    methods.delete,
    {},
    options,
  );

  try {
    return parseCondition(response.condition);
  } catch {
    throw new DeserializationError(
      'Could not deserialize condition',
      response,
    );
  }
}

export async function addTaxToAdjustment({
  adjustment,
  tax
} : {
  adjustment : Adjustment,
  tax : Tax
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${ADJUSTMENTS_PATH}${adjustment.id}/${TAXES_PATH }${tax.id}`),
    methods.post,
    {},
    options,
  )

  try {
    return parseAdjustment(response.adjustment);
  } catch {
    throw new DeserializationError(
      'Could not deserialize adjustment',
      response,
    );
  }
}

export async function removeTaxFromAdjustment({
  adjustment,
  tax
} : {
  adjustment : Adjustment,
  tax : Tax
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${ADJUSTMENTS_PATH}${adjustment.id}/${TAXES_PATH }${tax.id}`),
    methods.delete,
    {},
    options,
  )

  try {
    return parseAdjustment(response.adjustment);
  } catch {
    throw new DeserializationError(
      'Could not deserialize adjustment',
      response,
    )
  }
}
