import { useContext } from 'react';

import SupplierContext from '#mrktbox/clerk/context/SupplierContext';

function useSuppliers() {
  const {
    suppliers,
    loaded,
    load,
    createSupplier,
    refreshSuppliers,
    refreshSupplier,
    retrieveSuppliers,
    retrieveSupplier,
    updateSupplier,
    deleteSupplier,
  } = useContext(SupplierContext);

  return {
    suppliers,
    loaded,
    load,
    createSupplier,
    refreshSuppliers,
    refreshSupplier,
    retrieveSuppliers,
    retrieveSupplier,
    updateSupplier,
    deleteSupplier,
  };
}

export default useSuppliers;
