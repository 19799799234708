import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Link } from '@mrktbox/clerk';

import { Theme } from '#types';

import Body from '#materials/Body';
import Heading from '#materials/Heading';
import { ArrowRight } from '#materials/icons';

interface Style { theme? : Theme; }
interface NavigationButtonViewStyle extends Style {
  delay? : number;
  disabled? : boolean;
  error? : boolean;
}
interface NavigationButtonErrorStyle extends Style {
  error? : boolean;
}

const NavButtonView = styled.button<NavigationButtonViewStyle>`
  display: flex;
  width: 100%;
  height: auto;
  min-height: 7rem;
  margin: 0 0 ${(props) => props.theme.layout.spacing.xsmall};
  padding:
    ${(props) => props.theme.layout.spacing.xsmall}
    ${(props) => props.theme.layout.spacing.small};
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  overflow: hidden;

  background-color: ${(props) => props.theme.palette.background.fill};

  border:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.error
      ? props.theme.palette.background.text.alert
      : props.theme.palette.border};
  border-radius: ${(props) => props.theme.layout.border.radius};

  text-decoration: none;

  transition: all 0.150s ease;
  animation: ${(props) => props.disabled
    ? 'slide-up-disabled'
    : 'slide-up'} 0.25s ease-in-out ${(props) => props.delay}s forwards;

  ${(props) => props.disabled && `
    pointer-events: none;
  `}

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    min-height: 6.5rem;
  }

  &:hover {
    background-color: ${(props) => props.error
      ? props.theme.palette.background.text.alert
      : props.theme.palette.background.text.hover};
    border-color: ${(props) => props.error
      ? props.theme.palette.background.text.alert
      : props.theme.palette.background.text.hover};
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const NavButtonLink = NavButtonView.withComponent(Link);

const NavButtonIcon = styled.span<NavigationButtonErrorStyle>`
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  flex-shrink: 0;

  color: ${(props) => props.error
    ? props.theme.palette.background.text.alert
    : props.theme.palette.background.text.primary};

  line-height: 1;

  transition: all 0.150s ease;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    width: 1.6rem;
    height: 1.6rem;
  }

  button:hover & {
    color: ${(props) => props.theme.palette.background.fill};
  }
`;

const NavButtonText = styled.span<NavigationButtonErrorStyle>`
  display: flex;
  width: 0;
  padding: 0 ${(props) => props.theme.layout.spacing.small};
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  text-align: left;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    padding: 0 ${(props) => props.theme.layout.spacing.small};
  }
`;

const NavButtonTitle = styled(Heading)<NavigationButtonErrorStyle>`
  display: block;
  margin: 0 0 0 -0.1rem;

  color: ${(props) => props.error
    ? props.theme.palette.background.text.alert
    : props.theme.palette.background.text.primary};

  font-family: ${(props) => props.theme.typography.fonts.title.family};
  font-size: ${(props) => props.theme.typography.fonts.title.sizes.medium};
  font-weight: ${(props) => props.theme.typography.fonts.title.weights.bold};

  transition: all 0.150s ease;

  button:hover & {
    color: ${(props) => props.theme.palette.background.fill};
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.title.sizes.small};
  }
`;

const NavButtonSubtitle = styled(Body)<NavigationButtonErrorStyle>`
  display: block;
  margin: 0.2rem 0 0;

  color: ${(props) => props.error
    ? props.theme.palette.background.text.alert
    : props.theme.palette.background.text.secondary};

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
  font-weight:
    ${(props) => props.theme.typography.fonts.default.weights.regular};

  transition: all 0.150s ease;

  button:hover & {
    color: ${(props) => props.theme.palette.background.fill};
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xxsmall};
  }
`;

const NavButtonArrow = styled.span<NavigationButtonErrorStyle>`
  position: relative;
  width: 2.2rem;
  height: 2.2rem;
  flex-shrink: 0;

  color: ${(props) => props.error
    ? props.theme.palette.background.text.alert
    : props.theme.palette.background.text.primary};

  line-height: 1;

  transform: translateX(0);
  transition: all 0.150s ease;

  button:hover & {
    color: ${(props) => props.theme.palette.background.fill};
    transform: translateX(1rem);
  }
`;

type ButtonLinkEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>
  | React.MouseEvent<HTMLAnchorElement, MouseEvent>;

interface ViewProps {
  href? : string;
  onClick? : (event : ButtonLinkEvent) => void;
  index : number;
  delay : number;
  disabled? : boolean;
  error? : boolean;
  children : React.ReactNode;
}

function View({
  href,
  onClick,
  index,
  delay,
  disabled,
  error,
  children,
} : ViewProps) {
  return href
    ? (<NavButtonLink
      to={href}
      onClick={onClick}
      delay={(index + 1) * 0.125 + delay}
      disabled={disabled}
      error={error}
    >
      { children }
    </NavButtonLink>)
    : (<NavButtonView
      onClick={onClick}
      delay={(index + 1) * 0.125 + delay}
      disabled={disabled}
      error={error}
    >
      { children }
    </NavButtonView>);
}

interface NavigationButtonProps {
  title : string;
  subtitle? : string;
  icon? : React.ReactNode;
  href? : string;
  onClick? : () => void;
  index? : number;
  delay? : number;
  disabled? : boolean;
  error? : boolean;
}

function NavigationButton({
  title,
  subtitle,
  icon,
  href,
  onClick,
  index = 0,
  delay = 0.125,
  disabled = false,
  error = false,
} : NavigationButtonProps) {
  const handleClick = useCallback(
    (event : ButtonLinkEvent) => {
      event.stopPropagation();
      if (onClick) onClick();
    },
    [onClick],
  );

  return (
    <View
      href={href}
      onClick={handleClick}
      index={index}
      delay={delay}
      disabled={disabled}
      error={error}
    >
      <NavButtonIcon error={error}>{ icon }</NavButtonIcon>
      <NavButtonText>
        <NavButtonTitle error={error}>{ title }</NavButtonTitle>
        { subtitle && (
          <NavButtonSubtitle error={error}>{ subtitle }</NavButtonSubtitle>
        ) }
      </NavButtonText>
      { !disabled && (
        <NavButtonArrow error={error}>
          <ArrowRight strokeWidth={2} />
        </NavButtonArrow>
      )}
    </View>
  );
}

export default NavigationButton;
