import React, { useCallback, useState, useEffect } from 'react';
import styled from '@emotion/styled';

import { Link } from '#mrktbox';
import { useAdjustments } from '#mrktbox';
import { Product } from '#mrktbox/types';

import { Theme } from '#types';

import useCatalogue from '#hooks/useCatalogue';

import Body from '#materials/Body';
import Heading from '#materials/Heading';

import ProductCardImage from '#components/products/ProductCardImage';
import ProductPriceCals from '#components/products/ProductPriceCals';

interface Style {
  theme? : Theme;
}

interface ProductCardButtonStyle extends Style {
  disabled? : boolean;
}

interface ProductCardDescriptionStyle extends Style {
  open? : boolean;
}

const ProductCardButtonView = styled(Link)<ProductCardButtonStyle>`
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 ${(props) => props.theme.layout.spacing.xsmall};
  flex-grow: 1;

  text-align: left;
  text-decoration: none;

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

const ProductCardContainer = styled.div<Style>`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const ProductCardContent = styled.div<Style>`
  padding: ${(props) => props.theme.layout.spacing.xsmall} 0 0;
`;

interface ProductCardInfoStyle extends Style {
  secondary? : boolean;
  reverseStack? : boolean;
}

const ProductCardInfo = styled.div<ProductCardInfoStyle>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.reverseStack ? 'row-reverse' : 'row')};
  align-items: ${(props) => (props.reverseStack ? 'flex-start' : 'flex-end')};
  justify-content: space-between;
  gap: ${(props) => props.theme.layout.spacing.xxsmall};

  ${(props) => props.secondary && `
    font-family: ${props.theme.typography.fonts.brand.family};
    font-size: ${props.theme.typography.fonts.brand.sizes.medium};

    * {
      color: ${props.theme.palette.background.text.secondary};
    }
  `}

  padding: 0
    ${(props) => props.theme.layout.spacing.xsmall}
    ${(props) => props.theme.layout.spacing.xxsmall};
`;

const ProductCardName = styled(Heading)`
  display: block;
  max-height: calc(3
    * ${(props) => props.theme.typography.fonts.default.sizes.large}
    * ${(props) => props.theme.typography.fonts.default.lineHeight});
  flex: 1 0 min-content;
  padding: 0 0.5rem 0 0;
  overflow: hidden;

  font-size: ${(props) => props.theme.typography.fonts.default.sizes.large};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  }
`;

const ProductCardPriceCals = styled.div<Style>`
  margin: 0 0 0 auto;
  flex-grow: 0;
  flex-shrink: 0;
  color: ${(props) => props.theme.palette.background.text.primary};
  text-align: right;

  * > * {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};

    @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
      font-size:
        ${(props) => props.theme.typography.fonts.default.sizes.small};
    }
  }
`;

const ProductAdjustment = styled.div<Style>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;

  font-family: ${(props) => props.theme.typography.fonts.brand.family};
  font-size: ${(props) => props.theme.typography.fonts.brand.sizes.small};

  * {
    margin: 0;
    white-space: nowrap;
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};

    @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
      font-size:
        ${(props) => props.theme.typography.fonts.default.sizes.small};
    }
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.brand.sizes.xsmall};
  }
`;

const ProductCardDescription = styled(Body)<ProductCardDescriptionStyle>`
  display: block;
  max-height: ${(props) => (props.open ? '100px' : '0')};
  margin:
    ${(props) => props.theme.layout.spacing.xsmall}
    0
    ${(props) => props.theme.layout.spacing.xxsmall};
  padding: 0 ${(props) => props.theme.layout.spacing.xsmall};
  opacity: ${(props) => (props.open ? '1' : '0')};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};

  overflow: hidden;
  transition: opacity 0.75s ease-in-out, max-height 0.75s ease-in-out;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
  }
`;

interface ProductCardButtonProps extends Style{
  product : Product;
  outOfStock? : boolean;
  onClick? : () => void;
}

function ProductCardButton({
  product,
  outOfStock,
  onClick,
} : ProductCardButtonProps) {
  const {
    getProductAdjustments,
    calculateAdjustmentAbsolute,
    calculateAdjustmentRelative,
  } = useAdjustments();
  const {
    getProductSlug,
    formatAdjustment,
    calculatePrice,
    getCurrentSelections,
  } = useCatalogue();

  const getAdjustments = useCallback(() => (
    getProductAdjustments(product)
  ), [getProductAdjustments, product]);

  const [adjustments, setAdjustments] = useState(getAdjustments());

  const getPrice = useCallback(() => (
    calculatePrice({ product, adjustments : []})
  ), [product, calculatePrice]);
  const getAdjusted = useCallback(() => (
    calculatePrice({ product, adjustments })
  ), [product, adjustments, calculatePrice]);
  const getSelectionPrice = useCallback(() => {
    const selections = getCurrentSelections(product);
    return calculatePrice({ product, selections, relative : true })
  }, [product, getCurrentSelections, calculatePrice]);

  const [price, setPrice] = useState(getPrice());
  const [newPrice, setNewPrice] = useState(getAdjusted());
  const [selectionPrice, setSelectionPrice] = useState(getSelectionPrice());

  useEffect(() => setAdjustments(getAdjustments()), [getAdjustments]);
  useEffect(() => setPrice(getPrice()), [getPrice]);
  useEffect(() => setNewPrice(getAdjusted()), [getAdjusted]);
  useEffect(() => setSelectionPrice(getSelectionPrice()), [getSelectionPrice]);

  const onClickHandler = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  const hasAdjustment = price.amount !== newPrice.amount;
  const relative = Math.abs(adjustments.length
    ? calculateAdjustmentRelative(adjustments[0], product)
    : 0) * 100;

  const href = `/product/${getProductSlug(product)}`;

  return (
    <ProductCardButtonView to={href} onClick={onClickHandler}>
      <ProductCardContainer>
        <ProductCardImage product={product} outOfStock={outOfStock} />
        <ProductCardContent>
          <ProductCardInfo>
            <ProductCardName className="title">
              { product.name }
            </ProductCardName>
            <ProductCardPriceCals>
              <ProductPriceCals
                price={price}
                adjustment={selectionPrice}
                size="small"
                variant={hasAdjustment ? 'strike' : 'default'}
              />
            </ProductCardPriceCals>
          </ProductCardInfo>
          { !!adjustments.length && (
            <ProductCardInfo secondary reverseStack>
              { hasAdjustment && (
                <ProductCardPriceCals>
                  <ProductPriceCals
                    price={newPrice}
                    size="medium"
                    variant="deal"
                    style={selectionPrice.amount
                      ? { marginRight : '6rem' }
                      : {}}
                  />
                </ProductCardPriceCals>
              ) }
              <ProductAdjustment>
                <span>{ formatAdjustment(adjustments[0], product) }</span>
                <ProductPriceCals
                  price={calculateAdjustmentAbsolute(adjustments[0], product)}
                  size="medium"
                  variant='deal'
                  absolute
                />
                { `(${relative % 1 ? relative.toFixed(1) : relative}%)` }
              </ProductAdjustment>
            </ProductCardInfo>
          ) }
          <ProductCardDescription open={!!product.summary}>
            { product.summary }
          </ProductCardDescription>
        </ProductCardContent>
      </ProductCardContainer>
    </ProductCardButtonView>
  )
}

export default ProductCardButton;
