import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }

const CartItemCountView = styled.div<Style>`
  display: flex;
  position: absolute;
  min-width: 2.2rem;
  height: 2.2rem;
  top: -1rem;
  right: -0.9rem;
  z-index: 5;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.palette.notifications.success.text};
  background-color:
    ${(props) => props.theme.palette.notifications.success.fill};

  border-radius: 50%;

  line-height: 1;

  span {
    display: block;
    font-family: ${(props) => props.theme.typography.fonts.default.family};
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
    font-weight:
      ${(props) => props.theme.typography.fonts.default.weights.regular};
    line-height: 0;
    -webkit-font-smoothing: antialiased;
  }
`;

interface CartItemCountProps {
  count: number;
}

function CartItemCount({ count } : CartItemCountProps) {
  if (!count) return null;
  return (
    <CartItemCountView>
      <span>{count}</span>
    </CartItemCountView>
  );
}

export default CartItemCount;
