import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import Footer from '#components/footer/Footer';

interface ContentViewStyle {
  theme? : Theme;
  maxWidth: string;
}

const ContentView = styled.div<ContentViewStyle>`
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  height: 100%;
  background-color: ${(props) => props.theme.palette.background.fill};
`;

interface ContentProps {
  maxWidth? : string;
  scrollTop? : boolean;
  hasRouter? : boolean;
  hasFooter? : boolean;
  children : React.ReactNode;
}

function Content({
  maxWidth = '100%',
  scrollTop = true,
  hasRouter = true,
  hasFooter = true,
  children,
} : ContentProps) {
  useEffect(() => {
    if (scrollTop) window.scrollTo(0, 0)
  }, [scrollTop]);

  return (
    <ContentView maxWidth={maxWidth}>
      <>
        { children }
        { hasFooter && <Footer hasRouter={hasRouter} /> }
      </>
    </ContentView>
  );
}

export default Content;
