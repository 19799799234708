
export interface Config {
  id? : number;
  name : string;
  value : string;
  integrationId? : number;
}

export interface Integration {
  id? : number;
  name : string;
  engineId : number;
  channelIds : number[];
  settings : {
    [key : string] : string;
  };
  configs : {
    [id : number] : Config;
  };
}

export interface Channel {
  id? : number;
  name : string;
  read : boolean;
  write : boolean;
}

export interface Engine {
  id? : number;
  name : string;
  module : string;
}

export function isConfig(config : any): config is Config {
  return (
    typeof config === 'object' &&
    ( config.id === undefined || typeof config.id === 'number') &&
    typeof config.name === 'string' &&
    typeof config.value === 'string' &&
    ( config.integrationId === undefined ||
      typeof config.integrationId === 'number')
  )
}

export function isIntegration(integration: any): integration is Integration {
  return (
    typeof integration === 'object' &&
    typeof integration.id === 'number' &&
    typeof integration.name === 'string' &&
    typeof integration.engineId === 'number' &&
    Array.isArray(integration.channelIds) &&
    integration.channelIds.every((id : any) => typeof id === 'number') &&
    typeof integration.settings === 'object' &&
    Object.values(integration.settings).every(
      (value : any) => typeof value === 'string',
    ) &&
    typeof integration.configs === 'object' &&
    Object.keys(integration.configs).every((id : any) => (
      typeof id === 'string' &&
      !isNaN(parseInt(id)) &&
      isConfig(integration.configs[id])
    ))
  )
}

export function isChannel(channel: any): channel is Channel {
  return (
    typeof channel === 'object' &&
    typeof channel.name === 'string' &&
    (channel.id === undefined || typeof channel.id === 'number') &&
    typeof channel.read === 'boolean' &&
    typeof channel.write === 'boolean'
  )
}

export function isEngine(engine : any): engine is Engine {
  return (
    typeof engine === 'object' &&
    typeof engine.id === 'number' &&
    typeof engine.name === 'string'
  )
}
