import React from 'react';

interface UserCircleProps {
  size?: number;
  strokeWidth?: number;
}

function UserCircle({
  size,
  strokeWidth,
} : UserCircleProps) {

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 28 28'
      fill='none'
      strokeWidth={strokeWidth || 2}
      strokeLinecap='round'
      strokeLinejoin='round'
      overflow='visible'
    >
      <circle
        cx='14'
        cy='14'
        r='13.5'
        stroke='currentColor'
        fill='none'
      />
      <path
        d={
          'M20 20.75 ' +
          'V19.25 ' +
          'C20 18.4544 19.6839 17.6913 19.1213 17.1287 ' +
          'C18.5587 16.5661 17.7956 16.25 17 16.25 ' +
          'H11 ' +
          'C10.2044 16.25 9.44129 16.5661 8.87868 17.1287 ' +
          'C8.31607 17.6913 8 18.4544 8 19.25 ' +
          'V20.75'
        }
        stroke='currentColor'
      />
      <path
        d={
          'M14 13.25 ' +
          'C15.6569 13.25 17 11.9069 17 10.25 ' +
          'C17 8.59315 15.6569 7.25 14 7.25 ' +
          'C12.3431 7.25 11 8.59315 11 10.25 ' +
          'C11 11.9069 12.3431 13.25 14 13.25Z'
        }
        stroke='currentColor'
      />
    </svg>
  );
}

export default UserCircle;
