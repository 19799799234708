import React, { useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import styled from '@emotion/styled';

import { BrowserRouter } from '#mrktbox';

import ConfigContext from '#context/ConfigContext';
import { ThemeProvider } from '#context/ThemeContext';

import useChat from '#hooks/useChat';

import GlobalStyles from 'GlobalStyles';
import ErrorBoundary from 'ErrorBoundary';
import AppRoutes from 'AppRoutes';
import Providers from 'Providers';
import { checkCache } from 'version';

const ROBOTS = process.env.REACT_APP_ROBOTS || '';

export const AppView = styled.div`
  label: AppView;

  height: 100%;
  display: flex;
  justify-content: flex-end;
`;

function App() {
  const { config, ready } = useContext(ConfigContext);

  useChat();

  useEffect(() => { checkCache(); }, []);

  return (
    <HelmetProvider>
      <ThemeProvider>
        <GlobalStyles />
        <AppView id="app">
          <Helmet>
            <title>{ config.brand.title }</title>
            <meta name="description" content={config.brand.description} />
            { ROBOTS && (<meta name="robots" content={ROBOTS} />) }
          </Helmet>
          { ready && (
            <ErrorBoundary>
              <BrowserRouter>
                <Providers>
                  <AppRoutes />
                </Providers>
              </BrowserRouter>
            </ErrorBoundary>
          ) }
        </AppView>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
