import React from 'react';
import styled from '@emotion/styled';

import { Tag } from '#mrktbox/types';
import { Theme } from '#types';

import Preface from '#materials/Preface';

interface Style { theme? : Theme; }
interface ViewStyle extends Style {
  isCentered: boolean;
}

const ProductTagsAllergensView = styled.div<ViewStyle>`
  display: flex;
  margin: ${(props) => props.theme.layout.spacing.xxsmall} 0 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${(props) => (props.isCentered ? 'center' : 'flex-start')};
  gap: ${(props) => props.theme.layout.spacing.xsmall};

  span {
    display: block;
    margin: 0 ${(props) => props.theme.layout.spacing.xsmall} 0 0;
  }
`;

interface TagStyle extends Style {
  size: keyof Theme['typography']['fonts']['default']['sizes'];
}

const ProductTag = styled(Preface)<TagStyle>`
  font-size: ${(props) => props.theme.typography.fonts.title.sizes[props.size]};
`;

interface ProductTagsAllergensProps {
  tags : Tag[];
  size? : keyof Theme['typography']['fonts']['default']['sizes'];
  isCentered? : boolean;
  style? : any;
}

function ProductTagsAllergens({
  tags,
  size = 'xxsmall',
  isCentered = false,
  style,
} : ProductTagsAllergensProps) {
  if (!tags.length) return null;

  return (
    <ProductTagsAllergensView isCentered={isCentered} style={style}>
      { tags.map((tag) => (
        <ProductTag key={tag.id} size={size}>
          { tag.name }
        </ProductTag>
      )) }
    </ProductTagsAllergensView>
  );
}

export default ProductTagsAllergens;
