import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style {
  theme?: Theme
}

const PageTitleButtons = styled.div<Style>`
  display: flex;
  margin: ${(props) => props.theme.layout.spacing.medium} auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    width: 100%;
    margin: ${(props) => props.theme.layout.spacing.small} auto;
    align-items: flex-start;
  }

  button {
    width: 24rem;
    max-width: 100%;
    margin: 0 0 ${(props) => props.theme.layout.spacing.xsmall};

    &:last-of-type {
      margin: 0;
    }
  }
`;

export default PageTitleButtons;
