import { useCallback } from 'react';

import { Supplier } from '#mrktbox/clerk/types';

import {
  createSupplier as createSupplierAPI,
  retrieveSuppliers as retrieveSuppliersAPI,
  retrieveSupplier as retrieveSupplierAPI,
  updateSupplier as updateSupplierAPI,
  deleteSupplier as deleteSupplierAPI,
} from '#mrktbox/clerk/api/mrktbox/suppliers';

import useAPI from '#mrktbox/clerk/hooks/api/useAPI';

function useSuppliersAPI() {
  const { processRequest } = useAPI();

  const createSupplier = useCallback(async (supplier : Supplier) => {
    return await processRequest(
      createSupplierAPI,
      { supplier },
    )
  }, [processRequest]);

  const retrieveSuppliers = useCallback(async () => {
    return await processRequest(
      retrieveSuppliersAPI,
      {},
    )
  }, [processRequest]);

  const retrieveSupplier = useCallback(async (supplierId : number) => {
    return await processRequest(
      retrieveSupplierAPI,
      { supplierId },
    )
  }, [processRequest]);

  const updateSupplier = useCallback(async (supplier : Supplier) => {
    return await processRequest(
      updateSupplierAPI,
      { supplier }
    )
  }, [processRequest]);

  const deleteSupplier = useCallback(async (supplier : Supplier) => {
    return await processRequest(
      deleteSupplierAPI,
      { supplier }
    )
  }, [processRequest]);

  return {
    createSupplier,
    retrieveSuppliers,
    retrieveSupplier,
    updateSupplier,
    deleteSupplier,
  };
}

export default useSuppliersAPI;
