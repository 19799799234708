import React, { createContext, useCallback, useState } from 'react';

type SidebarContent = React.ReactNode;

const SidebarContext = createContext({
  open : false,
  content : null as SidebarContent,
  openSidebar : (content : SidebarContent) => {},
  closeSidebar : () => {},
});

interface SidebarProviderProps {
  children : React.ReactNode;
}

export function SidebarProvider({ children } : SidebarProviderProps) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<SidebarContent>(null);

  const openSidebar = useCallback((content : SidebarContent) => {
    setContent(content);
    setOpen(true);
  }, []);

  const closeSidebar = useCallback(() => {
    setContent(null);
    setOpen(false);
  }, []);

  const context = {
    open,
    content,
    openSidebar,
    closeSidebar,
  };

  return (
    <SidebarContext.Provider value={context}>
      { children }
    </SidebarContext.Provider>
  );
}

export default SidebarContext;
