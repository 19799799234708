import { Currency } from '#mrktbox/clerk/types';

export function formatCurrency(currency: Currency): string {
  const s = currency.calculatedValue.toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

   return `$ ${s}`;
}

export function addCurrency(c1: Currency, c2: Currency): Currency {
  const newAmount = Math.round(c1.amount + c2.amount);
  return {
    amount: newAmount,
    calculatedValue: newAmount * c1.increment,
    currencyCode: c1.currencyCode,
    increment: c1.increment,
  };
}

export function subtractCurrency(c1: Currency, c2: Currency): Currency {
  const newAmount = Math.round(c1.amount - c2.amount);
  return {
    amount: newAmount,
    calculatedValue: newAmount * c1.increment,
    currencyCode: c1.currencyCode,
    increment: c1.increment,
  };
}

export function multiplyCurrency(c: Currency, factor: number): Currency {
  const newAmount = Math.round(c.amount * factor);
  return {
    amount: newAmount,
    calculatedValue: newAmount * c.increment,
    currencyCode: c.currencyCode,
    increment: c.increment,
  };
}
