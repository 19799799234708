import {
  Integration,
  Channel,
  Engine,
  Config,
  isIntegration,
  isEngine,
  isChannel,
  isConfig,
} from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

const INTEGRATIONS_PATH = 'integrations/';
const CHANNELS_PATH = 'channels/';
const ENGINES_PATH = 'engines/';
const CONFIGS_PATH = 'configs/';

interface IntegrationProps {
  integration : Integration;
}

interface ChannelProps {
  channel : Channel;
  integrations? : Integration;
}

interface ConfigProps {
  config : Config;
  integration? : Integration;
}

function parseConfig(config : any) : Config {
  if (!isConfig(config))
    throw new TypeError('provided config is not a Config Object');

  return config;
}

function parseConfigs(configs : any) : { [id : number] : Config } {
  const parsedConfigs = {} as { [id : number] : Config };
  for (const configId in configs) {
    if (typeof configId !== 'string')
      throw new TypeError('Config id is not a string');

    const id = parseInt(configId);
    if (isNaN(id))
      throw new TypeError('Config id is not a number');

    if (!isConfig(configs[id]))
      throw new TypeError('Config does not match Config type');

    parsedConfigs[id] = configs[id];
  }
  return parsedConfigs;
}

function parseIntegration(integration : any) : Integration {
  if (!isIntegration(integration))
    throw new TypeError('Integration is not an integration')

  return integration;
}

function parseIntegrations(integrations : any) : {
  [id : number] : Integration } {
  const parsedIntegrations = {} as { [id : number] : Integration };
  for (const integrationId in integrations) {

    const id = parseInt(integrationId);
    if (isNaN(id))
      throw new TypeError('Integration id is not a number');

    parsedIntegrations[id] = parseIntegration(integrations[id]);
  }
  return parsedIntegrations;
}

function parseChannel(channel : any) : Channel {
  if (typeof channel.id !== 'number')
    throw new TypeError('Channel id is not a number');

  if (!isChannel(channel))
    throw new TypeError('provided channel is not a Channel Object');

  return channel;
}

function parseChannels(channels : any) : { [id : number] : Channel } {
  const parsedChannels = {} as { [id : number] : Channel };
  for (const channelId in channels) {
    if (typeof channelId !== 'string')
      throw new TypeError('Channel id is not a string');

    const id = parseInt(channelId);
    if (isNaN(id))
      throw new TypeError('Channel id is not a number');

    parsedChannels[id] = parseChannel(channels[id]);
  }
  return parsedChannels;
}

function parseEngine(engine : any) : Engine {
  if (!isEngine(engine))
    throw new TypeError('provided engine is not an Engine Object');

  return engine;
}

function parseEngines(engines : any) : { [id : number] : Engine } {
  const parsedEngines = {} as { [id : number] : Engine };
  for (const engineId in engines) {
    if (typeof engineId !== 'string')
      throw new TypeError('Engine id is not a string');

    const id = parseInt(engineId);
    if (isNaN(id))
      throw new TypeError('Engine id is not a number');

    parsedEngines[id] = parseEngine(engines[id]);
  }
  return parsedEngines;
}

export async function createIntegration(
  { integration } : IntegrationProps,
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(INTEGRATIONS_PATH),
    methods.post,
    { integration },
    options,
  );

  try {
    return parseIntegration(response.integration);
  } catch {
    throw new DeserializationError(
      'Could not deserialize integration',
      response,
    );
  }
}

export async function retrieveIntegrations(
  input? : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(INTEGRATIONS_PATH),
    methods.get,
    undefined,
    options,
  );
  try {
    return parseIntegrations(response.integrations);
  } catch {
    throw new DeserializationError(
      'Could not deserialize integrations',
      response
    );
  }
}

export async function retrieveIntegration(
  { integrationId } : { integrationId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${integrationId}`),
    methods.get,
    undefined,
    options,
  );
  try {
    return parseIntegration(response.integration);
  } catch {
    throw new DeserializationError(
      'Could not deserialize integration',
      response
    )
  };
}

export async function deleteIntegration(
  { integration } : IntegrationProps,
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${integration.id}`),
    methods.delete,
    undefined,
    options,
  );

  return !!response?.success;
}

export async function addIntegrationToChannel(
  { channel, integration } : { channel : Channel, integration : Integration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${integration.id}/` +
      `${CHANNELS_PATH}${channel.id}`),
    methods.post,
    {},
    options,
  );

  return !!response;
}

export async function removeIntegrationFromChannel(
  { channel, integration } : { channel : Channel, integration : Integration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${integration.id}/` +
      `${CHANNELS_PATH}${channel.id}`),
    methods.delete,
    undefined,
    options,
  );

  return !!response;
}

export async function createChannel(
  { channel } : ChannelProps,
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(CHANNELS_PATH),
    methods.post,
    { channel },
    options,
  );
  try {
    return parseChannel(response.channel);
  } catch {
    throw new DeserializationError(
      'Could not deserialize channel',
      response,
    );
  }
}

export async function retrieveChannels(
  input? : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${CHANNELS_PATH}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseChannels(response.channels);
  } catch {
    throw new DeserializationError(
      'Could not deserialize channels',
      response
    )
  };
}

export async function retrieveChannel(
  { channelId } : { channelId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${CHANNELS_PATH}${channelId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseChannel(response.channel);
  } catch {
    throw new DeserializationError(
      'Could not deserialize channel',
      response,
    );
  }
}

export async function updateChannel(
  { channel } : { channel : Channel },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${CHANNELS_PATH}${channel.id}`),
    methods.put,
    { channel },
    options,
  );

  try {
    return parseChannel(response.channel);
  } catch {
    throw new DeserializationError(
      'Could not deserialize channel',
      response,
    );
  }
}

export async function retrieveEngines(
  input? : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${ENGINES_PATH}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseEngines(response.engines);
  } catch {
    throw new DeserializationError(
      'Could not deserialize engines',
      response
    )
  }
}

export async function retrieveEngine(
  { engineId } : {engineId : number},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${ENGINES_PATH}${engineId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseEngine(response.engine);
  } catch {
    throw new DeserializationError(
      'Could not deserialize engine',
      response
    )
  }
}

export async function createConfig(
  { config } : ConfigProps,
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${CONFIGS_PATH}`),
    methods.post,
    { config },
    options,
  );

  try {
    return parseConfig(response.config);
  } catch {
    throw new DeserializationError(
      'Could not deserialize config',
      response,
    );
  }
}

export async function retrieveConfigs(
  input? : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${CONFIGS_PATH}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseConfigs(response.config);
  } catch {
    throw new DeserializationError(
      'Could not deserialize config',
      response
    )
  }
}

export async function retrieveConfig(
  { configId } : { configId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${CONFIGS_PATH}${configId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseConfig(response.config);
  } catch{
    throw new DeserializationError(
      'Could not deserialize config',
      response,
    );
  }
}

export async function updateConfig(
  { config } : ConfigProps,
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${CONFIGS_PATH}${config.id}`),
    methods.put,
    { config },
    options,
  );

  try {
    return parseConfig(response.config);
  } catch {
    throw new DeserializationError(
      'Could not deserialize config',
      response,
    );
  }
}

export async function deleteConfig(
  { config } : ConfigProps,
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${INTEGRATIONS_PATH}${CONFIGS_PATH}${config.id}`),
    methods.delete,
    undefined,
    options,
  );

  return !!response?.success;
}
