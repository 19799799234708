import { useCallback } from 'react';

import { Integration, Channel, Config } from '#mrktbox/clerk/types';

import {
  createIntegration as createIntegrationAPI,
  retrieveIntegrations as retrieveIntegrationsAPI,
  retrieveIntegration as retrieveIntegrationAPI,
  deleteIntegration as deleteIntegrationAPI,
  retrieveChannels as retrieveChannelsAPI,
  retrieveChannel as retrieveChannelAPI,
  updateChannel as updateChannelAPI,
  addIntegrationToChannel as addIntegrationToChannelAPI,
  removeIntegrationFromChannel as removeIntegrationFromChannelAPI,
  retrieveEngines as retrieveEnginesAPI,
  retrieveConfigs as retrieveConfigsAPI,
  createConfig as createConfigAPI,
  updateConfig as updateConfigAPI,
  deleteConfig as deleteConfigAPI,
} from '#mrktbox/clerk/api/mrktbox/integrations';
import useAPI from '#mrktbox/clerk/hooks/api/useAPI';

function useIntegrationsAPI() {
  const { processRequest } = useAPI();

  const createIntegration = useCallback(
    async (integration : Integration) => {
      return await processRequest(
        createIntegrationAPI,
        { integration },
      )
  },
  [processRequest],)

  const retrieveIntegrations = useCallback(async () => {
    return await processRequest(
      retrieveIntegrationsAPI,
      {},
    )
  }, [processRequest])

  const retrieveIntegration = useCallback(async (integrationId : number) => {
    return await processRequest(
      retrieveIntegrationAPI,
      { integrationId },
    )
  }, [processRequest])

  const deleteIntegration = useCallback(async (integration : Integration) => {
    return await processRequest(
      deleteIntegrationAPI,
      { integration },
    )
  }, [processRequest])

  const removeIntegrationFromChannel = useCallback(
    async (integration : Integration, channel : Channel) => {
      return await processRequest(
        removeIntegrationFromChannelAPI,
        { channel, integration },
      )
    },
    [processRequest],
  )

  const addIntegrationToChannel = useCallback(
    async (integration : Integration, channel : Channel) => {
      return await processRequest(
        addIntegrationToChannelAPI,
        { channel, integration },
      )
    },
    [processRequest],
  )

  const retrieveChannels = useCallback(async () => {
    return await processRequest(
      retrieveChannelsAPI,
      {},
    )
  }, [processRequest])

  const retrieveChannel = useCallback(async (channelId : number) => {
    return await processRequest(
      retrieveChannelAPI,
      { channelId },
    )
  }, [processRequest])

  const updateChannel = useCallback(async (channel : Channel) => {
    return await processRequest(
      updateChannelAPI,
      { channel },
    )
  }, [processRequest])

  const retrieveEngines = useCallback(async () => {
    return await processRequest(
      retrieveEnginesAPI,
      {},
    )
  }, [processRequest])

  const retrieveEngine = useCallback(async (engineId : number) => {
    return await processRequest(
      retrieveEnginesAPI,
      { engineId },
    )
  }, [processRequest])

  const createConfig = useCallback(
    async (integration : Integration, config : Config) => {
      return await processRequest(
        createConfigAPI,
        {
          config : {
            ...config,
            ...integration && { integrationId : integration.id }
          }
        },
      )
  },
  [processRequest])

  const retrieveConfigs = useCallback(async () => {
    return await processRequest(
      retrieveConfigsAPI,
      {},
    )
  }, [processRequest])

  const updateConfig = useCallback( async (config : Config) => {
    return await processRequest(
      updateConfigAPI,
      { config },
    )
  }, [processRequest])

  const deleteConfig = useCallback( async (config : Config) => {
    return await processRequest(
      deleteConfigAPI,
      { config },
    )
  }, [processRequest])

  return {
    createIntegration,
    retrieveIntegrations,
    retrieveIntegration,
    deleteIntegration,
    retrieveChannels,
    retrieveChannel,
    updateChannel,
    addIntegrationToChannel,
    removeIntegrationFromChannel,
    retrieveEngines,
    retrieveEngine,
    createConfig,
    retrieveConfigs,
    deleteConfig,
    updateConfig,
  }
}

export default useIntegrationsAPI;
