import styled from '@emotion/styled';

import BackgroundImage from '#materials/BackgroundImage';

const BackgroundView = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 76.8rem;
  display: flex;
`

interface BackgroundProps {
  imageUrl? : string;
  children? : React.ReactNode;
}

function Background({ imageUrl, children } : BackgroundProps) {
  return (
    <BackgroundView>
      { imageUrl ?
        (<BackgroundImage imageUrl={imageUrl}>{children}</BackgroundImage>)
        : null
      }
    </BackgroundView>
  );
}

export default Background;
