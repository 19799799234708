import React from 'react';
import styled from '@emotion/styled';

import { Address } from '#mrktbox/types';

import { Theme } from '#types';

import TextArea from '#materials/TextArea';

interface Style { theme? : Theme; }

const NoteView = styled.div<Style>`
  margin-top: ${(props) => props.theme.layout.spacing.small};
`;

interface OrderAddressProps {
  address : Address;
  note? : string;
  setNote? : (note : string) => void;
  editing? : boolean;
  isCard : boolean;
  children? : React.ReactNode;
}

function OrderAddress({
  address,
  note,
  editing = false,
  isCard,
  setNote,
  children,
} : OrderAddressProps
) {
  return (
    <>
      <p className={isCard ? 'title' : ''}>{ address.street }</p>
      <p>
        {address.city}, {address.postal}
      </p>
      { (note !== undefined) && (
        <NoteView>
          <TextArea
            label="Delivery Note"
            name="note"
            value={note}
            onChange={setNote}
            disabled={!editing}
          />
        </NoteView>
      ) }
      { children }
    </>
  );
}

export default OrderAddress;
