import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useRequests from '#hooks/useRequests';

import ButtonLink from '#materials/ButtonLink';
import ButtonStyled from '#materials/ButtonStyled';
import { AlertCircle } from '#materials/icons';

import PageContent from '#components/page/PageContent';
import PageTitle from '#components/page/PageTitle';
import OrderList from '#components/orders/OrderList';

interface Style { theme? : Theme; }
interface AlertStyle extends Style {
  error? : boolean;
}

const Alert = styled.p<AlertStyle>`
  display: flex;
  margin: 0 0 ${(props) => props.theme.layout.spacing.medium};
  gap: ${(props) => props.theme.layout.spacing.xxsmall};
  align-items: top;
  justify-content: center;

  color: ${(props) => props.error
    ? props.theme.palette.background.text.alert
    : props.theme.palette.background.text.primary};

  text-align: left;

  & > svg {
    flex-shrink: 0;
  }

  & > span {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
    line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
  }
`;

const OrdersView = styled.div<Style>`
  margin: 0 0 ${(props) => props.theme.layout.spacing.large};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    margin: 0 0 ${(props) => props.theme.layout.spacing.medium};
  }
`;

const ButtonView = styled.div<Style>`
  display: flex;
  gap: ${(props) => props.theme.layout.spacing.xsmall};
  width: fit-content;
  margin: auto;
`;

function Orders() {
  const {
    getOpenOrders,
    getClosedOrders,
    getDraftOrders,
    getUpcomingOrders,
    getRecentOrders,
    hasCardOnFile,
  } = useRequests();

  const [showUpcoming, setShowUpcoming] = useState(0);
  const [showHistory, setShowHistory] = useState(0);

  const open = useMemo(() => getOpenOrders(), [getOpenOrders]);
  const past = useMemo(() => getClosedOrders(), [getClosedOrders]);
  const drafts = useMemo(() => getDraftOrders(), [getDraftOrders]);
  const upcoming = useMemo(
    () => getUpcomingOrders({ pages : showUpcoming }),
    [getUpcomingOrders, showUpcoming],
  );
  const recent = useMemo(
    () => getRecentOrders({ pages : showHistory }),
    [getRecentOrders, showHistory],
  );

  const needsCard = useMemo(() => (
    !hasCardOnFile()
      && upcoming.some((order) => (!order.serviceChannel?.requireCheckout
        && !order.paid))
  ), [hasCardOnFile, upcoming]);

  return (
    <>
      { !!drafts.length && (
        <OrdersView>
          <PageTitle
            title="Draft Orders"
            subtitle="Finish and submit your orders"
          />
          <OrderList orders={drafts} />
        </OrdersView>
      ) }
      <OrdersView>
        <PageTitle
          title="Upcoming Orders"
          subtitle="Manage your current orders"
        />
        { needsCard && (
          <Alert error>
            <AlertCircle size={16}/>
            <span>
              { 'You have upcoming orders that require a ' }
              <ButtonLink
                href='/credit-cards/'
                colour='alert'
              >
                card on file
              </ButtonLink>
              { ' for payment.' }
            </span>
          </Alert>
        ) }
        { !!upcoming.length
          ? (
            <>
              <OrderList orders={upcoming} />
              { !((upcoming.length === open.length) && (showUpcoming < 1)) && (
                <ButtonView>
                  <ButtonStyled
                    onClick={() => setShowUpcoming(showUpcoming + 1)}
                    disabled={upcoming.length === open.length}
                  >
                    Show More
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={() => setShowUpcoming(showUpcoming - 1)}
                    disabled={showUpcoming < 1}
                  >
                    Show Less
                  </ButtonStyled>
                </ButtonView>
              ) }
            </>
          ) : (
            <PageContent>
              <p>Looks like you don't have any upcoming orders.</p>
            </PageContent>
          )
        }
      </OrdersView>
      <OrdersView>
        <PageTitle
          title='Order History'
          subtitle={'Reorder past orders, ' +
            'or add order ratings & comments.'}
          style={{ marginTop : '8rem' }}
        />
        { past.length
          ? (
            <>
              <OrderList orders={recent} />
              { !((recent.length === past.length) && (showHistory < 1)) && (
                <ButtonView>
                  <ButtonStyled
                    onClick={() => setShowHistory(showHistory + 1)}
                    disabled={recent.length === past.length}
                  >
                    Show More
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={() => setShowHistory(showHistory - 1)}
                    disabled={showHistory < 1}
                  >
                    Show Less
                  </ButtonStyled>
                </ButtonView>
              ) }
            </>
          ) : (
            <PageContent>
              <p>Looks like you don't have any past orders.</p>
            </PageContent>
          )
        }
      </OrdersView>
    </>
  );
}

export default Orders;
