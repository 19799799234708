import React from 'react';

import { Address, Customer } from '#mrktbox/types';

import AddressCard from '#slices/AddressCard';

interface AddressListProps {
  addresses : Address[];
  customer : Customer;
}

function AddressList({
  addresses,
  customer,
} : AddressListProps) {
  return (addresses.map((address) => {
    return (
      <AddressCard
        key={address.id}
        address={address}
        customer={customer}
      />
    )
  }));
}

export default AddressList;
