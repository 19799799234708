import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import Label from '#materials/Label';

interface Style { theme? : Theme; }

const TextAreaView = styled.textarea<Style>`
  width: 100%;
  padding: ${(props) => props.theme.layout.spacing.xsmall};
  resize: vertical;
  overflow: hidden;
  opacity: 0.85;

  color: ${(props) => props.theme.palette.background.text.primary};
  background-color: ${(props) => props.theme.palette.background.fill};
  border-color: ${(props) => props.theme.palette.border};
  border-width: ${(props) => props.theme.layout.border.width};
  border-style: solid;
  border-radius: ${(props) => props.theme.layout.border.radius};

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};

  &:active,
  &:focus {
    opacity: 1;
    color: ${(props) => props.theme.palette.background.text.primary};
    border-color: ${(props) => props.theme.palette.border};
    outline: none;
  }

  &::placeholder {
    opacity: 0.85;
    color: ${(props) => props.theme.palette.background.text.primary};
  }

  &:disabled {
    opacity: 0.85;
    color: ${(props) => props.theme.palette.background.text.primary};
    border-color: ${(props) => props.theme.palette.border};
  }
`;

interface TextAreaProps {
  label? : string;
  name? : string;
  value? : string | number;
  onChange? : (value : string) => void;
  error? : string;
  showLabel? : boolean;
  disabled? : boolean;
  readOnly? : boolean;
  required? : boolean;
  placeholder? : string;
  style? : React.CSSProperties;
}

function TextArea({
  label,
  name,
  value,
  onChange,
  error,
  showLabel = true,
  disabled = false,
  readOnly = false,
  required = false,
  placeholder = '',
  style = undefined,
} : TextAreaProps){
  const handleChange = useCallback(
    (e : React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <Label
      htmlFor={name}
      text={label}
      value={value}
      required={required}
      errMsg={error}
      showLabel={showLabel}
      disabled={disabled}
      style={style}
      isTextarea={true}
    >
      <TextAreaView
        rows={0}
        aria-label={label}
        id={name}
        name={name}
        value={value || ''}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        onChange={handleChange}
      />
    </Label>
  )
}

export default TextArea;
