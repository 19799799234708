import React from 'react';
import {
  Icon,
  AlertCircle as AlertCircleIcon,
  ArrowRight as ArrowRightIcon,
  Calendar as CalendarIcon,
  Check as CheckIcon,
  CheckCircle as CheckCircleIcon,
  ChevronRight as ChevronRightIcon,
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
  Coffee as CoffeeIcon,
  Clock as ClockIcon,
  CreditCard as CreditCardIcon,
  Grid as GridIcon,
  Info as InfoIcon,
  MapPin as MapPinIcon,
  Minus as MinusIcon,
  Phone as PhoneIcon,
  Plus as PlusIcon,
  PlusCircle as PlusCircleIcon,
  RefreshCw as RefreshCwIcon,
  Search as SearchIcon,
  ShoppingBag as ShoppingBagIcon,
  Truck as TruckIcon,
  LogOut as LogOutIcon,
  Users as UsersIcon,
  X as XIcon,
  XCircle as XCircleIcon,
} from 'react-feather';

export { default as ArrowRightLong }  from './ArrowRightLong';
export { default as ChevronLeftCircle }  from './ChevronLeftCircle';
export { default as Hamburger }  from './Hamburger';
export { default as UserCircle }  from './UserCircle';

interface CoreIconProps extends IconProps {
  Icon: Icon;
  color?: string;
}

interface IconProps {
  size?: number;
  strokeWidth?: number;
  colour?: string;
}

function IconComponent({ Icon, size, strokeWidth }: CoreIconProps) {

  return (
    <Icon
      size={size ?? '100%'}
      strokeWidth={strokeWidth ?? 2}
    />
  );
}

function generateIconComponent(Icon: Icon) {
  return (props: IconProps) => (
    <IconComponent Icon={Icon} color={props.colour} {...props} />
  );
}

export const AlertCircle = generateIconComponent(AlertCircleIcon);
export const ArrowRight = generateIconComponent(ArrowRightIcon);
export const Calendar = generateIconComponent(CalendarIcon);
export const Check = generateIconComponent(CheckIcon);
export const CheckCircle = generateIconComponent(CheckCircleIcon);
export const ChevronRight = generateIconComponent(ChevronRightIcon);
export const ChevronDown = generateIconComponent(ChevronDownIcon);
export const ChevronUp = generateIconComponent(ChevronUpIcon);
export const Coffee = generateIconComponent(CoffeeIcon);
export const Clock = generateIconComponent(ClockIcon);
export const CreditCard = generateIconComponent(CreditCardIcon);
export const Grid = generateIconComponent(GridIcon);
export const Info = generateIconComponent(InfoIcon);
export const MapPin = generateIconComponent(MapPinIcon);
export const Minus = generateIconComponent(MinusIcon);
export const Phone = generateIconComponent(PhoneIcon);
export const Plus = generateIconComponent(PlusIcon);
export const PlusCircle = generateIconComponent(PlusCircleIcon);
export const Refresh = generateIconComponent(RefreshCwIcon);
export const Search = generateIconComponent(SearchIcon);
export const ShoppingBag = generateIconComponent(ShoppingBagIcon);
export const Truck = generateIconComponent(TruckIcon);
export const Users = generateIconComponent(UsersIcon);
export const X = generateIconComponent(XIcon);
export const XCircle = generateIconComponent(XCircleIcon);
export const LogOut = generateIconComponent(LogOutIcon);
