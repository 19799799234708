import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { User, Customer } from '#mrktbox/types';
import { useAuth, useNotes } from '#mrktbox';

import CustomerContext from 'context/CustomerContext';

import useRequests from '#hooks/useRequests';

const LoginContext = createContext({
  signing : false,
  user : null as User | null,
  customer : null as Customer | null,
  login : async (
    username : string,
    password : string
  ) => ({
    user : null,
    error : true,
  }) as { user : User | null, error : boolean },
});

interface LoginProviderProps {
  children : React.ReactNode;
}

export function LoginProvider({ children } : LoginProviderProps) {
  const { customer, user } = useContext(CustomerContext);

  const { claimItems } = useRequests();
  const { login } = useAuth();
  const { refreshNotes } = useNotes();

  const [waiting, setWaiting] = useState(false);
  const [claim, setClaim] = useState(false);

  const signin = useCallback(async (username : string, password : string) => {
    if (waiting) return { user : null, error : true };
    setWaiting(true);
    const result = await login(username, password, []);
    setWaiting(false);
    if (result.user) setClaim(true);
    return result;
  }, [waiting, login]);

  const claimGuest = useCallback(async () => {
    if (!claim || !user || !customer) return;
    setClaim(false);
    await claimItems(customer, { token : user.token });
    refreshNotes();
  }, [
    claim,
    user,
    customer,
    claimItems,
    refreshNotes,
  ]);

  useEffect(() => { claimGuest(); }, [claimGuest]);

  const context = {
    signing : waiting || claim,
    user,
    customer,
    login : signin,
  };

  return (
    <LoginContext.Provider value={context}>
      { children }
    </LoginContext.Provider>
  );
}

export default LoginContext;
