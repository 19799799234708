import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useFocus from '#hooks/useFocus';
import useSidebar from '#hooks/useSidebar';

import Transition, { TransitionGroup } from '#materials/Transition';

import SidebarClose from '#components/sidebar/SidebarClose';

interface Style { theme? : Theme; }
interface SidebarStyle extends Style { show : boolean; }

const SidebarView = styled.aside<SidebarStyle>`
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 0;
  max-width: ${(props) => props.theme.view.breakpoints.mobile};
  overflow: hidden;

  background-color: ${(props) => props.theme.palette.background.fill};

  opacity: ${(props) => (props.show ? 1 : 0)};
  transform: translateX(${(props) => (props.show ? '0' : '100%')});
  transition: all 0.25s ease;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    width: 100%;
  }

  > div {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;

const SidebarOverlayView = styled.div<Style>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 90;
  background-color: ${(props) => props.theme.palette.overlay};
`;

function Sidebar() {
  const { open, content, closeSidebar } = useSidebar();
  const sidebar = useRef<HTMLDivElement>(null)

  const { takeFocus, returnFocus } = useFocus({
    container : sidebar,
    onEscape : closeSidebar,
    focusInput : false,
  });

  return (
    <>
      <SidebarView ref={sidebar} show={open}>
        <div>
          <SidebarClose />
          { content }
        </div>
      </SidebarView>
      <TransitionGroup component={null}>
        { open && (
          <Transition
            key="sidebar"
            classNames="overlay"
            timeout={250}
            onEntered={takeFocus}
            onExited={returnFocus}
          >
            <SidebarOverlayView onClick={closeSidebar} />
          </Transition>
        ) }
      </TransitionGroup>
    </>
  );
}

export default Sidebar;
