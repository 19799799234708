import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { Partial } from '#mrktbox/clerk/types';

import FormContext, {
  FormState,
  FormContextState,
  formReducer,
} from '#mrktbox/clerk/context/FormContext';

interface useFormProps<T extends FormState> {
  init? : T;
  onChange? : (newState : T) => void;
  key? : string;
  editing? : boolean;
  validators? : ((state : T) => { [key : string] : string } | null)[];
}

interface useFormInitProps<T extends FormState> extends useFormProps<T> {
  init : T;
}

interface useFormHook<T extends FormState> {
  state? : T
  dispatch : React.Dispatch<Partial<T>>;
  reset : () => void;
  editing : boolean;
  valid : boolean;
  setValid : (valid : boolean) => void;
  errors : { [key : string] : string[] };
};

interface useFormInitHook<T extends FormState> extends useFormHook<T> {
  state : T;
}

export function useFormContext<T extends FormState>() {
  return useContext<FormContextState<T>>(FormContext);
};

function useForm<T extends FormState>(props : useFormInitProps<T>) :
  useFormInitHook<T>;
function useForm<T extends FormState>(props? : useFormProps<T>) :
  useFormHook<T>;

function useForm<T extends FormState>({
  init,
  onChange,
  key : formKey,
  editing : edit = true,
  validators,
} : useFormProps<T> = {}) : useFormHook<T> {
  const {
    state : contextState,
    dispatch : contextDispatch,
    reset : contextReset,
    onChange : contextOnChange,
    editing : contextEditing,
    valid : contextValid,
    setValid : setValidContext,
    validation : contextValidation,
    errors : contextErrors,
    exists : contextExists,
  } = useContext<FormContextState<T>>(FormContext);

  const [state, dispatch] = useReducer(
    formReducer<T>,
    init,
  );
  const [key, setKey] = useState(formKey ?? '');
  const [editing, setEditing] = useState(edit);
  const [errors, setErrors] = useState<{ [key : string] : string[] }>({});
  const [valid, setValid] = useState(true);

  const reset = useCallback(() => {
    if (formKey === undefined) setKey(key === '' ? '_' : '');
  }, [formKey, key, setKey]);

  useEffect(() => {
    if (formKey !== key) {
      dispatch(init);
      setKey(formKey ?? '');
    }
  }, [formKey, key, init]);

  useEffect(() => {
    if ((edit !== editing) && !edit) dispatch(init);
    setEditing(edit);
  }, [edit, editing, init, setEditing]);

  useEffect(() => {
    if (onChange && state) onChange(state);
  }, [state, onChange]);

  useEffect(() => {
    if (!contextExists || !contextState) return;
    if (contextOnChange) contextOnChange(contextState);
    if (onChange) onChange(contextState);
  }, [contextExists, contextState, contextOnChange, onChange]);

  useEffect(() => {
    // TODO: validate/report state exists
    const applicableState = contextState ?? state;
    if (!applicableState) return;
    if (!validators) {
      setErrors(contextErrors);
      return;
    }

    let stateValid = contextValidation;
    const validatorErrors = validators.reduce((acc, validator) => {
      const newErrors = validator(applicableState);
      if (newErrors) {
        stateValid = false;
        return Object.entries(newErrors).reduce((a, [k, v]) => {
          if (acc[k]) a[k] = acc[k].concat(newErrors[k]);
          else a[k] = [newErrors[k]];
          return a;
        }, {} as { [key : string] : string[] });
      }
      return acc;
    }, contextErrors as { [key : string] : string[] });

    if (stateValid !== contextValid) setValidContext(stateValid);
    if (stateValid !== valid) setValid(stateValid);
    setErrors(validatorErrors);
  }, [
    validators,
    state,
    valid,
    contextState,
    contextValid,
    contextValidation,
    setValidContext,
    contextErrors,
  ]);

  useEffect(() => {
    if (contextExists) {
      if (init !== undefined) {
        console.warn('Initial state may not be defined inside of form context');
      }
      if (!editing) {
        console.warn('Editing may not be defined inside of form context');
      }
      if (formKey !== undefined) {
        console.warn('Key may not be defined inside of form context');
      }
    } else {
      if (init === undefined) {
        console.error('Initial state must be defined outside of form context');
      }
    }
  }, [init, editing, formKey, contextExists]);

  return contextExists
    ? {
      state : contextState,
      dispatch : contextDispatch,
      reset : contextReset,
      editing : contextEditing,
      valid : contextValid,
      setValid : setValidContext,
      errors,
    }
    : {
      state,
      dispatch,
      reset,
      editing,
      valid,
      setValid : () => {},
      errors,
    };
}

export default useForm;
