import React, { createContext, useCallback, useState } from 'react';

type Modal = React.ReactNode;

const ModalContext = createContext({
  open : false,
  modal : null as Modal,
  openModal : (modal : Modal) => {},
  closeModal : () => {},
});

interface ModalProviderProps {
  children : React.ReactNode;
}

export function ModalProvider({ children } : ModalProviderProps) {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState<Modal>(null);

  const openModal = useCallback((modal : Modal) => {
    setModal(modal);
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModal(null);
    setOpen(false);
  }, []);

  const context = {
    open : open,
    modal,
    openModal,
    closeModal,
  };

  return (
    <ModalContext.Provider value={context}>
      { children }
    </ModalContext.Provider>
  );
}

export default ModalContext;
