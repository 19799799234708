import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';

import { isProductLoaded } from '#mrktbox/types';
import {
  useParams,
  useNavigation,
  useSearchParams,
  useProducts,
} from '#mrktbox';
import { listRecords } from '#mrktbox/utils';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';
import useChat from '#hooks/useChat';
import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';


import Main from '#components/page/Main';
import Content from '#components/page/Content';
import ScreenreaderTitle from '#components/page/ScreenreaderTitle';
import ProductImage from '#components/products/ProductImage';
import ProductItem from '#components/products/ProductItem';

interface Style {
  theme? : Theme;
}

const ProductPageView = styled.div<Style>`
  display: flex;
  height: 100%;
  align-items: stretch;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

const ProductPageImage = styled.div<Style>`
  display: flex;
  flex: 1 1 auto;
  height: 100%;

  background-color: ${(props) => props.theme.palette.background.fill};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    display: none;
  }
`;

const ProductPageContent = styled.div<Style>`
  width: 64rem;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;

  background-color: ${(props) => props.theme.palette.background.fill};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    width: 100%;
    flex: 1 1 auto;
  }
`;

function ProductPage() {
  const { navigate } = useNavigation();
  const [searchParams] = useSearchParams();
  const urlParams = useParams<{ productId? : string; }>();
  const { brand } = useConfig();
  const { setBottom } = useChat();
  const { refreshProduct } = useProducts();
  const { allProducts : products, getProductSlug } = useCatalogue();
  const { currentOrder } = useRequests();

  const [loaded, setLoaded] = useState(false);
  const [itemInit, setItemInit] = useState(false);
  const [itemTimeout, setItemTimeout] = useState(false);

  const parsedId = parseInt(urlParams.productId || '0');
  const id = isNaN(parsedId) ? urlParams.productId : parsedId;

  const itemId = parseInt(searchParams.get('lineItemId') ?? '');
  const lineItemId = isNaN(itemId) ? undefined : itemId;

  const product = useMemo(() => {
    const p = typeof id === 'number'
      ? (products?.[id] ?? null)
      : (listRecords(products).find((p) => id === getProductSlug(p))
        ?? null)

    if (loaded || !p?.id) return p;
    const productId = p.id;

    if (!isProductLoaded(p)) refreshProduct(productId);
    setLoaded(true);
    return p;
  }, [id, loaded, products, refreshProduct, getProductSlug]);

  const lineItem = useMemo(() => (
    lineItemId ? (currentOrder?.lineItems[lineItemId] ?? null) : null
  ), [currentOrder, lineItemId]);

  const [quantity, setQuantity] = useState<number>(
    lineItem ? lineItem.quantity : 1,
  );

  useEffect(() => {
    setBottom(80)
    return () => { setBottom(20) }
  });

  useEffect(() => {
    if (id === undefined) navigate('/');
  }, [id, navigate]);

  useEffect(() => {
    if (!lineItemId) {
      setItemTimeout(false);
      setItemInit(false);
      return;
    }

    if (!itemInit) {
      setTimeout(() => setItemTimeout(true), 2000);
      setItemInit(true);
    }
    if (itemTimeout && lineItemId && !lineItem) navigate('/');
  }, [lineItem, lineItemId, itemInit, itemTimeout, navigate]);

  const itemLoading = !product || (lineItemId && !lineItem);

  return (
    <>
      <Helmet>
        <title>Menu - { product ? product.name : brand.title }</title>
      </Helmet>
      <Content hasFooter={false}>
        <Main fullHeight padBottom={false}>
          { product &&
            <ScreenreaderTitle>{ product.name }</ScreenreaderTitle>
          }
          <ProductPageView>
            <ProductPageImage>
              <ProductImage product={product} variant="full" />
            </ProductPageImage>
            <ProductPageContent>
              { !itemLoading &&
                <ProductItem
                  product={product}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  lineItem={lineItem}
                />
              }
            </ProductPageContent>
          </ProductPageView>
        </Main>
      </Content>
    </>
  );
}

export default ProductPage;
