import { useCallback } from 'react';

import {
  Image,
  Service,
  ServiceChannel,
  Location,
  LocationIntegration,
  ExternalLocation,
} from '#mrktbox/clerk/types';

import {
  createService as createServiceAPI,
  retrieveServices as retrieveServicesAPI,
  retrieveService as retrieveServiceAPI,
  updateService as updateServiceAPI,
  deleteService as deleteServiceAPI,
  createServiceChannel as createServiceChannelAPI,
  retrieveServiceChannels as retrieveServiceChannelsAPI,
  retrieveServiceChannel as retrieveServiceChannelAPI,
  updateServiceChannel as updateServiceChannelAPI,
  deleteServiceChannel as deleteServiceChannelAPI,
  addServiceChannelToService as addServiceChannelToServiceAPI,
  removeServiceChannelFromService as removeServiceChannelFromServiceAPI,
  createLocation as createLocationAPI,
  retrieveLocations as retrieveLocationsAPI,
  retrieveLocation as retrieveLocationAPI,
  updateLocation as updateLocationAPI,
  deleteLocation as deleteLocationAPI,
  retrieveExternalLocations as retrieveExternalLocationsAPI,
  createLocationIntegration as createLocationIntegrationAPI,
  deleteLocationIntegration as deleteLocationIntegrationAPI,
  createLocationImageUpload as createLocationImageUploadAPI,
  pullLocationImage as pullLocationImageAPI,
  removeImageFromLocation as removeImageFromLocationAPI,
} from '#mrktbox/clerk/api/mrktbox/services';
import useAPI from '#mrktbox/clerk/hooks/api/useAPI';
import useProcessRequest from '#mrktbox/clerk/hooks/api/useProcessRequest';

function useServiceAPI() {
  const { processRequest } = useAPI();
  const process = useProcessRequest();

  const createService = useCallback(async (service : Service) => {
    return await processRequest(
      createServiceAPI,
      { service },
    )
  }, [processRequest]);

  const retrieveServices = useCallback(async () => {
    return await processRequest(
      retrieveServicesAPI,
      {},
    )
  }, [processRequest]);

  const retrieveService = useCallback(async (serviceId : number) => {
    return await processRequest(
      retrieveServiceAPI,
      { serviceId },
    )
  }, [processRequest]);

  const updateService = useCallback(async (service : Service) => {
    return await processRequest(
      updateServiceAPI,
      { service },
    )
  }, [processRequest]);

  const deleteService = useCallback(async (service : Service) => {
    return await processRequest(
      deleteServiceAPI,
      { service },
    )
  }, [processRequest]);

  const createServiceChannel = useCallback(
    async (serviceChannel : ServiceChannel) => {
      return await processRequest(
        createServiceChannelAPI,
        { serviceChannel },
      )
    },
    [processRequest]
  );

  const retrieveServiceChannels = useCallback(async () => {
    return await processRequest(
      retrieveServiceChannelsAPI,
      {},
    )
  }, [processRequest]);

  const retrieveServiceChannel = useCallback(
    async (serviceChannelId : number) => {
      return await processRequest(
        retrieveServiceChannelAPI,
        { serviceChannelId },
      )
    },
    [processRequest]
  );

  const updateServiceChannel = useCallback(
    async (serviceChannel : ServiceChannel) => {
      return await processRequest(
        updateServiceChannelAPI,
        { serviceChannel },
      )
    },
    [processRequest]
  );

  const deleteServiceChannel = useCallback(
    async (serviceChannel : ServiceChannel) => {
      return await processRequest(
        deleteServiceChannelAPI,
        { serviceChannel },
      )
    },
    [processRequest]
  );

  const addServiceChannelToService = useCallback(
    async ( service : Service, serviceChannel : ServiceChannel ) => {
      return await processRequest(
        addServiceChannelToServiceAPI,
        { service, serviceChannel },
      )
    },
    [processRequest]
  );

  const removeServiceChannelFromService = useCallback(
    async ( service : Service, serviceChannel : ServiceChannel ) => {
      return await processRequest(
        removeServiceChannelFromServiceAPI,
        { service, serviceChannel },
      )
    },
    [processRequest]
  );

  const createLocation = useCallback(async (location : Location) => {
    return await process(
      createLocationAPI,
      { location },
    )
  }, [process]);

  const retrieveLocations = useCallback(async () => {
    return await process(
      retrieveLocationsAPI,
      {},
    )
  }, [process]);

  const retrieveLocation = useCallback(async (locationId : number) => {
    return await process(
      retrieveLocationAPI,
      { locationId },
    )
  }, [process]);

  const updateLocation = useCallback(async (location : Location) => {
    return await process(
      updateLocationAPI,
      { location },
    )
  }, [process]);

  const deleteLocation = useCallback(async (location : Location) => {
    return await process(
      deleteLocationAPI,
      { location },
    )
  }, [process]);

  const retrieveExternalLocations = useCallback(async () => {
    return await process(
      retrieveExternalLocationsAPI,
      {},
    )
  }, [process]);

  const createLocationIntegration = useCallback(async (
    location : Location,
    externalLocation : ExternalLocation,
  ) => {
    return await process(
      createLocationIntegrationAPI,
      { location, externalLocation },
    )
  }, [process]);

  const deleteLocationIntegration = useCallback(async (
    location : Location,
    locationIntegration : LocationIntegration,
  ) => {
    return await process(
      deleteLocationIntegrationAPI,
      { location, locationIntegration },
    )
  }, [process]);

  const createLocationImageUpload = useCallback(
    async (location : Location) => {
      return await process(
        createLocationImageUploadAPI,
        { location },
      )
    },
    [process]
  );

  const pullLocationImage = useCallback(
    async (location : Location, image : Image) => {
      return await process(
        pullLocationImageAPI,
        { location, image },
      )
    },
    [process]
  );

  const removeImageFromLocation = useCallback(
    async (location : Location, image : Image) => {
      return await process(
        removeImageFromLocationAPI,
        { location, image },
      )
    },
    [process]
  );

  return {
    createService,
    retrieveServices,
    retrieveService,
    updateService,
    deleteService,
    createServiceChannel,
    retrieveServiceChannels,
    retrieveServiceChannel,
    updateServiceChannel,
    deleteServiceChannel,
    addServiceChannelToService,
    removeServiceChannelFromService,
    createLocation,
    retrieveLocations,
    retrieveLocation,
    updateLocation,
    deleteLocation,
    retrieveExternalLocations,
    createLocationIntegration,
    deleteLocationIntegration,
    createLocationImageUpload,
    pullLocationImage,
    removeImageFromLocation,
  }
}

export default useServiceAPI;
