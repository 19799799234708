import { useContext, useCallback } from 'react';

import {
  Address,
  Service,
  ServiceChannel,
  Product,
  Subscription,
  DraftOrder,
  DraftCustomOrder,
} from '#mrktbox/types';
import {
  useServices,
  useRoutes,
  useScheduling,
  useCards,
  useOrders,
  useOptions,
  useSubscriptions,
  useNotes,
  useUsers,
} from '#mrktbox';
import { listRecords } from '#mrktbox/utils';

import RequestContext from '#context/RequestContext';
import CatalogueContext from '#context/CatalogueContext';

import useCustomer from '#hooks/useCustomer';

import { scrollToId } from '#utils/scroll';

const SOON = 14 * 24 * 60 * 60 * 1000;
const RECENT = 14 * 24 * 60 * 60 * 1000;
const ORDERS_PER_PAGE = 6;

function useRequests() {
  const {
    serviceChannel,
    location,
    timeSlot,
    availableSchedules : availSchedules,
    currentOrder,
    addItem,
    updateItem,
    setTimeSlot,
    getOrder,
    ...context
  } = useContext(RequestContext);

  const { serviceChannels, locations } = useServices();
  const { routes } = useRoutes();
  const {
    services,
    loaded : servicesLoaded,
    load : loadServices,
  } = useServices();
  const {
    loaded : schedulingLoaded,
    load : loadScheduling,
    checkSchedule,
  } = useScheduling();
  const { creditCards, load : loadCards } = useCards();
  const {
    loaded : ordersLoaded,
    load : loadOrders,
    evaluateOptions,
    generateOrderUrl,
  } = useOrders();
  const {
    loaded : optionsLoaded,
    load : loadOptions,
    listOrphanedFulfilments,
  } = useOptions();
  const {
    projectedOrders : orders,
    subscriptions,
    loaded : subscriptionsLoaded,
    load : loadSubscriptions,
    isServiceChannelSubscribable,
    findProductSubscription,
  } = useSubscriptions();
  const { loadNotes } = useNotes();
  const { token } = useUsers();

  const { isProductStocked, isProductAvailable } = useContext(CatalogueContext);

  const { customer } = useCustomer();

  const hasCardOnFile = useCallback(() => {
    if (!customer) return false;
    return listRecords(creditCards).some(
      (card) => card.customerId === customer?.id,
    );
  }, [customer, creditCards]);

  const getOpenOrders = useCallback(() => {
    return orders.filter((order) => (
      (order.complete && (!order.serviceChannel?.requireCheckout || order.paid))
        && order.status !== 'cancelled'
        && order.status !== 'fulfilled'
    )).sort((a, b) => (a.time?.getTime() ?? 0) - (b.time?.getTime() ?? 0));
  }, [orders]);

  const getClosedOrders = useCallback(() => {
    return orders.filter((order) => (
      (order.complete && (!order.serviceChannel?.requireCheckout || order.paid))
        && (order.status === 'cancelled' || order.status === 'fulfilled')
    )).sort((a, b) => {
      return (b.time?.getTime() ?? 0) - (a.time?.getTime() ?? 0);
    })
  }, [orders]);

  const getDraftOrders = useCallback(() => {
    return orders.filter((order) => (
      (!order.complete
        || (order.serviceChannel?.requireCheckout && !order.paid))
    )).sort((a, b) => (a.time?.getTime() ?? 0) - (b.time?.getTime() ?? 0));
  }, [orders]);

  const getUpcomingOrders = useCallback((options? : { pages? : number }) => {
    const soonThreshold = new Date(Date.now() + SOON);
    const soonCount = getOpenOrders().filter((order) => (
      !order.time || order.time < soonThreshold
    )).length;
    const count = soonCount + ((options?.pages ?? 0) * ORDERS_PER_PAGE);
    return getOpenOrders().slice(0, count);
  }, [getOpenOrders]);

  const getRecentOrders = useCallback((options? : { pages? : number }) => {
    const historyThreshold = new Date(Date.now() - RECENT);
    const historyCount = getClosedOrders().filter((order) => (
      order.time && order.time > historyThreshold
    )).length;
    const count = historyCount + ((options?.pages ?? 0) * ORDERS_PER_PAGE);
    return getClosedOrders().slice(0, count);
  }, [getClosedOrders]);

  const evaluateReccurence = useCallback((
    subscription : Subscription | null,
    options? : { iteration? : number, },
  ) => {
    if (!subscription) return 0;

    const trueIteration = options?.iteration
      ? options?.iteration
        - (subscription.targetIteration - subscription.startIteration)
      : subscription.startIteration;

    if (subscription.startIteration === subscription.endIteration) {
      const lastSubscriptions = listRecords(subscriptions).filter(
        (s) => s.lineItemId === subscription?.lineItemId
          && s.startIteration <= trueIteration
          && (s.endIteration === null || (s.endIteration >= trueIteration))
          && s.startIteration !== s.endIteration
      );
      const lastSubscription = lastSubscriptions[lastSubscriptions.length - 1];
      subscription = lastSubscription || subscription;
    }

    const nextSubscriptions = listRecords(subscriptions).filter(
      (s) => s.lineItemId === subscription?.lineItemId
        && s?.startIteration === trueIteration + 1
        && (s.id && subscription.id && s.id > subscription.id),
    );

    if (nextSubscriptions.some(
      (s) => (s.quantity === 0 && s.endIteration === null)
    )) return 0;

    return subscription.period;
  }, [subscriptions]);

  const formatRecurrence = useCallback((period : number) => {
    if (period === 1) return 'every week';
    if (period > 1) return `every ${period} weeks`;
    return 'one-time';
  }, []);

  const addProduct = useCallback(async (
    product : Product,
    increment : number = 1,
  ) => {
    const subscription = currentOrder
      && findProductSubscription(product, currentOrder);

    const exitingItem = currentOrder
      && Object.values(currentOrder.lineItems).find(
        (item) => item.productId === product.id,
      );

    if (subscription) {
      if (!exitingItem) return false;
      return await updateItem(
        exitingItem,
        { quantity : subscription.quantity + increment },
        undefined,
        { target : 'this' },
      );
    }

    if (exitingItem) {
      return await updateItem(
        exitingItem,
        { quantity : exitingItem.quantity + increment },
      );
    }

    return await addItem({
      product : product,
      quantity : 1,
    });
  }, [
    currentOrder,
    addItem,
    updateItem,
    findProductSubscription,
  ]);

  const subscribeProduct = useCallback(async (
    product : Product,
    period : number,
  ) => {
    const subscription = currentOrder
      && findProductSubscription(product, currentOrder);

    const exitingItem = currentOrder
      && Object.values(currentOrder.lineItems).find(
        (item) => item.productId === product.id,
      );

    if (exitingItem) {
      return await updateItem(
        exitingItem,
        { quantity : (subscription?.quantity ?? exitingItem.quantity) },
        undefined,
        { period : period },
      );
    }

    return await addItem({
      product : product,
      quantity : 1,
      period : period,
    });
  }, [
    currentOrder,
    addItem,
    updateItem,
    findProductSubscription,
  ]);

  const canSubscribe = useCallback(() => {
    if (
      !serviceChannel
        || !currentOrder
        || !currentOrder.customer
        || !currentOrder.serviceChannel
        || (!currentOrder.address && !currentOrder.location)
        || !currentOrder.timeSlot
    ) return false;
    return isServiceChannelSubscribable(serviceChannel);
  }, [serviceChannel, currentOrder, isServiceChannelSubscribable]);

  const setTime = useCallback(async (
    time : Date | null,
    options : {
      moveItems? : boolean,
      target? : 'this' | 'future',
    } = {},
  ) => {
      if (!time || !availSchedules) {
        return await setTimeSlot({
          timeSlot : null,
          iteration : 0,
          division : 0,
        }, { moveItems : options.moveItems });
      }
      const check = checkSchedule(Object.values(availSchedules), time);
      if (!check) return false;
      const { timeSlot, iteration, division } = check;

      if (!timeSlot || iteration === undefined) return false;
      return await setTimeSlot({
        timeSlot : timeSlot,
        iteration : iteration,
        division : division,
      }, {
        moveItems : options.moveItems,
        target : options.target,
      });
    },
    [checkSchedule, availSchedules, setTimeSlot],
  );

  const deliveryServices = useCallback((serviceChannel : ServiceChannel) => {
    if (!services || !routes) return [];

    const serviceIds = serviceChannel.serviceIds;
    if (!serviceIds) return [];
    return serviceIds.reduce((acc, serviceId) => {
      const route = listRecords(routes).find(
        (route) => route.serviceIds?.includes(serviceId),
      );
      if (route) {
        const service = services[serviceId];
        if (service) acc.push(service);
      }
      return acc;
    }, [] as Service[])
  }, [services, routes]);

  const pickupServices = useCallback((serviceChannel : ServiceChannel) => {
    if (!services || !routes) return [];

    const serviceIds = serviceChannel.serviceIds;
    if (!serviceIds) return [];
    return serviceIds.reduce((acc, serviceId) => {
      const route = listRecords(routes).find(
        (route) => route.serviceIds?.includes(serviceId),
      );
      if (!route) {
        const service = services[serviceId];
        if (service) acc.push(service);
      }
      return acc;
    }, [] as Service[])
  }, [services, routes]);

  const checkAddress = useCallback((add : Address) => {
    const { errors } = evaluateOptions({
      address: add,
      serviceChannel,
    });
    return !errors.address;
  }, [evaluateOptions, serviceChannel]);

  const isCurrentOrder = useCallback((order : DraftOrder) => {
    if (order?.order?.id === currentOrder?.order?.id) return true;
    return (
      order.customer?.id === currentOrder?.customer?.id
        && order.serviceChannel?.id === currentOrder?.serviceChannel?.id
        && order.location?.id === currentOrder?.location?.id
        && order.address?.id === currentOrder?.address?.id
        && order.timeSlot?.id === currentOrder?.timeSlot?.id
        && order.timeSlotIteration === currentOrder?.timeSlotIteration
        && order.timeSlotDivision === currentOrder?.timeSlotDivision
    );
  }, [currentOrder]);

  const isOrderStocked = useCallback((order? : DraftOrder | null) => {
    order = order || currentOrder;
    if (!order) return true;
    return Object.values(order.lineItems).every(
      (item) => isProductStocked(item.productId, {
        locations : order.location ? [order.location] : undefined,
        services : order.service ? [order.service] : undefined,
        time : order.time ?? undefined,
      })
    )
  }, [currentOrder, isProductStocked]);

  const isOrderAvailable = useCallback((order? : DraftOrder | null) => {
    order = order || currentOrder;
    if (!order) return true;
    return Object.values(order.lineItems).every(
      (item) => isProductAvailable(item.productId, order)
    )
  }, [currentOrder, isProductAvailable]);

  const getSubtotal = useCallback(() => {
    return currentOrder?.totals.find((t) => t.key === 'subtotal') ?? null;
  }, [currentOrder]);

  const getAdjustments = useCallback(() => {
    return currentOrder?.totals.filter((t) => !!t.adjustmentId
      || t.appliedAdjustmentIds?.length) ?? [];
  }, [currentOrder]);

  const getTaxes = useCallback(() => {
    return currentOrder?.totals.filter((t) => !!t.taxId) ?? [];
  }, [currentOrder]);

  const getPaid = useCallback(() => {
    return currentOrder?.totals.find((t) => t.key === 'paid') ?? null;
  }, [currentOrder]);

  const getBalance = useCallback(() => {
    return currentOrder?.totals.find((t) => t.key === 'balance') ?? null;
  }, [currentOrder]);

  const getTotal = useCallback(() => {
    return currentOrder?.totals.find((t) => t.key === 'total') ?? null;
  }, [currentOrder]);

  const getLineItems = useCallback((
    order : DraftCustomOrder | null = currentOrder,
  ) => {
    if (!order) return [];
    return [
      ...Object.values(order.lineItems),
      ...listOrphanedFulfilments(order).lineItems,
    ]
  }, [currentOrder, listOrphanedFulfilments]);

  const scrollToLineItem = useCallback((id : number) => {
    setTimeout(() => {
      const cartView = document.getElementById('cart-view');
      const offset = cartView ? cartView.getBoundingClientRect().top : 0;
      scrollToId(`line-item-${id}`, {
        offset : offset,
        view : cartView ?? undefined,
      });
    }, 200);
  }, []);

  const load = useCallback(() => {
    loadServices();
    loadScheduling();
    token && loadCards();
    loadOrders();
    loadOptions();
    token && loadSubscriptions();
    loadNotes();
  }, [
    token,
    loadServices,
    loadScheduling,
    loadCards,
    loadOrders,
    loadOptions,
    loadSubscriptions,
    loadNotes,
  ]);

  const cartCount = getLineItems().reduce((acc, item) => {
    const subscription = currentOrder?.subscriptions
      ? Object.values(currentOrder.subscriptions).find(
        (s) => s.lineItemId === item.id,
      ) : undefined;
    return acc + (subscription?.quantity ?? item.quantity);
  }, 0);

  return {
    ...context,
    allServiceChannels: serviceChannels,
    allLocations : locations,
    availableSchedules: availSchedules,
    availableTimes : context.timeOptions,
    serviceChannel,
    location,
    timeSlot,
    currentOrder,
    cartCount,
    loaded : servicesLoaded
      && schedulingLoaded
      && ordersLoaded
      && optionsLoaded
      && (!token || subscriptionsLoaded),
    addItem,
    updateItem,
    setTime,
    setTimeSlot,
    load,
    hasCardOnFile,
    getOpenOrders,
    getClosedOrders,
    getDraftOrders,
    getUpcomingOrders,
    getRecentOrders,
    evaluateReccurence,
    formatRecurrence,
    addProduct,
    subscribeProduct,
    canSubscribe,
    checkAddress,
    deliveryServices,
    pickupServices,
    isCurrentOrder,
    isOrderOpen : context.canUpdateOrder,
    isOrderStocked,
    isOrderAvailable,
    getLineItems,
    getSubtotal,
    getAdjustments,
    getTaxes,
    getTotal,
    getPaid,
    getBalance,
    findOrder : getOrder,
    generateOrderUrl,
    scrollToLineItem,
  };
}

export default useRequests;
