import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Product, Assembly } from '#mrktbox/types';
import { useOptions } from '#mrktbox';

import { Theme } from '#types';

import useCatalogue from '#hooks/useCatalogue';

import ProductOption from '#components/products/ProductOption';
import ProductOptionsNav from '#components/products/ProductOptionsNav';

interface Style { theme? : Theme; }

const ProductOptionsList = styled.div<Style>`
  padding: 0 ${(props) => props.theme.layout.spacing.small};
  margin:
    ${(props) => props.theme.layout.spacing.medium}
    0
    ${(props) => props.theme.layout.spacing.small};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    padding: 0 ${(props) => props.theme.layout.spacing.xsmall};
    margin:
      ${(props) => props.theme.layout.spacing.medium}
      0
      ${(props) => props.theme.layout.spacing.xsmall};
  }
`;

interface ProductOptionsProps {
  product : Product;
  selections : { [id : number] : Product[]; };
  setSelections : (selections : { [id : number] : Product[]; }) => void;
  scrollOffset? : number;
  scrollRef? : React.RefObject<HTMLDivElement>;
}

function ProductOptions({
  product,
  selections,
  setSelections,
  scrollOffset = 0,
  scrollRef,
} : ProductOptionsProps) {
  const { getProductAssemblies } = useOptions();
  const { loadOptions } = useCatalogue();

  const [assemblies, setAssemblies] = useState<Assembly[]>(
    getProductAssemblies(product)
  );

  const handleSelection = useCallback(
    (assembly : Assembly) => (selected : Product[]) => {
      setSelections({
        ...selections,
        [assembly.id ?? NaN]: selected,
      });
    },
    [selections, setSelections],
  );

  useEffect(() => { loadOptions(product); }, [product, loadOptions]);
  useEffect(() => {
    setAssemblies(getProductAssemblies(product));
  }, [product, getProductAssemblies]);

  return (
    <>
      <ProductOptionsNav
        assemblies={assemblies}
        selections={selections}
        scrollOffset={scrollOffset}
        scrollRef={scrollRef}
      />
      <ProductOptionsList>
        { assemblies.map((assembly) => (
          <ProductOption
            key={assembly.id}
            assembly={assembly}
            selected={selections[assembly.id ?? NaN] ?? []}
            setSelected={handleSelection(assembly)}
          />
        ))}
      </ProductOptionsList>
    </>
  );
}

export default ProductOptions;
