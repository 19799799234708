import React from 'react';

import { ServiceChannel } from '#mrktbox/types';

interface OrderTypeProps {
  serviceChannel : ServiceChannel;
  isCard? : boolean;
  children? : React.ReactNode;
}

function OrderType({
  serviceChannel,
  isCard,
  children,
} : OrderTypeProps) {

  return (
    <>
      <p className={isCard ? 'title' : ''}>{ serviceChannel.name }</p>
    </>
  );
}

export default OrderType;
