import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

import { isUser } from '#mrktbox/clerk/types';

const AUTH_PATH = 'auth/';

export async function login({
  username,
  password,
} : { username : string, password : string }) {
  const response = await request(
    getUrl(`${AUTH_PATH}login/`),
    methods.post,
    { username, password },
  );

  const token = response.token;
  if (typeof token !== 'string') {
    throw new DeserializationError(
      'Token is not a string',
      response,
    );
  }

  return token;
}

export async function verify({ token } : { token : string }) {
  const response = await request(
    getUrl(`${AUTH_PATH}verify/`),
    methods.post,
    { token },
  );

  const user = response.user;
  if (!isUser(user)) {
    throw new DeserializationError(
      'User is not a User',
      response,
    );
  }

  return user;
}

export async function retrievePasswordResetToken(
  { username } : { username : string },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${AUTH_PATH}reset/${username}`),
    methods.get,
    undefined,
    options,
  );

  const token = response.token;
  if (typeof token !== 'string') {
    throw new DeserializationError(
      'Could not deserialize response',
      response,
    );
  }

  return token;
}

export async function resetPassword({
  username,
  password,
  token,
} : {
  username : string,
  password : string,
  token : string,
}) {
  const response = await request(
    getUrl(`${AUTH_PATH}reset/`),
    methods.post,
    {
      username,
      password,
      token,
    },
  );

  return !!response;
}

export async function sendPasswordReset({ username } : { username : string }) {
  const response = await request(
    getUrl(`${AUTH_PATH}reset/send/`),
    methods.post,
    { username },
  );

  return !!response;
}
