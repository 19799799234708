import styled from '@emotion/styled';

import { Theme } from '#types';

interface TextStyle {
  theme? : Theme;
  colour?: keyof Theme['palette']['background']['text'];
  size?: keyof Theme['typography']['fonts']['default']['sizes'];
  bold?: boolean;
}

const Text = styled.span<TextStyle>`
  color: ${(props) => props.colour
    ? props.theme.palette.background.text[props.colour]
    : props.theme.palette.background.text.primary};
  font-size: ${(props) => props.size
    ? props.theme.typography.fonts.default.sizes[props.size]
    : props.theme.typography.fonts.default.sizes.medium};
  font-weight: ${(props) => props.bold
    ? props.theme.typography.fonts.default.weights.bold
    : props.theme.typography.fonts.default.weights.regular};
`;

export default Text;
