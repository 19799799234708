import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { Product, Assembly } from '#mrktbox/types';
import { useOptions } from '#mrktbox/hooks';

import { Theme } from '#types';

import useRequests from '#hooks/useRequests';

import Count from '#materials/Count';
import { Check } from '#materials/icons';

import { scrollToId } from '#utils/scroll';

interface Style { theme? : Theme; }
interface ViewStyle extends Style {
  active? : boolean;
  belowMin : boolean;
}

const ProductOptionsNavScrollButtonView = styled.button<ViewStyle>`
  position: relative;
  height: calc(3
    * ${(props) => props.theme.typography.fonts.default.sizes.small});
  padding: ${(props) => props.theme.layout.spacing.xsmall} 0 0;
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  color: ${(props) => props.belowMin
    ? props.theme.palette.background.text.alert
    : props.theme.palette.background.text.primary};
`;

const ProductOptionsNavScrollButtonContainer = styled.span<Style>`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    display: block;
  }

  span + span {
    margin-left: ${(props) => props.theme.layout.spacing.xxsmall};
  }
`;

const ProductOptionsNavScrollButtonCompleted = styled.span<Style>`
  width: ${(props) => `calc(1.5
    * ${props.theme.typography.fonts.default.sizes.xsmall})`};
  height: ${(props) => `calc(1.5
    * ${props.theme.typography.fonts.default.sizes.xsmall})`};
  border-radius: 50vh;

  color: ${(props) => props.theme.palette.notifications.success.text};
  background-color: ${(props) =>
    props.theme.palette.notifications.success.fill};

  transition: all 500ms ease;

  & > span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ProductOptionsNavScrollButtonActive = styled.span<ViewStyle>`
  position: absolute;
  height: 0.2rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.belowMin
    ? props.theme.palette.background.text.alert
    : props.theme.palette.background.text.primary};
`;

interface ProductOptionsNavScrollButtonProps {
  assembly : Assembly;
  count : number;
  active? : boolean;
  offset? : number;
  scrollRef? : React.RefObject<HTMLDivElement>;
}

function ProductOptionsNavScrollButton({
  assembly,
  count,
  active,
  offset = 0,
  scrollRef,
} : ProductOptionsNavScrollButtonProps) {
  const { getAssemblyCounts } = useOptions();
  const { time } = useRequests();

  const { min } = getAssemblyCounts(assembly, time ?? new Date());

  const handleClick = useCallback((
    evt : React.MouseEvent<HTMLButtonElement>
  ) => {
    evt.preventDefault();
    if (!scrollRef?.current) return;

    scrollToId(`assembly-${assembly.id}`, {
      offset : offset + 10,
      view : scrollRef.current,
    });
    const element = document.getElementById(`assembly-${assembly.id}`);
    if (!element) return;

    const items = element.querySelectorAll('button')
    const firstItem = items.length ? items[0] : null
    if (firstItem) firstItem.focus({ preventScroll: true })
  }, [assembly, offset, scrollRef]);

  return (
    <ProductOptionsNavScrollButtonView
      onClick={handleClick}
      active={active}
      belowMin={count < min}
    >
      <ProductOptionsNavScrollButtonContainer>
        <span>{ assembly.name }</span>
        { (count < min)
          ? (
            <span>
              <Count
                count={min - count}
                size='xsmall'
                colour='alert'
              />
            </span>
          ) : (
            <ProductOptionsNavScrollButtonCompleted>
              <span>
                <Check size={12} strokeWidth={3} />
              </span>
            </ProductOptionsNavScrollButtonCompleted>
          )
        }
      </ProductOptionsNavScrollButtonContainer>
      { active && (
        <ProductOptionsNavScrollButtonActive belowMin={count < min} />
      ) }
    </ProductOptionsNavScrollButtonView>
  );
}

const ProductOptionsNavScrollView = styled.div<Style>`
  width: 100%;
  overflow-x: scroll;
  transition: all 500ms ease;

  &::-webkit-scrollbar {
    display: none;
  }

  ul {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 4.5rem;

    li {
      display: block;
      flex-shrink: 0;
      padding: 0
        ${(props) => props.theme.layout.spacing.medium}
        0
        ${(props) => props.theme.layout.spacing.small};

      @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
        padding: 0
          ${(props) => props.theme.layout.spacing.small}
          0
          ${(props) => props.theme.layout.spacing.xsmall};
      }

      &:last-of-type {
        padding-right: ${(props) => props.theme.layout.spacing.small};

        @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
          padding-right: ${(props) => props.theme.layout.spacing.xsmall};
        }
      }
    }
  }
`;

interface ProductOptionsNavScrollProps {
  assemblies : Assembly[],
  selections : { [id : number]  : Product[] }
  scrollOffset : number;
  scrollRef? : React.RefObject<HTMLDivElement>;
}

function ProductOptionsNavScroll({
  assemblies,
  selections,
  scrollOffset,
  scrollRef,
} : ProductOptionsNavScrollProps) {
  const navRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const [active, setActive] = useState<Assembly | null>(null);

  const navBarHeight = navRef.current?.offsetHeight || 45;
  const paddingTop = 20;
  const buttonOffset = (navBarHeight || 45) + scrollOffset + 45
    + (paddingTop - 1);

  useEffect(() => {
    const ref = scrollRef?.current;
    if (!ref) return;

    const handleScroll = () => {
      const scrollY = ref.scrollTop + scrollOffset;

      let firstTop : Assembly | null = null;
      assemblies.forEach((a) => {
        if (firstTop) return;
        const element = document.getElementById(`assembly-${a.id}`);
        if (!element) return;

        const top = element.offsetTop;
        if (top >= scrollY) firstTop = a;
      });
      setActive(firstTop);
    }

    ref.addEventListener('scroll', handleScroll);
    return () => { ref.removeEventListener('scroll', handleScroll); }
  }, [assemblies, scrollOffset, scrollRef]);

  return (
    <ProductOptionsNavScrollView ref={navRef}>
      <ul ref={listRef}>
        {assemblies.map((a, index) => {
          const sectionId = `assembly-${a.id}`
          return (
            <li
              key={`${sectionId}-${index}`}
              id={`nav-${sectionId}`}
              className="nav-section"
            >
              <ProductOptionsNavScrollButton
                assembly={a}
                count={a.id ? (selections[a.id]?.length || 0) : 0}
                active={active?.id === a.id}
                offset={buttonOffset}
                scrollRef={scrollRef}
              />
            </li>
          )
        })}
      </ul>
    </ProductOptionsNavScrollView>
  );
}

export default ProductOptionsNavScroll;
