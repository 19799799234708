import { useCallback, useContext } from 'react';

import {
  Address,
  Product,
  Customer,
  ServiceChannel,
  Location,
  TimeSlot,
  LineItem,
  Selection,
  Subscription,
  Fulfilment,
  Order,
  DraftOrder,
  DraftCustomOrder,
  ProjectedOrder,
  ScheduleSelection,
} from '#mrktbox/clerk/types';

import SubscriptionsContext from '#mrktbox/clerk/context/SubscriptionsContext';

import useScheduling from '#mrktbox/clerk/hooks/useScheduling';
import useOrders from '#mrktbox/clerk/hooks/useOrders';
import useOptions, {
  draftEmptyCustomOrder,
} from '#mrktbox/clerk/hooks/useOptions';

import { listRecords } from '#mrktbox/clerk/utils/data';

function draftEmptyProjectedOrder() : ProjectedOrder {
  return {
    ...draftEmptyCustomOrder(),
    subscriptions : {},
  };
}

function useSubscriptions() {
  const {
    subscriptions,
    susbcriptionOptions,
    evaluateSubscriptions,
    createSubscription,
    bulkCreateSubscriptions,
    bulkCreateFulfilments,
    projectOrders,
    ...context
  } = useContext(SubscriptionsContext);

  const { calculateTime } = useScheduling();
  const {
    createLineItem,
    updateLineItem,
    deleteLineItem,
    createFulfilment,
    bulkUpdateFulfilments,
    bulkDeleteLineItems,
    bulkDeleteFulfilments,
    findOrder,
  } = useOrders();
  const {
    customiseOrder,
    bulkCreateCustomisedLineItems,
    bulkUpdateCustomisedLineItems,
    resolveSelections,
  } = useOptions();

  const isServiceChannelSubscribable = useCallback((
    serviceChannel : ServiceChannel
  ) => {
    const serviceChannelId = serviceChannel.id;
    if (!serviceChannelId) return false;
    return listRecords(susbcriptionOptions).some(
      option => option.serviceChannelIds.includes(serviceChannelId)
    );
  }, [susbcriptionOptions]);

  const findProjectedOrder = useCallback(async ({
    address,
    customer,
    serviceChannel,
    location,
    timeSlot,
    iteration,
    division,
  } : {
    address : Address | null,
    customer : Customer | null,
    serviceChannel : ServiceChannel | null,
    location : Location | null,
    timeSlot : TimeSlot | null,
    iteration : number,
    division : number,
  }) : Promise<ProjectedOrder | null> => {
    if (
      !customer
        || !serviceChannel
        || (!address && !location)
        || !timeSlot
    ) {
      const simplerOrder = findOrder({
        address,
        customer,
        serviceChannel,
        location,
        timeSlot,
        iteration,
        division,
      });
      if (!simplerOrder) return null;
      else return {
        ...customiseOrder(simplerOrder),
        subscriptions : {},
      }
    }

    const time = calculateTime(timeSlot, iteration, division);
    const projectedOrders = await projectOrders(
      time,
      time,
    );

    return projectedOrders.find(order => (
      (address ? order.address?.id === address.id : !order.address)
        && order.customer?.id === customer.id
        && order.serviceChannel?.id === serviceChannel.id
        && (location ? order.location?.id === location.id : !order.location)
        && order.timeSlot?.id === timeSlot.id
        && order.timeSlotIteration === iteration
        && order.timeSlotDivision === division
    )) ?? null;
  }, [
    projectOrders,
    customiseOrder,
    findOrder,
    calculateTime,
  ]);

  const isLineItemRecurring = useCallback((lineItem : LineItem) => {
    // TODO: warn if subscriptions not loaded
    const subsctiptions = subscriptions
      ? listRecords(subscriptions).filter(
        subscription => subscription.lineItemId === lineItem.id
      ) : [];

    return subsctiptions.length > 0;
  }, [subscriptions]);

  const findLineItemSubscription = useCallback((
    lineItem : LineItem,
    order : ProjectedOrder,
  ) => {
    const subscriptions = listRecords(order.subscriptions).filter(
      subscription => subscription.lineItemId === lineItem.id
    );
    if (!subscriptions.length) return null;
    return subscriptions[subscriptions.length - 1];
  }, []);

  const findProductSubscription = useCallback((
    product : Product,
    order : ProjectedOrder,
  ) => {
    const lineItems = listRecords(order.lineItems).filter(
      lineItem => lineItem.productId === product.id
    );

    for (const lineItem of lineItems) {
      return findLineItemSubscription(lineItem, order);
    }
    return null;
  }, [findLineItemSubscription]);

  const determineProductsCount = useCallback((
    products : Product[],
    order : ProjectedOrder,
  ) => {
    return Object.values(order.lineItems).reduce((count, lineItem) => {
      if (!products.some(product => product.id === lineItem.productId)) {
        return count;
      }
      const subscription = findLineItemSubscription(lineItem, order);
      if (!subscription) return count + lineItem.quantity;
      return count + subscription.quantity;
    }, 0);
  }, [findLineItemSubscription]);

  const determineTrueIteration = useCallback((
    lineItem : LineItem,
    timeSlot : TimeSlot,
    effectiveIteration : number,
  ) => {
    const subscription = listRecords(subscriptions).filter(
      s => s.lineItemId === lineItem.id
        && s.timeSlotId === timeSlot.id
        && s.targetIteration <= effectiveIteration
        && ((s.endIteration === null)
          || s.targetIteration
            + (s.endIteration - s.startIteration) >= effectiveIteration
    )).sort((a, b) => (b.id ?? 0) - (a.id ?? 0))[0];
    if (!subscription) return effectiveIteration;

    return effectiveIteration
      - (subscription.targetIteration - subscription.startIteration);
  }, [subscriptions]);

  const isOrderRecurring = useCallback((order : DraftOrder) => {
    return Object.values(order.lineItems).some(isLineItemRecurring);
  }, [isLineItemRecurring]);

  const getLineItemSubscription = useCallback((
    lineItem : LineItem,
    order : ProjectedOrder,
  ) => {
    const subscriptions = listRecords(order.subscriptions).filter(
      subscription => (subscription.lineItemId === lineItem.id)
        && (subscription.timeSlotId === order.timeSlot?.id)
        && (subscription.targetIteration <= order.timeSlotIteration)
        && ((subscription.endIteration === null)
          || ((subscription.targetIteration
            + (subscription.endIteration - subscription.startIteration))
              >= order.timeSlotIteration))
    ).sort((a, b) => (b.id ?? 0) - (a.id ?? 0));
    if (!subscriptions.length) return null;
    return subscriptions[0];
  }, []);

  const buildLineItemSubscription = useCallback((
    lineItem : LineItem,
    selections : Selection[],
    subscriptionOptions : Subscription | {
      startIteration? : number,
      endIteration? : number | null,
      targetIteration? : number,
      period? : number,
    },
  ) : Subscription => {
    return {
      quantity : lineItem.quantity,
      lineItemId : lineItem.id ?? NaN,
      serviceChannelId : lineItem.serviceChannelId ?? NaN,
      locationId : lineItem.locationId ?? NaN,
      addressId : lineItem.addressId ?? NaN,
      timeSlotId : lineItem.timeSlotId ?? NaN,
      timeSlotDivision : lineItem.timeSlotDivision,
      targetIteration : subscriptionOptions.targetIteration
        ?? subscriptionOptions.startIteration
        ?? lineItem.timeSlotIteration,
      startIteration : subscriptionOptions.startIteration
        ?? lineItem.timeSlotIteration,
      endIteration : subscriptionOptions.endIteration ?? null,
      period : subscriptionOptions.period ?? 1,
      selectionIds : selections
        .filter(s => s.id !== undefined)
        .map(s => s.id ?? NaN),
    };
  }, []);

  const createLineItemSubscription = useCallback(async (
    lineItem : LineItem,
    selections? : Selection[],
    subscriptionOptions? : Subscription | {
      startIteration? : number,
      endIteration? : number | null,
      targetIteration? : number,
      period? : number,
    },
  ) : Promise<{
    lineItem : LineItem;
    selections : Selection[];
    subscription : Subscription;
  } | null> => {
    if (
      !lineItem.id
        || !lineItem.serviceChannelId
        || (!lineItem.locationId && !lineItem.addressId)
        || !lineItem.timeSlotId
    ) return null;

    const response = await createSubscription(
      buildLineItemSubscription(
        lineItem,
        selections ?? [],
        subscriptionOptions ?? {},
      ),
      selections ? selections.map(s => ({ ...s, id : undefined })) : [],
    )
    return response ? {
      ...response,
      selections : listRecords(response.selections),
    } : null;
  }, [buildLineItemSubscription, createSubscription]);

  const generateOrder = useCallback(async (
    order : ProjectedOrder,
    options? : {
      defer? : boolean,
      resolveSelections? : boolean,
      failFast? : boolean,
    }
  ) => {
    return await bulkCreateFulfilments({
      lineItems : Object.values(order.lineItems),
      selections : Object.values(order.selections),
      subscriptions : Object.values(order.subscriptions),
      targetIteration : order.timeSlotIteration,
      defer : options?.defer,
    });
  }, [bulkCreateFulfilments]);

  const updateSubscriptionOrder = useCallback(async (
    order : ProjectedOrder,
    options : {
      target? : 'this' | 'future',
      targetTime? : ScheduleSelection,
    } = {},
  ) : Promise<{
    success : boolean;
    lineItems : LineItem[];
    selections : Selection[];
    subscriptions : Subscription[];
    errors : ("needsTarget" | "needsTargetTime")[];
  }> => {
    if (!isOrderRecurring(order)) {
      const response = await bulkUpdateCustomisedLineItems(
        Object.values(order.lineItems).map((lineItem) => ({
          ...lineItem,
          customerId : order.customer?.id ?? null,
          serviceChannelId : order.serviceChannel?.id ?? null,
          locationId : order.location?.id ?? null,
          addressId : order.address?.id ?? null,
          timeSlotId : order.timeSlot?.id ?? null,
          timeSlotIteration : order.timeSlotIteration,
          timeSlotDivision : order.timeSlotDivision,
        })),
        Object.values(order.selections),
      )

      return {
        success : !!response,
        lineItems : listRecords(response?.lineItems ?? null) ?? [],
        selections : listRecords(order.selections),
        subscriptions : [],
        errors : [],
      };
    }

    if (!options.target || !options.targetTime) return {
      success : false,
      lineItems : [] ,
      selections : [],
      subscriptions : [],
      errors : [
        !options.target ? 'needsTarget' : null,
        !options.targetTime ? 'needsTargetTime' : null,
      ].filter(e => e) as ('needsTarget' | 'needsTargetTime')[],
    };

    const lineItems = Object.values(order.lineItems).map((lineItem) => ({
      ...lineItem,
      customerId : order.customer?.id ?? null,
      serviceChannelId : order.serviceChannel?.id ?? null,
      locationId : order.location?.id ?? null,
      addressId : order.address?.id ?? null,
      timeSlotId : order.timeSlot?.id ?? null,
      timeSlotIteration : order.timeSlotIteration,
      timeSlotDivision : order.timeSlotDivision,
    }));

    const proposedSubscriptions : Subscription[] = [];
    for (const lineItem of Object.values(order.lineItems)) {
      const subscription = Object.values(order.subscriptions).filter(
        (subscription) => subscription.lineItemId === lineItem.id
      )[0];
      if (!subscription) continue;

      const trueIteration = determineTrueIteration(
        lineItem,
        options.targetTime.timeSlot,
        options.targetTime.iteration,
      )
      proposedSubscriptions.push(buildLineItemSubscription(
        {
          ...lineItem,
          timeSlotIteration : order.timeSlotIteration,
          timeSlotDivision : order.timeSlotDivision,
          addressId : order.address?.id ?? null,
          customerId : order.customer?.id ?? null,
          serviceChannelId : order.serviceChannel?.id ?? null,
          locationId : order.location?.id ?? null,
          timeSlotId : order.timeSlot?.id ?? null,
        },
        Object.values(order.selections).filter(
          (selection) => selection.lineItemId === lineItem.id
        ),
        {
          startIteration : trueIteration,
          endIteration : options.target === 'this' ? trueIteration : null,
          targetIteration : order.timeSlotIteration,
          period : subscription.period,
        }
      ));
    }

    const subscriptionResponse = await bulkCreateSubscriptions({
      lineItems : lineItems,
      selections : Object.values(order.selections),
      subscriptions : proposedSubscriptions,
    });

    return {
      success : !!subscriptionResponse,
      lineItems : listRecords(subscriptionResponse?.lineItems ?? null),
      selections : listRecords(subscriptionResponse?.selections ?? null),
      subscriptions : listRecords(subscriptionResponse?.subscriptions ?? null),
      errors : [],
    };
  }, [
    isOrderRecurring,
    bulkUpdateCustomisedLineItems,
    determineTrueIteration,
    buildLineItemSubscription,
    bulkCreateSubscriptions,
  ]);

  const deleteSubscriptionOrder = useCallback(async (
    order : ProjectedOrder,
    options : {
      target? : 'this' | 'future',
      targetTime? : ScheduleSelection,
    } = {},
  ) : Promise<{
    success : boolean;
    lineItems : LineItem[];
    selections : Selection[];
    subscriptions : Subscription[];
    errors : "needsTarget"[];
  }> => {
    const oneTimeItems = Object.values(order.lineItems).filter(
      (lineItem) => !isLineItemRecurring(lineItem)
    );
    const recurringItems = Object.values(order.lineItems).filter(
      (lineItem) => !oneTimeItems.includes(lineItem),
    );

    if (recurringItems.length && (
      !options.target || !options.targetTime
    )) return {
      success : false,
      lineItems : [],
      selections : [],
      subscriptions : [],
      errors : ['needsTarget'],
    };

    const orphanedFulfilments = order.order
      ? Object.values(order.order.fulfilments).filter(
        (fulfilment) => !Object.values(order.lineItems).some(
          (lineItem) => fulfilment.lineItemId === lineItem.id
        )
      ) : [];

    const deletedItems = await bulkDeleteLineItems(oneTimeItems);

    const proposedSubscriptions : Subscription[] = [];
    for (const lineItem of recurringItems) {
      if (!options.target || !options.targetTime) continue;

      const trueIteration = determineTrueIteration(
        lineItem,
        options.targetTime.timeSlot,
        options.targetTime.iteration,
      );
      proposedSubscriptions.push(buildLineItemSubscription(
        {
          ...lineItem,
          quantity : 0,
        },
        [],
        {
          startIteration : trueIteration,
          endIteration : options.target === 'this' ? trueIteration : null,
          targetIteration : order.timeSlotIteration,
          period : 1,
        },
      ));
    }

    const subscriptionResponse = await bulkCreateSubscriptions({
      subscriptions : proposedSubscriptions,
    });

    const fulfilmentResponse = await bulkDeleteFulfilments(orphanedFulfilments);

    return {
      success : !!deletedItems
        && !!subscriptionResponse
        && !!fulfilmentResponse,
      lineItems : [
        ...listRecords(deletedItems),
        ...listRecords(subscriptionResponse?.lineItems ?? null),
      ],
      selections : [
        ...listRecords(subscriptionResponse?.selections ?? null),
        ...listRecords(subscriptionResponse?.selections ?? null),
      ],
      subscriptions : listRecords(subscriptionResponse?.subscriptions ?? null),
      errors : [],
    };
  }, [
    isLineItemRecurring,
    bulkDeleteLineItems,
    bulkDeleteFulfilments,
    determineTrueIteration,
    buildLineItemSubscription,
    bulkCreateSubscriptions,
  ]);

  const createSubscriptionLineItem = useCallback(async (
    order : DraftOrder,
    lineItem : LineItem,
    selections? : Selection[],
    options? : { period? : number },
  ) : Promise<{
    success : boolean;
    lineItem : LineItem | null;
    selections : Selection[];
    subscription : Subscription | null;
  }> => {
    if (lineItem.id !== undefined) {
      lineItem.refId = lineItem.id;
      lineItem.id = undefined;
    }

    if (!options?.period) {
      if ((!selections || !selections.length)) {
        const newLineItem = await createLineItem(lineItem);
        return {
          success : !!newLineItem,
          lineItem : newLineItem,
          selections : [],
          subscription : null,
        };
      }
      const response = await bulkCreateCustomisedLineItems(
        selections ?? [],
        [{
          ...lineItem,
          id : undefined,
          refId : lineItem.id ?? selections[0].lineItemId,
        }],
      );
      return {
        success : !!response,
        lineItem : listRecords(response?.lineItems ?? null)[0] ?? null,
        selections : listRecords(response?.selections ?? null),
        subscription : null,
      };
    }

    const response = await createSubscription(
      buildLineItemSubscription(
        {
          ...lineItem,
          serviceChannelId : order.serviceChannel?.id ?? null,
          locationId : order.location?.id ?? null,
          addressId : order.address?.id ?? null,
          timeSlotId : order.timeSlot?.id ?? null,
          timeSlotDivision : order.timeSlotDivision,
          timeSlotIteration : order.timeSlotIteration,
        },
        selections ?? [],
        options ?? {},
      ),
      selections,
      lineItem,
    )

    return {
      success : !!response,
      lineItem : response?.lineItem ?? null,
      selections : listRecords(response?.selections ?? null),
      subscription : response?.subscription ?? null,
    };
  }, [
    buildLineItemSubscription,
    createLineItem,
    bulkCreateCustomisedLineItems,
    createSubscription,
  ]);

  const updateSubscriptionLineItem = useCallback(async (
    order : DraftCustomOrder,
    lineItem : LineItem,
    selections? : Selection[],
    options? : {
      startIteration? : number,
      endIteration? : number | null,
      targetIteration? : number,
      period? : number
    },
  ) : Promise<{
    success : boolean;
    lineItem : LineItem | null;
    selections : Selection[];
    subscription : Subscription | null;
    errors : ("invalidOptions"
      | "lineItemError"
      | "optionError"
      | "subscriptionError")[];
  }> => {
    const errors = [] as ('invalidOptions'
      | 'lineItemError'
      | 'optionError'
      | 'subscriptionError')[];

    let newLineItem : LineItem | null = lineItem;
    let newSelections : Selection[] = selections ?? [];
    let newSubscubscription = null;

    if (!options) {
      if (isLineItemRecurring(lineItem)) {
        errors.push('invalidOptions');
      } else {
        const existingSelections = Object.values(order.selections).filter(
          (selection) => selection.lineItemId === lineItem.id
        );
        if ((!selections || !selections.length) && !existingSelections.length) {
          newLineItem = await updateLineItem(lineItem);
          if (!newLineItem) errors.push('lineItemError');
        } else {
          const response = await bulkUpdateCustomisedLineItems(
            [lineItem],
            selections ? selections.map((selection) => ({
              ...selection,
              id : ((selection.id ?? 0) > 0) ? selection.id : undefined,
            })) : [],
          )
          newLineItem = listRecords(response?.lineItems ?? null)[0] ?? null;
          newSelections = listRecords(response?.selections ?? null);
          if (!response) errors.push('lineItemError');
        }
      }
    } else {
      const response = await createLineItemSubscription(
        {
          ...lineItem,
          serviceChannelId : order.serviceChannel?.id ?? null,
          locationId : order.location?.id ?? null,
          addressId : order.address?.id ?? null,
          timeSlotId : order.timeSlot?.id ?? null,
          timeSlotIteration : order.timeSlotIteration,
          timeSlotDivision : order.timeSlotDivision,
        },
        (selections ?? []).map(
          (selection) => ({ ...selection, id : undefined }),
        ),
        options,
      );
      newLineItem = response?.lineItem ?? null;
      newSelections = response?.selections ?? [];
      newSubscubscription = response?.subscription ?? null;
      if (!response) errors.push('subscriptionError');
    }

    return {
      success : !errors.length,
      lineItem : newLineItem,
      selections : newSelections,
      subscription : newSubscubscription,
      errors,
    };
  }, [
    isLineItemRecurring,
    createLineItemSubscription,
    updateLineItem,
    bulkUpdateCustomisedLineItems,
  ]);

  const deleteSubscriptionLineItem = useCallback(async (
    lineItem : LineItem,
    options? : {
      startIteration? : number,
      endIteration? : number | null,
    },
  ) : Promise<{
    success : boolean;
    lineItem : LineItem | null;
    subscription : Subscription | null;
    errors : ("needsOptions"
      | "invalidOptions"
      | "lineItemError"
      | "subscriptionError")[];
  }> => {
    const errors = [] as ('needsOptions'
      | 'invalidOptions'
      | 'lineItemError'
      | 'subscriptionError')[];
    if (!isLineItemRecurring(lineItem)) {
      if (options) return {
        success : false,
        lineItem : null,
        subscription : null,
        errors : ['invalidOptions'],
      };

      const deleted = await deleteLineItem(lineItem);
      if (!deleted) errors.push('lineItemError');
      return {
        success : !!deleted,
        lineItem : deleted,
        subscription : null,
        errors,
      };
    }

    if (!options) return {
      success : false,
      lineItem : null,
      subscription : null,
      errors : ['needsOptions'],
    };

    const subscriptionResponse = await createLineItemSubscription(
      { ...lineItem, quantity : 0 },
      [],
      options,
    );
    if (!subscriptionResponse) errors.push('subscriptionError');
    return {
      success : !errors.length,
      lineItem,
      subscription : subscriptionResponse?.subscription ?? null,
      errors,
    };
  }, [
    isLineItemRecurring,
    deleteLineItem,
    createLineItemSubscription,
  ]);

  return {
    ...context,
    subscriptions,
    susbcriptionOptions,
    evaluateSubscriptions,
    createSubscription,
    bulkCreateFulfilments,
    isServiceChannelSubscribable,
    projectOrders,
    findProjectedOrder,
    isLineItemRecurring,
    isOrderRecurring,
    findLineItemSubscription,
    findProductSubscription,
    determineProductsCount,
    determineTrueIteration,
    getLineItemSubscription,
    buildLineItemSubscription,
    createLineItemSubscription,
    updateRecurringLineItem : createLineItemSubscription,
    bulkCreateRecurringLineItems : bulkCreateSubscriptions,
    generateOrder,
    updateOrder: updateSubscriptionOrder,
    deleteOrder: deleteSubscriptionOrder,
    createLineItem: createSubscriptionLineItem,
    updateLineItem: updateSubscriptionLineItem,
    deleteLineItem: deleteSubscriptionLineItem,
    draftEmptyOrder : draftEmptyProjectedOrder,
  };
}

export default useSubscriptions;
