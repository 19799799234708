import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async'

import { useParams, useSearchParams, useNavigation } from '#mrktbox';

import { ProjectedOrder } from '#mrktbox/types';
import {
  useAddresses,
  useCustomers,
  useServices,
  useScheduling,
  useOrders,
  useOptions,
  useSubscriptions,
} from '#mrktbox';

import useConfig from '#hooks/useConfig';
import useRequests from '#hooks/useRequests';

import Content from '#components/page/Content';
import Main from '#components/page/Main';
import PageContainer from '#components/page/PageContainer';
import OrderHistory from '#slices/OrderHistory';
import OrderSummary from '#slices/Order';

function Order() {
  const { navigate } = useNavigation();
  const { orderId } = useParams();
  const [searchParams] = useSearchParams();

  const { brand } = useConfig();

  const { retrieveAddress } = useAddresses();
  const { customers } = useCustomers();
  const { serviceChannels, locations } = useServices();
  const { timeSlots } = useScheduling();
  const { reloadLineItems, reloadOrders } = useOrders();
  const { reloadSelections } = useOptions();
  const { reloadSubscriptions } = useSubscriptions();
  const { orders } = useRequests();

  const [reloaded, setReloaded] = useState(false);
  const [order, setOrder] = useState<ProjectedOrder | null>(null);
  const [init, setInit] = useState(false);
  const [timedOut, setTimedOut] = useState(false);

  const search = useCallback(async() => {
    const addressId = searchParams.has('address')
      ? parseInt(searchParams.get('address') ?? '')
      : null;
    const customerId = parseInt(searchParams.get('customer') ?? '');
    const serviceChannelId = parseInt(searchParams.get('serviceChannel') ?? '');
    const locationId = searchParams.has('location')
      ? parseInt(searchParams.get('location') ?? '')
      : null;
    const timeParam = searchParams.get('timeSlot');
    const [
      timeSlotParam,
      iterationParam,
      divisionParam,
    ] = timeParam ? timeParam.split('-') : [];
    const timeSlotId = parseInt(timeSlotParam ?? '');
    const iteration = parseInt(iterationParam ?? '');
    const division = parseInt(divisionParam ?? '');

    const address = addressId ? await retrieveAddress(addressId) : null;
    if (addressId && !address) return null;
    const customer = (customers && customerId) ? customers[customerId] : null;
    if (customerId && !customer) return null;
    const serviceChannel = (serviceChannelId && serviceChannels)
      ? serviceChannels[serviceChannelId]
      : null;
    if (serviceChannelId && !serviceChannel) return null;
    const location = (locationId  && locations) ? locations[locationId] : null;
    if (locationId && !location) return null;
    const timeSlot = (timeSlotId && timeSlots) ? timeSlots[timeSlotId] : null;
    if (timeSlotId && !timeSlot) return null;

    return orders?.find((order) => (
      (order.address?.id ?? null) === (address?.id ?? null)
      && (order.customer?.id ?? null) === (customer?.id ?? null)
      && (order.serviceChannel?.id ?? null) === (serviceChannel?.id ?? null)
      && (order.location?.id ?? null) === (location?.id ?? null)
      && (order.timeSlot?.id ?? null) === (timeSlot?.id ?? null)
      && order.timeSlotIteration === (isNaN(iteration) ? 0 : iteration)
      && order.timeSlotDivision === (isNaN(division) ? 0 : division)
    )) ?? null;
  }, [
    orders,
    retrieveAddress,
    customers,
    serviceChannels,
    locations,
    timeSlots,
    searchParams,
  ]);

  useEffect(() => {
    if (!orderId && !searchParams.size) {
      setInit(false);
      setTimedOut(false);
      return;
    }

    if (!init) {
      setTimeout(() => setTimedOut(true), 2000);
      setInit(true);
    }
    if (timedOut && !order) navigate('/orders/');

    if (!orders?.length) return;

    if (!orderId) {
      search().then((order) => {
        if (order) setOrder(order);
      });
      return;
    }

    const match = orders?.find(
      (order) => order.order?.id === parseInt(orderId)
    );
    if (match) setOrder(match ?? null);
  }, [order, init, timedOut, orderId, orders, search, searchParams, navigate]);

  useEffect(() => {
    if (reloaded) return;
    setReloaded(true);
    reloadOrders();
    reloadLineItems();
    reloadSelections();
    reloadSubscriptions();
  }, [
    reloaded,
    reloadOrders,
    reloadLineItems,
    reloadSelections,
    reloadSubscriptions,
  ]);

  const index = !orderId && !searchParams.size;
  const title = index
  ? 'Upcoming Orders'
  : (orderId
    ? `Order #${orderId}`
    : (order?.complete
      ? 'Order Details'
      : 'Draft Order'));

  if (!index && !order) return null;

  return (
    <>
      <Helmet>
        <title>
          {title} | {brand.title}
        </title>
      </Helmet>
      <Content>
        <Main>
          <PageContainer {...(index && { style : { maxWidth: '114rem' } })}>
            { (index || !order)
              ? (<OrderHistory />)
              : (<OrderSummary order={order} />)
            }
          </PageContainer>
        </Main>
      </Content>
    </>
  );
}

export default Order;
