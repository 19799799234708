
export class APIError extends Error {
  code : number | undefined = undefined;
  key : string = '';

  constructor(
    message : string | number,
    code? : number | string,
    key? : string,
  ) {
    let safeMsg : string;
    let safeCode : number | undefined;
    if (typeof message === 'number' && typeof code === 'string') {
      safeMsg = code;
      safeCode = message;
    } else if (
      typeof message === 'string' &&
      (code === undefined || typeof code === 'number')
    ) {
      safeMsg = message;
      safeCode = typeof code === 'number' ? code : 0;
    } else {
      safeMsg = 'Unknown error';
      safeCode = 0;
    }

    super(safeMsg);
    Object.setPrototypeOf(this, APIError.prototype);
    this.code = safeCode;
    this.key = key ?? '';
  }
}

export class DeserializationError extends Error {
  response : any = undefined;

  constructor(message : string, response : any) {
    super(message);
    this.response = response;
  }
}

const METHODS = {
  get : 'GET' as const,
  post : 'POST' as const,
  put : 'PUT' as const,
  delete : 'DELETE' as const,
}
export type Method = typeof METHODS[keyof typeof METHODS];
export const methods = METHODS as { [key : string] : Method }

export async function request(
  url : string,
  method : Method,
  body : any  = null,
  token : string | null = null,
) {
  try {
    const init = {
      method : method,
      headers : {
        'Content-Type' : 'application/json',
        ...(token && { 'Authorization' : `Bearer ${token}` }),
      },
      ...(body && { body : JSON.stringify(body) }),
    };

    const response = await fetch(url, init);

    if (!response) throw new APIError(
      503,
      'No response from server',
    );

    return await response.json();
  } catch (e) {
    console.error(e);
    throw new APIError(503, 'Fetch failed');
  }
}
