import React, { useCallback, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { Partial } from '#mrktbox/types';

export { TransitionGroup } from 'react-transition-group';

type CSSTransitionComponent = typeof CSSTransition<HTMLElement>
type CSSTransitionProps = React.ComponentProps<CSSTransitionComponent>;
type TransitionProps = Partial<CSSTransitionProps> & {
  children : React.ReactElement;
}

function Transition({
  children,
  nodeRef,
  addEndListener,
  ...props
} : TransitionProps) {
  const ref = useRef(null);
  const fallback = useCallback(() => {}, []);

  return (
    <CSSTransition
      nodeRef={nodeRef ?? ref}
      addEndListener={addEndListener ?? fallback}
      {...props}
    >
      { nodeRef
        ? children
        : React.cloneElement(children, { ref : ref })
      }
    </CSSTransition>
  );
}

export default Transition;
