import { useContext } from 'react';

import TaxContext from '#mrktbox/clerk/context/TaxContext';

function useTaxes() {
  const context = useContext(TaxContext);

  return context;
}

export default useTaxes;
