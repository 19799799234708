import React, { createContext, useEffect, useState } from 'react';

import { Auth, Customer } from '#mrktbox/types';
import { useAuth, useCustomers } from '#mrktbox';

const CustomerContext = createContext({
  user : null as Auth | null,
  customer : null as Customer | null,
});

interface CustomerProviderProps {
  children : React.ReactNode;
}

export function CustomerProvider({ children } : CustomerProviderProps) {
  const { user } = useAuth();
  const { customers, refreshCustomers } = useCustomers();

  const [customer, setCustomer] = useState<Customer | null>(null);

  useEffect(() => { if (user) refreshCustomers(); }, [user, refreshCustomers]);

  useEffect(() => {
    if (!customers) return;
    const customer = Object.values(customers).find((customer) => (
      !!customer?.users && Object.values(customer.users).some(
        (u) => u.id === user?.id
      )
    ));
    if (customer) setCustomer(customer);
    else setCustomer(null);
  }, [customers, user]);

  const context = {
    user,
    customer,
  };

  return (
    <CustomerContext.Provider value={context}>
      { children }
    </CustomerContext.Provider>
  );
}

export default CustomerContext;
