import React, { createContext, useCallback } from 'react';

import { Supplier } from '#mrktbox/clerk/types';

import useData, {
  DataIndex,
  useLoad,
  useRefreshIndex,
  useRefresh,
  useRetrieveIndex,
  useRetrieve,
  useChange,
  useDelete,
} from '#mrktbox/clerk/hooks/useData';
import useTags from '#mrktbox/clerk/hooks/useTags';
import useSuppliersAPI from '#mrktbox/clerk/hooks/api/useSuppliersAPI';

export type SupplierIndex = DataIndex<Supplier>;

const MAX_AGE = 1000 * 60 * 60;

const SupplierContext = createContext({
  suppliers : null as DataIndex<Supplier> | null,
  loaded : false,
  load : () => {},
  createSupplier : async (supplier : Supplier) => null as Supplier | null,
  refreshSupplier : async (id : number) => null as Supplier | null,
  refreshSuppliers : async () => null as SupplierIndex | null,
  retrieveSupplier : async (id : number) => null as Supplier | null,
  retrieveSuppliers : async () => null as SupplierIndex | null,
  updateSupplier : async (supplier : Supplier) => null as Supplier | null,
  deleteSupplier : async (supplier : Supplier) => null as boolean | null,
});

interface SupplierProviderProps {
  children : React.ReactNode;
}

export function SupplierProvider({ children } : SupplierProviderProps) {
  const { refreshTag, refreshTags } = useTags();
  const {
    createSupplier,
    retrieveSuppliers,
    retrieveSupplier,
    updateSupplier,
    deleteSupplier,
  } = useSuppliersAPI();

  const {
    data : suppliers,
    dispatch : dispatchSuppliers,
    lastUpdated,
  } = useData<Supplier>({ storageKey : 'suppliers' });

  const refreshSupplierTag = useCallback((supplier : Supplier | null) => {
    if (supplier?.id) refreshTag(supplier.id);
  }, [refreshTag]);

  const newSupplier = useChange({
    dispatch : dispatchSuppliers,
    change : createSupplier,
    callback : refreshSupplierTag,
  });
  const refreshSuppliers = useRefreshIndex({
    dispatch : dispatchSuppliers,
    retrieve : retrieveSuppliers,
    callback : refreshTags,
  });
  const refreshSupplier = useRefresh({
    dispatch : dispatchSuppliers,
    retrieve : retrieveSupplier,
    callback : refreshSupplierTag,
  });
  const getSuppliers = useRetrieveIndex({
    data : suppliers,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshSuppliers,
  });
  const getSupplier = useRetrieve({
    data : suppliers,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshSupplier,
  });
  const amendSupplier = useChange({
    dispatch : dispatchSuppliers,
    change : updateSupplier,
    callback : refreshSupplierTag,
  });
  const removeSupplier = useDelete({
    dispatch : dispatchSuppliers,
    delete : deleteSupplier,
  });

  const { loaded, load } = useLoad({
    data : suppliers,
    loader : refreshSuppliers,
  });

  const context = {
    suppliers,
    loaded,
    load,
    createSupplier : newSupplier,
    refreshSuppliers,
    refreshSupplier,
    retrieveSuppliers : getSuppliers,
    retrieveSupplier : getSupplier,
    updateSupplier : amendSupplier,
    deleteSupplier : removeSupplier,
  };

  return (
    <SupplierContext.Provider value={context}>
      { children }
    </SupplierContext.Provider>
  );
}

export default SupplierContext;
