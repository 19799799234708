import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Image,
  ServiceChannel,
  Product,
  ExternalProduct,
  ProductIntegration,
  ProductImageUpload,
  Integration,
  isProductLoaded,
  ActionType,
} from '#mrktbox/clerk/types';

import useData, {
  actionTypes,
  DataIndex,
  useLoad,
  useRefreshIndex,
  useRefreshBulk,
  useRefresh,
  useRetrieveIndex,
  useRetrieveBulk,
  useRetrieve,
  useChange,
  useDelete,
  useRelate,
} from '#mrktbox/clerk/hooks/useData';
import useSearch, { SearchResults } from '#mrktbox/clerk/hooks/useSearch';
import useProductsAPI from '#mrktbox/clerk/hooks/api/useProductsAPI';

import { listRecords, indexRecords } from '#mrktbox/clerk/utils';

export interface ProductIndex extends DataIndex<Product> {}

const PRODUCT_SERACH_URL = 'products/index/';
const MAX_AGE = 1000;

const ProductsContext = createContext({
  products : null as DataIndex<Product> | null,
  loaded : false,
  load : () => {},
  refreshProducts : async () => null as ProductIndex | null,
  refreshProductsBulk : async (ids : number[]) => null as ProductIndex | null,
  refreshProduct : async (
    id : number,
    options? : { withIntegrations? : boolean },
  ) => null as Product | null,
  createProduct : async (product : Product) => null as Product | null,
  retrieveProducts : async () => null as ProductIndex | null,
  retrieveProductsBulk : async (ids : number[]) => null as ProductIndex | null,
  retrieveProduct : async (
    id : number,
    options? : { withIntegrations? : boolean },
  ) => null as Product | null,
  updateProduct : async (product : Product) => null as Product | null,
  deleteProduct : async (product : Product) => null as boolean | null,
  importProduct : async (
    integration : Integration,
    externalProduct : ExternalProduct,
  ) => null as Product | null,
  createProductIntegration : async (
    product : Product,
    externalProduct : ExternalProduct,
  ) => null as Product | null,
  deleteProductIntegration : async (
    productIntegration : ProductIntegration,
  ) => null as Product | null,
  syncProductIntegration : async (
    productIntegration : ProductIntegration,
  ) => null as Product | null,
  pushProductIntegration : async (
    productIntegration : ProductIntegration,
  ) => null as Product | null,
  pullProductIntegration : async (
    productIntegration : ProductIntegration,
  ) => null as Product | null,
  addServiceChannelToProduct : async (
    product : Product,
    serviceChannel : ServiceChannel,
  ) => null as boolean | null,
  removeServiceChannelFromProduct : async (
    product : Product,
    serviceChannel : ServiceChannel,
  ) => null as boolean | null,
  searchProducts : (query : string) => null as SearchResults<Product> | null,
  regenerateProductIndex : async () => null as boolean | null,
  createProductImageUpload :
    async (product : Product) => null as ProductImageUpload | null,
  pullProductImage : async (
    product : Product,
    image : Image,
  ) => null as Image | null,
  removeImageFromProduct : async (
    product : Product,
    image : Image,
  ) => null as boolean | null,
});

interface ProductsProviderProps {
  children : React.ReactNode;
}

export function ProductsProvider({
  children,
} : ProductsProviderProps) {
  const {
    createProduct,
    retrieveProducts,
    retrieveProductsBulk,
    retrieveProduct,
    updateProduct,
    deleteProduct,
    importProduct,
    createProductIntegration,
    deleteProductIntegration,
    syncProductIntegration,
    pushProductIntegration,
    pullProductIntegration,
    addServiceChannelToProduct,
    removeServiceChannelFromProduct,
    createProductImageUpload,
    pullProductImage,
    removeImageFromProduct,
  } = useProductsAPI();

  const [indexLoaded, setIndexLoaded] = useState(() => {
    try { return localStorage.getItem('indexLoaded') === 'true'; }
    catch { return false; }
  });

  const {
    data : products,
    dispatch : dispatchProducts,
    lastUpdated,
  } = useData<Product>({ storageKey : 'products' });
  const cache = useRef(products);
  cache.current = products;

  const checkProduct = useCallback((
    product : Product | null,
    options? : { withIntegrations? : boolean, since? : any },
  ) => {
    if (product && options?.withIntegrations) {
      return product.integrations !== undefined;
    }
    return true;
  }, []);

  const dispatchIndexProducts = useCallback((
    action : {
      data : DataIndex<Product> ,
      type? : ActionType,
    },
  ) => {
    const cached = cache.current;

    if (!cached) {
      dispatchProducts(action);
    } else {
      const newProducts = indexRecords(listRecords(action.data).filter(
        (p) => (p.id && !(p.id in cached)) || isProductLoaded(p),
      ));
      dispatchProducts({ data : newProducts, type : actionTypes.update });
    }
    setIndexLoaded(true);
  }, [cache, dispatchProducts]);

  const newProduct = useChange({
    dispatch : dispatchProducts,
    change : createProduct,
  })
  const refreshProducts = useRefreshIndex({
    dispatch : dispatchIndexProducts,
    retrieve : retrieveProducts,
    timestamp : (indexLoaded && listRecords(products).length)
      ? lastUpdated
      : undefined,
  });
  const refreshProductsBulk = useRefreshBulk({
    dispatch : dispatchProducts,
    retrieve : retrieveProductsBulk,
  });
  const refreshProduct = useRefresh({
    dispatch : dispatchProducts,
    retrieve : retrieveProduct,
  });
  const getProducts = useRetrieveIndex({
    data : products,
    timestamp : (indexLoaded && listRecords(products).length)
      ? lastUpdated
      : undefined,
    maxAge : MAX_AGE,
    refresh : refreshProducts,
  });
  const getProductsBulk = useRetrieveBulk({
    data : products,
    refresh : refreshProductsBulk,
    validate : isProductLoaded,
  });
  const getProduct = useRetrieve({
    data : products,
    refresh : refreshProduct,
    validate : checkProduct,
  });
  const amendProduct = useChange({
    dispatch : dispatchProducts,
    change : updateProduct,
  });
  const removeProduct = useDelete({
    dispatch : dispatchProducts,
    delete : deleteProduct,
  });

  const checkProducts = useCallback(async () => {
    return getProducts({ check : true });
  }, [getProducts]);

  const addServiceChannel = useRelate({
    dispatch : dispatchProducts,
    relate : addServiceChannelToProduct,
    callback : checkProducts,
  });
  const removeServiceChannel = useRelate({
    dispatch : dispatchProducts,
    relate : removeServiceChannelFromProduct,
    callback : checkProducts,
  });

  const loadProduct = useRelate({
    dispatch : dispatchProducts,
    relate : importProduct,
  });
  const newProductIntegration = useRelate({
    dispatch : dispatchProducts,
    relate : createProductIntegration,
  });
  const removeProductIntegration = useChange({
    dispatch : dispatchProducts,
    change : deleteProductIntegration,
  });
  const refreshProductIntegration = useChange({
    dispatch : dispatchProducts,
    change : syncProductIntegration,
  });
  const exportProductIntegration = useChange({
    dispatch : dispatchProducts,
    change : pushProductIntegration,
  });
  const importProductIntegration = useChange({
    dispatch : dispatchProducts,
    change : pullProductIntegration,
  });

  const dispatchUploadProduct = useCallback(
    (action : { data : DataIndex<ProductImageUpload> }) => {
      if (!action.data) return;
      Object.values(action.data).forEach((image) => {
        const product = Object.values(products || {}).find(
          (p) => Object.keys(p?.images ?? {}).includes(`${image?.id}`),
        );
        if (product?.id) refreshProduct(product.id);
      });
    },
    [products, refreshProduct]
  )
  const dispatchImageProduct = useCallback(
    (action : { data : DataIndex<Image> }) => {
      if (!action.data) return;
      Object.values(action.data).forEach((image) => {
        const product = Object.values(products || {}).find(
          (p) => Object.keys(p?.images ?? {}).includes(`${image?.id}`),
        );
        if (product?.id) refreshProduct(product.id);
      });
    },
    [products, refreshProduct],
  );

  const newImageUpload = useChange({
    dispatch : dispatchUploadProduct,
    change : createProductImageUpload,
  });
  const importImage = useRelate({
    dispatch : dispatchImageProduct,
    relate : pullProductImage,
  });
  const removeImage = useRelate({
    dispatch : dispatchProducts,
    relate : removeImageFromProduct,
    callback : checkProducts,
  });

  const { loaded, load } = useLoad({
    data : products,
    loader : checkProducts,
  });

  const {
    search : searchProducts,
    regenerate : regenerateProductIndex,
  } = useSearch<Product>({
    url : PRODUCT_SERACH_URL,
    records : products,
  });

  useEffect(() => {
    if (!indexLoaded) return;
    try { localStorage.setItem('indexLoaded', 'true'); } catch {}
  }, [indexLoaded]);

  const context = {
    products,
    loaded,
    load,
    refreshProducts,
    refreshProductsBulk,
    refreshProduct,
    createProduct : newProduct,
    retrieveProduct : getProduct,
    retrieveProductsBulk : getProductsBulk,
    retrieveProducts : getProducts,
    updateProduct : amendProduct,
    deleteProduct : removeProduct,
    importProduct : loadProduct,
    createProductIntegration : newProductIntegration,
    deleteProductIntegration : removeProductIntegration,
    syncProductIntegration : refreshProductIntegration,
    pushProductIntegration : exportProductIntegration,
    pullProductIntegration : importProductIntegration,
    addServiceChannelToProduct : addServiceChannel,
    removeServiceChannelFromProduct : removeServiceChannel,
    createProductImageUpload : newImageUpload,
    pullProductImage : importImage,
    removeImageFromProduct : removeImage,
    searchProducts,
    regenerateProductIndex,
  }

  return (
    <ProductsContext.Provider value={context}>
      { children }
    </ProductsContext.Provider>
  );
}

export default ProductsContext;
