import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import Preface from '#materials/Preface';
import Text from '#materials/Text';

interface Style { theme? : Theme; }

const FormSectionView = styled.div<Style>`
  text-align: left;
  margin: ${(props) => props.theme.layout.spacing.medium} 0 0;

  & > p {
    margin: ${(props) => props.theme.layout.spacing.xxsmall} 0 0;

    @media (max-width: ${(props) => props.theme.view.breakpoints.narrow}) {
      font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
    }
  }
`;

const FormSectionLabel = styled.div<Style>`
  div {
    @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
      font-size:
        ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
    }
  }
`;

const FormSectionContent = styled.div<Style>`
  margin: ${(props) => props.theme.layout.spacing.small} 0 0;
`;

interface FormSectionProps {
  title? : string;
  subtitle? : string;
  children? : React.ReactNode;
}

function FormSection({
  title,
  subtitle,
  children,
} : FormSectionProps) {
  return (
    <FormSectionView>
      { title ? (
        <FormSectionLabel>
          <Preface as="div" size="small">
            { title }
          </Preface>
        </FormSectionLabel>
      ) : null }
      { subtitle ? (
        <Text as="p" size="small">
          { subtitle }
        </Text>
      ) : null }
      <FormSectionContent>{ children }</FormSectionContent>
    </FormSectionView>
  );
}

export default FormSection;
