import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import { Minus, Plus } from '#materials/icons'

interface Style { theme? : Theme; }
interface QuantityViewStyle extends Style { bgColour? : string; }

const QuantityView = styled.div<QuantityViewStyle>`
  display: inline-flex;
  min-width: 9.2rem;
  align-items: center;
  justify-content: center;
  text-align: center;

  label {
    display: block;
  }

  button {
    transition: none;
    &:disabled {
      opacity: 0.75;
    }
  }
`;

const QuantityInput = styled.input<Style>`
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;

  color: ${(props) => props.theme.palette.accent.primary.text};
  background-color: ${(props) => props.theme.palette.accent.primary.fill};

  border: 0;
  border-radius: 1.2rem;
  border-color: ${(props) => props.theme.palette.border};

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
  font-weight: ${(props) =>
    props.theme.typography.fonts.default.weights.regular};
  line-height: 1;
  text-align: center;
  -webkit-font-smoothing: antialiased;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xxsmall};
  }

  &:active,
  &:focus {
    color: ${(props) => props.theme.palette.accent.primary.text};
    background-color: ${(props) => props.theme.palette.background.text.hover};
    border-color: ${(props) => props.theme.palette.border};
  }

  &:disabled {
    color: ${(props) => props.theme.palette.accent.primary.text};
    background-color: ${(props) => props.theme.palette.accent.primary.fill};
    border-color: ${(props) => props.theme.palette.border};
    opacity: 0.75;
  }
`;

const QuantityIncrement = styled.button<Style>`
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.8rem;
  color: ${(props) => props.theme.palette.accent.primary.fill};

  &:hover {
    color: ${(props) => props.theme.palette.background.text.hover};
  }

  &:disabled {
    color: ${(props) => props.theme.palette.accent.primary.fill};
  }
`;

interface QuantityProps {
  id : string;
  label : string;
  count : number;
  setCount : (count : number) => void;
  increment : () => void;
  decrement : () => void;
  incrementDisabled? : boolean;
  decrementDisabled? : boolean;
}

function Quantity({
  id,
  label,
  count,
  setCount,
  increment,
  decrement,
  incrementDisabled,
  decrementDisabled,
} : QuantityProps) {

  const [qty, setQty] = useState(count);

  const handleIncrement = useCallback(
    (evt : React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault();
      increment();
    },
    [increment],
  );

  const handleDecrement = useCallback(
    (evt : React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault();
      decrement();
    },
    [decrement],
  );

  const handleSetQuantity = useCallback(
    (evt : React.ChangeEvent<HTMLInputElement>) => {
      evt.preventDefault();
      setQty(parseInt(evt.target.value));
    },
    [],
  );

  const handleBlur = useCallback(
    (evt : React.FocusEvent<HTMLInputElement>) => {
      evt.preventDefault();
      setCount(qty);
    },
    [qty, setCount],
  );

  useEffect(() => { setQty(count); }, [count]);

  return (
    <QuantityView bgColour="secondary">
      <QuantityIncrement
        style={{ marginLeft: '0.2rem' }}
        onClick={handleDecrement}
        disabled={decrementDisabled}
        aria-label="Decrease quantity"
      >
        <Minus strokeWidth={2} />
      </QuantityIncrement>
      <label htmlFor={label}>
        <QuantityInput
          id={id}
          type="number"
          value={qty}
          onChange={handleSetQuantity}
          disabled={incrementDisabled && decrementDisabled}
          aria-label={label}
          onBlur={handleBlur}
        />
      </label>
      <QuantityIncrement
        style={{ marginRight: '0.2rem' }}
        onClick={handleIncrement}
        disabled={incrementDisabled}
        aria-label="Increase quantity"
      >
        <Plus strokeWidth={2} />
      </QuantityIncrement>
    </QuantityView>
  );
}

export default Quantity;
