import React, { useCallback, useEffect, useState } from 'react';

import { Category } from '#mrktbox/types';

import useCatalogue from '#hooks/useCatalogue';

import CategoriesListComponent from '#components/categories/CategoriesList';

function CategoriesList() {
  const {
    getRootCategories,
  } = useCatalogue();
  const [ categories, setCategories ] = useState<Category[]>([]);

  const retrieve = useCallback(async () => {
    setCategories(getRootCategories());
  }, [getRootCategories]);

  useEffect(() => {
    retrieve();
  }, [retrieve]);

  return (
    <CategoriesListComponent
      categories={categories}
      title="Categories"
    />
  );
}

export default CategoriesList;
