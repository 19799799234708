import React, { useEffect } from 'react';

import { useSearchParams } from '@mrktbox/clerk';

import useModal from '#hooks/useModal';

import Login from '#components/auth/Login';

function LoginRouter() {
  const { openModal } = useModal();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const email = searchParams.get('resetPassword');
    const activate = searchParams.get('activate') === 'true';
    if (email) {
      openModal((<Login resetEmail={email} activating={activate} />));
      searchParams.delete('resetPassword');
      if (activate) searchParams.delete('activate');
      setSearchParams(searchParams);
    }
  }, [openModal, searchParams, setSearchParams]);

  return null;
}

export default LoginRouter;
