import { Count, isCount } from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { RequestOptions, getUrl, request } from '#mrktbox/clerk/api/mrktbox';
import { formats, formatDateTime } from '#mrktbox/clerk/utils/date';

const INVENTORY_PATH = 'inventory/';
const COUNTS_PATH = `${INVENTORY_PATH}counts/`;

function parseCount(count : any) : Count {
  if (!isCount(count)) {
    throw new DeserializationError('Count', count);
  }
  return count;
}

function parseCounts(counts : any) {
  const parsedCounts : { [id : number] : Count } = {};
  for (const countId in counts) {
    if (typeof countId !== 'string') {
      throw new TypeError('Count id is not a string');
    }

    const id = parseInt(countId);
    if (isNaN(id)) throw new TypeError('Count id is not a number');

    parsedCounts[id] = parseCount(counts[countId]);
  }

  return parsedCounts;
}

export async function retrieveCounts(
  input : { since? : Date },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(
      COUNTS_PATH,
      input.since
        ? { since: formatDateTime(input.since, formats.iso) }
        : {},
    ),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseCounts(response.counts);
  } catch {
    throw new DeserializationError(
      'Could not parse count list',
      response,
    );
  }
}

export async function retrieveCount(
  { countId } : { countId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${COUNTS_PATH}${countId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseCount(response.count);
  } catch {
    throw new DeserializationError(
      'Could not parse count',
      response,
    );
  }
}
