import React from 'react';
import styled from '@emotion/styled';

import { Currency } from '#mrktbox/types';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

interface Style { theme? : Theme; }

const CheckSummaryTotalView = styled.li<Style>`
  display: flex;
  width: 100%;
  margin: ${(props) => props.theme.layout.spacing.small} 0;
  padding: ${(props) => props.theme.layout.spacing.small} 0 0;
  align-items: center;
  justify-content: space-between;

  color: ${(props) => props.theme.palette.background.text.primary};

  border-top:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.border};

  font-size: ${(props) => props.theme.typography.fonts.default.sizes.large};
  font-weight: ${(props) => props.theme.typography.fonts.default.weights.bold};

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }
`;

interface CheckSummaryTotalProps {
  label : string;
  value : Currency;
}

function CheckSummaryTotal({ label, value } : CheckSummaryTotalProps) {
  return (
    <CheckSummaryTotalView>
      <span>{ label }</span>
      <span>{ formatCurrency(value) }</span>
    </CheckSummaryTotalView>
  );
}

export default CheckSummaryTotal;
