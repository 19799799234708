import { ServiceChannel } from '@mrktbox/clerk/types';

import useConfig from '#hooks/useConfig';

import {
  Coffee,
  ShoppingBag,
  Truck,
  Users,
} from '#materials/icons';

interface ServiceIconProps {
  channel : ServiceChannel | null;
}

function ServiceIcon({ channel } : ServiceIconProps) {
  const { content } = useConfig();

  const key = Object.keys(content.serviceIcons).find(
    (icon) => channel &&
      channel.name.toLowerCase().includes(icon.toLowerCase()),
  ) as keyof typeof content.serviceIcons | undefined;

  if (!key) return (<Truck />);
  switch (content.serviceIcons[key]) {
    case 'coffee':
      return (<Coffee />);
    case 'shopping':
      return (<ShoppingBag />);
    case 'group':
      return (<Users />);
    default:
      return (<Truck />);
  }
}

export default ServiceIcon;
