import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { Address } from '#mrktbox/types';
import { useDebounce } from '@mrktbox/clerk';

import { Theme } from '#types';

import useMap from '#hooks/useMap';

import Input from '#materials/Input';
import ClearButton from '#materials/ClearButton';
import { MapPin } from '#materials/icons';

const SEARCH_DELAY = 1000; // ms

interface Style { theme? : Theme; }
interface AutocompletePredictionStyle extends Style { active? : boolean; }

const SearchView = styled.div<Style>`
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 ${(props) => props.theme.layout.spacing.small};

  label {
    padding: 0;
    margin-bottom: 0;

    & > span {
      padding-left: ${(props) => props.theme.layout.spacing.medium};
    }

    & > span > span:first-of-type {
      margin: 0;
    }

    input {
      line-height: 1;
      padding:
        ${(props) => props.theme.layout.spacing.small}
        ${(props) => props.theme.layout.spacing.medium}
        ${(props) => props.theme.layout.spacing.xsmall};
    }

    input:focus,
    input:active {
      padding:
        ${(props) => props.theme.layout.spacing.small}
        ${(props) => props.theme.layout.spacing.medium}
        ${(props) => props.theme.layout.spacing.xsmall};

      & + div {
        display: block;
      }
    }
  }
`;

const AutocompletePredictions = styled.div<Style>`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;

  background-color: ${(props) => props.theme.palette.background.fill};
`;

const AutocompletePrediction = styled.li<AutocompletePredictionStyle>`
  background-color: ${(props) => props.active
    ? props.theme.palette.background.text.hover
    : props.theme.palette.background.fill};

  border:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.border};
  border-top: none;
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};

  transition: background-color 0.150s;

  button {
    width: 100%;
    padding: ${(props) => props.theme.layout.spacing.xsmall};

    color: ${(props) => props.active
      ? props.theme.palette.background.fill
      : props.theme.palette.background.text.primary};

    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
    text-align: left;
  }
`;

const AutocompleteIcon = styled.div<Style>`
  display: flex;
  position: absolute;
  width: 3.2rem;
  top: ${(props) => props.theme.layout.spacing.small};
  bottom: ${(props) => props.theme.layout.spacing.xsmall};
  left: 0;
  align-items: center;
  justify-content: center;
`;

const AutocompleteSearchIcon = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  line-height: 1;
`;

const AutocompleteClear = styled(AutocompleteIcon)`
  width: auto;
  left: auto;
  right: 0;
`;

interface AddressAutocompleteProps {
  address? : Address | null;
  setAddress? : (address : Address | null) => void;
  placeholder? : string;
  defaults? : Address[];
}

function AddressSearch({
  address = null,
  placeholder = 'Enter your address',
  defaults,
  setAddress,
} : AddressAutocompleteProps) {
  const { debounce } = useDebounce({ delay : SEARCH_DELAY });
  const {
    searchAddresses,
    refreshAddress,
    setCenter,
    setCurrentLocation,
    removeCurrentLocation,
  } = useMap();

  const inputRef = useRef<HTMLInputElement>(null);
  const [input, setInput] = useState(address?.description || '');
  const [lastInput, setLastInput] = useState('');
  const [activeIndex, setActiveIndex] = useState(defaults?.length ? 0 : -1);
  const [predictions, setPredictions] = useState(defaults ?? []);

  const search = useCallback(async (input : string) => {
    const results = await searchAddresses(input);
    if (!results) return;

    const predictions = Object.values(results);
    setPredictions(predictions);
    if (predictions.length && predictions[0].description !== input) {
      setActiveIndex(0);
    }
  }, [searchAddresses]);

  const refresh = useCallback(async () => {
    if (!address?.id || (address.latitude && address.longitude)) return;
    const refreshed = await refreshAddress(address.id);
    if (!refreshed) return;
    if (setAddress) setAddress(refreshed);
  }, [address, setAddress, refreshAddress]);

  const chooseAddress = useCallback((address : Address) => () => {
    if (setAddress) setAddress(address);
    setInput(address.description ?? '');
  }, [setAddress]);

  const clearInput = useCallback(() => {
    if (setAddress) setAddress(null);
    setInput('');
    setPredictions(defaults ?? []);
    inputRef.current?.focus();
  }, [defaults, setAddress]);

  const handleBlur = useCallback(() => {
    if (input) return;
    setPredictions(defaults ?? []);
  }, [defaults, input]);

  const handleKeyPress = useCallback(
    (evt : KeyboardEvent) => {
      const handleEnter = (targetId : string) => {
        if (!predictions || !predictions.length) return;
        if (targetId !== 'address') return;
        if (activeIndex < 0) return;

        const prediction = activeIndex
          ? (predictions[activeIndex] ?? predictions[0])
          : predictions[0];
        if (prediction) chooseAddress(prediction)();
        setActiveIndex(-1);
      }

      const handleMove = (increment : number) => {
        evt.preventDefault();
        if (!predictions.length) setActiveIndex(0);

        const max = predictions.length - 1;
        let val = activeIndex === null ? 0 : activeIndex + increment;
        val = val < -1 ? -1 : (val > max ? max : val);
        setActiveIndex(val);
      }

      const targetId = (evt.target && 'id' in evt.target)
          ? evt.target.id as string
          : '';

      switch (evt.key) {
        case 'Enter':
          return handleEnter(targetId);
        case 'ArrowUp':
          return handleMove(-1);
        case 'ArrowDown':
          return handleMove(1);
        case 'Escape':
          return setActiveIndex(-1);
        default:
          break
      }
    },
    [activeIndex, predictions, chooseAddress],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false);
    return () => document.removeEventListener('keydown', handleKeyPress, false);
  }, [handleKeyPress]);

  useEffect(() => {
    if (input) {
      if (input !== lastInput && (!address || input !== address?.description)) {
        debounce(() => search(input));
        setLastInput(input);
      }
    } else setPredictions(defaults ?? []);
  }, [address, defaults, input, lastInput, search, debounce]);

  useEffect(() => {
    if (!address) {
      removeCurrentLocation();
      return;
    }

    refresh();
    if (!address.latitude || !address.longitude) return;

    setCenter(address.latitude, address.longitude);
    setCurrentLocation(address.latitude, address.longitude);
  }, [address, setCenter, refresh, setCurrentLocation, removeCurrentLocation]);

  return (
    <SearchView>
      <Input
        label="Please enter your address"
        name="address"
        type="text"
        value={input}
        placeholder={placeholder}
        onChange={setInput}
        showLabel={false}
        inputRef={inputRef}
        onBlur={handleBlur}
      >
        <AutocompletePredictions>
          { (predictions && (activeIndex > -1)) ? (
            <ul>
              {predictions.map((address, index) => (
                <AutocompletePrediction
                  key={address.id ?? `prediction-${index}`}
                  active={activeIndex === index}
                >
                  <button onClick={chooseAddress(address)}>
                    {address.description}
                  </button>
                </AutocompletePrediction>
              ))}
            </ul>
          ) : null}
        </AutocompletePredictions>
        <AutocompleteIcon>
          <AutocompleteSearchIcon>
            <MapPin />
          </AutocompleteSearchIcon>
        </AutocompleteIcon>
        {input.length ? (
          <AutocompleteClear>
            <ClearButton
              ariaLabel="Clear text & start over"
              onClick={clearInput}
            />
          </AutocompleteClear>
        ) : null}
      </Input>
    </SearchView>
  );
}

export default AddressSearch;
