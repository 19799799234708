import React from 'react';
import styled from '@emotion/styled'

import { Theme } from '#types';

import Body from '#materials/Body';
import Heading from '#materials/Heading';

interface Style {
  theme? : Theme;
}

interface CategoryHeaderTitleProps extends Style {
  isChild : boolean;
}

const CategoryHeaderView = styled.div<Style>`
  margin: 0 0 ${(props) => props.theme.layout.spacing.medium};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    margin: 0 0 ${(props) => props.theme.layout.spacing.small};
  }
`;


const CategoryHeaderTitle = styled(Heading)<CategoryHeaderTitleProps>`
  font-family: ${(props) => props.theme.typography.fonts.brand.family};
  font-size: ${(props) => props.theme.typography.fonts.brand.sizes[
    props.isChild ? 'xlarge' : 'xxlarge'
  ]};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.brand.sizes[
      props.isChild ? 'large' : 'xlarge'
    ]};
  }
`;

const CategoryHeaderSubtitle = styled(Body)`
  margin: ${(props) => props.theme.layout.spacing.xsmall} 0 0;
  white-space: pre-line;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.spacing.xxsmall} 0 0;
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  }
`;

interface CategoryHeaderProps {
  title : string;
  subtitle? : string;
  focusable? : boolean;
  id? : string;
  isChild : boolean;
}

function CategoryHeader({
  title,
  subtitle,
  focusable = true,
  id,
  isChild,
} : CategoryHeaderProps) {
  return (
    <CategoryHeaderView>
      <CategoryHeaderTitle
        id={id}
        as="p"
        isChild={isChild}
        tabIndex={focusable ? 0 : undefined}
      >
        {title}
      </CategoryHeaderTitle>
      { subtitle &&
        <CategoryHeaderSubtitle as="p">
          { subtitle }
        </CategoryHeaderSubtitle>
      }
    </CategoryHeaderView>
  )
}

export default CategoryHeader;
