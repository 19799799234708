import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';

import { useAuth } from '#mrktbox';

import Text from '#materials/Text';
import Input from '#materials/Input';
import Form from '#materials/Form';

import ButtonStyled from '#materials/ButtonStyled';

const ReturnButton = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem auto 0;
`

interface SendResetProps {
  init? : string;
  activating?: boolean;
  onClose: () => void;
}

function SendReset({ init, activating, onClose } : SendResetProps) {
  const [email, setEmail] = useState(init ?? '');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { sendPasswordReset } = useAuth();

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    await sendPasswordReset(email);
    setSubmitted(true);
    setSubmitting(false);
  }, [email, sendPasswordReset]);

  const prompt = activating
    ? 'Click below to recieve an activation email.'
    : 'Enter your email address to receive a reset link.'

  const confirmation = activating
    ? 'An activation link has been sent to your email.'
    : 'If an account with that email exists, we sent a password reset link.'

  return (submitted
    ? (
      <>
        <Text>
          { confirmation }
        </Text>
        <ReturnButton>
          <ButtonStyled onClick={onClose}>
            Return
          </ButtonStyled>
        </ReturnButton>
      </>
    ) : (
      <>
        <Text>
          { prompt }
        </Text>
        <Form
          onSubmit={handleSubmit}
          buttons={(
            <>
              { !activating && (
                <ButtonStyled
                  type="button"
                  onClick={onClose}
                  preventDefault
                >
                  Cancel
                </ButtonStyled>
              ) }
              <ButtonStyled
                type="submit"
                disabled={submitting}
              >
                Send {activating ? 'Activation' : 'Reset'} Email
              </ButtonStyled>
            </>
          )}
        >
          <Input
            label="Email"
            name="email"
            type="email"
            value={email}
            disabled={activating}
            onChange={setEmail}
            required={true}
          />
        </Form>
      </>
    )
  );
}

export default SendReset;
