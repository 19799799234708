import { useCallback } from 'react';

import { Area, Service, Route } from '#mrktbox/clerk/types';

import {
  createRoute as createRouteAPI,
  retrieveRoutes as retrieveRoutesAPI,
  retrieveRoute as retrieveRouteAPI,
  updateRoute as updateRouteAPI,
  deleteRoute as deleteRouteAPI,
  addAreaToRoute as addAreaToRouteAPI,
  removeAreaFromRoute as removeAreaFromRouteAPI,
  addServiceToRoute as addServiceToRouteAPI,
  removeServiceFromRoute as removeServiceFromRouteAPI,
} from '#mrktbox/clerk/api/mrktbox/routes';

import useAPI from '#mrktbox/clerk/hooks/api/useAPI';

function useRoutesAPI() {
  const { processRequest } = useAPI();

  const createRoute = useCallback(async (route : Route) => {
    return await processRequest(
      createRouteAPI,
      { route },
    )
  }, [processRequest]);

  const retrieveRoutes = useCallback(async () => {
    return await processRequest(
      retrieveRoutesAPI,
      {},
    )
  }, [processRequest]);

  const retrieveRoute = useCallback(async (routeId : number) => {
    return await processRequest(
      retrieveRouteAPI,
      { routeId },
    )
  }, [processRequest]);

  const updateRoute = useCallback(async (route : Route) => {
    return await processRequest(
      updateRouteAPI,
      { route },
    )
  }, [processRequest]);

  const deleteRoute = useCallback(async (route : Route) => {
    return await processRequest(
      deleteRouteAPI,
      { route },
    )
  }, [processRequest]);

  const addAreaToRoute = useCallback(async (route : Route, area : Area) => {
    return await processRequest(
      addAreaToRouteAPI,
      { route, area },
    )
  }, [processRequest]);

  const removeAreaFromRoute = useCallback(
    async (route : Route, area : Area) => {
      return await processRequest(
        removeAreaFromRouteAPI,
        { route, area },
      )
    },
    [processRequest],
  );

  const addServiceToRoute = useCallback(
    async (route : Route, service : Service) => {
      return await processRequest(
        addServiceToRouteAPI,
        { route, service },
      )
    },
    [processRequest],
  );

  const removeServiceFromRoute = useCallback(
    async (route : Route, service : Service) => {
      return await processRequest(
        removeServiceFromRouteAPI,
        { route, service },
      )
    },
    [processRequest],
  );

  return {
    createRoute,
    retrieveRoutes,
    retrieveRoute,
    updateRoute,
    deleteRoute,
    addAreaToRoute,
    removeAreaFromRoute,
    addServiceToRoute,
    removeServiceFromRoute,
  };
}

export default useRoutesAPI;
