import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import { ThemeProvider } from '#context/ThemeContext';

import ButtonLink from '#materials/ButtonLink';
import IconButton from '#materials/IconButton';
import { X } from '#materials/icons';

interface Style { theme? : Theme; }

const BannerView = styled.div<Style>`
  position: fixed;
  top: ${(props) => props.theme.view.nav.height.default};
  left: 0;
  right: 0;
  z-index: 30;
  padding: ${(props) => props.theme.layout.spacing.small};

  color: ${(props) => props.theme.palette.background.text.primary};
  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  font-weight: ${(props) => props.theme.typography.fonts.default.weights.bold};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
  letter-spacing:
    ${(props) => props.theme.typography.fonts.default.letterSpacing};

  background-color: ${(props) => props.theme.palette.background.fill};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    top: ${(props) => props.theme.view.nav.height.mobile};
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
    padding: ${(props) => props.theme.layout.spacing.xsmall}
      ${(props) => props.theme.layout.spacing.small};
  }
`;

const ContentView = styled.div<Style>`
  display: flex;
  width: calc(${(props) => props.theme.view.breakpoints.full}
    - ${(props) => props.theme.layout.spacing.small} * 2);
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.layout.spacing.xsmall};

  @media (max-width: ${(props) => props.theme.view.breakpoints.full}) {
    width: 100%;
  }

  & > button {
    height: 1.5rem;
    width: 1.5rem;
    margin: 0;
  }
`;

const Content = styled.div<Style>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

interface BannerProps {
  link? : string;
  href? : string;
  onClose? : () => void;
  children? : React.ReactNode;
}

function Banner({
  link,
  href,
  onClose,
  children,
} : BannerProps) {
  return (
    <ThemeProvider themeKey={'banner'} >
      <BannerView>
        <ContentView>
          <Content>
            { children }
            { (link || href) && (
              <ButtonLink href={href} newTab>
                { link ?? 'Learn More' }
              </ButtonLink>
            ) }
          </Content>
          { onClose && (
            <IconButton onClick={onClose} >
              <X />
            </IconButton>
          ) }
        </ContentView>
      </BannerView>
    </ThemeProvider>
  );
}

export default Banner;
