import React, { createContext, useCallback } from 'react';

import { Image, Category, CategoryImageUpload } from '#mrktbox/clerk/types';

import useData, {
  DataIndex,
  useLoad,
  useChange,
  useRefreshIndex,
  useRefresh,
  useRetrieveIndex,
  useRetrieve,
  useDelete,
  useRelate,
} from '#mrktbox/clerk/hooks/useData';
import useTags from '#mrktbox/clerk/hooks/useTags';
import useCategoriesAPI from '#mrktbox/clerk/hooks/api/useCategoriesAPI';

export type CategoryIndex = DataIndex<Category>;

const MAX_AGE = 1000 * 60 * 60;

const CategoryContext = createContext({
  categories: null as DataIndex<Category> | null,
  loaded: false,
  load: () => {},
  refreshCategory: async (id : number) => null as Category | null,
  refreshCategories: async () => null as CategoryIndex | null,
  createCategory: async (category : Category) => null as Category | null,
  retrieveCategory: async (id : number) => null as Category | null,
  retrieveCategories: async () => null as CategoryIndex | null,
  updateCategory: async (category : Category) => null as Category | null,
  deleteCategory: async (category : Category) => null as boolean | null,
  addSubcategoryToCategory: async (
    category : Category,
    subcategory : Category,
    before? : Category | null,
  ) => null as Category | null,
  removeSubcategoryFromCategory: async (
    category : Category,
    subcategory : Category,
  ) => null as Category | null,
  createCategoryImageUpload:
    async (category : Category) => null as CategoryImageUpload | null,
  pullCategoryImage: async (
    category : Category,
    image : Image,
  ) => null as Image | null,
  removeImageFromCategory: async (
    category : Category,
    image : Image,
  ) => null as boolean | null,
});

interface CategoryProviderProps {
  children : React.ReactNode;
}

export function CategoryProvider({ children } : CategoryProviderProps) {
  const { refreshTag, refreshTags } = useTags();
  const {
    createCategory,
    retrieveCategories,
    retrieveCategory,
    updateCategory,
    deleteCategory,
    addSubcategoryToCategory,
    removeSubcategoryFromCategory,
    createCategoryImageUpload,
    pullCategoryImage,
    removeImageFromCategory,
  } = useCategoriesAPI();

  const refreshCategoryTag = useCallback(async (category : Category | null) => {
    if (!category?.id) return null;
    return await refreshTag(category.id);
  }, [refreshTag]);

  const {
    data: categories,
    dispatch: dispatchCategories,
    lastUpdated,
  } = useData<Category>({ storageKey : 'categories' });

  const newCategory = useChange({
    dispatch : dispatchCategories,
    change : createCategory,
    callback : refreshCategoryTag,
  });
  const refreshCategories = useRefreshIndex({
    dispatch : dispatchCategories,
    retrieve : retrieveCategories,
    callback : refreshTags,
  });
  const refreshCategory = useRefresh({
    dispatch : dispatchCategories,
    retrieve : retrieveCategory,
    callback : refreshCategoryTag,
  });
  const getCategory = useRetrieve({
    data : categories,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshCategory,
  });
  const getCategories = useRetrieveIndex({
    data : categories,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshCategories,
  });
  const getCategorySubcategories = useRetrieveIndex({
    data : categories,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshCategories,
  });
  const amendCategory = useChange({
    dispatch : dispatchCategories,
    change : updateCategory,
    callback : refreshCategoryTag,
  });
  const removeCategory = useDelete({
    dispatch : dispatchCategories,
    delete : deleteCategory,
  });

  const addSubcategory = useRelate({
    dispatch : dispatchCategories,
    relate : addSubcategoryToCategory,
  });
  const removeSubcategory = useRelate({
    dispatch : dispatchCategories,
    relate : removeSubcategoryFromCategory,
  });

  const dispatchUploadCategory = useCallback(
    (action : { data : DataIndex<CategoryImageUpload> }) => {
      if (!action.data) return;
      Object.values(action.data).forEach((image) => {
        if (image?.categoryId) refreshCategory(image.categoryId);
      });
    },
    [refreshCategory],
  );
  const dispatchImageCategory = useCallback(
    (action : { data : DataIndex<Image> }) => {
      if (!action.data) return;
      Object.values(action.data).forEach((image) => {
        const category = Object.values(categories || {}).find(
          (c) => Object.keys(c?.images ?? {}).includes(`${image?.id}`),
        );
        if (category?.id) refreshCategory(category.id);
      });
    },
    [categories, refreshCategory],
  );

  const newImageUpload = useChange({
    dispatch : dispatchUploadCategory,
    change : createCategoryImageUpload,
  });
  const importImage = useRelate({
    dispatch : dispatchImageCategory,
    relate : pullCategoryImage,
  });
  const removeImage = useRelate({
    dispatch : dispatchCategories,
    relate : removeImageFromCategory,
    callback : refreshCategories,
  });

  const { loaded, load } = useLoad({
    data : categories,
    loader : refreshCategories,
  });

  const context = {
    categories,
    loaded,
    load,
    createCategory : newCategory,
    refreshCategories,
    refreshCategory,
    retrieveCategory : getCategory,
    retrieveCategories : getCategories,
    retrieveCategorySubcategories : getCategorySubcategories,
    updateCategory : amendCategory,
    deleteCategory : removeCategory,
    addSubcategoryToCategory : addSubcategory,
    removeSubcategoryFromCategory : removeSubcategory,
    createCategoryImageUpload : newImageUpload,
    pullCategoryImage : importImage,
    removeImageFromCategory : removeImage,
  };

  return (
    <CategoryContext.Provider value={context}>
      { children }
    </CategoryContext.Provider>
  );
}

export default CategoryContext;
