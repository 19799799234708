import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useModal from '#hooks/useModal';

import Heading from '#materials/Heading';
import ButtonStyled from '#materials/ButtonStyled';

import ModalView from '#components/modal/ModalView';
import ModalClose from '#components/modal/ModalClose';

interface Style { theme? : Theme; }

const SubscriptionView = styled(ModalView)`
  text-align: center;
  padding: ${(props) => props.theme.layout.spacing.medium};

  color: ${(props) => props.theme.palette.background.text.primary};

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    padding: ${(props) => props.theme.layout.spacing.small};
  }
`;

const Title = styled.p<Style>`
  width: 100%;
  margin: 0 0 ${(props) => props.theme.layout.spacing.xsmall};
  text-align: center;
  font-size: ${(props) => props.theme.typography.fonts.title.sizes.xxlarge};
  line-height: ${(props) => props.theme.typography.fonts.title.lineHeight};
`;

const Preface = styled.p<Style>`
  margin: 0 0 ${(props) => props.theme.layout.spacing.small};
  white-space: pre-wrap;
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
`;

const Buttons = styled.div<Style>`
  display: flex;
  margin-top: ${(props) => props.theme.layout.spacing.medium};
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.layout.spacing.xsmall};
  justify-content: center;

  * {
    white-space: nowrap;
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    margin-top: ${(props) => props.theme.layout.spacing.small};
  }
`;

interface SubscriptionChangeProps {
  title : string;
  prompt : string;
  selectTarget? : (target : 'this' | 'future') => Promise<boolean | void>
    | boolean
    | void;
}

function SubscriptionChange({
  title,
  prompt,
  selectTarget,
} : SubscriptionChangeProps) {
  const { closeModal } = useModal();
  const [processing, setProcessing] = useState(false);

  const handleThis = useCallback(async () => {
    setProcessing(true);
    const success = selectTarget ? (await selectTarget?.('this')) : false;
    setProcessing(false);
    if (success) closeModal();
  }, [selectTarget, closeModal]);

  const handleFuture = useCallback(async () => {
    setProcessing(true);
    const success = selectTarget ? (await selectTarget?.('future')) : false;
    setProcessing(false);
    if (success) closeModal();
  }, [selectTarget, closeModal]);

  return (
    <SubscriptionView>
      <ModalClose onClick={closeModal} />
      <Heading>
        <Title>{ title }</Title>
      </Heading>
      <Preface>{ prompt }</Preface>
      <Buttons>
        <ButtonStyled onClick={handleThis} disabled={processing}>
          This Order
        </ButtonStyled>
        <ButtonStyled onClick={handleFuture} disabled={processing}>
          Future Orders
        </ButtonStyled>
      </Buttons>
    </SubscriptionView>
  );
}

export default SubscriptionChange;
