import styled from '@emotion/styled'
import { Theme } from '#types';

interface Style {
  theme? : Theme;
  size? : keyof Theme['typography']['fonts']['default']['sizes'];
  colour? : keyof Theme['palette']['background']['text'];
}

const Preface = styled.span<Style>`
  display: block;
  margin: 0 0 ${(props) => props.theme.layout.spacing.xxsmall};
  color: ${(props) => props.theme.palette.background.text.primary};

  font-size: ${(props) => props.theme.typography.fonts.title.sizes[
    props.size ? props.size : 'small'
  ]};
  letter-spacing: ${(props) =>
    props.theme.typography.fonts.title.letterSpacing};
  line-height: ${(props) => props.theme.typography.fonts.title.lineHeight};
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
`;

export default Preface;
