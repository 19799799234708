import {
  Image,
  Category,
  isImage,
  isCategory,
  isCategoryImageUpload,
} from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

const SUBCATEGORIES_PATH = 'subcategories/';
const CATEGORIES_PATH = 'categories/';

function parseCategory(category : any) {
  if (!isCategory(category))
    throw new TypeError('Category is not a category');
  return category;
}

function parseCategories(categories : any) {
  const parsedCategories : { [id : number] : Category } = {};
  for (const categoryId in categories) {
    if (typeof categoryId !== 'string')
      throw new TypeError('Category id is not a string');

    const id = parseInt(categoryId);
    if (isNaN(id)) throw new TypeError('Category id is not a number');

    parsedCategories[id] = parseCategory(categories[id]);
  }

  return parsedCategories;
}

function parseCategoryImageUpload(upload : any) {
  if (!isCategoryImageUpload(upload))
    throw new TypeError('Upload is not a category image upload');
  return upload;
}

function parseImage(image : any) {
  if (!isImage(image)) throw new TypeError('Image is not an image');
  return image;
}

export async function createCategory(
  { category } : { category : Category },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(CATEGORIES_PATH),
    methods.post,
    { category },
    options,
  );

  try {
    return parseCategory(response.category);
  } catch {
    throw new DeserializationError(
      'Could not deserialize category',
      response,
    );
  }
}

export async function retrieveCategories(
  input : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(CATEGORIES_PATH),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseCategories(response.categories);
  } catch {
    throw new DeserializationError(
      'Could not deserialize categories',
      response,
    );
  }
}

export async function retrieveCategory(
  { categoryId } : { categoryId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${CATEGORIES_PATH}${categoryId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseCategory(response.category);
  } catch {
    throw new DeserializationError(
      'Could not deserialize category',
      response,
    );
  }
}

export async function updateCategory(
  { category } : { category : Category },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${CATEGORIES_PATH}${category.id}`),
    methods.put,
    { category },
    options,
  );

  try {
    return parseCategory(response.category);
  } catch {
    throw new DeserializationError(
      'Could not deserialize category',
      response,
    );
  }
}

export async function deleteCategory(
  { category } : { category : Category },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${CATEGORIES_PATH}${category.id}`),
    methods.delete,
    undefined,
    options,
  );

  return !!response;
}

export async function addSubcategoryToCategory({
  subcategory,
  category,
  before,
} : {
  subcategory : Category,
  category : Category,
  before? : Category | null,
}, options? : RequestOptions) {
  if (subcategory.id === category.id) return null;
  const response = await request(
    getUrl(`${CATEGORIES_PATH}${category.id}/${SUBCATEGORIES_PATH}` +
      `${subcategory.id}`),
    methods.post,
    { before : before?.id },
    options,
  );

  try {
    return parseCategory(response.category);
  } catch {
    throw new DeserializationError(
      'Could not deserialize category',
      response,
    );
  }
}

export async function removeSubcategoryFromCategory({
  subcategory,
  category,
} : {
  subcategory : Category,
  category : Category,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${CATEGORIES_PATH}${category.id}/${SUBCATEGORIES_PATH}` +
      `${subcategory.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseCategory(response.category);
  } catch {
    throw new DeserializationError(
      'Could not deserialize category',
      response,
    );
  }
}

export async function createCategoryImageUpload(
  { category } : { category : Category },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${CATEGORIES_PATH}${category.id}/images/upload/`),
    methods.post,
    {},
    options,
  );

  try {
    return parseCategoryImageUpload(response.categoryImageUpload);
  } catch {
    throw new DeserializationError(
      'Could not deserialize category image upload',
      response,
    );
  }
}

export async function pullCategoryImage({
  category,
  image,
} : {
  category : Category,
  image : Image,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${CATEGORIES_PATH}${category.id}/images/${image.id}/pull/`),
    methods.post,
    {},
    options,
  );

  try {
    return parseImage(response.image);
  } catch {
    throw new DeserializationError(
      'Could not deserialize image',
      response,
    );
  }
}

export async function removeImageFromCategory({
  category,
  image,
} : {
  category : Category,
  image : Image,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${CATEGORIES_PATH}${category.id}/images/${image.id}`),
    methods.delete,
    undefined,
    options,
  );

  return !!response;
}
