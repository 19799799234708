import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import ButtonStyled from '#materials/ButtonStyled';

import {
  formats,
  formatDateTime,
  formatDate,
  formatTime,
} from '#mrktbox/utils';

function formatWindow(window : { start : Date, end : Date | null }) {
  const start = formatTime(window.start, formats.short);
  const end = window.end ? formatTime(window.end, formats.short) : '';
  return end
    ? `from ${start} to ${end}`
    : `@ ${start}`;
}

function formatCuttoff(cutoff : Date) {
  const dateTime = formatDateTime(cutoff, formats.easy);
  return `Order by ${dateTime}`;
}

interface Style { theme? : Theme; }

const OrderTimeOptionsView = styled.ul<Style>`
  margin: ${(props) => props.theme.layout.spacing.medium} 0;
  color: ${(props) => props.theme.palette.background.text.primary};
`;

const OrderTimeOptionView = styled.li<Style>`
  padding: ${(props) => props.theme.layout.spacing.xsmall} 0;
  border-bottom:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.border};

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${(props) => props.theme.layout.spacing.xsmall};
  }
`;

const OrderTimeOption = styled.div<Style>`
  p {
    color: ${(props) => props.theme.palette.background.text.primary};
    text-align: left;
    line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};

    &:last-of-type {
      margin: 0.2rem 0 0;
    }
  }

  p > span {
    white-space: nowrap;
  }

  & + button {
    width: 9rem;
    flex-shrink: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;

interface OrderTimeOptionsProps {
  options : { start : Date, end : Date | null, cutoff? : Date | null }[];
  selected? : Date | null;
  onSelect? : (date : Date) => void;
  disabled? : boolean;
}

function DateTimeOptions({
  options,
  selected,
  disabled,
  onSelect,
} : OrderTimeOptionsProps) {
  const handleClick = useCallback((date : Date) => () => {
    if (onSelect) onSelect(date);
  }, [onSelect]);

  function isSelected(option : { start : Date, end : Date | null }) {
    return selected && selected.getTime() === option.start.getTime();
  }

  function sortOptions(
    a : { start : Date, end : Date | null },
    b : { start : Date, end : Date | null },
  ) {
    return a.start.getTime() - b.start.getTime();
  }

  const sortedOptions = options.sort(sortOptions);

  return (
    <OrderTimeOptionsView>
      { sortedOptions.map((option) => (
        <OrderTimeOptionView key={option.start.toISOString()}>
          <div>
            <OrderTimeOption>
              <p>
                <span>{ formatDate(option.start, formats.easy) }</span>
                { ' ' }
                <span>{ formatWindow(option) }</span>
              </p>
              <p>{ formatCuttoff(option.cutoff ?? option.start) }</p>
            </OrderTimeOption>
            <ButtonStyled
              onClick={handleClick(option.start)}
              disabled={disabled}
              colour={isSelected(option) ? 'primary' : 'secondary'}
              size="small"
            >
              { isSelected(option) ? 'Keep' : 'Select' }
            </ButtonStyled>
          </div>
        </OrderTimeOptionView>
      )) }
    </OrderTimeOptionsView>
  );
}

export default DateTimeOptions;
