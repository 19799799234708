
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled';

import { Currency, Product, Tag } from '#mrktbox/types';

import { Theme } from '#types';

import { ThemeProvider } from '#context/ThemeContext';

import useConfig from '#hooks/useConfig';
import useCatalogue from '#hooks/useCatalogue';

import Heading from '#materials/Heading';
import Count from '#materials/Count';
import { X } from '#materials/icons'

import ProductImage from '#components/products/ProductImage';
import ProductPriceCals from '#components/products/ProductPriceCals';
import ProductTags from '#components/products/ProductTags';

interface Style { theme? : Theme; }

const ProductOptionSquareView = styled.div<Style>`
  position: relative;
  width:
    calc(25% - ((3 / 4) * ${(props) => props.theme.layout.spacing.xsmall}));
  padding: 0;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    width:
      calc(33.3% - ((2 / 3) * ${(props) => props.theme.layout.spacing.xsmall}));
  }

  @media (max-width: 320px) {
    width:
      calc(50% - ((1 / 2) * ${(props) => props.theme.layout.spacing.xsmall}));
  }

  @media (max-width: 240px) {
    width: 100%;
  }
`;

const ProductOptionSquareCount = styled.div<Style>`
  position: absolute;
  z-index: 5;
  top:calc(-${(props) => props.theme.typography.fonts.default.sizes.small}
    * 0.75);
  right: calc(-${(props) => props.theme.typography.fonts.default.sizes.small}
    * 0.5);
`;

const ProductOptionSquareRemove = styled.button<Style>`
  display: flex;
  position: absolute;
  width:
    calc(2 * ${(props) => props.theme.typography.fonts.default.sizes.large});
  height:
    calc(2 * ${(props) => props.theme.typography.fonts.default.sizes.large});
  top: calc(-${(props) => props.theme.typography.fonts.default.sizes.small}
    * 0.5);
  left:
    calc(-${(props) => props.theme.typography.fonts.default.sizes.small}
      * 0.5);
  z-index: 5;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.palette.background.text.primary};
`;

const ProductOptionSquareRemoveIcon = styled.div<Style>`
  display: flex;
  width: ${(props) => props.theme.typography.fonts.default.sizes.large};
  height: ${(props) => props.theme.typography.fonts.default.sizes.large};
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const ProductOptionSquareButton = styled.button<Style>`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1rem 0 0.8rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  background-color: ${(props) => props.theme.palette.background.fill};
  box-shadow: ${(props) => props.theme.layout.boxShadow.outer.map((bs) => (`
    ${bs.x} ${bs.y} ${bs.blur} ${bs.spread} ${bs.colour}
  `)).join(', ')};

  border:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.border};
  border-radius: ${(props) => props.theme.layout.border.radius};
`;

const ProductOptionSquareImageContainer = styled.div<Style>`
  display: flex;
  width: 100%;
  height: 6rem;
  margin:
    ${(props) => props.theme.layout.spacing.xxsmall}
    0
    ${(props) => props.theme.layout.spacing.xsmall};
  padding: 0 1.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
`;

const ProductOptionImageView = styled.div<Style>`
  position: relative;
  width: 6rem;
  height: 6rem;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;

  background-color: ${(props) => props.theme.palette.background.fill};
  border-radius: ${(props) => props.theme.layout.border.radius};
`;

const ProductOptionSquareText = styled.div<Style>`
  display: flex;
  max-width: 100%;
  padding: 0 ${(props) => props.theme.layout.spacing.xxsmall};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;

const ProductOptionSquareName = styled(Heading)``;

interface ProductOptionsSquare {
  product : Product;
  price? : Currency;
  count : number;
  disabled? : boolean;
  disabledRemove? : boolean;
  addProduct : () => void;
  removeProduct : () => void;
}

function ProductOptionSquare({
  product,
  price,
  count,
  disabled = false,
  disabledRemove = false,
  addProduct,
  removeProduct,
} : ProductOptionsSquare) {
  const { theme } = useConfig();
  const { getProductTags, isProductStocked } = useCatalogue();

  const [tags, setTags] = useState<Tag[]>(getProductTags(product));

  useEffect(() => {
    setTags(getProductTags(product));
  }, [product, getProductTags]);

  const outOfStock = !isProductStocked(
    product,
    undefined,
    { checkOptions: false },
  );

  return (
    <ThemeProvider themeKey="card">
      <ProductOptionSquareView>
        { count > 0 && (
          <>
            <ProductOptionSquareCount key={count}>
              <Count count={count} size="small" colour="success" />
            </ProductOptionSquareCount>
            { !disabledRemove && (
              <ProductOptionSquareRemove onClick={removeProduct}>
                <ProductOptionSquareRemoveIcon>
                  <X
                    colour={theme.palette.notifications.alert.fill}
                    size={20}
                    strokeWidth={2}
                  />
                </ProductOptionSquareRemoveIcon>
              </ProductOptionSquareRemove>
            ) }
          </>
        )}
        <ProductOptionSquareButton
          onClick={addProduct}
          disabled={disabled || outOfStock}
        >
          <ProductOptionSquareImageContainer>
            <ProductOptionImageView>
              <ProductImage product={product} variant="thumbnail" />
            </ProductOptionImageView>
          </ProductOptionSquareImageContainer>
          <ProductOptionSquareText>
            <ProductOptionSquareName size="xsmall">
              { product.name }
            </ProductOptionSquareName>
            { outOfStock
              ? (
                <Heading size="xsmall">
                  Out of stock
                </Heading>
              ) : (
                price && (
                  <>
                    <ProductPriceCals
                      price={price}
                      size="xsmall"
                      style={{ marginTop: '0.5rem' }}
                    />
                  </>
                )
              ) }
              <ProductTags
                tags={tags}
                isCentered
              />
          </ProductOptionSquareText>
        </ProductOptionSquareButton>
      </ProductOptionSquareView>
    </ThemeProvider>
  );
}

export default ProductOptionSquare;
