import React, { createContext } from 'react';

import { Area, Service, Route } from '#mrktbox/clerk/types';

import useData, {
  DataIndex,
  useLoad,
  useRefreshIndex,
  useRefresh,
  useRetrieveIndex,
  useRetrieve,
  useChange,
  useDelete,
  useRelate,
} from '#mrktbox/clerk/hooks/useData';
import useRoutesAPI from '#mrktbox/clerk/hooks/api/useRoutesAPI';

export type RouteIndex = DataIndex<Route>;

const MAX_AGE = 1000 * 60 * 60;

const RouteContext = createContext({
  routes: null as DataIndex<Route> | null,
  loaded: false,
  load: () => {},
  refreshRoutes : async () => null as RouteIndex | null,
  refreshRoute : async (id : number) => null as Route | null,
  retrieveRoutes : async () => null as RouteIndex | null,
  retrieveRoute : async (id : number) => null as Route | null,
  createRoute : async (route : Route) => null as Route | null,
  updateRoute : async (route : Route) => null as Route | null,
  deleteRoute : async (route : Route) => null as boolean | null,
  addAreaToRoute : async (route : Route, area : Area) => null as boolean | null,
  removeAreaFromRoute :
    async (route : Route, area : Area) => null as boolean | null,
  addServiceToRoute :
    async (route : Route, service : Service) => null as boolean | null,
  removeServiceFromRoute :
    async (route : Route, service : Service) => null as boolean | null,
});

interface RouteProviderProps {
  children : React.ReactNode;
}

export function RouteProvider({ children } : RouteProviderProps) {
  const {
    createRoute,
    retrieveRoutes,
    retrieveRoute,
    updateRoute,
    deleteRoute,
    addAreaToRoute,
    removeAreaFromRoute,
    addServiceToRoute,
    removeServiceFromRoute,
  } = useRoutesAPI();

  const {
    data : routes,
    dispatch : dispatchRoutes,
    lastUpdated,
  } = useData<Route>({ storageKey : 'routes' });

  const newRoute = useChange({
    dispatch : dispatchRoutes,
    change : createRoute,
  });
  const refreshRoutes = useRefreshIndex({
    dispatch : dispatchRoutes,
    retrieve : retrieveRoutes,
  });
  const refreshRoute = useRefresh({
    dispatch : dispatchRoutes,
    retrieve : retrieveRoute,
  });
  const getRoutes = useRetrieveIndex({
    data : routes,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshRoutes,
  });
  const getRoute = useRetrieve({
    data : routes,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshRoute,
  });
  const amendRoute = useChange({
    dispatch : dispatchRoutes,
    change : updateRoute,
  });
  const removeRoute = useDelete({
    dispatch : dispatchRoutes,
    delete : deleteRoute,
  });

  const addArea = useRelate({
    dispatch : dispatchRoutes,
    relate : addAreaToRoute,
    callback : refreshRoutes,
  });
  const removeArea = useRelate({
    dispatch : dispatchRoutes,
    relate : removeAreaFromRoute,
    callback : refreshRoutes,
  });

  const addService = useRelate({
    dispatch : dispatchRoutes,
    relate : addServiceToRoute,
    callback : refreshRoutes,
  });
  const removeService = useRelate({
    dispatch : dispatchRoutes,
    relate : removeServiceFromRoute,
    callback : refreshRoutes,
  });

  const { loaded, load } = useLoad({
    data : routes,
    loader : refreshRoutes,
  });

  const context = {
    routes,
    loaded,
    load,
    createRoute : newRoute,
    refreshRoutes,
    refreshRoute,
    retrieveRoutes : getRoutes,
    retrieveRoute : getRoute,
    updateRoute : amendRoute,
    deleteRoute : removeRoute,
    addAreaToRoute : addArea,
    removeAreaFromRoute : removeArea,
    addServiceToRoute : addService,
    removeServiceFromRoute : removeService,
  }

  return (
    <RouteContext.Provider value={context}>
      { children }
    </RouteContext.Provider>
  );
}

export default RouteContext;
