import {
  Image,
  Service,
  isService,
  ServiceChannel,
  isServiceChannel,
  Location,
  LocationIntegration,
  ExternalLocation,
  isImage,
  isLocation,
  isExternalLocation,
  isLocationImageUpload,
} from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

const SERVICES_PATH = 'services/';
const SERVICE_CHANNELS_PATH = 'service-channels/';
const LOCATIONS_PATH = 'locations/';

interface ServiceProps {
  service : Service;
}

interface ServiceChannelProps {
  serviceChannel : ServiceChannel;
}

interface LocationProps {
  location : Location;
}

function parseService(service : any) : Service {
  if (!isService(service))
    throw new TypeError('service is not a Service Object');

  return service;
}

function parseServices(service : any) : { [id : number] : Service } {
  const parsedServices : { [id : number] : Service } = {} ;
  for (const serviceId in service) {
    if (typeof serviceId !== 'string')
      throw new TypeError('Service id is not a string');

    const id = parseInt(serviceId);
    if (isNaN(id))
      throw new TypeError('Service id is not a number');

    parsedServices[parseInt(serviceId)] = parseService(service[id]);
  }

  return parsedServices;
}

function parseServiceChannel(serviceChannel : any) : ServiceChannel{
  if (!isServiceChannel(serviceChannel))
    throw new TypeError('service channel is not a Service Channel Object');

  return serviceChannel;
}

function parseServiceChannels(serviceChannel : any) : {
  [id : number] : ServiceChannel
} {
  const parsedServiceChannels : { [id : number] : ServiceChannel } = {};
  for (const serviceChannelId in serviceChannel) {
    if (typeof serviceChannelId !== 'string')
      throw new TypeError('Service Channel id is not a string')

    const id = parseInt(serviceChannelId);
    if (isNaN(id))
      throw new TypeError('Service Channel id is not a number');

    parsedServiceChannels[id] = parseServiceChannel(serviceChannel[id]);
  }

  return parsedServiceChannels;
}

function parseLocation(location : any) : Location {
  if (!isLocation(location))
    throw new TypeError('location is not a Location Object');

    return location;
}

function parseLocations(location : any) : {[id : number] : Location} {
  const parsedLocations : { [id : number] : Location } = {};
  for (const locationId in location) {
    if (typeof locationId !== 'string')
      throw new TypeError('Location id is not a string')

    const id = parseInt(locationId);
    if (isNaN(id))
      throw new TypeError('Location id is not a number');

    parsedLocations[id] = parseLocation(location[id]);
  }

  return parsedLocations;
}

function parseExternalLocations(
  externalLocations : any,
  integrationId : number,
) {
  const parsedExternal : { [id : string] : ExternalLocation } = {};
  for (const externalId in externalLocations) {
    if (typeof externalId !== 'string')
      throw new TypeError('External id is not a string');

    const externalLocation = externalLocations[externalId];
    externalLocation.externalId = externalId;
    externalLocation.integrationId = integrationId;

    if (!isExternalLocation(externalLocation))
      throw new TypeError('Object is not an External Location');
    parsedExternal[externalId] = externalLocation;
  }

  return parsedExternal;
}

function parseExternalLocationIndex(externalLocations : any) {
  const parsedExternal : {
    [integrationId : number] : { [id : string] : ExternalLocation }
  } = {};
  for (const integrationId in externalLocations) {
    if (typeof integrationId !== 'string')
      throw new TypeError('Integration id is not a string');

    const id = parseInt(integrationId);
    if (isNaN(id)) throw new TypeError('Integration id is not a number');

    parsedExternal[id] =

    parsedExternal[id] = parseExternalLocations(
      externalLocations[integrationId],
      id,
    );
  }

  return parsedExternal;
}

function parseLocationImageUpload(upload : any) {
  if (!isLocationImageUpload(upload))
    throw new TypeError('Product image upload is not a product image upload');

  return upload;
}

function parseLocationImage(image : any) {
  if (!isImage(image))
    throw new TypeError('Product image is not an image');

  return image;
}

export async function createService(
  { service } : ServiceProps,
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(SERVICES_PATH),
    methods.post,
    { service },
    options,
  );

  try {
    return parseService(response.service);
  } catch {
    throw new DeserializationError(
      'Could not deserialize service',
      response,
    );
  }
}

export async function retrieveServices(input? : {}, options? : RequestOptions) {
  const response = await request(
    getUrl(SERVICES_PATH),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseServices(response.services);
  } catch {
    throw new DeserializationError(
      'Could not parse service list',
      response,
    );
  }
}

export async function retrieveService(
  { serviceId } : { serviceId : number },
  options? : RequestOptions,
) {
  const response = await request(
    `${getUrl(SERVICES_PATH)}${serviceId}`,
    methods.get,
    undefined,
    options,
  );

  try {
    return parseService(response.service);
  } catch {
    throw new DeserializationError(
      'Could not deserialize service',
      response,
    );
  }
}

export async function updateService(
  { service } : { service : Service },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${service.id}`),
    methods.put,
    { service },
    options,
  );

  try {
    return parseService(response.service);
  } catch {
    throw new DeserializationError(
      'Could not deserialize service',
      response,
    );
  }
}

export async function deleteService(
  { service } : ServiceProps,
  options? : RequestOptions,
) {
  const response = await request(
    `${getUrl(SERVICES_PATH)}${service.id}`,
    methods.delete,
    undefined,
    options,
  );

  return !!response?.success;
}

export async function createServiceChannel(
  { serviceChannel } : ServiceChannelProps,
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${SERVICE_CHANNELS_PATH}`),
    methods.post,
    { serviceChannel },
    options,
  );

  try {
    return parseServiceChannel(response.serviceChannel);
  } catch {
    throw new DeserializationError(
      'Could not deserialize service channel',
      response,
    );
  }
}

export async function retrieveServiceChannels(
  input? : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${SERVICE_CHANNELS_PATH}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseServiceChannels(response.serviceChannels);
  } catch {
    throw new DeserializationError(
      'Could not parse service channel list',
      response,
    );
  }
}

export async function retrieveServiceChannel(
  { serviceChannelId } : { serviceChannelId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${SERVICE_CHANNELS_PATH}${serviceChannelId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseServiceChannel(response.serviceChannel);
  } catch {
    throw new DeserializationError(
      'Could not deserialize service channel',
      response,
    );
  }
}

export async function updateServiceChannel(
  { serviceChannel } : { serviceChannel : ServiceChannel },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${SERVICE_CHANNELS_PATH}${serviceChannel.id}`),
    methods.put,
    { serviceChannel },
    options,
  );

  try {
    return parseServiceChannel(response.serviceChannel);
  } catch {
    throw new DeserializationError(
      'Could not deserialize service channel',
      response,
    );
  }
}

export async function deleteServiceChannel(
  { serviceChannel } : ServiceChannelProps,
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${SERVICE_CHANNELS_PATH}${serviceChannel.id}`),
    methods.delete,
    undefined,
    options,
  );
  return !!response?.success;
}

export async function addServiceChannelToService({
  service,
  serviceChannel
} : {
  service : Service,
  serviceChannel : ServiceChannel
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${SERVICE_CHANNELS_PATH}${serviceChannel.id}/` +
      `${SERVICES_PATH}${service.id}`),
    methods.post,
    {},
    options,
  );

  return !!response;
}

export async function removeServiceChannelFromService({
  service,
  serviceChannel
} : {
  service : Service,
  serviceChannel : ServiceChannel
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${SERVICE_CHANNELS_PATH}${serviceChannel.id}/` +
      `${SERVICES_PATH}${service.id}`),
    methods.delete,
    undefined,
    options,
  );

  return !!response;
}

export async function createLocation(
  { location } : LocationProps,
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${LOCATIONS_PATH}`),
    methods.post,
    { location },
    options,
  );

  try {
    return parseLocation(response.location);
  } catch {
    throw new DeserializationError(
      'Could not deserialize location',
      response,
    );
  }
}

export async function retrieveLocations(
  input? : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${LOCATIONS_PATH}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseLocations(response.locations);
  } catch {
    throw new DeserializationError(
      'Could not parse location list',
      response,
    );
  }
}

export async function retrieveLocation(
  { locationId } : { locationId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${LOCATIONS_PATH}${locationId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseLocation(response.location);
  } catch {
    throw new DeserializationError(
      'Could not deserialize location',
      response,
    );
  }
}

export async function updateLocation(
  { location } : { location : Location },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${LOCATIONS_PATH}${location.id}`),
    methods.put,
    { location },
    options,
  );

  try {
    return parseLocation(response.location);
  } catch {
    throw new DeserializationError(
      'Could not deserialize location',
      response,
    );
  }
}

export async function deleteLocation(
  { location } : LocationProps,
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${LOCATIONS_PATH}${location.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseLocation(response.location);
  } catch {
    throw new DeserializationError(
      'Could not deserialize location',
      response,
    );
  }
}

export async function retrieveExternalLocations(
  input? : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${LOCATIONS_PATH}external/`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseExternalLocationIndex(response.externalLocations);
  } catch {
    throw new DeserializationError(
      'Could not parse external location index',
      response,
    );
  }
}

export async function createLocationIntegration({
  location,
  externalLocation,
} : {
  location : Location,
  externalLocation : ExternalLocation,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${LOCATIONS_PATH}${location.id}/integrations/` +
      `${externalLocation.integrationId}/${externalLocation.externalId}`),
    methods.post,
    { },
    options,
  );

  try {
    return parseLocation(response.location);
  } catch {
    throw new DeserializationError(
      'Could not deserialize location integration',
      response,
    );
  }
}

export async function deleteLocationIntegration({
  location,
  locationIntegration,
} : {
  location : Location,
  locationIntegration : LocationIntegration,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${LOCATIONS_PATH}${location.id}/` +
      `integrations/${locationIntegration.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseLocation(response.location);
  } catch {
    throw new DeserializationError(
      'Could not deserialize location integration',
      response,
    );
  }
}

export async function addLocationToService({
  service,
  location,
} : {
  service : Service,
  location : Location,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${LOCATIONS_PATH}${location.id}/` +
      `${SERVICES_PATH}${service.id}`),
    methods.post,
    {},
    options,
  );

  return !!response;
}

export async function removeLocationFromService({
  service,
  location,
} : {
  service : Service,
  location : Location,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${LOCATIONS_PATH}${location.id}/` +
      `${SERVICES_PATH}${service.id}`),
    methods.delete,
    undefined,
    options,
  );

  return !!response;
}

export async function createLocationImageUpload(
  { location } : { location : Location },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${LOCATIONS_PATH}${location.id}/` +
      'images/upload/'),
    methods.post,
    undefined,
    options,
  );

  try {
    return parseLocationImageUpload(response.locationImageUpload);
  } catch {
    throw new DeserializationError(
      'Could not deserialize location image upload',
      response,
    );
  }
}

export async function pullLocationImage({
  location,
  image,
} : {
  location : Location,
  image : Image,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${LOCATIONS_PATH}${location.id}/` +
      `images/${image.id}/pull/`),
    methods.post,
    undefined,
    options,
  );

  try {
    return parseLocationImage(response.image);
  } catch {
    throw new DeserializationError(
      'Could not deserialize location image',
      response,
    );
  }
}

export async function removeImageFromLocation({
  location,
  image,
} : {
  location : Location,
  image : Image,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${SERVICES_PATH}${LOCATIONS_PATH}${location.id}/` +
      `images/${image.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseLocation(response.location);
  } catch {
    throw new DeserializationError(
      'Could not deserialize location',
      response,
    );
  }
}
