import { useContext } from 'react';

import SidebarContext from '#context/SidebarContext';

function useSidebar() {
  const {
    open,
    content,
    openSidebar,
    closeSidebar,
  } = useContext(SidebarContext);

  return {
    open,
    content,
    openSidebar,
    closeSidebar,
  };
}

export default useSidebar;
