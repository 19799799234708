import { useCallback } from 'react';

import { Customer, CreditCard } from '#mrktbox/clerk/types';

import {
  retrieveCreditCards as retrieveCreditCardsAPI,
  retrieveCreditCard as retrieveCreditCardAPI,
  createCreditCard as createCreditCardAPI,
  deleteCreditCard as deleteCreditCardAPI,
  pullCreditCards as pullCreditCardsAPI,
} from '#mrktbox/clerk/api/mrktbox/accounts';

import useProcessRequest from '#mrktbox/clerk/hooks/api/useProcessRequest';

function useAccountsAPI() {
  const processRequest = useProcessRequest();

  const retrieveCreditCards = useCallback(async () => {
    return await processRequest(
      retrieveCreditCardsAPI,
      {},
    )
  }, [processRequest]);

  const retrieveCreditCard = useCallback(
    async (creditCardId : number) => {
      return await processRequest(
        retrieveCreditCardAPI,
        { cardId : creditCardId },
      )
    },
    [processRequest],
  );

  const createCreditCard = useCallback(
    async (
      creditCard : CreditCard,
      customer : Customer,
      validation? : {
        token : string,
        postal : string,
      },
    ) => {
      return await processRequest(
        createCreditCardAPI,
        {
          creditCard,
          customer,
          token : validation?.token || '',
          postal : validation?.postal || '',
        },
      )
    },
    [processRequest],
  );

  const deleteCreditCard = useCallback(
    async (creditCard : CreditCard) => {
      return await processRequest(
        deleteCreditCardAPI,
        { creditCard },
      )
    },
    [processRequest],
  );

  const pullCreditCards = useCallback(
    async (args : { customer : Customer }) => {
      return await processRequest(
        pullCreditCardsAPI,
        args,
      )
    },
    [processRequest],
  );

  return {
    retrieveCreditCards,
    retrieveCreditCard,
    createCreditCard,
    deleteCreditCard,
    pullCreditCards,
  };
}

export default useAccountsAPI;
