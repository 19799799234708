import React from 'react';

import { Subtotal } from '#mrktbox/types';
import { useTaxes } from '#mrktbox';

import TaxSubtotal from '#components/cart/Subtotal';

interface TaxProps {
  total : Subtotal;
}

function Tax({ total } : TaxProps) {
  const { taxes } = useTaxes();

  const tax = total.taxId ? (taxes?.[total.taxId] ?? null) : null;

  return (
    <TaxSubtotal
      label={tax?.name ?? 'Tax'}
      subtotal={total.total}
      bold
    />
  );
}

export default Tax;
