import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import { useProducts } from '#mrktbox';
import { Product } from '#mrktbox/types';

import Input from '#materials/Input';
import { Search } from '#materials/icons';

interface Style {
  theme? : Theme;
}

const SearchContainer = styled.div<Style>`
  width: 50%;
  margin: ${(props) => props.theme.layout.spacing.large} auto;
  position: relative;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    width: 80%;
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    margin-top: ${(props) => props.theme.layout.spacing.small};
  }
`;

const SearchIconView = styled.div<Style>`
  display: flex;
  padding:
    ${(props) => props.theme.layout.spacing.xsmall}
    0
    ${(props) => props.theme.layout.spacing.xxsmall};
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    width: ${(props) => props.theme.typography.fonts.default.sizes.small};
    height: ${(props) => props.theme.typography.fonts.default.sizes.small};
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    svg {
      width: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
      height: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
    }
`;

interface SearchBarProps {
  input : string;
  setInput : (input : string) => void;
  setProducts : (products : Product[]) => void;
}

function SearchBar({
  input,
  setInput,
  setProducts,
} : SearchBarProps) {
  const { searchProducts, retrieveProducts } = useProducts();

  const search = useCallback(async (query : string) => {
    await retrieveProducts();

    const newProducts = searchProducts(query);
    if (newProducts) setProducts(newProducts.sorted);
    else setProducts([]);
  }, [setProducts, searchProducts, retrieveProducts]);

  useEffect(() => { search(input); }, [input, search]);

  return (
    <SearchContainer>
      <Input
        name="Search"
        icon={(
          <SearchIconView>
            <Search />
          </SearchIconView>
        )}
        value={input}
        onChange={setInput}
      />
    </SearchContainer>
  );
}

export default SearchBar;
