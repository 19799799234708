import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }

const LocationActionView = styled.div<Style>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.theme.layout.spacing.xsmall} 0 0;
`

const LocationActionIcon = styled.div<Style>`
  position: relative;
  width: 1.4rem;
  height: 1.5rem;
  top: 0.1rem;
  color: ${(props) => props.theme.palette.background.text.primary};
  line-height: 0;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    display: none;
  }
`;

const LocationActionArrowText = styled.div<Style>`
  width: 100%;
  padding: 0 ${(props) => props.theme.layout.spacing.xsmall};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    padding: 0;
  }

  p {
    color: ${(props) => props.theme.palette.background.text.primary};
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
    line-height: 1;

    @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
      font-size:
        ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
    }
  }
`;

interface LocationActionProps {
  icon : React.ReactNode;
  text : React.ReactNode;
}

function LocationAction({ icon, text } : LocationActionProps) {
  return (
    <LocationActionView>
      <LocationActionIcon>{icon}</LocationActionIcon>
      <LocationActionArrowText>
        <p>{text}</p>
      </LocationActionArrowText>
    </LocationActionView>
  );
}

export default LocationAction;
