import React from 'react';
import styled from '@emotion/styled';

import { Product } from '#mrktbox/types';

import { Theme } from '#types';

import Tag from '#materials/Tag';

import ProductImage from '#components/products/ProductImage';

interface Style { theme? : Theme; }
interface OrderImageStyle extends Style { fade? : boolean; }

const OrderImageView = styled.div<OrderImageStyle>`
  display: flex;
  position: relative;
  width: 4.5rem;
  height: 4.5rem;
  margin-right: ${(props) => props.theme.layout.spacing.xsmall};
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => props.fade ? 0.75 : 1};

  background-color: ${(props) => props.theme.palette.background.fill};

  border-radius: ${(props) => props.theme.layout.border.radius};
`;

const OrderImageIcon = styled.div<Style>`
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  z-index: 20;
`;

interface OrderImageProps {
  product : Product,
  icon? : React.ReactNode,
  fade? : boolean,
}

const OrderImage = ({ product, icon, fade } : OrderImageProps) => {
  return (
    <OrderImageView fade={fade}>
      { !!icon && (<OrderImageIcon>
        <Tag icon={ icon } />
      </OrderImageIcon>) }
      <ProductImage product={product} variant="thumbnail" />
    </OrderImageView>
  );
}

export default OrderImage;
