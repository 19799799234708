import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export {
  createBrowserRouter,
  RouterProvider,
  useParams,
  useSearchParams,
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Link,
} from 'react-router-dom';

function useNavigation() {
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();

  const openTab = useCallback((url : string) => {
    window.open(`${window.location.origin}${url}`);
  }, []);

  const getLocation = useCallback(() => {
    return window.location;
  }, []);

  const generatePath = useCallback((path : string, params : any) => {
    return `${path}?${new URLSearchParams(params).toString()}`
  }, []);

  const generateUrl = useCallback((path : string, params : any) => {
    const origin = getLocation().origin;
    return `${origin}${generatePath(path, params)}`;
  }, [getLocation, generatePath]);

  const adjustSearchParams = useCallback((params : any) => {
    for (const [key, value] of Object.entries(params)) {
      if (value === null || value === undefined) {
        urlParams.delete(key);
      } else {
        urlParams.set(key, `${value}`);
      }
    }
    navigate(`${getLocation().pathname}?${urlParams.toString()}`);
  }, [urlParams, getLocation, navigate]);

  const isRouteActive = useCallback((route : string) => {
    const path = getLocation().pathname.replace(/\/+$/, '');
    return path.startsWith(route.replace(/\/+$/, ''));
  }, [getLocation]);

  return {
    navigate,
    openTab,
    generatePath,
    generateUrl,
    adjustSearchParams,
    isRouteActive,
  };
}

export default useNavigation;
