import { useCallback } from 'react';

import {
  login as loginAPI,
  verify as verifyAPI,
  retrievePasswordResetToken as retrievePasswordResetTokenAPI,
  resetPassword as resetPasswordAPI,
  sendPasswordReset as sendPasswordResetAPI,
} from '#mrktbox/clerk/api/mrktbox/auth';

import useAPI, { responseStatus } from '#mrktbox/clerk/hooks/api/useAPI';

function useAuthAPI() {
  const { processRequestWithFeeback : processRequest } = useAPI();

  const login = useCallback(async (username : string, password : string) => {
    const response = await processRequest(
      loginAPI,
      { username, password },
    )
    switch (response.status) {
      case responseStatus.success:
        return { authorized : true, token : response.response };
      case responseStatus.unauthorized:
        return { authorized : false, token : '' };
      default:
        return { authorized : null, token : '' };
    }
  },
  [processRequest]);

  const verify = useCallback(async (token : string) => {
    const repsonse = await processRequest(
      verifyAPI,
      { token },
    )
    switch (repsonse.status) {
      case responseStatus.success:
        return { authorized: true, user: repsonse.response };
      case responseStatus.unauthorized:
        return { authorized: false, user: null };
      default:
        return { authorized : null, user : null };
    }
  }, [processRequest]);

  const retrievePasswordResetToken = useCallback(
    async (username : string) => {
      return (await processRequest(
        retrievePasswordResetTokenAPI,
        { username },
      )).response;
    },
    [processRequest],
  );

  const resetPassword = useCallback(
    async (username : string, password : string, token : string) => {
      const response = await processRequest(
        resetPasswordAPI,
        { username, password, token },
      )
      switch (response.status) {
        case responseStatus.success: return true;
        case responseStatus.unauthorized: return false;
        default: return null;
      }
    },
    [processRequest],
  );

  const sendPasswordReset = useCallback(
    async (username : string) => {
      return (await processRequest(
        sendPasswordResetAPI,
        { username },
      )).response;
    },
    [processRequest],
  );

  return {
    login,
    verify,
    retrievePasswordResetToken,
    resetPassword,
    sendPasswordReset,
  };
}

export default useAuthAPI;
