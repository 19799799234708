import {
  Tax,
  TaxIntegration,
  ExternalTax,
  isTax,
  isExternalTax,
} from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

const TAXES_PATH = 'taxes/';

function parseTax(tax : any) {
  if (!isTax(tax))
    throw new TypeError('Tax is not a tax');
  return tax;
}

function parseTaxes(taxes : any) {
  const parsedTaxes : { [id : number] : Tax } = {};
  for (const taxId in taxes) {
    if (typeof taxId !== 'string')
      throw new TypeError('Tax id is not a string');

    const id = parseInt(taxId);
    if (isNaN(id)) throw new TypeError('Tax id is not a number');

    parsedTaxes[id] = parseTax(taxes[id]);
  }

  return parsedTaxes;
}

function parseIntegrationTaxes(integrationTaxes : any, integrationId : number) {
  const parsedIntegrationTaxes : { [id : string] : ExternalTax } = {};
  for (const externalId in integrationTaxes) {
    if (typeof externalId !== 'string')
      throw new TypeError('External id is not a string');

    const tax = integrationTaxes[externalId];
    tax.externalId = externalId;
    tax.integrationId = integrationId;

    if (!isExternalTax(tax))
      throw new TypeError('Tax is not an external tax');

    parsedIntegrationTaxes[externalId] = tax;
  }
  return parsedIntegrationTaxes;
}

function parseExternalTaxes(externalTaxes : any) {
  const parsedExternalTaxes : {
    [id : number] : { [id : string] : ExternalTax }
  } = {};
  for (const integrationId in externalTaxes) {
    if (typeof integrationId !== 'string')
      throw new TypeError('Integration id is not a string');

    const id = parseInt(integrationId);
    if (isNaN(id)) throw new TypeError('Integration id is not a number');

    parsedExternalTaxes[id] = parseIntegrationTaxes(externalTaxes[id], id);
  }
  return parsedExternalTaxes;
}

export async function createTax(
  { tax } : { tax : Tax },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(TAXES_PATH),
    methods.post,
    { tax },
    options,
  );

  try {
    return parseTax(response.tax);
  } catch {
    throw new DeserializationError(
      'Could not deserialize tax',
      response,
    );
  }
}

export async function retrieveTaxes(
  input : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(TAXES_PATH),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseTaxes(response.taxes);
  } catch {
    throw new DeserializationError(
      'Could not deserialize taxes',
      response,
    );
  }
}

export async function retrieveTax(
  { taxId } : { taxId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAXES_PATH}${taxId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseTax(response.tax);
  } catch {
    throw new DeserializationError(
      'Could not deserialize tax',
      response,
    );
  }
}

export async function updateTax(
  { tax } : { tax : Tax },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAXES_PATH}${tax.id}`),
    methods.put,
    { tax },
    options,
  );

  try {
    return parseTax(response.tax);
  } catch {
    throw new DeserializationError(
      'Could not deserialize tax',
      response,
    );
  }
}

export async function deleteTax(
  { tax } : { tax : Tax },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAXES_PATH}${tax.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseTax(response.tax);
  } catch {
    throw new DeserializationError(
      'Could not deserialize tax',
      response,
    );
  }
}

export async function retrieveExternalTaxes(
  input : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAXES_PATH}external/`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseExternalTaxes(response.externalTaxes);
  } catch {
    throw new DeserializationError(
      'Could not deserialize external taxes',
      response,
    );
  }
}

export async function createTaxIntegration({
  tax,
  externalTax,
} : {
  tax : Tax,
  externalTax : ExternalTax,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${TAXES_PATH}${tax.id}/integrations/` +
      `${externalTax.integrationId}/${externalTax.externalId}`),
    methods.post,
    { },
    options,
  );

  try {
    return parseTax(response.tax);
  } catch {
    throw new DeserializationError(
      'Could not deserialize tax integration',
      response,
    );
  }
}

export async function deleteTaxIntegration(
  { taxIntegration } : { taxIntegration : TaxIntegration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAXES_PATH}integrations/${taxIntegration.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseTax(response.tax);
  } catch {
    throw new DeserializationError(
      'Could not deserialize tax integration',
      response,
    );
  }
}

export async function syncTaxIntegration(
  { taxIntegration } : { taxIntegration : TaxIntegration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAXES_PATH}integrations/${taxIntegration.id}/sync/`),
    methods.post,
    { taxIntegration },
    options,
  );

  try {
    return parseTax(response.tax);
  } catch {
    throw new DeserializationError(
      'Could not deserialize tax integration',
      response,
    );
  }
}

export async function pushTaxIntegration(
  { taxIntegration } : { taxIntegration : TaxIntegration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAXES_PATH}integrations/${taxIntegration.id}/push/`),
    methods.post,
    { taxIntegration },
    options,
  );

  try {
    return parseTax(response.tax);
  } catch {
    throw new DeserializationError(
      'Could not deserialize tax integration',
      response,
    );
  }
}

export async function pullTaxIntegration(
  { taxIntegration } : { taxIntegration : TaxIntegration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAXES_PATH}integrations/${taxIntegration.id}/pull/`),
    methods.post,
    undefined,
    options,
  );

  try {
    return parseTax(response.tax);
  } catch {
    throw new DeserializationError(
      'Could not deserialize tax integration',
      response,
    );
  }
}
