import styled from '@emotion/styled'

import { Link } from '#mrktbox';

import { Theme } from '#types'

interface IconLinkStyle {
  theme? : Theme;
  margin? : string;
}

const IconLink = styled(Link)<IconLinkStyle>`
  display: flex;
  width: 5rem;
  margin: ${(props) => props.margin || '0'};
  height: 5rem;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.color
    || props.theme.palette.background.text.primary};

    cursor: pointer;
  transition: color 0.150s ease;

  &:hover {
    color: ${(props) => props.color
      || props.theme.palette.background.text.hover};
  }
`;

export default IconLink;
