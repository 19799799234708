import { useCallback } from 'react';

import { Tax, TaxIntegration, ExternalTax } from '#mrktbox/clerk/types';

import {
  createTax as createTaxAPI,
  retrieveTaxes as retrieveTaxesAPI,
  retrieveTax as retrieveTaxAPI,
  updateTax as updateTaxAPI,
  deleteTax as deleteTaxAPI,
  retrieveExternalTaxes as retrieveExternalTaxesAPI,
  createTaxIntegration as createTaxIntegrationAPI,
  deleteTaxIntegration as deleteTaxIntegrationAPI,
  syncTaxIntegration as syncTaxIntegrationAPI,
  pushTaxIntegration as pushTaxIntegrationAPI,
  pullTaxIntegration as pullTaxIntegrationAPI,
} from '#mrktbox/clerk/api/mrktbox/taxes';

import useProcessRequest from '#mrktbox/clerk/hooks/api/useProcessRequest';

function useTaxesAPI() {
  const processRequest = useProcessRequest();

  const createTax = useCallback(async (tax : Tax) => {
    return await processRequest(
      createTaxAPI,
      { tax },
    )
  }, [processRequest]);

  const retrieveTaxes = useCallback(async () => {
    return await processRequest(
      retrieveTaxesAPI,
      {},
    )
  }, [processRequest]);

  const retrieveTax = useCallback(async (taxId : number) => {
    return await processRequest(
      retrieveTaxAPI,
      { taxId },
    )
  }, [processRequest]);

  const updateTax = useCallback(async (tax : Tax) => {
    return await processRequest(
      updateTaxAPI,
      { tax }
    )
  }, [processRequest]);

  const deleteTax = useCallback(async (tax : Tax) => {
    return await processRequest(
      deleteTaxAPI,
      { tax }
    )
  }, [processRequest]);

  const retrieveExternalTaxes = useCallback(async () => {
    return await processRequest(
      retrieveExternalTaxesAPI,
      {}
    )
  }, [processRequest]);

  const createTaxIntegration = useCallback(
    async (tax : Tax, externalTax : ExternalTax) => {
      return await processRequest(
        createTaxIntegrationAPI,
        { tax, externalTax }
      )
    },
    [processRequest]
  );

  const deleteTaxIntegration = useCallback(
    async (taxIntegration : TaxIntegration) => {
      return await processRequest(
        deleteTaxIntegrationAPI,
        { taxIntegration }
      )
    },
    [processRequest]
  );

  const syncTaxIntegration = useCallback(
    async (taxIntegration : TaxIntegration) => {
      return await processRequest(
        syncTaxIntegrationAPI,
        { taxIntegration }
      )
    },
    [processRequest]
  );

  const pushTaxIntegration = useCallback(
    async (taxIntegration : TaxIntegration) => {
      return await processRequest(
        pushTaxIntegrationAPI,
        { taxIntegration }
      )
    },
    [processRequest]
  );

  const pullTaxIntegration = useCallback(
    async (taxIntegration : TaxIntegration) => {
      return await processRequest(
        pullTaxIntegrationAPI,
        { taxIntegration }
      )
    },
    [processRequest]
  );

  return {
    createTax,
    retrieveTaxes,
    retrieveTax,
    updateTax,
    deleteTax,
    retrieveExternalTaxes,
    createTaxIntegration,
    deleteTaxIntegration,
    syncTaxIntegration,
    pushTaxIntegration,
    pullTaxIntegration,
  };
}

export default useTaxesAPI;
