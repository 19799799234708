import { Route, isRoute, Area, Service } from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request } from '#mrktbox/clerk/api/mrktbox';

const ROUTES_PATH = 'routes/';
const AREAS_PATH = 'areas/';
const SERVICES_PATH = 'services/';

function parseRoute(route : any) {
  if (!isRoute(route))
    throw new TypeError('Route is not a route');
  return route;
}

function parseRoutes(routes : any) {
  const parsedRoutes : { [id : number] : Route } = {};
  for (const routeId in routes) {
    if (typeof routeId !== 'string')
      throw new TypeError('Route id is not a string');

    const id = parseInt(routeId);
    if (isNaN(id)) throw new TypeError('Route id is not a number');

    parsedRoutes[id] = parseRoute(routes[id]);
  }

  return parsedRoutes;
}

export async function createRoute({ route } : { route : Route}) {
  const response = await request(
    getUrl(ROUTES_PATH),
    methods.post,
    { route },
  );

  try {
    return parseRoute(response.route);
  } catch {
    throw new DeserializationError(
      'Could not deserialize route',
      response,
    );
  }
}

export async function retrieveRoutes() {
  const response = await request(
    getUrl(ROUTES_PATH),
    methods.get,
  );

  try {
    return parseRoutes(response.routes);
  } catch {
    throw new DeserializationError(
      'Could not deserialize routes',
      response,
    );
  }
}

export async function retrieveRoute({ routeId } : { routeId : number }) {
  const response = await request(
    getUrl(`${ROUTES_PATH}${routeId}`),
    methods.get,
  );

  try {
    return parseRoute(response.route);
  } catch {
    throw new DeserializationError(
      'Could not deserialize route',
      response,
    );
  }
}

export async function updateRoute({ route } : { route : Route }) {
  const response = await request(
    getUrl(`${ROUTES_PATH}${route.id}`),
    methods.put,
    { route },
  );

  try {
    return parseRoute(response.route);
  } catch {
    throw new DeserializationError(
      'Could not deserialize route',
      response,
    );
  }
}

export async function deleteRoute({ route } : { route : Route }) {
  const response = await request(
    getUrl(`${ROUTES_PATH}${route.id}`),
    methods.delete,
  );

  return !!response?.success;
}

export async function addAreaToRoute({
  route,
  area,
} : { route : Route, area : Area }) {
  const response = await request(
    getUrl(`${ROUTES_PATH}${route.id}/${AREAS_PATH}${area.id}`),
    methods.post,
  );

  return !!response;
}

export async function removeAreaFromRoute({
  route,
  area,
} : { route : Route, area : Area }) {
  const response = await request(
    getUrl(`${ROUTES_PATH}${route.id}/${AREAS_PATH}${area.id}`),
    methods.delete,
  );

  return !!response;
}

export async function addServiceToRoute({
  route,
  service,
} : { route : Route, service : Service }) {
  const response = await request(
    getUrl(`${ROUTES_PATH}${route.id}/${SERVICES_PATH}${service.id}`),
    methods.post,
  );

  return !!response;
}

export async function removeServiceFromRoute({
  route,
  service,
} : { route : Route, service : Service }) {
  const response = await request(
    getUrl(`${ROUTES_PATH}${route.id}/${SERVICES_PATH}${service.id}`),
    methods.delete,
  );

  return !!response;
}
