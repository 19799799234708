export interface Note {
  id? : number;
  content : string;
  customerId : number | null,
  addressId : number | null,
  orderId : number | null,
  lineItemId : number | null,
}

export interface ProductNote {
  id? : number;
  enabled : boolean;
  required : boolean;
  productId : number;
}

export function isNote(note : any) : note is Note {
  return (
    typeof note === 'object' &&
    (note.id === undefined || typeof note.id === 'number') &&
    typeof note.content === 'string' &&
    (note.customerId === null ||
      typeof note.customerId === 'number') &&
    (note.addressId === null || typeof note.addressId === 'number') &&
    (note.orderId === null || typeof note.orderId === 'number') &&
    (note.lineItemId === null || typeof note.lineItemId === 'number')
  )
}

export function isProductNote(
  productNote : any,
) : productNote is ProductNote {
  return (
    typeof productNote === 'object' &&
    (productNote.id === undefined || typeof productNote.id === 'number') &&
    typeof productNote.enabled === 'boolean' &&
    typeof productNote.required === 'boolean' &&
    typeof productNote.productId === 'number'
  );
}
