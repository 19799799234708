import { useCallback, useContext } from 'react';

import { Service, ServiceChannel } from '#mrktbox/clerk/types';

import ServiceContext from '#mrktbox/clerk/context/ServiceContext';
import ServiceChannelContext
  from '#mrktbox/clerk/context/ServiceChannelContext';
import LocationContext from '#mrktbox/clerk/context/LocationContext';

import { listRecords } from '#mrktbox/clerk/utils/data';

function gernerateDefaultService() : Service {
  return {
    name: '',
    serviceChannelId: null,
    locationId: null,
    leadTime: null,
    cutoffTime: null,
  };
}

function useServices() {
  const {
    services,
    loaded : servicesLoaded,
    load : loadServices,
    refreshServices,
    refreshService,
    retrieveServices,
    retrieveService,
    createService,
    updateService,
    deleteService,
  } = useContext(ServiceContext);
  const {
    serviceChannels,
    loaded : serviceChannelsLoaded,
    load : loadServiceChannels,
    createServiceChannel,
    refreshServiceChannels,
    refreshServiceChannel,
    retrieveServiceChannels,
    retrieveServiceChannel,
    updateServiceChannel,
    deleteServiceChannel,
  } = useContext(ServiceChannelContext);
  const {
    locations,
    loaded : locationsLoaded,
    load : loadLocations,
    createLocation,
    refreshLocations,
    refreshLocation,
    retrieveLocations,
    retrieveLocation,
    updateLocation,
    deleteLocation,
    retrieveExternalLocations,
    createLocationIntegration,
    deleteLocationIntegration,
    createLocationImageUpload,
    pullLocationImage,
    removeImageFromLocation,
  } = useContext(LocationContext);

  const getChannelServices = useCallback(
    (channels : ServiceChannel[] | ServiceChannel | null) => {
      if (!services) return [];
      if (!channels) return [];

      const serviceArray = Array.isArray(channels) ? channels : [channels];
      return listRecords(services).filter((service) => {
        return serviceArray && serviceArray.some(s => (
          s.id && service.serviceChannelId === s.id
        ));
      });
    },
    [services],
  );

  const load = useCallback(() => {
    loadServices();
    loadServiceChannels();
    loadLocations();
  }, [loadServices, loadServiceChannels, loadLocations]);

  return {
    services,
    serviceChannels,
    locations,
    loaded : servicesLoaded && serviceChannelsLoaded && locationsLoaded,
    load,
    gernerateDefaultService,
    createService,
    refreshServices,
    refreshService,
    retrieveServices,
    retrieveService,
    updateService,
    deleteService,
    createServiceChannel,
    refreshServiceChannels,
    refreshServiceChannel,
    retrieveServiceChannels,
    retrieveServiceChannel,
    updateServiceChannel,
    deleteServiceChannel,
    createLocation,
    refreshLocations,
    refreshLocation,
    retrieveLocations,
    retrieveLocation,
    updateLocation,
    deleteLocation,
    retrieveExternalLocations,
    createLocationIntegration,
    deleteLocationIntegration,
    createLocationImageUpload,
    pullLocationImage,
    removeImageFromLocation,
    getChannelServices,
  };
}

export default useServices;
