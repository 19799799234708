import { DataIndex } from '#mrktbox/clerk/types';

export { actionTypes } from '#mrktbox/clerk/types';

export function parseIndex<T>(
  data : any,
  predicate? : (data : any) => data is T,
) {
  if (data === null) return null;

  if (data.hasOwnProperty('id')) {
    return { [data.id] : data as T };
  }

  if (
    (typeof data === 'object') &&
    (Object.keys(data).every((key) => !isNaN(parseInt(key)))) &&
    (Object.values(data).every((value) =>
      (value === null) ||
      ((typeof value === 'object') && (!predicate || predicate(value)))
    ))
  ) {
    return data as DataIndex<T>;
  }

  throw new Error('Invalid data');
}

export function listRecords<T>(index : DataIndex<T> | null) {
  if (index === null) return [];
  return Object.values(index).filter((record) => record !== null) as T[];
}

export function indexRecords<
  I extends string | number = number,
  T extends { id? : I } = { id? : I },
>(
  records : T[],
  key? : (record : T) => I | undefined,
) {
  const keyer = key || ((record) => record.id);
  return records.reduce((acc, record) => {
    const k = keyer(record);
    return (k === undefined)
      ? acc
      : { ...acc, [k] : record };
  }, {} as DataIndex<T>);
}

export function filterIndex<T>(
  index : DataIndex<T>,
  predicate? : (data : T | null) => boolean,
  options : { dropNull? : boolean, dropDeleted? : boolean } = {},
) {
  return Object.entries(index).reduce((acc, [key, value]) => {
    if (options.dropNull && value === null) return acc;
    if (options.dropDeleted && (
      value === null
        || (value as any).deleted_at
        || (value as any).deletedAt
    )) return acc;

    if (!predicate || predicate(value)) return { ...acc, [key] : value };
    return acc;
  }, {});
}

export function serializeIndex<T>(
  index : DataIndex<T>,
  serializer : (data : T | null) => any,
) {
  return Object.entries(index).reduce((acc, [key, value]) => {
    return { ...acc, [key] : serializer(value) };
  }, {});
}

export function deserializeIndex<T>(
  index : DataIndex<T>,
  deserializer : (data : any) => T | null,
) {
  return Object.entries(index).reduce((acc, [key, value]) => {
    return { ...acc, [key] : deserializer(value) };
  }, {});
}
