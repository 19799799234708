import React, { useCallback, useEffect, useState } from 'react';

import { ServiceChannel } from '@mrktbox/clerk/types';
import { useServices } from '@mrktbox/clerk';
import { listRecords } from '@mrktbox/clerk/utils';

import Types from '#components/orders/OrderTypes';

function OrderTypes() {
  const { retrieveServiceChannels } = useServices();

  const [serviceChannels, setServiceChannels] = useState<ServiceChannel[]>([]);
  const [loading, setLoading] = useState(true);

  const retrieve = useCallback(async () => {
    const channels = await retrieveServiceChannels();
    if (!channels) return;
    setServiceChannels(listRecords(channels));
    setLoading(false);
  }, [retrieveServiceChannels]);

  useEffect(() => { retrieve(); }, [retrieve]);

  return !loading
    ? (<Types serviceChannels={serviceChannels} />)
    : null;
}

export default OrderTypes;
