import { useContext, useCallback } from 'react';

import { Service, Product, Adjustment, Condition } from '#mrktbox/clerk/types';

import AdjustmentContext from '#mrktbox/clerk/context/AdjustmentContext';
import ConditionContext from '#mrktbox/clerk/context/ConditionContext';
import ProductsContext from '#mrktbox/clerk/context/ProductsContext';

import { listRecords } from '#mrktbox/clerk/utils';

const generateDefaultAdjustment = ({
  scope,
} : {
  scope : 'order' | 'lineItem';
}) : Adjustment => ({
  name : 'Adjustment',
  scope,
  factor : null,
  currency : null,
  conditionIds : [],
  taxIds : [],
});

function useAdjustments() {
  const {
    adjustments,
    loaded : adustmentsLoaded,
    load : loadAdjustments,
    refreshAdjustments,
    refreshAdjustment,
    createAdjustment,
    retrieveAdjustments,
    retrieveAdjustment,
    updateAdjustment,
    deleteAdjustment,
    addConditionToAdjustment,
    removeConditionFromAdjustment,
    addTaxToAdjustment,
    removeTaxFromAdjustment
  } = useContext(AdjustmentContext);
  const {
    conditions,
    loaded : conditionsLoaded,
    load : loadConditions,
    refreshConditions,
    refreshCondition,
    createCondition,
    retrieveConditions,
    retrieveCondition,
    updateCondition,
    deleteCondition,
    addProductToCondition,
    removeProductFromCondition,
    addServiceToCondition,
    removeServiceFromCondition,
  } = useContext(ConditionContext);

  const {
    products,
    loaded : productsLoaded,
    load : loadProducts,
  } = useContext(ProductsContext);

  const load = useCallback(() => {
    loadAdjustments();
    loadConditions();
    loadProducts();
  }, [loadAdjustments, loadConditions, loadProducts]);

  const getConditionAdjustments = useCallback((conditions : Condition[]) => {
    if (!adjustments) return [];
    return listRecords(adjustments).filter(
      (adjustment) => adjustment.conditionIds.some(
        (id) => conditions.some((condition) => condition.id === id)
    ));
  }, [adjustments]);

  const getProductAdjustments = useCallback((product : Product) => {
    if (!conditions) return [];
    const productConditions = listRecords(conditions).filter(
      (condition) => product.id && condition.productIds.includes(product.id)
    );
    return getConditionAdjustments(productConditions);
  }, [getConditionAdjustments, conditions]);

  const getServiceAdjustments = useCallback((service : Service) => {
    if (!conditions) return [];
    const serviceConditions = listRecords(conditions).filter(
      (condition) => service.id && condition.serviceIds.includes(service.id)
    );
    return getConditionAdjustments(serviceConditions);
  }, [getConditionAdjustments, conditions]);

  const getAdjustmentConditions = useCallback((adjustment : Adjustment) => {
    if (!adjustments || !conditions) return [];
    return listRecords(conditions).filter((condition) =>
      condition.id && adjustment.conditionIds.includes(condition.id)
    );
  }, [conditions, adjustments]);

  const calculateAdjustmentRelative = useCallback((
    adjustment : Adjustment,
    product : Product,
  ) => {
    const condition = getAdjustmentConditions(adjustment).find((c) => {
      return product.id && c.productIds.includes(product.id);
    });
    const factor = adjustment.factor
      ? adjustment.factor
      : (adjustment.currency?.amount ?? 0) / product.price.amount;
    return factor / (condition?.count || 1);
  }, [getAdjustmentConditions]);

  const calculateAdjustmentAbsolute = useCallback((
    adjustment : Adjustment,
    product : Product,
  ) => {
    if (adjustment.currency?.amount) return adjustment.currency;
    const amount = Math.round(product.price.amount * (adjustment.factor ?? 1));
    return {
      ...product.price,
      amount : amount,
      calculatedValue : amount * product.price.increment,
    }
  }, []);

  const calculateProductPrice = useCallback((
    product : Product,
    options? : {
      quantity? : number,
      adjustments? : Adjustment[],
    },
  ) => {
    const quantity = options?.quantity ?? 1;
    const productAdjustments = options?.adjustments ||
      getProductAdjustments(product);

    const productPriceAmount = productAdjustments.reduce(
      (price, adjustment) => {
        const condition = getAdjustmentConditions(adjustment).find((c) => {
          return product.id && c.productIds.includes(product.id);
        });
        if (!condition) return price;

        const count = condition.count
          ? Math.floor(quantity / condition.count)
          : quantity;
        return price
          + (calculateAdjustmentAbsolute(adjustment, product).amount * count)
      },
      product.price.amount * quantity,
    );

    return {
      ...product.price,
      amount : productPriceAmount,
      calculatedValue : productPriceAmount * product.price.increment,
    };
  }, [
    getProductAdjustments,
    getAdjustmentConditions,
    calculateAdjustmentAbsolute,
  ]);

  const getProductDeals = useCallback(() => {
    if (products && adjustments && conditions) {
      return listRecords(products)?.filter((product: Product) => {
        return listRecords(conditions)?.some((condition : Condition) =>
          product.id && condition.productIds.includes(product.id));
      });
    }
  }, [products, adjustments, conditions]);

  return {
    adjustments,
    conditions,
    loaded : adustmentsLoaded && conditionsLoaded && productsLoaded,
    load,
    refreshAdjustments,
    refreshAdjustment,
    retrieveAdjustments,
    retrieveAdjustment,
    createAdjustment,
    updateAdjustment,
    deleteAdjustment,
    refreshConditions,
    refreshCondition,
    retrieveConditions,
    retrieveCondition,
    createCondition,
    updateCondition,
    deleteCondition,
    addConditionToAdjustment,
    removeConditionFromAdjustment,
    addProductToCondition,
    removeProductFromCondition,
    addServiceToCondition,
    removeServiceFromCondition,
    addTaxToAdjustment,
    removeTaxFromAdjustment,
    getProductAdjustments,
    getServiceAdjustments,
    getAdjustmentConditions,
    calculateAdjustmentRelative,
    calculateAdjustmentAbsolute,
    calculateProductPrice,
    getProductDeals,
    generateDefaultAdjustment,
  };
}

export default useAdjustments;
