import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface BackgroundImageViewStyle {
  theme? : Theme;
  bgColour? : string;
}

interface BackgroundOverlayStyle {
  colour? : string;
}

const BackgroundImageView = styled.div<BackgroundImageViewStyle>`
  position: relative;
  flex-grow: 1;
  background-color: ${(props) => props.bgColour
    || props.theme.palette.background.fill};
`;

export const BgImage = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const BackgroundImageImage = styled(BgImage)`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  inset: 0;
  opacity: 1;
  animation: fade-in 0.5s ease-in-out 0s forwards;
`;

const BackgroundOverlay = styled.div<BackgroundOverlayStyle>`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.colour || 'rgba(0, 0, 0, 0.3)'};
`

function makeOverlayColour(colour? : string, opacity? : number) {
  if (!colour || !opacity) return undefined;
  const r = parseInt(colour.slice(0, 2), 16);
  const g = parseInt(colour.slice(2, 4), 16);
  const b = parseInt(colour.slice(4, 6), 16);
  const o = (opacity / 100.0).toFixed(2);
  return `rgba(${r}, ${g}, ${b}, ${o})`;
}

interface ImageProps {
  imageUrl? : string;
}

export function LoadedImage({ imageUrl } : ImageProps) {
  const [initilized, setInitilized] = useState('');
  const [loaded, setLoaded] = useState('');

  const bgStyle = imageUrl ? { backgroundImage: `url(${imageUrl}` } : undefined;

  useEffect(() => {
    if (initilized !== imageUrl) {
      setInitilized(imageUrl || '');
      const img = new Image();
      img.onload = () => setLoaded(imageUrl || '');
      img.src = imageUrl || '';
    }
  }, [imageUrl, initilized]);

  return (
    (loaded && loaded === imageUrl)
      ? (<BackgroundImageImage style={bgStyle} />)
      : null
  );
}

interface BackgroundImageProps {
  imageUrl? : string;
  show_overlay? : boolean;
  overlay_colour? : string;
  overlay_opacity? : number;
  bgColour? : string;
  children? : React.ReactNode;
}

function BackgroundImage({
  imageUrl,
  show_overlay = false,
  overlay_colour,
  overlay_opacity,
  bgColour,
  children,
} : BackgroundImageProps) {
  if (!imageUrl) return null

  const bgStyle = imageUrl ? { backgroundImage: `url(${imageUrl}` } : undefined;
  const overlayColour = makeOverlayColour(overlay_colour, overlay_opacity);

  return (
    <BackgroundImageView bgColour={bgColour}>
      <BackgroundImageImage style={bgStyle} />
      {show_overlay && <BackgroundOverlay colour={overlayColour} />}
      {children}
    </BackgroundImageView>
  );
}

export default BackgroundImage;
