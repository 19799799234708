
export interface Route {
  id?: number;
  name: string;
  areaIds: number[];
  serviceIds?: number[];
}

export function isRoute(route: any) : route is Route {
  return (
    (route.id === undefined || typeof route.id === 'number') &&
    typeof route.name === 'string' &&
    Array.isArray(route.areaIds) &&
    route.areaIds.every((id: any) => typeof id === 'number') &&
    (route.serviceIds === undefined ||
      (Array.isArray(route.serviceIds) &&
      route.serviceIds.every((id: any) => typeof id === 'number'))
    )
  );
}
