import { DraftCustomOrder } from './options';

export interface Subscription {
  id? : number;
  quantity : number;
  lineItemId : number;
  serviceChannelId : number;
  locationId : number | null;
  addressId : number | null;
  timeSlotId : number;
  period : number;
  targetIteration : number;
  startIteration : number;
  endIteration : number | null;
  timeSlotDivision : number;
  selectionIds : number[];
}

export interface SubscriptionOption {
  id? : number;
  serviceChannelIds : number[];
}

export function isSubscription(
  subscription : any,
) : subscription is Subscription {
  return (
    (subscription.id === undefined || typeof subscription.id === 'number') &&
    typeof subscription.quantity === 'number' &&
    typeof subscription.lineItemId === 'number' &&
    typeof subscription.serviceChannelId === 'number' &&
    (subscription.locationId === null ||
      typeof subscription.locationId === 'number') &&
    (subscription.addressId === null ||
      typeof subscription.addressId === 'number') &&
    typeof subscription.timeSlotId === 'number' &&
    typeof subscription.period === 'number' &&
    typeof subscription.targetIteration === 'number' &&
    typeof subscription.startIteration === 'number' &&
    (subscription.endIteration === null ||
      typeof subscription.endIteration === 'number') &&
    typeof subscription.timeSlotDivision === 'number' &&
    Array.isArray(subscription.selectionIds) &&
    subscription.selectionIds.every(
      (id : any) => typeof id === 'number'
    )
  );
}

export function isSubscriptionOption(
  option : any,
) : option is SubscriptionOption {
  return (
    (option.id === undefined || typeof option.id === 'number') &&
    Array.isArray(option.serviceChannelIds) &&
    option.serviceChannelIds.every(
      (id : any) => typeof id === 'number'
    )
  );
}

export interface ProjectedOrder extends DraftCustomOrder {
  subscriptions : { [id : number] : Subscription };
}
