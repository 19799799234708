import React, { createContext, useContext, useEffect, useState } from 'react';

import { Tax, TaxIntegration, ExternalTax } from '#mrktbox/clerk/types';

import TagContext from '#mrktbox/clerk/context/TagContext';

import useData, {
  DataIndex,
  useLoad,
} from '#mrktbox/clerk/hooks/useData';
import { useResponse } from '#mrktbox/clerk/hooks/api/useAPI';
import useCache from '#mrktbox/clerk/hooks/useDataCache';
import useTaxesAPI from '#mrktbox/clerk/hooks/api/useTaxesAPI';

import { filterIndex } from '#mrktbox/clerk/utils/data';

export type TaxIndex = DataIndex<Tax>;

const MAX_AGE = 1000 * 60 * 60;

const TaxContext = createContext({
  taxes : null as DataIndex<Tax> | null,
  loaded : false,
  load : () => {},
  createTax : async (tax : Tax) => null as Tax | null,
  refreshTax : async (id : number) => null as Tax | null,
  refreshTaxes : async () => null as TaxIndex | null,
  retrieveTax : async (id : number) => null as Tax | null,
  retrieveTaxes : async () => null as TaxIndex | null,
  updateTax : async (tax : Tax) => null as Tax | null,
  deleteTax : async (tax : Tax) => null as Tax | null,
  retrieveExternalTaxes : async () => null as {
    [key : number] : { [id : string] : ExternalTax }
  } | null,
  createTaxIntegration : async (
    tax : Tax,
    externalTax : ExternalTax,
  ) => null as Tax | null,
  deleteTaxIntegration :
    async (taxIntegration : TaxIntegration) => null as Tax | null,
  syncTaxIntegration :
    async (taxIntegration : TaxIntegration) => null as Tax | null,
  pushTaxIntegration :
    async (taxIntegration : TaxIntegration) => null as Tax | null,
  pullTaxIntegration :
    async (taxIntegration : TaxIntegration) => null as Tax | null,
});

interface TaxProviderProps {
  children : React.ReactNode;
}

export function TaxProvider({ children } : TaxProviderProps) {
  const { cacheTags } = useContext(TagContext);

  const {
    createTax,
    retrieveTaxes,
    retrieveTax,
    updateTax,
    deleteTax,
    retrieveExternalTaxes,
    createTaxIntegration,
    deleteTaxIntegration,
    syncTaxIntegration,
    pushTaxIntegration,
    pullTaxIntegration,
  } = useTaxesAPI();

  const {
    data : allTaxes,
    dispatch : dispatchTaxes,
    lastUpdated,
  } = useData<Tax>({ storageKey : 'taxes' });

  const [taxes, setTaxes] = useState(allTaxes
    ? filterIndex(allTaxes, undefined, { dropDeleted : true })
    : null
  );

  const stale = lastUpdated !== undefined
    && (new Date().getTime() - lastUpdated.getTime()) > MAX_AGE;

  const newTax = useCache({
    process : createTax,
    dispatch : dispatchTaxes,
    parser : cacheTags,
  });
  const refreshTaxes = useCache({
    process : retrieveTaxes,
    dispatch : dispatchTaxes,
    parser : cacheTags,
    refresh : true,
    isLoader : true,
  });
  const refreshTax = useCache({
    process : retrieveTax,
    dispatch : dispatchTaxes,
    parser : cacheTags,
    refresh : true,
    dropNull : true,
  });
  const getTaxes = useCache({
    process : retrieveTaxes,
    dispatch : dispatchTaxes,
    parser : cacheTags,
    data : allTaxes,
    stale,
    refresh : true,
    isLoader : true,
  });
  const getTax = useCache({
    process : retrieveTax,
    dispatch : dispatchTaxes,
    parser : cacheTags,
    data : allTaxes,
    stale,
    isLoader : true,
    dropNull : true,
  })
  const amendTax = useCache({
    process : updateTax,
    dispatch : dispatchTaxes,
    parser : cacheTags,
  });
  const removeTax = useCache({
    process : deleteTax,
    dispatch : dispatchTaxes,
    parser : cacheTags,
  });

  const newTaxIntegration = useCache({
    process : createTaxIntegration,
    dispatch : dispatchTaxes,
    parser : cacheTags,
  });
  const removeTaxIntegration = useCache({
    process : deleteTaxIntegration,
    dispatch : dispatchTaxes,
    parser : cacheTags,
  });
  const refreshTaxIntegration = useCache({
    process : syncTaxIntegration,
    dispatch : dispatchTaxes,
    parser : cacheTags,
  });
  const exportTaxIntegration = useCache({
    process : pushTaxIntegration,
    dispatch : dispatchTaxes,
    parser : cacheTags,
  });
  const importTaxIntegration = useCache({
    process : pullTaxIntegration,
    dispatch : dispatchTaxes,
    parser : cacheTags,
  });

  const getExternalTaxes = useResponse(retrieveExternalTaxes);

  const { loaded, load } = useLoad({
    data : taxes,
    loader : refreshTaxes,
  });

  useEffect(() => {
    if (!allTaxes) setTaxes(null);
    else setTaxes(filterIndex(allTaxes, undefined, { dropDeleted : true }));
  }, [allTaxes]);

  const context = {
    taxes,
    loaded,
    load,
    createTax : newTax,
    refreshTaxes,
    refreshTax,
    retrieveTaxes : getTaxes,
    retrieveTax : getTax,
    updateTax : amendTax,
    deleteTax : removeTax,
    retrieveExternalTaxes : getExternalTaxes,
    createTaxIntegration : newTaxIntegration,
    deleteTaxIntegration : removeTaxIntegration,
    syncTaxIntegration : refreshTaxIntegration,
    pushTaxIntegration : exportTaxIntegration,
    pullTaxIntegration : importTaxIntegration,
  };

  return (
    <TaxContext.Provider value={context}>
      { children }
    </TaxContext.Provider>
  );
}

export default TaxContext;
