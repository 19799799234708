import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme;}

const ModalView = styled.div<Style>`
  position: relative;
  width: 48rem;
  max-width: 90%;
  margin: ${(props) => props.theme.layout.spacing.medium} auto;
  overflow: hidden;

  background-color: ${(props) => props.theme.palette.background.fill};
  border:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.border};
  border-radius: ${(props) => props.theme.layout.border.radius};

  box-shadow: ${(props) => props.theme.layout.boxShadow.outer.map((bs) => (`
    ${bs.x} ${bs.y} ${bs.blur} ${bs.spread} ${bs.colour}
  `)).join(', ')};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.spacing.small} auto;
  }
`;

export default ModalView;
