import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }

const MenuItemCount = styled.div<Style>`
  display: flex;
  position: absolute;
  width:
    calc(1.5 * ${(props) => props.theme.typography.fonts.default.sizes.medium});
  height:
    calc(1.5 * ${(props) => props.theme.typography.fonts.default.sizes.medium});
  top: calc(0.75 *
    -${(props) => props.theme.typography.fonts.default.sizes.medium});
  right: calc(0.75 *
    -${(props) => props.theme.typography.fonts.default.sizes.medium});
  z-index: 5;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.palette.notifications.success.text};
  background-color:
    ${(props) => props.theme.palette.notifications.success.fill};

  border-radius: 50%;

  line-height: 1;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    top: calc(0.75
      * -${(props) => props.theme.typography.fonts.default.sizes.small});
    right: calc(0.75

    width: calc(1.5
      * ${(props) => props.theme.typography.fonts.default.sizes.small});
    height: calc(1.5
      * ${(props) => props.theme.typography.fonts.default.sizes.small});
  }

  span {
    display: block;

    font-family: ${(props) => props.theme.typography.fonts.default.family};
    font-weight:
      ${(props) => props.theme.typography.fonts.default.weights.regular};
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};
    line-height: 0;
    -webkit-font-smoothing: antialiased;

    @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
    }
  }
`;

export default MenuItemCount;
