import { useCallback } from 'react';

import { responseStatus } from '#mrktbox/clerk/hooks/api/useAPI';

import {
  Address,
  Customer,
  ContactInfo,
  CustomerIntegration,
  ExternalCustomer,
  CustomerUser,
  Integration,
} from '#mrktbox/clerk/types';

import {
  createCustomer as createCustomerAPI,
  retrieveCustomers as retrieveCustomersAPI,
  retrieveCustomer as retrieveCustomerAPI,
  deleteCustomer as deleteCustomerAPI,
  importCustomer as importCustomerAPI,
  createCustomerIntegration as createCustomerIntegrationAPI,
  deleteCustomerIntegration as deleteCustomerIntegrationAPI,
  syncCustomerIntegration as syncCustomerIntegrationAPI,
  pushCustomerIntegration as pushCustomerIntegrationAPI,
  pullCustomerIntegration as pullCustomerIntegrationAPI,
  retrieveExternalCustomers as retrieveExternalCustomersAPI,
  addCustomerIntegration as addCustomerIntegrationAPI,
  removeCustomerIntegration as removeCustomerIntegrationAPI,
  createContactInfo as createContactInfoAPI,
  updateContactInfo as updateContactInfoAPI,
  deleteContactInfo as deleteContactInfoAPI,
  addAddressToCustomer as addAddressToCustomerAPI,
  removeAddressFromCustomer as removeAddressFromCustomerAPI,
} from '#mrktbox/clerk/api/mrktbox/customers';
import useAPI from '#mrktbox/clerk/hooks/api/useAPI';

function useCustomersAPI() {
  const { processRequest, processRequestWithFeeback } = useAPI();

  const createCustomer = useCallback(
    async (
      customer : Customer,
      { contactInfo, user } : {
        contactInfo? : ContactInfo,
        user? : CustomerUser,
      } = {},
    ) => {
      const response =  await processRequestWithFeeback(
        createCustomerAPI,
        { customer, contactInfo, user },
      )
      return [
        response.response,
        { invalidUser : response.status === responseStatus.conflict },
      ] as [Customer | null, { invalidUser : boolean }];
    },
    [processRequestWithFeeback],
  )

  const retrieveCustomers = useCallback( async () => {
    return await processRequest(
      retrieveCustomersAPI,
      {},
    )
  }, [processRequest])

  const retrieveCustomer = useCallback( async ( customerId : number ) => {
    return await processRequest(
      retrieveCustomerAPI,
      { customerId },
    )
  }, [processRequest])

  const deleteCustomer = useCallback( async (customer : Customer) => {
    return await processRequest(
      deleteCustomerAPI,
      { customer },
    )
  }, [processRequest])

  const importCustomer = useCallback(
    async (integration : Integration, externalCustomer : ExternalCustomer) => {
      return await processRequest(
        importCustomerAPI,
        { integration, externalCustomer },
      )
    },
    [processRequest],
  )

  const createCustomerIntegration = useCallback(
    async (customer : Customer, externalCustomer : ExternalCustomer) => {
      return await processRequest(
        createCustomerIntegrationAPI,
        { customer, externalCustomer },
      )
    },
    [processRequest],
  )

  const deleteCustomerIntegration = useCallback(
    async (customerIntegration : CustomerIntegration) => {
      return await processRequest(
        deleteCustomerIntegrationAPI,
        { customerIntegration },
      )
    },
    [processRequest],
  )

  const syncCustomerIntegration = useCallback(
    async (customerIntegration : CustomerIntegration) => {
      return await processRequest(
        syncCustomerIntegrationAPI,
        { customerIntegration },
      )
    },
    [processRequest],
  )

  const pushCustomerIntegration = useCallback(
    async (customerIntegration : CustomerIntegration) => {
      return await processRequest(
        pushCustomerIntegrationAPI,
        { customerIntegration },
      )
    },
    [processRequest],
  )

  const pullCustomerIntegration = useCallback(
    async (customerIntegration : CustomerIntegration) => {
      return await processRequest(
        pullCustomerIntegrationAPI,
        { customerIntegration },
      )
    },
    [processRequest],
  )

  const retrieveExternalCustomers = useCallback(async (
    query? : string,
  ) => {
    return await processRequest(
      retrieveExternalCustomersAPI,
      { query },
    )
  }, [processRequest]);

  const addCustomerIntegration = useCallback(
    async (customer : Customer, externalCustomer : CustomerIntegration) => {
      return await processRequest(
        addCustomerIntegrationAPI,
        { customer, externalCustomer },
      )
    },
    [processRequest],
  )

  const removeCustomerIntegration = useCallback(
    async (customer : Customer, customerIntegration : CustomerIntegration) => {
      return await processRequest(
        removeCustomerIntegrationAPI,
        { customer, customerIntegration },
      )
    },
    [processRequest],
  )

  const createContactInfo = useCallback(
    async (customer : Customer, contactInfo : ContactInfo) => {
      return await processRequest(
        createContactInfoAPI,
        {
          contactInfo : {
            ...contactInfo,
            ...customer && { customerId : customer.id },
          }
        },
      )
    },
    [processRequest],
  )

  const updateContactInfo = useCallback( async (contactInfo : ContactInfo) => {
    return await processRequest(
      updateContactInfoAPI,
      { contactInfo },
    )
  }, [processRequest])

  const deleteContactInfo = useCallback( async (contactInfo : ContactInfo) => {
    return await processRequest(
      deleteContactInfoAPI,
      { contactInfo },
    )
  }, [processRequest])

  const addAddressToCustomer = useCallback(
    async (customer : Customer, address : Address) => {
      return await processRequest(
        addAddressToCustomerAPI,
        { customer, address },
      )
    }, [processRequest])

  const removeAddressFromCustomer = useCallback(
    async (customer : Customer, address : Address) => {
      return await processRequest(
        removeAddressFromCustomerAPI,
        { customer, address },
      )
    },
    [processRequest],
  )

  return {
    createCustomer,
    retrieveCustomers,
    retrieveCustomer,
    deleteCustomer,
    importCustomer,
    createCustomerIntegration,
    deleteCustomerIntegration,
    syncCustomerIntegration,
    pushCustomerIntegration,
    pullCustomerIntegration,
    retrieveExternalCustomers,
    addCustomerIntegration,
    removeCustomerIntegration,
    createContactInfo,
    updateContactInfo,
    deleteContactInfo,
    addAddressToCustomer,
    removeAddressFromCustomer,
  }
}

export default useCustomersAPI;
