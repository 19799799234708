
export interface ImageUpload {
  id : number;
  uploadUrl : string;
}

export interface Image {
  id : number;
  imageUrl : string;
  uploadUrl? : string;
  variants : { [key : string] : string };
}

export function isImageUpload(upload : ImageUpload) : upload is ImageUpload {
  return (
    typeof upload === 'object' &&
    typeof upload.id === 'number' &&
    typeof upload.uploadUrl === 'string'
  );
}

export function isImage(image : any) : image is Image {
  return (
    typeof image === 'object' &&
    typeof image.id === 'number' &&
    typeof image.imageUrl === 'string' &&
    (image.uploadUrl === undefined ||
      typeof image.uploadUrl === 'string') &&
    typeof image.variants === 'object' &&
    Object.keys(image.variants).every((key) => (
      typeof image.variants[key] === 'string'
    ))
  );
}
