import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }

const Ingredients = styled.p<Style>`
  margin: 0 0 ${(props) => props.theme.layout.spacing.xsmall};
  color: ${(props) => props.theme.palette.background.text.primary};

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
`;

interface ProductIngredientsProps {
  ingredients: string[];
}

function MenuItemIngredients({ ingredients } : ProductIngredientsProps) {
  return (
    <Ingredients>{
      ingredients.map((ing, i) => {
        switch (i) {
          case 0:
            return ing.charAt(0).toUpperCase() + ing.slice(1);
          case ingredients.length - 1:
            return ' and ' + ing + '.';
          default:
            return ing;
        }
      }).join(', ')
    }</Ingredients>
  );
}

export default MenuItemIngredients;
