import React, { useCallback } from 'react'
import styled from '@emotion/styled'

import { Theme } from '#types'

import Label from '#materials/Label';

interface InputViewStyle {
  theme? : Theme;
  hasValue? : boolean;
  hasError? : boolean;
}

const InputView = styled.input<InputViewStyle>`
  width: 100%;
  padding:
    ${(props) => props.theme.layout.spacing.small}
    0
    ${(props) => props.theme.layout.spacing.xsmall};

  opacity: 0.85;
  color: ${(props) => props.theme.palette.background.text.primary};
  background-color: transparent;

  border: none;
  border-bottom:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.border};

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  font-weight: ${(props) =>
    props.theme.typography.fonts.default.weights.regular};
  letter-spacing: ${(props) =>
    props.theme.typography.fonts.default.letterSpacing};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
  -webkit-font-smoothing: antialiased;

  transition: all 0.150s ease;

  ${(props) => props.hasError
    ? `border-color: ${props.theme.palette.background.text.alert};`
    : ''}

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
  }

  &:active,
  &:focus {
    opacity: 1;
    color: ${(props) => props.theme.palette.background.text.primary};
    border-color:${(props) => props.theme.palette.background.text.primary};

    outline: none;

    ${(props) =>
      props.hasError
        ? `border-color: ${props.theme.palette.background.text.alert};`
        : ''}
  }

  &::placeholder {
    opacity: 0.85;
    color: ${(props) => props.theme.palette.background.text.primary};
  }

  &:disabled,
  &:read-only {
    cursor: default;
    opacity: 0.85;

    color: ${(props) => props.theme.palette.background.text.primary};
    border-color: ${(props) => props.theme.palette.background.text.primary};
  }
`;

interface InputProps {
  icon? : React.ReactNode;
  label? : string;
  name : string;
  type? : string;
  value? : string;
  onChange? : (value : string) => void;
  onBlur? : () => void;
  error? : string;
  showLabel? : boolean;
  placeholder? : string;
  disabled? : boolean;
  readOnly? : boolean;
  required? : boolean;
  autoComplete? : string;
  pattern? : string;
  min? : string;
  max? : string;
  style? : React.CSSProperties;
  inputRef? : React.Ref<HTMLInputElement>;
  children? : React.ReactNode;
}

function Input({
  icon = null,
  label,
  name,
  type,
  value,
  onChange,
  onBlur,
  error = '',
  showLabel = true,
  placeholder = '',
  disabled = false,
  readOnly = false,
  required = false,
  autoComplete,
  pattern,
  min,
  max,
  style,
  inputRef,
  children,
} : InputProps) {
  const handleChange = useCallback(
    (e : React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <Label
      htmlFor={name}
      icon={icon}
      text={label}
      value={value}
      required={required}
      errMsg={error}
      showLabel={showLabel}
      disabled={disabled}
      style={style}
    >
      <InputView
        aria-label={label}
        id={name}
        name={name}
        type={type}
        pattern={pattern}
        min={min}
        max={max}
        autoComplete={autoComplete || undefined}
        value={value || ''}
        placeholder={placeholder || ''}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        onChange={handleChange}
        onBlur={onBlur}
        ref={inputRef}
        hasValue={!!value}
        hasError={!!error}
      />
      { children }
    </Label>
  );
}

export default Input;
