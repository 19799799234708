import React from 'react';
import styled from '@emotion/styled';

import { Product, Assembly } from '#mrktbox/types';
import { Theme } from '#types';

import ProductOptionsNavScroll
  from '#components/products/ProductOptionsNavScroll';

interface Style { theme? : Theme; }
interface ProductOptionsNavViewStyle extends Style {
  stickyTop? : number;
}

const ProductOptionsNavView = styled.div<ProductOptionsNavViewStyle>`
  label: ProductOptionsNavView;
  position: sticky;
  z-index: 10;
  top: ${(props) => (props.stickyTop ?? 0) - 2}px;
  height: fit-content;
  width: 100%;
  margin: ${(props) => props.theme.layout.spacing.xsmall} 0 0;

  color: ${(props) => props.theme.palette.background.text.primary};
  border-color: ${(props) => props.theme.palette.border};
`;

const ProductOptionsNavInner = styled.div<Style>`
  width: 100%;
  position: relative;
  background-color: ${(props) => props.theme.palette.background.fill};
  border-bottom:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.border};
`;

const ProductOptionsNavContainer = styled.div<Style>`
  display: flex;
  position: relative;
  width: 100%;
  align-items: end;
  justify-content: flex-start;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface ProductOptionsNavProps {
  assemblies : Assembly[];
  selections : { [id : number] : Product[] }
  scrollOffset : number;
  scrollRef? : React.RefObject<HTMLDivElement>;
}

function ProductOptionsNav({
  assemblies,
  selections,
  scrollOffset,
  scrollRef,
} : ProductOptionsNavProps) {

  return (
    <ProductOptionsNavView stickyTop={scrollOffset}>
      <ProductOptionsNavInner>
        <ProductOptionsNavContainer>
          <ProductOptionsNavScroll
            assemblies={assemblies}
            selections={selections}
            scrollOffset={scrollOffset}
            scrollRef={scrollRef}
          />
        </ProductOptionsNavContainer>
      </ProductOptionsNavInner>
    </ProductOptionsNavView>
  );
}

export default ProductOptionsNav;
