import {
  Address,
  Customer,
  ContactInfo,
  CustomerIntegration,
  ExternalCustomer,
  CustomerUser,
  isCustomer,
  isExternalCustomer,
  isContactInfo,
  Integration,
} from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

const CUSTOMERS_PATH = 'customers/'
const IMPORT_PATH = `${CUSTOMERS_PATH}import/`
const CUSTOMER_INTEGRATIONS_PATH = `${CUSTOMERS_PATH}integrations/`
const EXTERNAL_CUSTOMERS_PATH = `${CUSTOMERS_PATH}external/`
const CONTACTINFO_PATH = `${CUSTOMERS_PATH}contacts/`

function parseContactInfo(contactInfo : any) : ContactInfo {
  if (typeof contactInfo.id !== 'number')
    throw new TypeError('Contact info id is not a number');
  if (typeof contactInfo.name !== 'string')
    throw new TypeError('Contact info name is not a string');
  if (typeof contactInfo.nickname !== 'string')
    throw new TypeError('Contact info nickname is not a string');
  if (typeof contactInfo.email !== 'string')
    throw new TypeError('Contact info email is not a string');
  if (contactInfo.phone !== undefined && typeof contactInfo.phone !== 'string')
    throw new TypeError('Contact info phone is not a string');
  if (
    contactInfo.smsNotifications !== undefined &&
    typeof contactInfo.smsNotifications !== 'boolean'
  ) throw new TypeError('Contact info smsNotifications is not a boolean');
  if (
    contactInfo.emailNotifications !== undefined &&
    typeof contactInfo.emailNotifications !== 'boolean'
  ) throw new TypeError('Contact info emailNotifications is not a boolean');
  if (
    contactInfo.customerId !== undefined &&
    typeof contactInfo.customerId !== 'number'
  ) throw new TypeError('Contact info customerId is not a number');

  return {
    id : contactInfo.id,
    name : contactInfo.name,
    nickname : contactInfo.nickname,
    email : contactInfo.email,
    phone : contactInfo.phone,
    smsNotifications : contactInfo.smsNotifications,
    emailNotifications : contactInfo.emailNotifications,
    customerId : contactInfo.customerId,
  }
}

function parseCustomer(customer : any) : Customer {
  let defaultName : string | undefined;
  let defaultEmail : string | undefined;

  if (typeof customer.contactInfo !== 'object')
    throw new TypeError('Customer contact info is not an object');
  if (Object.keys(customer.contactInfo).length > 0) {
    const primaryContact = Object.values(customer.contactInfo)[0];
    if (!isContactInfo(primaryContact))
      throw new TypeError('Customer contact info is not a contact info');
    defaultName = primaryContact.name;
    defaultEmail = primaryContact.email;
  }

  customer.defaultName = defaultName;
  customer.defaultEmail = defaultEmail;
  if (!isCustomer(customer))
    throw new TypeError('Customer is not a customer');

  return customer;
}

function parseCustomers(customers : any) : { [id : number] : Customer } {
  const parsedCustomers = {} as { [id : number] : Customer };
  for (const customerId in customers) {
    if (typeof customerId !== 'string')
    throw new TypeError('Customer id is not a string');

    const id = parseInt(customerId);
    if (isNaN(id))
      throw new TypeError('Customer id is not a number');

    parsedCustomers[id] = parseCustomer(customers[id]);
  }
  return parsedCustomers;
}

function parseIntegrationCustomers(
  customerIntergrations : any,
  integrationId? : number,
) {
  const parsedExternalCustomers = {} as { [id : string] : ExternalCustomer; };
  for (const externalId in customerIntergrations) {
    if (typeof externalId !== 'string')
      throw new TypeError('External customer id is not a string');

    const customerIntegration = customerIntergrations[externalId];
    customerIntegration.externalId = externalId;
    if (integrationId) customerIntegration.integrationId = integrationId;
    if (!isExternalCustomer(customerIntegration))
      throw new TypeError('External customer is not an external customer');

    parsedExternalCustomers[externalId] = customerIntegration;
  }

  return parsedExternalCustomers;
}

function parseExternalCustomers(externalCustomers : any) {
  const parsedIntegrationCustomers = {} as {
    [integrationId : number] : { [id : string] : ExternalCustomer; };
  };
  for (const integrationId in externalCustomers) {
    if (typeof integrationId !== 'string')
      throw new TypeError('Integration id is not a string');

    const id = parseInt(integrationId);
    if (isNaN(id))
      throw new TypeError('Integration id is not a number');

    parsedIntegrationCustomers[id] = parseIntegrationCustomers(
      externalCustomers[id],
      id,
    );
  }
  return parsedIntegrationCustomers;
}

export async function createCustomer({
  customer,
  contactInfo,
  user,
} : {
  customer : Customer,
  contactInfo? :ContactInfo,
  user? : CustomerUser,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(CUSTOMERS_PATH),
    methods.post,
    { customer, contactInfo, user },
    options,
  );

  try {
    return parseCustomer(response.customer);
  } catch {
    throw new DeserializationError(
      'Could not deserialize customer',
      response,
    );
  }
}

export async function retrieveCustomers(
  input? : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(CUSTOMERS_PATH, { includeIntegrations : 'true' }),
    methods.get,
    undefined,
    options,
  );
  try {
    return parseCustomers(response.customers);
  } catch (err) {
    console.error(err);
    throw new DeserializationError(
      'Could not parse customer list',
      response,
    );
  }
}

export async function retrieveCustomer({
  customerId
} : { customerId : number }, options? : RequestOptions) {
  const response = await request(
    getUrl(CUSTOMERS_PATH + customerId, { includeIntegrations : 'true' }),
    methods.get,
    undefined,
    options,
  );
  try {
    return parseCustomer(response.customer);
  } catch {
    throw new DeserializationError(
      'Could not parse customer',
      response,
    );
  }
}

export async function deleteCustomer(
  { customer } : { customer : Customer },
  options? : RequestOptions,
) {
  const response = await request(
    `${getUrl(CUSTOMERS_PATH)}${customer.id}`,
    methods.delete,
    undefined,
    options,
  );

  return !!response?.success;
}

export async function retrieveExternalCustomers(
  input? : { query? : string },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(
      EXTERNAL_CUSTOMERS_PATH,
      input?.query ? { query : input.query } : {},
    ),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseExternalCustomers(response.externalCustomers);
  } catch (err) {
    console.error(err);
    throw new DeserializationError(
      'Could not parse external customers',
      response,
    );
  }
}

export async function importCustomer({
  integration,
  externalCustomer,
} : {
  integration : Integration,
  externalCustomer : ExternalCustomer,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${IMPORT_PATH}${integration.id}/${externalCustomer.externalId}`),
    methods.post,
    {},
    options,
  );

  try {
    return parseCustomer(response.customer);
  } catch {
    throw new DeserializationError(
      'Could not deserialize customer',
      response,
    );
  }
}

export async function createCustomerIntegration({
  customer,
  externalCustomer,
} : {
  customer : Customer,
  externalCustomer : ExternalCustomer,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${CUSTOMERS_PATH}${customer.id}/integrations/` +
      `${externalCustomer.integrationId}/${externalCustomer.externalId}`),
    methods.post,
    {},
    options,
  );

  try {
    return parseCustomer(response.customer);
  } catch {
    throw new DeserializationError(
      'Could not deserialize customer',
      response,
    );
  }
}

export async function deleteCustomerIntegration(
  { customerIntegration } : { customerIntegration : CustomerIntegration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${CUSTOMER_INTEGRATIONS_PATH}${customerIntegration.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseCustomer(response.customer);
  } catch {
    throw new DeserializationError(
      'Could not deserialize customer',
      response,
    );
  }
}

export async function syncCustomerIntegration(
  { customerIntegration } : { customerIntegration : CustomerIntegration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${CUSTOMER_INTEGRATIONS_PATH}${customerIntegration.id}/sync/`),
    methods.post,
    undefined,
    options,
  );

  try {
    return parseCustomer(response.customer);
  } catch {
    throw new DeserializationError(
      'Could not deserialize customer',
      response,
    );
  }
}

export async function pushCustomerIntegration(
  { customerIntegration } : { customerIntegration : CustomerIntegration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${CUSTOMER_INTEGRATIONS_PATH}${customerIntegration.id}/push/`),
    methods.post,
    undefined,
    options,
  );

  try {
    return parseCustomer(response.customer);
  } catch {
    throw new DeserializationError(
      'Could not deserialize customer',
      response,
    );
  }
}

export async function pullCustomerIntegration(
  { customerIntegration } : { customerIntegration : CustomerIntegration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${CUSTOMER_INTEGRATIONS_PATH}${customerIntegration.id}/pull/`),
    methods.post,
    undefined,
    options,
  );

  try {
    return parseCustomer(response.customer);
  } catch {
    throw new DeserializationError(
      'Could not deserialize customer',
      response,
    );
  }
}

export async function addCustomerIntegration({
  customer,
  externalCustomer,
} : {
  customer : Customer,
  externalCustomer : CustomerIntegration,
}, options? : RequestOptions) {
  const response = await request(
    `${getUrl(CUSTOMERS_PATH)}${customer.id}/integrations/` +
      `${externalCustomer.integrationId}/${externalCustomer.externalId}`,
    methods.post,
    {},
    options,
  );

  return !!response;
}

export async function removeCustomerIntegration({
  customer,
  customerIntegration,
} : {
  customer : Customer,
  customerIntegration : CustomerIntegration,
}, options? : RequestOptions) {
  const response = await request(
    `${getUrl(CUSTOMERS_PATH)}${customer.id}/integrations/` +
      `${customerIntegration.integrationId}/${customerIntegration.externalId}`,
    methods.delete,
    undefined,
    options,
  );

  return !!response;
}

export async function createContactInfo(
  { contactInfo } : { contactInfo : ContactInfo },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(CONTACTINFO_PATH),
    methods.post,
    {
      contactInfo : {
        name : contactInfo.name,
        nickname : contactInfo.nickname,
        email : contactInfo.email,
        smsNotifications : contactInfo.smsNotifications,
        emailNotifications : contactInfo.emailNotifications,
        ...(contactInfo.phone && { phone : contactInfo.phone }),
        ...(contactInfo.customerId && { customerId : contactInfo.customerId }),
      }
    },
    options,
  );

  try {
    return parseContactInfo(response.contactInfo);
  } catch {
    throw new DeserializationError(
      'Could not deserialize contact info',
      response,
    );
  }
}

export async function updateContactInfo(
  { contactInfo } : { contactInfo : ContactInfo },
  options? : RequestOptions,
) {
  const response = await request(
    `${getUrl(CONTACTINFO_PATH)}${contactInfo.id}`,
    methods.put,
    {
      contactInfo : {
        name : contactInfo.name,
        nickname : contactInfo.nickname,
        email : contactInfo.email,
        smsNotifications : contactInfo.smsNotifications,
        emailNotifications : contactInfo.emailNotifications,
        ...(contactInfo.phone && { phone : contactInfo.phone }),
        ...(contactInfo.customerId && { customerId : contactInfo.customerId })
      }
    },
    options,
  );

  try {
    return parseContactInfo(response.contactInfo);
  } catch {
    throw new DeserializationError(
      'Could not deserialize contact info',
      response,
    );
  }
}

export async function deleteContactInfo(
  { contactInfo } : { contactInfo : ContactInfo },
  options? : RequestOptions,
) {
  const response = await request(
    `${getUrl(CONTACTINFO_PATH)}${contactInfo.id}`,
    methods.delete,
    undefined,
    options,
  );

  return !!response?.success;
}

export async function addAddressToCustomer(
  { customer, address } : { customer : Customer, address : Address },
  options? : RequestOptions,
) {
  const response = await request(
    `${getUrl(CUSTOMERS_PATH)}${customer.id}/addresses/${address.id}`,
    methods.post,
    {},
    options,
  );

  return !!response;
}

export async function removeAddressFromCustomer(
  { customer, address } : { customer : Customer, address : Address },
  options? : RequestOptions,
) {
  const response = await request(
    `${getUrl(CUSTOMERS_PATH)}${customer.id}/addresses/${address.id}`,
    methods.delete,
    undefined,
    options,
  );

  return !!response;
}
