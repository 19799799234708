import { useCallback } from 'react';

import { Partial } from '#mrktbox/clerk/types';

import useNavigation from '#mrktbox/clerk/hooks/useNavigation';
import useUsers from '#mrktbox/clerk/hooks/useUsers';

const disableAuth = process.env.REACT_APP_DISABLE_AUTH_CHECK ?? false;

const checkAuthDefault = {
  redirect : '/login/' as string | null,
  permissions : [] as string[] | undefined,
};
type CheckAuthOptions = Partial<typeof checkAuthDefault>;

function useAuth() {
  const { navigate } = useNavigation();
  const {
    user,
    token,
    login,
    logout,
    retrievePasswordResetToken,
    resetPassword,
    sendPasswordReset,
    checkAuth : checkUserAuth,
  } = useUsers();

  const checkAuth = useCallback(
    (options : CheckAuthOptions = checkAuthDefault) => {
      const opt = { ...checkAuthDefault, ...options };
      if (disableAuth) return true;

      if (checkUserAuth(opt)) return true;

      if (opt.redirect !== null) navigate(opt.redirect);
      return false;
    },
    [navigate, checkUserAuth],
  );

  return {
    user,
    token,
    login,
    logout,
    retrievePasswordResetToken,
    resetPassword,
    sendPasswordReset,
    checkAuth,
  };
}

export default useAuth;
