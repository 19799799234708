import styled from '@emotion/styled';

import { Theme } from '#types';

interface MessageStyle {
  theme? : Theme;
}

const Message = styled.span<MessageStyle>`
  display: inline-block;
  padding: ${(props) => props.theme.layout.spacing.xsmall};
  color: ${(props) => props.theme.palette.background.text.primary};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
`;

export default Message;
