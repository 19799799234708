import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useModal from '#hooks/useModal';

import Headline from '#components/page/Headline';
import ModalClose from '#components/modal/ModalClose';

interface Style { theme? : Theme; }

const ModalContentView = styled('div')<Style>`
  padding: 3.5rem 3.5rem 4rem;
  color: ${(props) => props.theme.palette.background.text.primary};

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    padding: 3rem 2.5rem 3rem;
  }
`;

const ModalHeader = styled('div')<Style>`
  margin: 0 0 ${(props) => props.theme.layout.spacing.small};
`;

const ModalTitle = styled(Headline)<Style>`
  margin: 0 0 0 -0.2rem;
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.xlarge};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.large};
  }
`;

const ModalSubtitle = styled.div<Style>`
  p {
    margin-top: ${(props) => props.theme.layout.spacing.xsmall};
    line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};

    @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
    }
  }
`;

const ModalBody = styled('div')<Style>`
  & > div {
    & > p {
      margin: ${(props) => props.theme.layout.spacing.xsmall} 0;
      line-height:
        ${(props) => props.theme.typography.fonts.default.lineHeight};

      button {
        margin: 0
          ${(props) => props.theme.layout.spacing.xsmall}
          ${(props) => props.theme.layout.spacing.xsmall}
          0;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
`;

const ModalFooter = styled('div')<Style>`
  margin-top: 2rem;

  > {
    p {
      font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
    }
  }

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    button {
      flex-shrink: 0;
      display: block;
      margin:
        ${(props) => props.theme.layout.spacing.xsmall}
        ${(props) => props.theme.layout.spacing.xsmall}
        0 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

interface ModalContentProps {
  title? : string;
  subtitle? : React.ReactNode;
  allowClose? : boolean;
  footer? : React.ReactNode;
  children : React.ReactNode;
}

const ModalContent = ({
  title,
  subtitle,
  allowClose = true,
  footer,
  children,
} : ModalContentProps) => {
  const { closeModal } = useModal();

  const hasHeader = title || subtitle ? true : false;

  return (
    <>
      <ModalContentView
        role="dialog"
        aria-labelledby="dialogTitle"
      >
        {allowClose && <ModalClose onClick={closeModal}/>}
        {hasHeader && (
          <ModalHeader>
            {title && (
              <ModalTitle as="p" id="dialogTitle">
                {title}
              </ModalTitle>
            )}
            {subtitle && <ModalSubtitle>{subtitle}</ModalSubtitle>}
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContentView>
    </>
  );
}

export default ModalContent;
