
export interface Count {
  id? : number;
  current : number;
  productId : number;
  locationId : number;
}

export function isCount(count : any) : count is Count {
  return (
    (count.id === undefined || typeof count.id === 'number')
      && typeof count.current === 'number'
      && typeof count.productId === 'number'
      && typeof count.locationId === 'number'
  )
}
