import styled from '@emotion/styled';

import { Theme } from '#types';

import useRequests from '#hooks/useRequests';

import { ShoppingBag } from '#materials/icons';
import IconButton from '#materials/IconButton';

interface Style {
  theme? : Theme;
}

interface CartIconStyle extends Style {
  isFilled? : boolean;
}

const CartIcon = styled.div<CartIconStyle>`
  position: relative;
  margin: 0;

  button {
    color: ${(props) => props.theme.palette.background.text.primary};

    svg {
      transition:all 0.150s ease;
      fill: 'transparent';
    }

    &:hover,
    &:active {
      color: ${(props) => props.theme.palette.background.text.hover};
    }
  }
`;

interface CountStlye extends Style { fade? : boolean; }

const CartCount = styled.div<CountStlye>`
  display: flex;
  position: absolute;
  min-width: 1.7rem;
  height: 1.7rem;
  top: 0.5rem;
  right: 0.6rem;
  z-index: 1;
  padding-bottom: 0.1rem;

  align-items: center;
  justify-content: center;
  ${(props) => props.fade && 'opacity: 0.75;'}

  color: ${(props) => props.theme.palette.notifications.success.text};
  background-color: ${(props) =>
    props.theme.palette.notifications.success.fill};

  border-radius: 50%;

  font-size: ${(props) => props.theme.typography.fonts.default.sizes.xxsmall};
`;

interface CartButtonProps {
  quantity? : number;
  filled? : boolean;
  onClick? : () => void;
}

function CartButton({
  quantity = 0,
  filled = false,
  onClick,
} : CartButtonProps) {
  const { waiting } = useRequests();

  return (
    <CartIcon isFilled={filled}>
      <IconButton onClick={onClick}>
        { quantity > 0 && (
          <CartCount fade={waiting}>{ quantity }</CartCount>
        ) }
        <ShoppingBag size={24} />
      </IconButton>
    </CartIcon>
  );
}

export default CartButton;
