import React, { createContext, useCallback, useContext } from 'react';

import {
  Adjustment,
  Condition,
  Product,
  Service,
  isCondition,
} from '#mrktbox/clerk/types';

import AdjustmentContext from '#mrktbox/clerk/context/AdjustmentContext';

import useData, {
  actionTypes,
  DataIndex,
  useLoad,
} from '#mrktbox/clerk/hooks/useData';
import useCache from '#mrktbox/clerk/hooks/useDataCache';
import useAdjustmentsAPI from '#mrktbox/clerk/hooks/api/useAdjustmentsAPI';

export type ConditionIndex = DataIndex<Condition>;

const MAX_AGE = 1000 * 60 * 60;

const ConditionContext = createContext({
  conditions: null as DataIndex<Condition> | null,
  loaded: false,
  load: () => {},
  cacheConditions: <T extends Condition>(
    condition: DataIndex<T> | T | null
  ) => null as DataIndex<T> | null,
  createCondition: async (
    condition : Condition,
    relationships? : {
      adjustments? : Adjustment[] | number[],
      products? : Product[] | number[],
      services? : Service[] | number[],
    }
  ) => null as Condition | null,
  refreshCondition: async (id: number) => null as Condition | null,
  refreshConditions: async () => null as ConditionIndex | null,
  retrieveCondition: async (id: number) => null as Condition | null,
  retrieveConditions: async () => null as ConditionIndex | null,
  updateCondition: async (
    condition: Condition,
    relationships? : {
      product? : Product | number,
      service? : Service | number,
    },
  ) => null as Condition | null,
  deleteCondition: async (condition: Condition) => null as Condition | null,
  addProductToCondition: async (
    condition: Condition,
    product: Product,
  ) => null as Condition | null,
  removeProductFromCondition: async (
    condition: Condition,
    product: Product,
  ) => null as Condition | null,
  addServiceToCondition: async (
    condition: Condition,
    service: Service,
  ) => null as Condition | null,
  removeServiceFromCondition: async (
    condition: Condition,
    service: Service,
  ) => null as Condition | null,
});

interface ConditionProviderProps {
  children: React.ReactNode;
}

export function ConditionProvider({ children } : ConditionProviderProps) {
  const { cacheAdjustments } = useContext(AdjustmentContext);
  const {
    createCondition,
    retrieveConditions,
    retrieveCondition,
    updateCondition,
    deleteCondition,
    addProductToCondition,
    removeProductFromCondition,
    addServiceToCondition,
    removeServiceFromCondition,
  } = useAdjustmentsAPI();

  const {
    data : conditions,
    dispatch : dispatchConditions,
    lastUpdated,
  } = useData<Condition>({ storageKey : 'conditions' });

  const cacheConditions = useCallback(
    <T extends Condition>(conditions : DataIndex<T> | T | null) => {
      if (!conditions) return null;
      const index = isCondition(conditions)
        ? (conditions.id ? { [conditions.id] : conditions } : {})
        : conditions;

      dispatchConditions({
        type : actionTypes.add,
        data : index,
      });
      return index;
    },
    [dispatchConditions],
  );

  const stale = lastUpdated !== undefined
    && (new Date().getTime() - lastUpdated.getTime()) > MAX_AGE;

  const parseConditionResponse = useCallback((response : {
    condition : Condition,
    adjustments : DataIndex<Adjustment>,
  }) => {
    cacheAdjustments(response.adjustments);
    if (!response.condition.id) return {};
    return { [response.condition.id] : response.condition };
  }, [cacheAdjustments]);

  const newCondition = useCache({
    process : createCondition,
    dispatch : dispatchConditions,
    parser : parseConditionResponse,
    filter : ((response) => response.condition)
  });
  const refreshConditions = useCache({
    process : retrieveConditions,
    dispatch : dispatchConditions,
    refresh : true,
    isLoader : true,
  });
  const refreshCondition = useCache({
    process : retrieveCondition,
    dispatch : dispatchConditions,
    refresh : true,
    dropNull : true,
  });
  const getConditions = useCache({
    process : retrieveConditions,
    dispatch : dispatchConditions,
    data : conditions,
    stale,
    refresh : true,
    isLoader : true,
  });
  const getCondition = useCache({
    process : retrieveCondition,
    dispatch : dispatchConditions,
    data : conditions,
    stale,
    isLoader : true,
    dropNull : true,
  });
  const amendCondition = useCache({
    process : updateCondition,
    dispatch : dispatchConditions,
  });
  const removeCondition = useCache({
    process : deleteCondition,
    dispatch : dispatchConditions,
    drop : true,
  });

  const addProduct = useCache({
    process : addProductToCondition,
    dispatch : dispatchConditions,
  })
  const removeProduct = useCache({
    process : removeProductFromCondition,
    dispatch : dispatchConditions,
  })

  const addService = useCache({
    process : addServiceToCondition,
    dispatch : dispatchConditions,
  })
  const removeService = useCache({
    process : removeServiceFromCondition,
    dispatch : dispatchConditions,
  })

  const { loaded, load } = useLoad({
    data : conditions,
    loader : refreshConditions,
  });

  const context = {
    conditions,
    loaded,
    load,
    cacheConditions,
    createCondition : newCondition,
    refreshConditions,
    refreshCondition,
    retrieveConditions : getConditions,
    retrieveCondition : getCondition,
    updateCondition : amendCondition,
    deleteCondition : removeCondition,
    addProductToCondition : addProduct,
    removeProductFromCondition : removeProduct,
    addServiceToCondition : addService,
    removeServiceFromCondition : removeService,
  };

  return (
    <ConditionContext.Provider value={context}>
      {children}
    </ConditionContext.Provider>
  );
}

export default ConditionContext;
