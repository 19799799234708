import React, { useRef, useCallback } from 'react';
import styled from '@emotion/styled';

import useFocus from '#hooks/useFocus';
import useModal from '#hooks/useModal';

import Transition, { TransitionGroup } from '#materials/Transition';

import ModalOverlay from '#components/modal/ModalOverlay';

const ModalContainer = styled('div')`
  position: fixed;
  z-index: 110;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ModalContentView = styled('div')`
  max-width: 100%;
`;

const Modal = () => {
  const { open, closeModal, modal : modalConent } = useModal();

  const modal = useRef<HTMLDivElement>(null)

  const { takeFocus, returnFocus } = useFocus({
    container : modal,
  });

  const handleClose = useCallback((evt : React.MouseEvent<HTMLDivElement>) => {
    closeModal();
  }, [closeModal]);

  const catchClick = useCallback((evt : React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
  }, []);

  return (
    <>
      <ModalOverlay show={open} />
      <TransitionGroup component={null}>
        {open ? (
          <Transition
            key="modal"
            classNames="md"
            timeout={{ enter: 250, exit: 250 }}
            onEntered={takeFocus}
            onExited={returnFocus}
          >
            <ModalContainer
              ref={modal}
              id="modal-container"
              onClick={handleClose}
            >
              <ModalContentView onClick={catchClick}>
                { modalConent }
              </ModalContentView>
            </ModalContainer>
          </Transition>
        ) : null}
      </TransitionGroup>
    </>
  );
}

export default Modal;
