
export interface Address {
  id? : number;
  street : string;
  city : string;
  postal : string;
  description? : string;
  latitude? : number;
  longitude? : number;
}

export interface Area {
  id? : number;
  name : string;
  vertices : [number, number][];
}

export function isAddress(address : any) : address is Address {
  return (
    (address.id === undefined || typeof address.id === 'number') &&
    typeof address.street === 'string' &&
    typeof address.city === 'string' &&
    typeof address.postal === 'string' &&
    (address.description === undefined ||
      typeof address.description === 'string') &&
    (address.latitude === undefined || address.longitude === null ||
      typeof address.latitude === 'number') &&
    (address.longitude === undefined || address.latitude === null ||
      typeof address.longitude === 'number')
  );
}

export function isArea(area : any) : area is Area {
  return (
    area &&
    (area.id === undefined || typeof area.id === 'number') &&
    typeof area.name === 'string' &&
    Array.isArray(area.vertices) &&
    area.vertices.every((vertex : any) =>
      Array.isArray(vertex) &&
      vertex.length === 2 &&
      vertex.every((coordinate : any) => typeof coordinate === 'number')
    )
  );
}
