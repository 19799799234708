import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useNavigation, useSearchParams } from '#mrktbox';

import Reset from '#slices/PasswordReset';

import Content from '#components/page/Content';
import Main from '#components/page/Main';
import PageContainer from '#components/page/PageContainer';
import PageTitle from '#components/page/PageTitle';

function PasswordReset() {
  const { navigate } = useNavigation();
  const [searchParams] = useSearchParams();

  const username = searchParams.get('username') ?? '';
  const token = searchParams.get('token') ?? '';

  useEffect(() => {
    if (!username || !token) {
      navigate('/');
    }
  }, [username, token, navigate]);

  if (!username || !token) return null;

  return (
    <>
      <Helmet>
        <title>{(`Sign Up`)}</title>
      </Helmet>
      <Content>
        <Main>
          <PageContainer style={{ maxWidth: '72rem' }}>
            <PageTitle
              title="Reset Your Password"
              subtitle={'Enter a new password to reset your account.'}
            />
            <Reset username={username} token={token} />
          </PageContainer>
        </Main>
      </Content>
    </>
  );
}

export default PasswordReset;
