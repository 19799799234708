import React, { useCallback } from 'react'
import styled from '@emotion/styled'

import { Link } from '#mrktbox';

import { Theme } from '#types';

interface ButtonLinkViewStyle {
  theme? : Theme;
  disabled? : boolean;
  colour : keyof Theme['palette']['background']['text'];
}

const LinkView = styled(Link)<ButtonLinkViewStyle>`
  display: inline;
  margin: 0;
  padding: 0;

  color: ${(props) => props.theme.palette.background.text[props.colour]};
  background: transparent;

  border: none;

  white-space: nowrap;
  font-size: inherit;
  letter-spacing: inherit;
  text-decoration: underline;

  cursor: pointer;
  appearance: none;

  transition: all 0.150s ease;

  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.palette.background.text.hover};
  }

  ${(props) => props.disabled && `
    color: ${props.theme.palette.background.text.secondary};
    opacity: 0.75;
    cursor: default;
  `}
`

const ButtonLinkView = styled.button<ButtonLinkViewStyle>`
  display: inline;
  border: none;
  padding: 0;
  margin: 0;

  overflow: hidden;
  text-overflow: ellipsis;

  color: ${(props) => props.theme.palette.background.text[props.colour]};
  background: transparent;

  white-space: nowrap;
  font-size: inherit;
  letter-spacing: inherit;
  text-decoration: underline;

  cursor: pointer;
  appearance: none;

  transition: all 0.150s ease;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.palette.background.text.hover};

  &:disabled {
    color: ${(props) => props.theme.palette.background.text.secondary};
    opacity: 0.75;
  }
`

interface ButtonLinkProps {
  label? : string;
  children : React.ReactNode;
  disabled? : boolean;
  href? : string;
  newTab? : boolean;
  onClick? : () => void;
  colour? : keyof Theme['palette']['background']['text'];
}

const ButtonLink = ({
  label,
  children,
  disabled,
  href,
  newTab,
  colour = 'primary',
  onClick,
} : ButtonLinkProps) => {
  const handleClick = useCallback(
    (event : React.MouseEvent<
      HTMLButtonElement | HTMLAnchorElement,
      MouseEvent
    >) => {
      if (!href) event.preventDefault();
      event.stopPropagation();
      if (onClick) onClick();
    },
    [href, onClick],
  )

  if (href) return (
    <LinkView
      to={href}
      onClick={handleClick}
      disabled={disabled}
      colour={colour}
      {...(newTab && {
        target : '_blank' ,
        rel : 'noopener noreferrer',
      })}
    >
      {children}
    </LinkView>
  );

  return (
    <ButtonLinkView
      type="button"
      aria-label={label}
      onClick={handleClick}
      colour={colour}
      disabled={disabled}
    >
      {children}
    </ButtonLinkView>
  );
}

export default ButtonLink
