import { useCallback, useState } from 'react';

interface useDebounceProps {
  delay : number;
}

function useDebounce({ delay } : useDebounceProps) {

  const [working, setWorking] = useState(false);

  const dispatch = useCallback(async <T>(callback : () => Promise<T>) => {
    await new Promise(resolve => setTimeout(resolve, delay));
    setWorking(false);
    return await callback();
  }, [delay, setWorking]);

  const debounce = useCallback(async <T>(callback : () => Promise<T>) => {
    if (working) return;
    setWorking(true);
    return await dispatch(callback);
  }, [working, setWorking, dispatch]);

  return {
    debounce,
  };
}

export default useDebounce;
