
export interface Currency {
  id? : number;
  amount : number;
  currencyCode : string;
  increment : number;
  calculatedValue : number;
}

export interface Payment {
  id : number;
  currency : Currency;
}

export interface RevenueCentre {
  id? : number;
  name : string;
  serviceIds : number[];
}

export function isCurrency(currency : any): currency is Currency {
  return (
    typeof currency === 'object' &&
    (currency.id === undefined || typeof currency.id === 'number') &&
    typeof currency.amount === 'number' &&
    typeof currency.currencyCode === 'string' &&
    typeof currency.increment === 'number' &&
    typeof currency.calculatedValue === 'number'
  );
}

export function isPayment(payment : any) : payment is Payment {
  return (
    typeof payment === 'object' &&
    typeof payment.id === 'number' &&
    isCurrency(payment.currency)
  );
}

export function isRevenueCentre(
  revenueCentre : any
): revenueCentre is RevenueCentre {
  return (
    (revenueCentre.id === undefined || typeof revenueCentre.id === 'number') &&
    typeof revenueCentre.name === 'string' &&
    (revenueCentre.serviceIds === undefined ||
      (Array.isArray(revenueCentre.serviceIds) &&
      revenueCentre.serviceIds.every((id : any) => typeof id === 'number'))
    )
  );
}

export interface PaymentToken {
  last4 : string;
  brand : string;
  expMonth : number;
  expYear : number;
  postal : string;
  token : string;
}

export interface CreditCard {
  id? : number;
  last4 : string;
  brand : string;
  expMonth : number;
  expYear : number;
  customerId : number;
}

export function isCreditCard(card : any) : card is CreditCard {
  return (
    (card.id === undefined || typeof card.id === 'number') &&
    typeof card.last4 === 'string' &&
    typeof card.brand === 'string' &&
    typeof card.expMonth === 'number' &&
    typeof card.expYear === 'number' &&
    typeof card.customerId === 'number'
  );
}
