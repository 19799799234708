import React from 'react';
import styled from '@emotion/styled'

import { Product } from '#mrktbox/types';

import { Theme } from '#types';

import ProductImage from '#components/products/ProductImage';

interface Style{
  theme? : Theme;
}

const ProductCardImageView = styled.div<Style>`
  position: relative;
  z-index: 1;
  height: 24rem;
  background-color: white;
  border-radius: ${(props) => props.theme.layout.border.radius};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    height: 12rem;
  }
`;

const Overlay = styled.div<Style>`
  display: flex;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  span {
    margin: auto;
    color: white;
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.large};
  }
`;

interface ProductCardImageProps{
  product : Product | null;
  outOfStock? : boolean;
}

function ProductCardImage({ product, outOfStock }: ProductCardImageProps) {

  return (
    <ProductCardImageView>
      <ProductImage product={product} variant="card" />
      { outOfStock && (
        <Overlay><span>Out of Stock</span></Overlay>
      ) }
    </ProductCardImageView>
  );
}

export default ProductCardImage;
