import React from 'react';

import Reset from '#components/auth/PasswordReset';

interface PasswordResetProps {
  username : string;
  token : string;
}

function PasswordReset({ username, token } : PasswordResetProps) {
  return (<Reset username={username} token={token} />);
}

export default PasswordReset
