import React from 'react';
import styled from '@emotion/styled';

import IconButton from '#materials/IconButton';
import IconLink from '#materials/IconLink';
import { ChevronLeftCircle } from '#materials/icons';

const Link = styled(IconLink)`
  margin: 0 0 0 -1.25rem;
  color: ${(props) => props.theme.palette.background.text.primary};

  &:active,
  &:focus {
    color: ${(props) => props.theme.palette.background.text.primary};
  }

  &:hover {
    color: ${(props) => props.theme.palette.background.text.hover};
  }

  transition: color .2s ease;
`;

const Button = styled(IconButton)`
  margin: 0 0 0 -1.25rem;
  color: ${(props) => props.theme.palette.background.text.primary};

  &:active,
  &:focus {
    color: ${(props) => props.theme.palette.background.text.primary};
  }

  &:hover {
    color: ${(props) => props.theme.palette.background.text.hover};
  }

  transition: color .2s ease;
`;

interface BackButtonProps {
  path?: string;
  onClick?: () => void;
}

const BackButton = ({ path = '/', onClick } : BackButtonProps) => {

  function Icon({ children } : { children : React.ReactNode } ) {
    return (
      path ? (
        <Link to={path} onClick={onClick}>
          {children}
        </Link>
      ) : (
        <Button onClick={onClick}>
          {children}
        </Button>
      )
    );
  }

  return (<Icon><ChevronLeftCircle size={25} /></Icon>);
}

export default BackButton;
