import React, { createContext, useCallback } from 'react';

import {
  Image,
  Location,
  LocationIntegration,
  ExternalLocation,
  LocationImageUpload,
} from '#mrktbox/clerk/types';

import useData, {
  DataIndex,
  useLoad,
} from '#mrktbox/clerk/hooks/useData';
import useCache from '#mrktbox/clerk/hooks/useDataCache';
import { useResponse } from '#mrktbox/clerk/hooks/api/useAPI';
import useServicesAPI from '#mrktbox/clerk/hooks/api/useServicesAPI';

export type LocationIndex = DataIndex<Location>;

const MAX_AGE = 1000 * 60 * 60;

const LocationContext = createContext({
  locations: null as DataIndex<Location> | null,
  loaded: false,
  load: () => {},
  refreshLocations : async () => null as LocationIndex | null,
  refreshLocation : async (id : number) => null as Location | null,
  retrieveLocations : async () => null as LocationIndex | null,
  retrieveLocation : async (id : number) => null as Location | null,
  createLocation : async (location : Location) => null as Location | null,
  updateLocation : async (location : Location) => null as Location | null,
  deleteLocation : async (location : Location) => null as Location | null,
  retrieveExternalLocations : async () => null as {
    [integrationId : number] : {
      [externalId : string] : ExternalLocation,
    };
  } | null,
  createLocationIntegration : async (
    location : Location,
    externalLocation : ExternalLocation,
  ) => null as Location | null,
  deleteLocationIntegration : async (
    location : Location,
    LocationIntegration : LocationIntegration,
  ) => null as Location | null,
  createLocationImageUpload :
    async (location : Location) => null as LocationImageUpload | null,
  pullLocationImage : async (
    location : Location,
    image : Image,
  ) => null as Image | null,
  removeImageFromLocation : async (
    location : Location,
    image : Image,
  ) => null as Location | null,
});

interface LocationProviderProps {
  children : React.ReactNode;
}

export function LocationProvider({
  children,
} : LocationProviderProps) {
  const {
    createLocation,
    retrieveLocations,
    retrieveLocation,
    updateLocation,
    deleteLocation,
    retrieveExternalLocations,
    createLocationIntegration,
    deleteLocationIntegration,
    createLocationImageUpload,
    pullLocationImage,
    removeImageFromLocation,
  } = useServicesAPI();

  const {
    data : locations,
    dispatch : dispatchLocations,
    lastUpdated,
  } = useData<Location>({ storageKey : 'locations' });

  const locationsStale = lastUpdated !== undefined &&
    (new Date().getTime() - lastUpdated.getTime()) > MAX_AGE;

  const newLocation = useCache({
    process : createLocation,
    dispatch : dispatchLocations,
  });
  const refreshLocations = useCache({
    process : retrieveLocations,
    dispatch : dispatchLocations,
    refresh : true,
    isLoader : true,
  });
  const refreshLocation = useCache({
    process : retrieveLocation,
    dispatch : dispatchLocations,
    isLoader : true,
  });
  const getLocations = useCache({
    process : retrieveLocations,
    dispatch : dispatchLocations,
    data : locations,
    stale : locationsStale,
    refresh : true,
    isLoader : true,
  });
  const getLocation = useCache({
    process : retrieveLocation,
    dispatch : dispatchLocations,
    data : locations,
    stale : locationsStale,
    isLoader : true,
  });
  const amendLocation = useCache({
    process : updateLocation,
    dispatch : dispatchLocations,
  });
  const removeLocation = useCache({
    process : deleteLocation,
    dispatch : dispatchLocations,
    drop : true,
  });

  const dispatchUploadLocation = useCallback(
    (action : { data : DataIndex<LocationImageUpload> }) => {
      if (!action.data) return;
      Object.values(action.data).forEach(image => {
        if (image?.locationId) refreshLocation(image.locationId);
      }
    )},
    [refreshLocation],
  );
  const dispatchImageLocation = useCallback(
    (action : { data : DataIndex<Image> }) => {
      if (!action.data) return;
      Object.values(action.data).forEach((image) => {
        const location = Object.values(locations || {}).find(
          (c) => Object.keys(c?.images ?? {}).includes(`${image?.id}`),
        );
        if (location?.id) refreshLocation(location.id);
      });
    },
    [locations, refreshLocation],
  );

  const getExternalLocations = useResponse(retrieveExternalLocations);
  const newLocationIntegration = useCache({
    process : createLocationIntegration,
    dispatch : dispatchLocations,
  });
  const removeLocationIntegration = useCache({
    process : deleteLocationIntegration,
    dispatch : dispatchLocations,
  });

  const newLocationImageUpload = useCache({
    process : createLocationImageUpload,
    dispatch : dispatchUploadLocation,
  });
  const pullLocationImageUpload = useCache({
    process : pullLocationImage,
    dispatch : dispatchImageLocation,
  });
  const removeLocationImageUpload = useCache({
    process : removeImageFromLocation,
    dispatch : dispatchLocations,
  });

  const { loaded, load } = useLoad({
    data : locations,
    loader : refreshLocations,
  });

  const context = {
    locations,
    loaded,
    load,
    createLocation: newLocation,
    refreshLocations,
    refreshLocation,
    retrieveLocations : getLocations,
    retrieveLocation : getLocation,
    updateLocation : amendLocation,
    deleteLocation : removeLocation,
    retrieveExternalLocations : getExternalLocations,
    createLocationIntegration : newLocationIntegration,
    deleteLocationIntegration : removeLocationIntegration,
    createLocationImageUpload : newLocationImageUpload,
    pullLocationImage : pullLocationImageUpload,
    removeImageFromLocation : removeLocationImageUpload,
  };

  return (
    <LocationContext.Provider value={context}>
      { children }
    </LocationContext.Provider>
  );
}

export default LocationContext;
