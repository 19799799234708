import { useCallback } from 'react';

import { Adjustment, Condition, Product, Service, Tax } from '#mrktbox/clerk/types';

import {
  createAdjustment as createAdjustmentAPI,
  retrieveAdjustments as retrieveAdjustmentsAPI,
  retrieveAdjustment as retrieveAdjustmentAPI,
  updateAdjustment as updateAdjustmentAPI,
  deleteAdjustment as deleteAdjustmentAPI,
  createCondition as createConditionAPI,
  retrieveConditions as retrieveConditionsAPI,
  retrieveCondition as retrieveConditionAPI,
  updateCondition as updateConditionAPI,
  deleteCondition as deleteConditionAPI,
  addConditionToAdjustment as addConditionToAdjustmentAPI,
  removeConditionFromAdjustment as removeConditionFromAdjustmentAPI,
  addProductToCondition as addProductToConditionAPI,
  removeProductFromCondition as removeProductFromConditionAPI,
  addServiceToCondition as addServiceToConditionAPI,
  removeServiceFromCondition as removeServiceFromConditionAPI,
  addTaxToAdjustment as addTaxToAdjustmentAPI,
  removeTaxFromAdjustment as removeTaxFromAdjustmentAPI,
} from '#mrktbox/clerk/api/mrktbox/adjustments';

import useProcessRequest from '#mrktbox/clerk/hooks/api/useProcessRequest';

function useAdjustmentsAPI() {
  const processRequest = useProcessRequest();

  const createAdjustment = useCallback(async (adjustment : Adjustment) => {
    return await processRequest(
      createAdjustmentAPI,
      { adjustment },
    )
  }, [processRequest]);

  const retrieveAdjustments = useCallback(async () => {
    return await processRequest(
      retrieveAdjustmentsAPI,
      {},
    )
  }, [processRequest]);

  const retrieveAdjustment = useCallback(async (adjustmentId : number) => {
    return await processRequest(
      retrieveAdjustmentAPI,
      { adjustmentId },
    )
  }, [processRequest]);

  const updateAdjustment = useCallback(async (adjustment : Adjustment) => {
    return await processRequest(
      updateAdjustmentAPI,
      { adjustment }
    )
  }, [processRequest]);

  const deleteAdjustment = useCallback(async (adjustment : Adjustment) => {
    return await processRequest(
      deleteAdjustmentAPI,
      { adjustment }
    )
  }, [processRequest]);

  const createCondition = useCallback(async (
    condition : Condition,
    relationships? : {
      adjustments? : Adjustment[] | number[],
      products? : Product[] | number[],
      services? : Service[] | number[],
    }
  ) => {

    const response = await processRequest(
      createConditionAPI,
      { condition,
        adjustments : relationships?.adjustments,
        products : relationships?.products,
        services : relationships?.services,
      },
    )

    return response;
  }, [processRequest]);

  const retrieveConditions = useCallback(async () => {
    return await processRequest(
      retrieveConditionsAPI,
      {},
    )
  }, [processRequest]);

  const retrieveCondition = useCallback(async (conditionId : number) => {
    return await processRequest(
      retrieveConditionAPI,
      { conditionId },
    )
  }, [processRequest]);

  const updateCondition = useCallback(async (
    condition : Condition,
    relationships? : {
      product? : Product | number,
      service? : Service | number,
    }
  ) => {
    return await processRequest(
      updateConditionAPI,
      {
        condition,
        product : relationships?.product,
        service : relationships?.service,
      }
    )
  }, [processRequest]);

  const deleteCondition = useCallback(async (condition : Condition) => {
    return await processRequest(
      deleteConditionAPI,
      { condition }
    )
  }, [processRequest]);

  const addConditionToAdjustment = useCallback(
    async (adjustment : Adjustment, condition : Condition) => {
      return await processRequest(
        addConditionToAdjustmentAPI,
        { adjustment, condition },
      )
    },
    [processRequest],
  );

  const removeConditionFromAdjustment = useCallback(
    async (adjustment : Adjustment, condition : Condition) => {
      return await processRequest(
        removeConditionFromAdjustmentAPI,
        { adjustment, condition },
      )
    },
    [processRequest],
  );

  const addProductToCondition = useCallback(
    async (condition : Condition, product : Product) => {
      return await processRequest(
        addProductToConditionAPI,
        { condition, product },
      )
    },
    [processRequest],
  );

  const removeProductFromCondition = useCallback(
    async (condition : Condition, product : Product) => {
      return await processRequest(
        removeProductFromConditionAPI,
        { condition, product },
      )
    },
    [processRequest],
  );

  const addServiceToCondition = useCallback(
    async (condition : Condition, service : Service) => {
      return await processRequest(
        addServiceToConditionAPI,
        { condition, service },
      )
    },
    [processRequest],
  );

  const removeServiceFromCondition = useCallback(
    async (condition : Condition, service : Service) => {
      return await processRequest(
        removeServiceFromConditionAPI,
        { condition, service },
      )
    },
    [processRequest],
  );

  const addTaxToAdjustment = useCallback(
    async (adjustment : Adjustment, tax : Tax) => {
      return await processRequest(
        addTaxToAdjustmentAPI,
        { adjustment, tax },
      )
  }, [processRequest])

  const removeTaxFromAdjustment = useCallback(
    async (adjustment : Adjustment, tax : Tax) => {
      return await processRequest(
        removeTaxFromAdjustmentAPI,
        { adjustment, tax },
      )
  }, [processRequest])

  return {
    createAdjustment,
    retrieveAdjustments,
    retrieveAdjustment,
    updateAdjustment,
    deleteAdjustment,
    createCondition,
    retrieveConditions,
    retrieveCondition,
    updateCondition,
    deleteCondition,
    addConditionToAdjustment,
    removeConditionFromAdjustment,
    addProductToCondition,
    removeProductFromCondition,
    addServiceToCondition,
    removeServiceFromCondition,
    addTaxToAdjustment,
    removeTaxFromAdjustment,
  };
}

export default useAdjustmentsAPI;
