import React from 'react';
import styled from '@emotion/styled';

import { Currency } from '#mrktbox/types';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

interface SubtotalViewStyle {
  theme? : Theme;
  isBold? : boolean;
}

const SubtotalView = styled.li<SubtotalViewStyle>`
  display: flex;
  width: 100%;
  margin: ${(props) => props.theme.layout.spacing.xsmall} 0 0;
  padding: 0 0 0 calc(100% - 24rem);
  align-items: center;
  justify-content: space-between;

  color: ${(props) => props.theme.palette.background.text.primary};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  font-weight: ${(props) => props.theme.typography.fonts.default.weights[
    props.isBold ? 'bold' : 'regular']
  };

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    width: 100%;
    padding: 0;
  }

  @media (max-height: 800px) {
    display: none;
  }

  span {
    display: block;
  }
`;

interface SubtotalProps {
  label : string;
  subtotal : Currency;
  bold? : boolean;
}

function Subtotal({
  label,
  subtotal,
  bold: isBold = false
} : SubtotalProps) {
  return (
    <SubtotalView isBold={isBold}>
      <span>{ label }</span>
      <span>{ formatCurrency(subtotal) }</span>
    </SubtotalView>
  );
}

export default Subtotal;
