import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Link } from '#mrktbox';

import { Theme } from '#types';

interface ComponentProps {
  component : 'button' | 'a';
  href? : string;
  onClick? : (
    event : React.MouseEvent<HTMLButtonElement>
    | React.MouseEvent<HTMLAnchorElement>
  ) => void;
  fullwidth? : boolean;
  disableHover? : boolean;
  children : React.ReactNode;
  ref? : React.RefObject<HTMLAnchorElement>
  | React.RefObject<HTMLButtonElement>;
  id? : string;
}
interface LinkProps extends ComponentProps{
  component : 'a';
  href : string;
}
interface ButtonProps extends ComponentProps{
  component : 'button';
  href? : undefined;
}

function Component(props : LinkProps) : React.ReactElement;
function Component(props : ButtonProps) : React.ReactElement;
function Component(props : ComponentProps) : React.ReactElement;
function Component({
  component,
  href,
  onClick,
  children,
  ref,
  fullwidth,
  disableHover,
  ...props
} : ComponentProps) {
  switch (component) {
    case 'a':
      const aRef = ref as React.RefObject<HTMLAnchorElement>;
      return (<Link to={href ?? '/'} ref={aRef} onClick={onClick} {...props}>
        { children }
      </Link>);
    default:
      const btnRef = ref as React.RefObject<HTMLButtonElement>;
      return (
        <button onClick={onClick} ref={btnRef} {...props}>{ children }</button>
      );
  }
}

interface Style { theme? : Theme; }

interface ButtonStyle extends Style {
  disabled? : boolean;
  disableHover? : boolean;
  size : keyof Theme['typography']['fonts']['default']['sizes'];
  colour : keyof Theme['palette']['accent'];
  variant : 'contained' | 'text';
  fullwidth : boolean;
}

interface ButtonIconStyle extends Style{
  isSmall : boolean;
}

const ButtonStyledView = styled(Component)<ButtonStyle>`
  display: flex;
  cursor: pointer;
  width: ${(props) => (props.fullwidth ? '100%' : 'fit-content')};
  height: calc(
    ${(props) => ['small', 'xsmall', 'xxsmall'].includes(props.size)
      ? '2' : '2.5'}
      * ${(props) => props.theme.typography.fonts.default.sizes[props.size]}
  );
  max-width: 100%;
  margin: 0;
  padding:
   calc(${(props) => props.theme.layout.spacing.xsmall}
    + (${(props) => props.theme.typography.fonts.default.sizes[props.size]}
      * (${(props) => props.theme.typography.fonts.default.lineHeight} - 1)))
   ${(props) => props.theme.layout.spacing.small};
  align-items: center;
  justify-content: center;
  overflow: hidden;

  color: ${(props) => props.variant === 'contained'
    ? props.theme.palette.accent[props.colour].text
    : props.theme.palette.accent[props.colour].fill};
  background-color: ${(props) => props.variant === 'contained'
    ? props.theme.palette.accent[props.colour].fill
    : 'transparent'};

  opacity: ${(props) => (props.disabled ? '0.75' : '1.0')};

  font-size: ${(props) =>
    props.theme.typography.fonts.default.sizes[props.size]};
  line-height: 1;
  text-align: center;
  text-decoration: ${(props) => props.variant === 'contained'
    ? 'none'
    : 'underline'};

  -webkit-font-smoothing: antialiased;

  border-style: ${(props) => props.variant === 'contained' ? 'solid' : 'none'};
  border-width: ${(props) => props.theme.layout.border.width};
  border-radius: ${(props) =>
    ['small', 'xsmall', 'xxsmall'].includes(props.size)
      ? props.theme.layout.border.radius
      : '100vh'};
  border-color: ${(props) => (
    props.variant === 'contained'
      && props.theme.palette.accent[props.colour].fill === 'transparent'
  )
    ? props.theme.palette.accent[props.colour].text
    : props.theme.palette.accent[props.colour].fill
  };

  cursor: pointer;

  transition: all 0.150s ease;

  &:visited, &:active, &:focus {
    color: ${(props) => props.variant === 'contained'
      ? props.theme.palette.accent[props.colour].text
      : props.theme.palette.background.text.secondary};
    background-color: ${(props) => props.variant === 'contained'
      ? props.theme.palette.accent[props.colour].fill
      : 'transparent'};
  }

  ${(props) => (props.disabled || props.disableHover)
    ? `pointer-events: none;`
    : `&:hover {
      color: ${props.variant === 'contained'
        ? props.theme.palette.background.fill
        : props.theme.palette.background.text.hover
      };
      background-color: ${props.variant === 'contained'
        ? props.theme.palette.background.text.hover
        : 'transparent'};
      border-color: transparent;
    }
  `}

  &:disabled {
    color: ${(props) => props.variant === 'contained'
      ? props.theme.palette.accent[props.colour].text
      : props.theme.palette.accent[props.colour].fill};
    };
    background-color: ${(props) => props.variant === 'contained'
      ? props.theme.palette.accent[props.colour].fill
      : 'transparent'};
  }
`;

const ButtonStyledIcon = styled.span<ButtonIconStyle>`
  display: block;
  flex-shrink: 0;
  line-height: 0;
  width: ${(props) => props.isSmall
    ? props.theme.typography.fonts.default.sizes.small
    : props.theme.typography.fonts.default.sizes.xsmall};
  margin-right: ${(props) => props.isSmall ? '0.6rem' : '0.8rem'};
`;

const ButtonStyledText = styled.span<Style>`
  display: block;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface ButtonStyledProps {
  label? : string;
  href? : string;
  onClick? : () => void;
  disabled? : boolean;
  preventDefault? : boolean;
  icon? : string | React.ReactNode;
  type? : 'button' | 'submit';
  size? : keyof Theme['typography']['fonts']['default']['sizes'];
  colour? : keyof Theme['palette']['accent'];
  variant? : 'contained' | 'text';
  fullwidth? : boolean;
  btnRef? : React.RefObject<HTMLButtonElement>;
  id? : string;
  children : React.ReactNode;
}

const ButtonStyled = ({
  label,
  href,
  onClick,
  disabled,
  preventDefault,
  icon,
  type = 'button',
  size = 'medium',
  colour = 'primary',
  variant = 'contained',
  fullwidth = false,
  btnRef,
  id,
  children,
} : ButtonStyledProps) => {
  const handleClick = useCallback(
    (event : React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation()
      if (preventDefault) event.preventDefault()
      if (!disabled && onClick) onClick()
    },
    [disabled, preventDefault, onClick],
  )

  return (
    <ButtonStyledView
      component={href ? 'a' : 'button'}
      href={!disabled ? href : '#'}
      ref={btnRef}
      id={id}
      aria-label={label}
      onClick={type === 'submit' ? undefined : handleClick}
      disabled={disabled}
      disableHover={disabled || (!href && !onClick && type !== 'submit')}
      size={size}
      colour={colour}
      variant={variant}
      fullwidth={fullwidth}
    >
      { icon && (
        <ButtonStyledIcon isSmall={['xsmall', 'xxsmall'].includes(size)}>
          { icon }
        </ButtonStyledIcon>
      ) }
      <ButtonStyledText>{ children }</ButtonStyledText>
    </ButtonStyledView>
  );
}

export default ButtonStyled;
