import { RevenueCentre, isRevenueCentre, Service } from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request } from '#mrktbox/clerk/api/mrktbox';

const REVENUE_CENTRES_PATH = 'payments/revenue-centres/';
const SERVICES_PATH = 'services/';

function parseRevenueCentre(revenueCentre : any) {
  if (!isRevenueCentre(revenueCentre))
    throw new TypeError('revenue centre is not a RevenueCentre Object')
  return revenueCentre;
}

function parseRevenueCentres(revenueCentres : any) {
  const parsedRevenueCentres : { [id : number] : RevenueCentre } = {};
  for (const revenueCentreId in revenueCentres) {
    if (typeof revenueCentreId !== 'string')
      throw new TypeError('Revenue Centre id is not a string');

    const id = parseInt(revenueCentreId);
    if (isNaN(id)) throw new TypeError('Revenue Centre id is not a number');

    parsedRevenueCentres[id] = parseRevenueCentre(revenueCentres[id]);
  }

  return parsedRevenueCentres;
}

export async function createRevenueCentre(
  { revenueCentre } : { revenueCentre : RevenueCentre }
) {
  const response = await request(
    getUrl(REVENUE_CENTRES_PATH),
    methods.post,
    { revenueCentre },
  );

  try {
    return parseRevenueCentre(response.revenueCentre);
  } catch {
    throw new DeserializationError('Could not deserialize revenue centre',
    response,
    );
  }
}

export async function retrieveRevenueCentres() {
  const response = await request(
    getUrl(`${REVENUE_CENTRES_PATH}`),
    methods.get,
  );

  try {
    return parseRevenueCentres(response.revenueCentres);
  } catch {
    throw new DeserializationError(
      'Could not parse revenue centre list',
      response,
    );
  }
}

export async function retrieveRevenueCentre(
  { revenueCentreId } : { revenueCentreId : number }
) {
  const response = await request(
    getUrl(`${REVENUE_CENTRES_PATH}${revenueCentreId}`),
    methods.get,
  );

  try {
    return parseRevenueCentre(response.revenueCentre);
  } catch {
    throw new DeserializationError(
      'Could not deserialize revenue centre',
      response,
    );
  }
}

export async function updateRevenueCentre(
  { revenueCentre } : { revenueCentre : RevenueCentre }
) {
  const response = await request(
    getUrl(`${REVENUE_CENTRES_PATH}${revenueCentre.id}`),
    methods.put,
    { revenueCentre },
  );

  try {
    return parseRevenueCentre(response.revenueCentre);
  } catch {
    throw new DeserializationError(
      'Could not deserialize revenue centre',
      response,
      );
  }
}

export async function deleteRevenueCentre(
  { revenueCentre } : { revenueCentre : RevenueCentre }
) {
  const response = await request(
    getUrl(`${REVENUE_CENTRES_PATH}${revenueCentre.id}`),
    methods.delete,
  );

  return !!response?.success;
}

export async function addServiceToRevenueCentre({
  revenueCentre,
  service,
} : { revenueCentre : RevenueCentre, service : Service }) {
  const response = await request(
    getUrl(`${REVENUE_CENTRES_PATH}${revenueCentre.id}/` +
      `${SERVICES_PATH}${service.id}`),
    methods.post,
  );

  return !!response;
}

export async function removeServiceFromRevenueCentre({
  revenueCentre,
  service,
} : { revenueCentre : RevenueCentre, service : Service }) {
  const response = await request(
    getUrl(`${REVENUE_CENTRES_PATH}${revenueCentre.id}/` +
      `${SERVICES_PATH}${service.id}`),
    methods.delete,
  );

  return !!response;
}
