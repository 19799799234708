import { useCallback } from 'react';

import { Address, Area } from '#mrktbox/clerk/types';

import {
  createAddress as createAddressAPI,
  retrieveAddressesBulk as retrieveAddressesBulkAPI,
  retrieveAddress as retrieveAddressAPI,
  searchAddresses as searchAddressesAPI,
  deleteAddress as deleteAddressAPI,
  createArea as createAreaAPI,
  retrieveAreas as retrieveAreasAPI,
  retrieveArea as retrieveAreaAPI,
  updateArea as updateAreaAPI,
  deleteArea as deleteAreaAPI,
} from '#mrktbox/clerk/api/mrktbox/addresses';
import useProcessRequest from '#mrktbox/clerk/hooks/api/useProcessRequest';

function useAddressesAPI() {
  const processRequest = useProcessRequest();

  const createAddress = useCallback(async (address : Address) => {
    return await processRequest(
      createAddressAPI,
      { address },
    )
  }, [processRequest]);

  const retrieveAddressesBulk = useCallback(async (
    addressIds : number[],
  ) => {
    return await processRequest(
      retrieveAddressesBulkAPI,
      { addressIds },
    )
  }, [processRequest]);

  const retrieveAddress = useCallback(async (addressId : number) => {
    return await processRequest(
      retrieveAddressAPI,
      { addressId },
    );
  }, [processRequest]);

  const searchAddresses = useCallback(async (query : string) => {
    return await processRequest(
      searchAddressesAPI,
      { query },
    )
  }, [processRequest]);

  const deleteAddress = useCallback(async (address : Address) => {
    return await processRequest(
      deleteAddressAPI,
      { address },
    )
  }, [processRequest]);

  const createArea = useCallback(async (area : Area) => {
    return await processRequest(
      createAreaAPI,
      { area },
    )
  }, [processRequest]);

  const retrieveAreas = useCallback(async () => {
    return await processRequest(
      retrieveAreasAPI,
      {},
    )
  }, [processRequest]);

  const retrieveArea = useCallback(async (areaId : number) => {
    return await processRequest(
      retrieveAreaAPI,
      { areaId },
    )
  }, [processRequest]);

  const updateArea = useCallback(async (area : Area) => {
    return await processRequest(
      updateAreaAPI,
      { area },
    )
  }, [processRequest]);

  const deleteArea = useCallback(async (area : Area) => {
    return await processRequest(
      deleteAreaAPI,
      { area },
    )
  }, [processRequest]);

  return {
    createAddress,
    retrieveAddressesBulk,
    retrieveAddress,
    searchAddresses,
    deleteAddress,
    createArea,
    retrieveAreas,
    retrieveArea,
    updateArea,
    deleteArea,
  };
}

export default useAddressesAPI;
