import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Loader } from '@googlemaps/js-api-loader';

import useConfig from '#hooks/useConfig';

type Maps = google.maps.MapsLibrary;

const makeMapStyles = ({
  labelColour,
  roadColour,
  featureColour,
  waterColour,
  backgroundColour,
} : {
  labelColour : string;
  roadColour : string;
  featureColour : string;
  waterColour : string;
  backgroundColour : string;
}) => [
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ visibility: 'simplified' }, { color: roadColour }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: labelColour }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: featureColour }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: waterColour }],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: backgroundColour }],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [{ color: backgroundColour }],
  },
  {
    featureType: 'landscape',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
]

const GoogleMapsContext = createContext({
  makeMap:
    (ref : React.MutableRefObject<null>) => null as google.maps.Map | null,
});

interface MapProviderProps {
  children : React.ReactNode;
}

export function GoogleMapsProvider({ children } : MapProviderProps) {
  const { brand, settings, theme } = useConfig();

  const [googleMaps, setGoogleMaps] = useState<Maps | null>(null);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState<unknown | null>(null);

  const load = useCallback(async () => {
    if (!settings.maps.api.key || googleMaps) return;

    const loader = new Loader({
      libraries: ['places'],
      apiKey : settings.maps.api.key,
    });

    try {
      const googleMaps = await loader.importLibrary('maps');
      setGoogleMaps(googleMaps);
      setLoading(false);
      setError(null);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  }, [settings, googleMaps]);

  const makeMap = useCallback((ref : React.MutableRefObject<null>) => {
    if (loading || !ref.current || !googleMaps) return null;

    const newMap = new googleMaps.Map(ref.current, {
      zoom: 12,
      center: {
        lat: settings.maps.defaults.centre.lat,
        lng: settings.maps.defaults.centre.lng,
      },
      styles: makeMapStyles({
        backgroundColour: brand.palette.maps?.fill ??
          theme.palette.background.fill,
        featureColour: brand.palette.maps?.accent ??
          theme.palette.accent.primary.fill,
        labelColour: brand.palette.maps?.text ??
          theme.palette.background.text.primary,
        roadColour: brand.palette.maps?.road ??
          theme.palette.accent.secondary.fill,
        waterColour: brand.palette.maps?.water ??
          theme.palette.accent.tertiary.fill,
      }),
      scrollwheel: false,
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      controlSize: 28,
    });

    return newMap;
  }, [googleMaps, loading, brand, settings, theme]);

  useEffect(() => { load(); }, [load]);

  const context = {
    makeMap
  };

  return (
    <GoogleMapsContext.Provider value={context}>
      { children }
    </GoogleMapsContext.Provider>
  );
}

export default GoogleMapsContext;
