import React from 'react';
import styled from '@emotion/styled';

import { Currency } from '#mrktbox/types';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

interface CheckSummaryItemViewStyle {
  isBold? : boolean;
  theme? : Theme;
}

const CheckSummaryItemView = styled.li<CheckSummaryItemViewStyle>`
  display: flex;
  width: 100%;
  margin: ${(props) => props.theme.layout.spacing.xsmall} 0 0;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  color: ${(props) => props.theme.palette.background.text.primary};

  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  font-weight: ${(props) => props.isBold
    ? props.theme.typography.fonts.default.weights.bold
    : props.theme.typography.fonts.default.weights.regular};

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }
`;

interface CheckSummaryItemProps {
  label : string;
  value : Currency;
  isBold? : boolean;
}

function CheckSummaryItem({
  label,
  value,
  isBold = false
} : CheckSummaryItemProps) {
  return (
    <CheckSummaryItemView isBold={isBold}>
      <span>{ label }</span>
      <span>{ formatCurrency(value) }</span>
    </CheckSummaryItemView>
  )
}

export default CheckSummaryItem;
