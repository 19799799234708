import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import Preface from '#materials/Preface';

interface Style { theme? : Theme; }
interface TagStyle extends Style {
  tagColour : keyof Theme['palette']['notifications'];
}
interface TagViewStyle extends TagStyle {
  round? : boolean;
}

const TagView = styled('span')<TagViewStyle>`
  display: inline-block;
  padding: ${(props) => props.round
    ? props.theme.layout.spacing.xxsmall
    : `${props.theme.layout.spacing.xxsmall}
      ${props.theme.layout.spacing.xsmall}`};

  color: ${(props) => props.theme.palette.notifications[props.tagColour].text};
  background-color:
    ${(props) => props.theme.palette.notifications[props.tagColour].fill};

  border-radius: ${(props) => props.round ? '50%' : '1.5rem'};
  line-height: 0;
`;

const TagContainer = styled('span')<Style>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.layout.spacing.xxsmall};
  line-height: 0;

  span {
    display: inline-block;
  }
`;

const TagIcon = styled.span<TagStyle>`
  position: relative;
  width: 1.2rem;
  height: 1.2rem;
  padding: 0;
  flex-shrink: 0;
  color: ${(props) => props.theme.palette.notifications[props.tagColour].text};
`;

const TagText = styled(Preface)<TagStyle>`
  margin: 0;
  color: ${(props) => props.theme.palette.notifications[props.tagColour].text};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
  line-height: 1;
`;

interface TagProps {
  text? : string;
  icon? : React.ReactNode;
  colour? : keyof Theme['palette']['notifications'];
  useBgColours? : boolean;
}

const Tag = ({
  text,
  icon,
  colour = 'default',
} : TagProps) => {
  return (
    <TagView
      round={!text}
      tagColour={colour}
    >
      <TagContainer>
        {icon && <TagIcon tagColour={colour}>{ icon }</TagIcon>}
        { !!text && (<TagText tagColour={colour}>{ text }</TagText>) }
      </TagContainer>
    </TagView>
  );
}

export default Tag;
