import styled from '@emotion/styled';

import { Theme } from '#types';

interface MainViewStyle {
  theme? : Theme;
  fullHeight? : boolean;
  padBottom? : boolean;
}

const MainView = styled.main<MainViewStyle>`
  display: flex;
  width: 100%;
  ${(props) => props.fullHeight ? `height: 100%;` : undefined}
  min-height: 100%;
  padding-top: ${(props) => props.theme.view.nav.height.default};
  padding-bottom: ${(props) => props.padBottom
    ? props.theme.layout.spacing.large
    : 0};
  flex-direction: column;

  background-color: ${(props) => props.theme.palette.background.fill};

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    padding-top: ${(props) => props.theme.view.nav.height.mobile};
  }
`;

interface MainProps {
  bgColour? : 'primary';
  imageUrl? : string;
  fullHeight? : boolean;
  padBottom? : boolean;
  children : React.ReactNode;
}

function Main({
  fullHeight = false,
  padBottom = true,
  children
} : MainProps) {
  return (
    <MainView
      role="main"
      id="main"
      fullHeight={fullHeight}
      padBottom={padBottom}
    >
      { children }
    </MainView>
  );
}

export default Main;
