import { useCallback } from 'react';

import { Tag, Product, ServiceChannel } from '#mrktbox/clerk/types';

import {
  createTag as createTagAPI,
  retrieveTags as retrieveTagsAPI,
  retrieveTag as retrieveTagAPI,
  updateTag as updateTagAPI,
  deleteTag as deleteTagAPI,
  addProductToTag as addProductToTagAPI,
  removeProductFromTag as removeProductFromTagAPI,
  addServiceChannelToTag as addServiceChannelToTagAPI,
  removeServiceChannelFromTag as removeServiceChannelFromTagAPI,
} from '#mrktbox/clerk/api/mrktbox/tags';

import useAPI from '#mrktbox/clerk/hooks/api/useAPI';

function useTagsAPI() {
  const { processRequest } = useAPI();

  const createTag = useCallback(async (tag : Tag) => {
    return await processRequest(
      createTagAPI,
      { tag },
    )
  }, [processRequest]);

  const retrieveTags = useCallback(async () => {
    return await processRequest(
      retrieveTagsAPI,
      {},
    )
  }, [processRequest]);

  const retrieveTag = useCallback(async (tagId : number) => {
    return await processRequest(
      retrieveTagAPI,
      { tagId },
    )
  }, [processRequest]);

  const updateTag = useCallback(async (tag : Tag) => {
    return await processRequest(
      updateTagAPI,
      { tag },
    )
  }, [processRequest]);

  const deleteTag = useCallback(async (tag : Tag) => {
    return await processRequest(
      deleteTagAPI,
      { tag },
    )
  }, [processRequest]);

  const addProductToTag = useCallback(
    async (tag : Tag, product : Product, options? : { before? : Product }) => {
      return await processRequest(
        addProductToTagAPI,
        { product, tag, before : options?.before },
      )
    },
    [processRequest],
  );

  const removeProductFromTag = useCallback(
    async (tag : Tag, product : Product) => {
      return await processRequest(
        removeProductFromTagAPI,
        { product, tag },
      )
    },
    [processRequest],
  );

  const addServiceChannelToTag = useCallback(
    async (tag : Tag, serviceChannel : ServiceChannel) => {
      return await processRequest(
        addServiceChannelToTagAPI,
        { tag, serviceChannel },
      )
    },
    [processRequest],
  );

  const removeServiceChannelFromTag = useCallback(
    async (tag : Tag, serviceChannel : ServiceChannel) => {
      return await processRequest(
        removeServiceChannelFromTagAPI,
        { tag, serviceChannel },
      )
    },
    [processRequest],
  );

  return {
    createTag,
    retrieveTags,
    retrieveTag,
    updateTag,
    deleteTag,
    addProductToTag,
    removeProductFromTag,
    addServiceChannelToTag,
    removeServiceChannelFromTag,
  };
}

export default useTagsAPI;
