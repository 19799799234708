import React from 'react';

import IconTextButton from '#materials/IconTextButton';
import { Clock } from '#materials/icons';

import { formatDateTime, formats } from '#mrktbox/utils';

interface TimeButtonProps {
  datetime? : Date | null;
  fallback? : string;
  label? : string;
  href? : string;
  onClick? : () => void;
  disabled? : boolean;
  fullButton? : boolean;
}

function TimeButton({
  datetime,
  fallback = 'N/A',
  label,
  href,
  disabled,
  onClick,
  fullButton = true,
} : TimeButtonProps) {
  return (
    <IconTextButton
      text={datetime ? formatDateTime(datetime, formats.easy) : fallback}
      label={label ?? 'Select a time'}
      href={href}
      onClick={onClick}
      disabled={disabled}
      icon={<Clock />}
      useButton={fullButton}
      size="xsmall"
    />
  );
}

export default TimeButton;
