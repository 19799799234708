import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled'

import { Category } from '#mrktbox/types';

import { Theme } from '#types';

import useFocus from '#hooks/useFocus';
import useCatalogue from '#hooks/useCatalogue';

import Transition, { TransitionGroup } from '#materials/Transition';
import { ChevronLeftCircle } from '#materials/icons';

import CategoriesList, {
  ListItem
} from '#components/categories/CategoriesList';

interface Style {
  theme? : Theme;
}

interface MenuCategoriesDropdownStyle extends Style {
  show : boolean;
}

const CategoriesDropdownView = styled.div<MenuCategoriesDropdownStyle>`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  max-height: calc(100vh - ${(props) => props.theme.view.nav.height.mobile});
  position: fixed;
  top: ${(props) => props.theme.view.nav.height.mobile};
  left: 0;
  right: 0;
  z-index: 40;
  padding: ${(props) => props.theme.layout.spacing.xsmall} 0;
  padding-top:
    calc(${(props) => props.theme.view.nav.height.default}
      - ${(props) => props.theme.view.nav.height.mobile}
      + ${(props) => props.theme.layout.spacing.xsmall});
    ${(props) => props.theme.layout.spacing.xsmall};
    0;
  opacity: ${(props) => (props.show ? 1 : 0)};
  overflow-y: auto;

  background-color: ${(props) => props.theme.palette.background.fill};

  transform: translateY(${(props) => (props.show ? '0' : '-100%')});
  transition: all 0.125s ease;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    padding: ${(props) => props.theme.layout.spacing.xsmall};
    top: ${(props) => props.theme.view.nav.height.mobile};
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CategoriesDropdownOverlay = styled.div<Style>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
  background-color: ${(props) => props.theme.palette.overlay};
`;

interface CategoriesDropdownProps {
  open : boolean;
  setOpen : (open : boolean) => void;
}

function CategoriesDropdown({
  open,
  setOpen,
} : CategoriesDropdownProps) {

  const {
    currentCategory,
    getRootCategories,
    getSubcategories,
    getCategorySlug,
    getCategoryImage,
    scrollToCategory,
  } = useCatalogue();

  const dropdown = useRef<HTMLDivElement>(null);
  const [category, setCategory] = useState(currentCategory());
  const [categories, setCategories] = useState<ListItem[]>([]);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleBackCategory = useCallback(() => { setCategory(null) }, []);

  const { contain, takeFocus, returnFocus, setLastActive } = useFocus({
    container : dropdown,
    onEscape : handleClose,
  });

  const handleCategoryClick = useCallback((cat : Category) => {
    if (!category && cat.subcategoryIds.length) {
      setCategory(cat);
      const scrollTarget = scrollToCategory(cat);
      if (scrollTarget) setLastActive(scrollTarget);
      return;
    }
    handleClose();
    setLastActive(scrollToCategory(cat) ?? null);
  }, [category, handleClose, setLastActive, scrollToCategory]);

  useEffect(() => {
    if (category) {
      const subcategories = [{
        name : 'All Categories',
        onClick : handleBackCategory,
        icon : <ChevronLeftCircle />,
      }] as ListItem[];

      if (category.productIds.length) {
        subcategories.push({
          name : category.name,
          href : `#${getCategorySlug(category)}`,
          image : getCategoryImage(category, 'card'),
          onClick : () => handleCategoryClick(category),
        });
      }
      subcategories.push(
        ...Object.values(getSubcategories(category)).map((subCategory) => ({
          name : subCategory.name,
          href : `#${getCategorySlug(subCategory)}`,
          image : getCategoryImage(subCategory, 'card'),
          onClick : () => handleCategoryClick(subCategory),
        }))
      );
      setCategories(subcategories);
      return;
    }

    setCategories(getRootCategories());
  }, [
    category,
    getRootCategories,
    getSubcategories,
    getCategorySlug,
    getCategoryImage,
    handleCategoryClick,
    handleBackCategory,
  ]);

  useEffect(() => { setCategory(currentCategory()); }, [open, currentCategory]);
  useEffect(() => { contain(); }, [categories, contain]);

  return (
    <>
      <CategoriesDropdownView ref={dropdown} show={open}>
        <CategoriesList categories={categories} onClick={handleCategoryClick}/>
      </CategoriesDropdownView>
      <TransitionGroup component={null}>
        { open && (
          <Transition
            key="mobile-menu-overlay"
            classNames="overlay"
            timeout={250}
            onEntered={takeFocus}
            onExited={returnFocus}
          >
            <CategoriesDropdownOverlay
              onClick={handleClose}
            />
          </Transition>
        ) }
      </TransitionGroup>
    </>
  );
}

export default CategoriesDropdown;
