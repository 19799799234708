import { Image, ImageUpload, isImage, isImageUpload } from './images';
import { Tag, isTag } from './tags';

export interface CategoryImageUpload extends ImageUpload {
  categoryId : number;
}

export interface Category extends Tag {
  subcategoryIds : number[];
  description? : string;
  images? : { [key : number] : Image };
}

export function isCategory(category : any) : category is Category {
  return (
    typeof category === 'object' &&
    typeof category.description === 'string' &&
    Array.isArray(category.subcategoryIds) &&
    category.subcategoryIds.every((id : any) => typeof id === 'number') &&
    (category.images === undefined ||
      (typeof category.images === 'object' &&
        Object.keys(category.images).every((key : any) => (
          typeof key === 'string' &&
          !isNaN(parseInt(key)) &&
          isImage(category.images[key])
        ))
      )
    ) &&
    isTag(category)
  )
}

export function isCategoryImageUpload(
  upload : any
) : upload is CategoryImageUpload {
  return (
    typeof upload === 'object' &&
    typeof upload.categoryId === 'number' &&
    isImageUpload(upload)
  )
}
