import { useCallback, useContext } from 'react';

import {
  Address,
  Customer,
  Product,
  LineItem,
  Order,
  ProductNote,
} from '#mrktbox/clerk/types';

import NoteContext from '#mrktbox/clerk/context/NoteContext';

import { listRecords } from '#mrktbox/clerk/utils/data';

function generateDefaultNote(seed? : { customerId? : number }) {
  return {
    customerId : seed?.customerId ?? null,
    addressId : null,
    lineItemId : null,
    orderId : null,
    content : '',
  };
}

function generateDefaultProductNote(
  seed : { productId : number },
) : ProductNote {
  return {
    productId : seed?.productId,
    enabled : false,
    required : false,
  };
}

function useNotes() {
  const {
    notes,
    productNotes,
    notesLoaded,
    productNotesLoaded,
    loadNotes,
    loadProductNotes,
    ...context
  } = useContext(NoteContext);

  const getCustomerNotes = useCallback((customer : Customer | number) => {
    const customerId = typeof customer === 'number' ? customer : customer.id;
    return listRecords(notes).filter((note) => {
      return note.customerId === customerId
        && !note.addressId
        && !note.lineItemId
        && !note.orderId;
    });
  }, [notes]);

  const getAddressNotes = useCallback((address : Address | number) => {
    const addressId = typeof address === 'number' ? address : address.id;
    return listRecords(notes).filter((note) => {
      return note.addressId === addressId
        && !note.orderId;
    });
  }, [notes]);

  const getLineItemNotes = useCallback((lineItem : LineItem | number) => {
    const lineItemId = typeof lineItem === 'number' ? lineItem : lineItem.id;
    return listRecords(notes).filter((note) => {
      return note.lineItemId === lineItemId;
    });
  }, [notes]);

  const getOrderNotes = useCallback((order : Order | number) => {
    const orderId = typeof order === 'number' ? order : order.id;
    return listRecords(notes).filter((note) => {
      return note.orderId === orderId;
    });
  }, [notes]);

  const getProductNoteSettings = useCallback((product : Product) => {
    return listRecords(productNotes).find((note) => {
      return note.productId === product.id;
    });
  }, [productNotes]);

  const canProductHaveNote = useCallback((product : Product) => {
    return !!listRecords(productNotes).filter((note) => {
      return note.productId === product.id
        && note.enabled === true;
    }).length;
  }, [productNotes]);

  const doesProductRequireNote = useCallback((product : Product) => {
    return !!listRecords(productNotes).filter((note) => {
      return note.productId === product.id
        && note.required === true;
    }).length;
  }, [productNotes]);

  const load = useCallback(() => {
    loadNotes();
    loadProductNotes();
  }, [loadNotes, loadProductNotes]);

  return {
    notes,
    productNotes,
    loaded : notesLoaded && productNotesLoaded,
    notesLoaded,
    productNotesLoaded,
    getCustomerNotes,
    getAddressNotes,
    getLineItemNotes,
    getOrderNotes,
    getProductNoteSettings,
    canProductHaveNote,
    doesProductRequireNote,
    load,
    loadNotes,
    loadProductNotes,
    generateDefaultNote,
    generateDefaultProductNote,
    ...context,
  };
}

export default useNotes;
