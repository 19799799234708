import React, { useCallback, useEffect, useState } from 'react';

import { useAuth } from '#mrktbox';

import Text from '#materials/Text';
import Form, { FormWrapper } from '#materials/Form';
import FormError from '#materials/FormError';
import Input from '#materials/Input';
import ButtonStyled from '#materials/ButtonStyled';

interface PasswordResetProps {
  username : string;
  token : string;
}

function PasswordReset({ username, token } : PasswordResetProps) {
  const { resetPassword } = useAuth();

  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [passwordValid, setPasswordValid] = useState(true);
  const [confirmValid, setConfirmValid] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handlePasswordBlur = useCallback(() => {
    setPasswordValid(!password || password.length >= 6);
  }, [password]);
  const handleConfirmBlur = useCallback(() => {
    setConfirmValid(!confirm || password === confirm);
  }, [password, confirm]);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    const success = await resetPassword(username, password, token);
    setSubmitting(false);

    if (success) {
      setSuccess(true);
    } else {
      setError(true);
    }
  }, [username, password, token, resetPassword]);

  useEffect(() => {
    if (password && confirm && password.length >= 6 && password === confirm) {
      setConfirmValid(true);
    }
  }, [password, confirm]);

  return (
    <FormWrapper>
      { (success && !error) && (
        <Text>
          Your password has been reset.
        </Text>
      ) }
      { error && (
        <Text>
          Sorry, something went wrong. Please try again.
        </Text>
      ) }
      { (!success && !error) && (
          <>
            <Text>
              Enter your new password.
            </Text>
            <Form
              onSubmit={handleSubmit}
              buttons={(
                <ButtonStyled
                  type="submit"
                  disabled={
                    submitting
                    || !password
                    || !confirm
                    || !passwordValid
                    || !confirmValid
                  }
                >
                  Reset Password
                </ButtonStyled>
              )}
            >
              <FormError errMsg={!passwordValid
                ? 'Password must be at least 6 characters'
                : undefined} />
              <Input
                label="Password"
                name='password'
                type="password"
                value={password}
                onChange={setPassword}
                onBlur={handlePasswordBlur}
                required={true}
              />
              <FormError errMsg={!confirmValid
                ? 'Passwords do not match'
                : undefined}
              />
              <Input
                name='confirm'
                label="Confirm Password"
                type="password"
                value={confirm}
                onChange={setConfirm}
                onBlur={handleConfirmBlur}
                required={true}
              />
            </Form>
          </>
        )
      }
    </FormWrapper>
  );
}

export default PasswordReset;
