import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Link } from '#mrktbox';
import { Category } from '#mrktbox/types';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';
import useCatalogue from '#hooks/useCatalogue';

import { remToPx } from '#utils/style';

interface Style {
  theme? : Theme;
}

interface CategoryImageStyle extends Style {
  imageUrl : string;
}

const CategoryItemView = styled.div<Style>`
  width: calc(25%
    - calc(${(props) => props.theme.layout.spacing.xsmall} * (3 / 4)));
  padding: 0;

  @media (max-width: ${(props) => props.theme.view.breakpoints.narrow}) {
    width: calc(50%
      - calc(${(props) => props.theme.layout.spacing.xsmall} / 2));
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    width: 100%;
  }
`;

const CategoryImage = styled.div<CategoryImageStyle>`
  width: 7rem;
  height: 7rem;
  flex-shrink: 0;

  background-size: cover;
  background-color: ${(props) => props.theme.palette.background.fill};
  background-image: url(${(props) => props.imageUrl});

  transition: all 0.150s ease;
`;

const CategoryItemButton = styled(Link)<Style>`
  display: flex;
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  color: ${(props) => props.theme.palette.background.text.primary};
  background-color: ${(props) => props.theme.palette.background.fill};
  border-radius: 6px;
  border: .1rem solid ${(props) => props.theme.palette.border};
  text-decoration: none;

  :hover {
    color: ${(props) => props.theme.palette.background.text.hover};
    border-color: ${(props) => props.theme.palette.background.text.hover};
    transform: scale(1.02);
  }
`;

const CategoryItemText = styled.span<Style>`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
`;

const IconImage = styled.div<Style>`
  height: calc((${(props) => props.theme.layout.spacing.medium} * 2)
    + ${(props) => props.theme.typography.fonts.default.sizes.medium});
  padding: ${(props) => props.theme.layout.spacing.small};
  flex-shrink: 0;
  line-height: 0;

  background-size: cover;
  background-color: ${(props) => props.theme.palette.background.fill};

  transition: all 0.150s ease;
`

interface ItemProps {
  key? : string,
  category? : Category,
  rootCategory? : Category,
  name? : string,
  image? : string,
  icon? : React.ReactElement<{ size : number }>,
  href? : string,
  onClick? : () => void,
}
interface CategoryItemProps extends ItemProps {
  category : Category,
  name? : undefined,
  icon? : undefined,
}
interface CategoryImageProps extends ItemProps {
  category? : undefined,
  name? : string,
  image? : string,
}
interface CategoryIconProps extends ItemProps {
  category? : undefined,
  name : string,
  icon : React.ReactElement<{ size : number }>,
}

function CategoryItem(props : CategoryItemProps) : React.ReactElement;
function CategoryItem(props : CategoryImageProps) : React.ReactElement;
function CategoryItem(props : CategoryIconProps) : React.ReactElement;
function CategoryItem({
  category,
  rootCategory,
  name,
  icon,
  image,
  href,
  onClick,
} : ItemProps) {
  const { content } = useConfig();
  const { getCategorySlug, getCategoryImage } = useCatalogue();
  const [categoryImage, setCategoryImage] = useState<string>(
    image ? image : (category ? getCategoryImage(category) : '')
  );

  const handleClick = useCallback(
    (event : React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) onClick();
    },
    [onClick],
  );

  useEffect(() => {
    if (category) setCategoryImage(getCategoryImage(category))
  }, [category, getCategoryImage]);

  const img = categoryImage || content.images.defaults.category;

  return (
    <CategoryItemView>
      <CategoryItemButton
        to={href ?? (category
          ? `/category/${getCategorySlug(rootCategory ?? category)}`
          : '#')}
        onClick={handleClick}
      >
        { icon
          ? (<IconImage>
            { React.cloneElement(icon, { size : remToPx('3rem') }) }
          </IconImage>)
          : (<CategoryImage imageUrl={img} />)
        }
        <CategoryItemText>
          { name ?? category?.name }
        </CategoryItemText>
      </CategoryItemButton>
    </CategoryItemView>
  );
}

export default CategoryItem;
