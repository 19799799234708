import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import { LineItem, ProjectedOrder } from '#mrktbox/types';
import { useOptions, useSubscriptions, useNotes } from '#mrktbox';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';

import Heading from '#materials/Heading';
import { AlertCircle, Refresh } from '#materials/icons';

import Body from '#components/page/Body';
import ProductImage from '#components/products/ProductImage';
import CartItemCount from '#components/cart/CartItemCount';

interface Style { theme? : Theme; }
interface ItemModsStyle extends Style { valid? : boolean; }

const CartSummaryItemView = styled.div<Style>`
  display: flex;
  width: 100%;
  margin: 0 0 ${(props) => props.theme.layout.spacing.small};
  align-items: center;
  justify-content: space-between;
`;

const CartSummaryItemImage = styled.div<Style>`
  position: relative;
  width: 6rem;
  min-width: 6rem;
  height: 6rem;
  margin: 0 ${(props) => props.theme.layout.spacing.small} 0 0;
  background-color: ${(props) => props.theme.palette.background.fill};
  border-radius: ${(props) => props.theme.layout.border.radius};
`;

const CartSummaryItemContainer = styled.div`
  flex-grow: 1;
`;

const CartSummaryItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
`;

const CartSummaryItemContent = styled.div`
  width: 0;
  flex: 1 1 0;
  overflow: hidden;
`;

const CartSummaryItemName = styled(Heading)`
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
`;

const CartSummaryItemMods = styled(Body)<ItemModsStyle>`
  display: flex;
  margin: ${(props) => props.theme.layout.spacing.xxsmall} 0 0;
  gap: ${(props) => props.theme.layout.spacing.xxsmall};

  font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};

  color: ${(props) => props.valid
    ? props.theme.palette.background.text.primary
    : props.theme.palette.background.text.alert};
`;

const CartSummarySubscription = styled.div<Style>`
  display: flex;
  align-items: center;
  margin: ${(props) => props.theme.layout.spacing.xxsmall} 0 0;
  gap: ${(props) => props.theme.layout.spacing.xxsmall};

  color: ${(props) => props.theme.palette.background.text.secondary};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
`;

const CartSummaryItemPrice = styled.div<Style>`
  flex: 0 0 auto;
  text-align: right;

  white-space: nowrap;

  span {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
    font-weight:
      ${(props) => props.theme.typography.fonts.default.weights.bold};
    color: ${(props) => props.theme.palette.background.text.primary};
  }

  s {
    opacity: 0.75;
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

interface CartSummaryItemProps {
  lineItem : LineItem;
  order : ProjectedOrder | null;
  children? : any;
}

function CartSummaryItem({
  lineItem,
  order,
  children,
} : CartSummaryItemProps) {
  const {
    calculateLinePrice,
    isProductCustomisable,
    validateSelections,
    resolveSelections,
  } = useOptions();
  const {
    isServiceChannelSubscribable,
    findLineItemSubscription,
  } = useSubscriptions();
  const {
    evaluateReccurence,
    formatRecurrence,
    canUpdateItem,
  } = useRequests();
  const { getLineItemNotes } = useNotes();
  const {
    allProducts : products,
    getProductImage,
    isProductAvailable,
    isProductStocked,
  } = useCatalogue();

  const selectionsValid = useMemo(() => {
    if (!order || !products) return true;

    const time = order.time ?? new Date();
    const product = products[lineItem.productId];
    if (!product || !isProductCustomisable(product, time)) return true;

    const selections = Object.values(order.selections)
      .filter((s) => s.lineItemId === lineItem.id);
    return validateSelections(product, selections, time).valid;
  }, [order, products, lineItem, isProductCustomisable, validateSelections]);

  const fulfilment = order?.order?.fulfilments
  ? (Object.values(order.order.fulfilments).find((f) => (
    f.lineItemId === lineItem.id
    && f.requestedProductId === lineItem.productId
    )) ?? null) : null;
  const productId = fulfilment
    ? (fulfilment.fulfilledProductId ?? fulfilment.requestedProductId)
    : lineItem.productId;
  const product = products ? (products[productId] ?? null) : null

  const selectedProducts = order
    ? resolveSelections(lineItem, order)?.map((r) => ({
      productId : r.fulfilment?.fulfilledProductId
        ?? r.fulfilment?.requestedProductId
        ?? r.selection?.productId,
      quantity : r.fulfilment?.fulfilledQty
        ?? r.fulfilment?.requestedQty
        ?? r.selection?.quantity,
    })) ?? [] : [];
  const selectionsDescription = selectedProducts.map(({
    productId,
    quantity,
  }) => {
    if (!productId || !quantity || !products) return null;
    return (products[productId]?.name ?? null) + ((quantity > 1)
      ? ` (${quantity})` : '');
  }).filter((s) => s !== null).join(', ');

  const full_note = getLineItemNotes(lineItem).map((n) => n.content).join(' ');
  const note = full_note.length > 100
    ? `${full_note.slice(0, 100)}...`
    : full_note;

  const subscription = order ? findLineItemSubscription(lineItem, order) : null;
  const quantity = fulfilment?.fulfilledQty
    ?? fulfilment?.requestedQty
    ?? subscription?.quantity
    ?? lineItem.quantity;

  const listPrice = calculateLinePrice(lineItem, order, { adjustments : [] });
  const price = calculateLinePrice(lineItem, order);

  const specialPrice = listPrice?.amount !== price?.amount;

  const subscribable = order
    && order.customer
    && order.serviceChannel
    && (order.location || order.address)
    && order.timeSlot
    && isServiceChannelSubscribable(order.serviceChannel);
  const recurrence = subscription ? evaluateReccurence(subscription) : 0;
  const subscriptionText = subscribable
    ? formatRecurrence(recurrence)
    : '';

  const imageUrl = product ? getProductImage(product) : '';
  const bgStyle = imageUrl ? { backgroundImage: `url(${imageUrl})` } : {};

  const available = !!product && isProductAvailable(product, order);
  const inStock = !!product && isProductStocked(product, {
    locations : order?.location ? [order.location] : undefined,
    services : order?.service ? [order.service] : undefined,
    time : order?.time ?? undefined,
  });

  const open = order
    ? Object.values(order.lineItems).every((li) => canUpdateItem(li, order))
    : true;
  const valid = available && inStock && (!open || selectionsValid);
  const description = valid
    ? selectionsDescription
    : (!available
      ? 'Product Not Available for '
        + (order?.serviceChannel?.name ?? 'Order Type')
      : (!inStock
        ? 'Out of Stock'
        : 'Additional Customisation Required'));

  return (
    <CartSummaryItemView>
      <CartSummaryItemImage style={bgStyle}>
        <CartItemCount count={quantity} />
        <ProductImage product={product} variant="thumbnail" />
      </CartSummaryItemImage>
      <CartSummaryItemContainer>
        <CartSummaryItemInfo>
          <CartSummaryItemContent>
            <CartSummaryItemName as="p">
              {product?.name || 'N/A'}
            </CartSummaryItemName>
            { description && (
              <CartSummaryItemMods as="p" valid={valid}>
                { !valid && <AlertCircle size={14} /> }
                <span>{ description }</span>
              </CartSummaryItemMods>
            ) }
            { !!note && (
              <CartSummaryItemMods as="p" valid>
                { note }
              </CartSummaryItemMods>
            ) }
            { subscriptionText && (
              <CartSummarySubscription>
                { !!recurrence && (<Refresh size={9} />) }
                <p>{ subscriptionText }</p>
              </CartSummarySubscription>
            ) }
          </CartSummaryItemContent>
          <CartSummaryItemPrice>
            { (!specialPrice && price)
              ? (<span>{ formatCurrency(price) }</span>)
              : null
            }
            { (specialPrice && price && listPrice) && (
              <>
                <span><s>{ formatCurrency(listPrice) }</s></span>
                <span>{ ' ' + formatCurrency(price) }</span>
              </>
            ) }
            { children }
          </CartSummaryItemPrice>
        </CartSummaryItemInfo>
      </CartSummaryItemContainer>
    </CartSummaryItemView>
  );
}

export default CartSummaryItem;
