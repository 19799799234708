import React, { createContext, useCallback } from 'react';

import {
  Note,
  ProductNote,
  isNote,
  isProductNote,
} from '#mrktbox/clerk/types';

import useData, {
  actionTypes,
  DataIndex,
  useLoad,
} from '#mrktbox/clerk/hooks/useData';
import useCache from '#mrktbox/clerk/hooks/useDataCache';
import useNotesAPI from '#mrktbox/clerk/hooks/api/useNotesAPI';

export type NoteIndex = DataIndex<Note>;
export type ProductNoteIndex = DataIndex<ProductNote>;

const MAX_AGE = 1000 * 60 * 60;

const NoteContext = createContext({
  notes: null as DataIndex<Note> | null,
  notesLoaded: false,
  loadNotes: () => {},
  cacheNotes: <T extends Note>(
    note: DataIndex<T> | T | null
  ) => null as DataIndex<T> | null,
  createNote: async (note: Note) => null as Note | null,
  refreshNote: async (id: number) => null as Note | null,
  refreshNotes: async () => null as NoteIndex | null,
  retrieveNote: async (id: number) => null as Note | null,
  retrieveNotes: async () => null as NoteIndex | null,
  updateNote: async (note: Note) => null as Note | null,
  deleteNote: async (note: Note) => null as Note | null,
  productNotes: null as DataIndex<ProductNote> | null,
  productNotesLoaded: false,
  loadProductNotes: () => {},
  cacheProductNotes: <T extends ProductNote>(
    productNote: DataIndex<T> | T | null
  ) => null as DataIndex<T> | null,
  createProductNote: async (productNote: ProductNote) => null as ProductNote | null,
  refreshProductNote: async (id: number) => null as ProductNote | null,
  refreshProductNotes: async () => null as ProductNoteIndex | null,
  retrieveProductNote: async (id: number) => null as ProductNote | null,
  retrieveProductNotes: async () => null as ProductNoteIndex | null,
  updateProductNote: async (productNote: ProductNote) =>
    null as ProductNote | null,
  deleteProductNote: async (productNote: ProductNote) => null as ProductNote | null,
});

interface NoteProviderProps {
  children: React.ReactNode;
}

export function NoteProvider({ children } : NoteProviderProps) {
  const {
    createNote,
    retrieveNotes,
    retrieveNote,
    updateNote,
    deleteNote,
    createProductNote,
    retrieveProductNotes,
    retrieveProductNote,
    updateProductNote,
    deleteProductNote,
  } = useNotesAPI();

  const {
    data : notes,
    dispatch : dispatchNotes,
    lastUpdated,
  } = useData<Note>({ storageKey : 'notes' });

  const {
    data : productNotes,
    dispatch : dispatchProductNotes,
    lastUpdated : lastUpdatedProductNotes,
  } = useData<ProductNote>({ storageKey : 'productNotes' });

  const cacheNotes = useCallback(
    <T extends Note>(notes : DataIndex<T> | T | null) => {
      if (!notes) return null;
      const index = isNote(notes)
        ? (notes.id ? { [notes.id] : notes } : {})
        : notes;

      dispatchNotes({
        type : actionTypes.add,
        data : index,
      });
      return index;
    },
    [dispatchNotes],
  );

  const cacheProductNotes = useCallback(
    <T extends ProductNote>(productNotes : DataIndex<T> | T | null) => {
      if (!productNotes) return null;
      const index = isProductNote(productNotes)
        ? (productNotes.id ? { [productNotes.id] : productNotes } : {})
        : productNotes;

      dispatchProductNotes({
        type : actionTypes.add,
        data : index,
      });
      return index;
    },
    [dispatchProductNotes],
  );

  const stale = lastUpdated !== undefined
    && (new Date().getTime() - lastUpdated.getTime()) > MAX_AGE;

  const staleProductNotes = lastUpdatedProductNotes !== undefined
    && (new Date().getTime() - lastUpdatedProductNotes.getTime()) > MAX_AGE;

  const newNote = useCache({
    process : createNote,
    dispatch : dispatchNotes,
  });
  const refreshNotes = useCache({
    process : retrieveNotes,
    dispatch : dispatchNotes,
    refresh : true,
    isLoader : true,
  });
  const refreshNote = useCache({
    process : retrieveNote,
    dispatch : dispatchNotes,
    refresh : true,
    dropNull : true,
  });
  const getNotes = useCache({
    process : retrieveNotes,
    dispatch : dispatchNotes,
    data : notes,
    stale,
    refresh : true,
    isLoader : true,
  });
  const getNote = useCache({
    process : retrieveNote,
    dispatch : dispatchNotes,
    data : notes,
    stale,
    isLoader : true,
    dropNull : true,
  });
  const amendNote = useCache({
    process : updateNote,
    dispatch : dispatchNotes,
  });
  const removeNote = useCache({
    process : deleteNote,
    dispatch : dispatchNotes,
    drop : true,
  });

  const newProductNote = useCache({
    process : createProductNote,
    dispatch : dispatchProductNotes,
  });
  const refreshProductNotes = useCache({
    process : retrieveProductNotes,
    dispatch : dispatchProductNotes,
    refresh : true,
    isLoader : true,
  });
  const refreshProductNote = useCache({
    process : retrieveProductNote,
    dispatch : dispatchProductNotes,
    refresh : true,
    dropNull : true,
  });
  const getProductNotes = useCache({
    process : retrieveProductNotes,
    dispatch : dispatchProductNotes,
    data : productNotes,
    stale : staleProductNotes,
    refresh : true,
    isLoader : true,
  });
  const getProductNote = useCache({
    process : retrieveProductNote,
    dispatch : dispatchProductNotes,
    data : productNotes,
    stale : staleProductNotes,
    isLoader : true,
    dropNull : true,
  });
  const amendProductNote = useCache({
    process : updateProductNote,
    dispatch : dispatchProductNotes,
  });
  const removeProductNote = useCache({
    process : deleteProductNote,
    dispatch : dispatchProductNotes,
    drop : true,
  });

  const { loaded : notesLoaded, load : loadNotes } = useLoad({
    data : notes,
    loader : refreshNotes,
  });

  const { loaded : productNotesLoaded, load : loadProductNotes } = useLoad({
    data : productNotes,
    loader : refreshProductNotes,
  });

  const context = {
    notes,
    notesLoaded,
    loadNotes,
    cacheNotes,
    createNote : newNote,
    refreshNotes,
    refreshNote,
    retrieveNotes : getNotes,
    retrieveNote : getNote,
    updateNote : amendNote,
    deleteNote : removeNote,
    productNotes,
    productNotesLoaded,
    loadProductNotes,
    cacheProductNotes,
    createProductNote : newProductNote,
    refreshProductNotes,
    refreshProductNote,
    updateProductNote : amendProductNote,
    retrieveProductNotes : getProductNotes,
    retrieveProductNote : getProductNote,
    deleteProductNote : removeProductNote,
  };

  return (
    <NoteContext.Provider value={context}>
      {children}
    </NoteContext.Provider>
  );
}

export default NoteContext;
