export { Search as JsSearch } from 'js-search';

export type SearchIdentifier = string | number;

export function isSearchIdentifier(id : any) : id is SearchIdentifier {
  return typeof id === 'string' || typeof id === 'number';
}

export type TokenMap = {
  [id : SearchIdentifier] : {
    $document : { id : SearchIdentifier; };
    $numTokenOccurrences : number;
  }
}

export type JsIndex = {
  [token : string] : {
    $numDocumentOccurrences : number;
    $totalNumOccurrences : number;
    $uidMap : TokenMap;
  };
}

export interface Search {
  search : (query : string) => any[];
  _searchIndex : {
    _uidFieldName : string;
    _tokenMap : JsIndex;
  }
  _documents : { id : SearchIdentifier }[];
}

export interface SearchEntry {
  tokens : string[];
  scores : { [id : SearchIdentifier] : number };
}

export interface SearchIndex {
  entries : SearchEntry[];
}
