import styled from '@emotion/styled';

import { Theme } from '#types';

const PageContent = styled.div<{ theme? : Theme }>`
  max-width: 64rem;
  margin: ${(props) => props.theme.layout.spacing.medium} auto;
  opacity: 0;

  text-align: center;

  animation: slide-up 0.25s ease-in-out 0.25s forwards;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.spacing.small} auto;
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
    text-align: left;
  }

  & > p {
    line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
    margin: ${(props) => props.theme.layout.spacing.xsmall} 0;
  }
`;

export default PageContent;
