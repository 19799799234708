import { useCallback, useRef } from 'react';

function useLoading<LT = any, CT = any>() {
  const loadingPromise = useRef<{
    [key : string | number] : Promise<[LT, CT?]> | null;
  }>({});
  const loadedPromise = useRef<{
    [key : string | number] : [LT, CT?] | null;
  }>({});

  const load = useCallback(
    async <LR extends LT, CR extends CT>(
      loader : () => Promise<LR>,
      callback? : (output : LR) => Promise<CR>,
      key : string = '',
    ) : Promise<[LT, CT?]> => {
      const current = loadingPromise.current[key] ?? null;
      if (current !== null) {
        return await current
      }

      async function loadAndCallback(
        loader : () => Promise<LR>,
        callback? : (output : LR) => Promise<CR>,
      ) : Promise<[LT, CT?]> {
        const result = await loader();

        const callbackResult = callback ? await callback(result) : undefined;
        return [result, callbackResult];
      }
      const promise = loadAndCallback(
        loader,
        callback,
      );

      loadingPromise.current[key] = promise;
      const results = await promise;
      loadingPromise.current[key] = null;

      return results;
    },
    [],
  );

  const splitLoad = useCallback(async <k extends(string | number) = (string | number)>(
    keys : k[],
    loader : (keys : k[]) =>
      Promise<Record<k, LT | null> | null>,
    callback? : (output : Record<k, LT | null> | null) => {},
  ) : Promise<Record<k, LT | null> | null> => {
    const pending = keys.filter(
      key => loadingPromise.current[key] !== null
        && loadingPromise.current[key] !== undefined,
    );
    const required = keys.filter(
      key => loadingPromise.current[key] === null
        || loadingPromise.current[key] === undefined,
    );

    async function buildFromPending(key : string | number) {
      const result = await loadingPromise.current[key];
      if (result === null || result === undefined) return {};
      return { [key] : result[0] };
    }

    const pendingPromises = pending.map(key => buildFromPending(key));
    const newPromise = required.length > 0 ? loader(required) : null;
    loadingPromise.current = {
      ...loadingPromise.current,
      ...required.reduce((acc, key) => ({ ...acc, [key] : newPromise }), {}),
    };

    if (newPromise) {
      newPromise.then((out) => {
        if (callback) callback(out);
        return out;
      });
    }
    const results = (await Promise.all([
      newPromise,
      ...pendingPromises,
    ])).reduce((acc, results) => ({
      ...acc,
      ...results,
    }), {}) as Record<k, LT | null>;
    for (const key of keys) {
      if (results) {
        if (results[key] === undefined) {
          results[key] = (loadedPromise.current[key]?.[0] ?? null);
        } else if (results[key] !== null) {
          loadedPromise.current[key] = [results[key]];
        }
      }
      if (key in loadingPromise.current) delete loadingPromise.current[key];
    }

    if (results === null) return {} as Record<k, LT | null>;
    return (Object.entries(results).reduce(
      (acc, [key, value]) => ({
        ...acc,
        ...(keys.map(String).includes(key) && { [key] : value }),
      }),
      {} as Record<k, LT | null>,
    ) ?? {});
  }, []);

  return {
    load,
    splitLoad,
  };
}

export default useLoading;
