
export const formats = {
  iso : 'iso',
  numeric : 'numeric',
  short : 'short',
  easy : 'easy',
  descript : 'descript',
} as const;
type Format = typeof formats[keyof typeof formats];

export const timeScales = {
  second : 'second',
  minute : 'minute',
  hour : 'hour',
  day : 'day',
}
type TimeScale = typeof timeScales[keyof typeof timeScales];

export function roundDateTime(date : Date, round : TimeScale) {
  const roundedDate = new Date(date);
  switch (round) {
    case timeScales.second:
      roundedDate.setMilliseconds(0);
      break;
    case timeScales.minute:
      roundedDate.setSeconds(0, 0);
      break;
    case timeScales.hour:
      roundedDate.setMinutes(0, 0, 0);
      break;
    case timeScales.day:
      roundedDate.setHours(0, 0, 0, 0);
      break;
  }
  return roundedDate;
}

export function shiftLocalDateTime(date : Date, shift : number) : Date {
  const f = (n : number) => n * (shift >= 0 ? 1 : -1);
  const shifted = new Date(date.getTime());

  let remaining = Math.abs(shift);
  shifted.setDate(shifted.getDate() + f(Math.floor(remaining / 86400000)));
  remaining %= 86400000;
  shifted.setHours(shifted.getHours() + f(Math.floor(remaining / 3600000)));
  remaining %= 3600000;
  shifted.setMinutes(shifted.getMinutes() + f(Math.floor(remaining / 60000)));
  remaining %= 60000;
  shifted.setSeconds(shifted.getSeconds() + f(Math.floor(remaining / 1000)));
  remaining %= 1000;
  shifted.setMilliseconds(shifted.getMilliseconds() + f(remaining));

  return shifted;
}

export function now() { return new Date(); }
export function today() { return roundDateTime(now(), timeScales.day); }
export function tomorrow() { return shiftLocalDateTime(today(), 86400000); }
export function yesterday() { return shiftLocalDateTime(today(), -86400000); }
export function nextWeek() { return shiftLocalDateTime(today(), 604800000); }

export function isToday(date : Date) {
  return roundDateTime(date, timeScales.day).getTime() === today().getTime();
}

export function isTomorrow(date : Date) {
  return roundDateTime(date, timeScales.day).getTime() === tomorrow().getTime();
}

export function isYesterday(date : Date) {
  return roundDateTime(
    date,
    timeScales.day
  ).getTime() === yesterday().getTime();
}

export function isThisWeek(date : Date) {
  const rounded = roundDateTime(date, timeScales.day).getTime();
  return rounded >= today().getTime() && rounded < nextWeek().getTime();
}

export function parseDateTime(date : string) {
  return new Date(date);
}

export function formatDate(date : Date, format : Format = formats.numeric) {
  switch (format) {
    case formats.numeric:
      return date.toISOString().split('T')[0];
    case formats.short:
      return date.toLocaleDateString("en-US", {
        weekday : 'short',
        month : 'short',
        day : '2-digit',
      });
    case formats.easy:
      const prefix = isToday(date) ? 'Today' :
        (isTomorrow(date) ? 'Tomorrow' :
          (isYesterday(date) ? 'Yesterday' : ''));
      if (prefix) return prefix;

      if (isThisWeek(date)) return date.toLocaleDateString("en-US", {
        weekday : 'long',
      });

      return formatDate(date, formats.short);
    case formats.descript:
      return date.toLocaleDateString("en-US", {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    default:
      return date.toISOString().split('T')[0];
  }
}

export function formatTime(date : Date, format : Format = formats.numeric) {
  switch (format) {
    case formats.numeric:
      return date.toLocaleTimeString("en-US", {
        hour : 'numeric',
        minute : 'numeric',
        second : 'numeric',
      });
    case formats.short:
      return date.toLocaleTimeString("en-US", {
        hour : 'numeric',
        minute : 'numeric',
      });
    case formats.easy:
      return formatTime(date, formats.short);
    case formats.descript:
      return date.toLocaleTimeString("en-US", {
        hour : 'numeric',
        minute : 'numeric',
        second : 'numeric',
      });
    default:
      return date.toISOString().split('T')[1];
  }
}

export function formatDateTime(date : Date, format : Format = formats.numeric) {
  switch (format) {
    case formats.numeric:
      return date.toLocaleString('en-US', {
        year : 'numeric',
        month : 'numeric',
        day : 'numeric',
        weekday : undefined as undefined | 'long',
        hour : 'numeric',
        minute : 'numeric',
        second : 'numeric',
      });
    case formats.short:
      return date.toLocaleString('en-US', {
        weekday : 'short',
        month : 'short',
        day : '2-digit',
        hour : 'numeric',
        minute : 'numeric',
      });
    case formats.easy:
      return formatDate(date, formats.easy) + ' @ ' +
        formatTime(date, formats.short);
    case formats.descript:
      return date.toLocaleString('en-US', {
        year : 'numeric',
        month : 'long',
        day : 'numeric',
        weekday : 'long',
        hour : 'numeric',
        minute : 'numeric',
        second : 'numeric',
      });
    default:
      return date.toISOString();
  }
}

export function serializeDateTime (date? : Date) {
  if (!date) return undefined;
  return formatDateTime(date, formats.iso);
}

export function deserializeDateTime (date? : string) {
  if (!date) return undefined;
  return parseDateTime(date);
}

export default formats;
