
const clerkGlobals = {
  config: {
    domain : '',
  },
};

export function getConfig() : typeof clerkGlobals.config {
  try {
    const config = localStorage.getItem('clerk');
    return config ? JSON.parse(config) : {};
  } catch {
    return clerkGlobals.config;
  }
}

export function setConfig(config : typeof clerkGlobals.config) {
  clerkGlobals.config = config;
  try {
    localStorage.setItem('clerk', JSON.stringify(config));
  } catch { }
}

export function getApiDomain() : string {
  const config = getConfig();
  return (config.domain || process.env.REACT_APP_DOMAIN) ?? '';
}
