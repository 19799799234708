import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import {
  Currency,
  Address,
  Customer,
  CreditCard,
  ServiceChannel,
  Location,
  TimeSlot,
  Product,
  Adjustment,
  AppliedAdjustment,
  LineItem,
  Fulfilment,
  Order,
  Hold,
  Subtotal,
  DraftOrder,
  OrderError,
  FulfilmentStatus,
  isLineItem,
  isOrder,
} from '#mrktbox/clerk/types';

import CustomersContext from '#mrktbox/clerk/context/CustomersContext';

import useData, {
  actionTypes,
  DataIndex,
  useLoad,
} from '#mrktbox/clerk/hooks/useData';
import useCache from '#mrktbox/clerk/hooks/useDataCache';
import useAddresses from '#mrktbox/clerk/hooks/useAddresses';
import useCards from '#mrktbox/clerk/hooks/useCards';
import useServices from '#mrktbox/clerk/hooks/useServices';
import useRoutes from '#mrktbox/clerk/hooks/useRoutes';
import useScheduling from '#mrktbox/clerk/hooks/useScheduling';
import useProducts from '#mrktbox/clerk/hooks/useProducts';
import useTaxes from '#mrktbox/clerk/hooks/useTaxes';
import useAdjustments from '#mrktbox/clerk/hooks/useAdjustments';
import useOrdersAPI from '#mrktbox/clerk/hooks/api/useOrdersAPI';

import { addCurrency, multiplyCurrency } from '#mrktbox/clerk/utils/currency';
import {
  serializeDateTime,
  deserializeDateTime,
} from '#mrktbox/clerk/utils/date';
import { listRecords, filterIndex } from '#mrktbox/clerk/utils';

export type LineItemIndex = DataIndex<LineItem>;
export type OrderIndex = DataIndex<Order>;
export type HoldIndex = DataIndex<Hold>;
type LineItemReturn = {
  lineItem? : LineItem | null;
  lineItems? : LineItemIndex | null;
  orders : DataIndex<Order> | null;
};
export type FulfilmentReturn = {
  fulfilment? : Fulfilment | null;
  order : Order | null;
};
export type FulfilmentsReturn = {
  fulfilments? : DataIndex<Fulfilment> | null;
  orders : DataIndex<Order> | null;
};

const MAX_AGE = 1000 * 60 * 60;

function serializeHold(hold : Hold | null) {
  if (!hold) return null;
  return {
    ...hold,
    start : serializeDateTime(hold.start),
    end : serializeDateTime(hold.end),
  }
}

function deserializeHold(hold : any | null) : Hold | null {
  if (!hold) return null;
  return {
    ...hold,
    start : deserializeDateTime(hold.start),
    end : deserializeDateTime(hold.end),
  }
}

const OrderContext = createContext({
  lineItems : null as DataIndex<LineItem> | null,
  holds : null as DataIndex<Hold> | null,
  orders : null as DraftOrder[] | null,
  orderAddresses : null as DataIndex<Address> | null,
  loaded : false,
  cacheLineItems : (
    lineItems : LineItemIndex | LineItem | null,
  ) => null as LineItemIndex | null,
  cacheOrders : (
    dataIndex : DataIndex<Order> | Order | null,
  ) => null as DataIndex<Order> | null,
  dispatchOrderAddresses : (addresses : DataIndex<Address>) => {},
  load : () => {},
  createLineItem : async (
    lineItem : LineItem,
    options? : {
      address? : Address | null,
      customer? : Customer | null,
      serviceChannel? : ServiceChannel | null,
      location? : Location | null,
      timeSlot? : TimeSlot | null,
      product : Product,
    },
  ) => null as LineItem | null,
  reloadLineItems : async () => null as LineItemIndex | null,
  refreshLineItems : async () => null as LineItemIndex | null,
  refreshLineItem : async (id : number) => null as LineItem | null,
  retrieveLineItems : async () => null as LineItemIndex | null,
  retrieveLineItem : async (id : number) => null as LineItem | null,
  updateLineItem : async (
    lineItem : LineItem,
    options? : {
      address? : Address | null,
      customer? : Customer | null,
      serviceChannel? : ServiceChannel | null,
      location? : Location | null,
      timeSlot? : TimeSlot | null,
      product? : Product | null,
    },
  ) => null as LineItem | null,
  deleteLineItem : async (lineItem : LineItem) => null as LineItem | null,
  bulkCreateLineItems :
    async (
      lineItems : LineItem[],
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
      },
    ) => null as DataIndex<LineItem> | null,
  bulkUpdateLineItems :
    async (
      lineItems : LineItem[],
      options? : {
        address? : Address | null,
        customer? : Customer | null,
        serviceChannel? : ServiceChannel | null,
        location? : Location | null,
        timeSlot? : TimeSlot | null,
      },
    ) => null as DataIndex<LineItem> | null,
  bulkDeleteLineItems : async (
    lineItems : LineItem[],
  ) => null as DataIndex<LineItem> | null,
  reloadOrders : async () => null as DataIndex<Order> | null,
  refreshOrders : async () => null as DataIndex<Order> | null,
  refreshOrder : async (id : number) => null as Order | null,
  retrieveOrders : async () => null as DataIndex<Order>| null,
  retrieveOrder : async (id : number) => null as Order | null,
  addAdjustmentToOrder : async (
    order : Order,
    adjustment : Adjustment,
  ) => null as Order | null,
  removeAdjustmentFromOrder : async (
    order : Order,
    appliedAdjustment : AppliedAdjustment,
  ) => null as Order | null,
  createFulfilment : async (
    fulfilment : Fulfilment,
  ) => null as FulfilmentReturn | null,
  updateFulfilment : async (
    fulfilment : Fulfilment,
  ) => null as FulfilmentReturn | null,
  deleteFulfilment :
    async (fulfilment : Fulfilment) => null as FulfilmentReturn | null,
  bulkCreateFulfilments :
    async (lineItems : LineItem[]) => null as null | {
      fulfilments : DataIndex<Fulfilment> | null,
      orders : DataIndex<Order> | null,
    },
  bulkUpdateFulfilments :
    async (fulfilments : Fulfilment[]) => null as null | {
      fulfilments : DataIndex<Fulfilment> | null,
      orders : DataIndex<Order> | null,
    },
  bulkDeleteFulfilments : async (
    fulfilments : Fulfilment[],
  ) => null as null | {
    fulfilments : DataIndex<Fulfilment> | null,
    orders : DataIndex<Order> | null,
  },
  addAdjustmentToFulfilment : async (
    fulfilment : Fulfilment,
    adjustment : Adjustment,
  ) => null as Order | null,
  removeAdjustmentFromFulfilment : async (
    fulfilment : Fulfilment,
    appliedAdjustment : AppliedAdjustment,
  ) => null as Order | null,
  createHold : async (hold : Hold) => null as { hold : Hold } | null,
  refreshHolds : async () => null as DataIndex<Hold> | null,
  refreshHold : async (id : number) => null as Hold | null,
  retrieveHolds : async () => null as DataIndex<Hold> | null,
  retrieveHold : async (id : number) => null as Hold | null,
  deleteHold : async (hold : Hold) => null as { hold : Hold } | null,
  claimGuestItems :
    async (
      customer : Customer,
      options? : { token? : string },
    ) => null as DataIndex<LineItem> | null,
  payOrders : async (
    orders : Order[],
    options? : { card? : CreditCard, token? : string },
  ) => null as DataIndex<Order> | null,
  createOrderFromLineItem :
    async (lineItem : LineItem) => null as DraftOrder | null,
  buildOrders : async (items? : LineItemIndex) => null as DraftOrder[] | null,
  buildOrphanedOrders :
    async (built : DraftOrder[]) => [] as DraftOrder[],
  validateOrder : (order : DraftOrder) => {},
  calculateFulfilmentPrice : (
    fulfilment : Fulfilment,
    options? : {
      quantity? : number | null,
      adjustments? : Adjustment[],
    },
  ) => null as Currency | null,
  calculateLinePrice : (
    line : LineItem | { id? : number, productId : number, quantity : number },
    order? : DraftOrder | null,
    options? : {
      quantity? : number | null,
      adjustments? : Adjustment[],
    },
  ) => null as Currency | null,
});

interface OrderProviderProps {
  children : React.ReactNode;
}

export function OrderProvider({ children } : OrderProviderProps) {
  const { retrieveAddressesBulk } = useAddresses();
  const {
    customers,
    loaded : customerLoaded,
    load : loadCustomers,
  } = useContext(CustomersContext);
  const { getCustomerCreditCards } = useCards();
  const {
    services,
    serviceChannels,
    locations,
    loaded : servicesLoaded,
    load : loadServices,
  } = useServices();
  const {
    loaded : routesLoaded,
    load : loadRoutes,
    retrieveAddressRoutesSync,
  } = useRoutes();
  const {
    timeSlots,
    schedules,
    loaded : schedulesLoaded,
    load : loadSchedules,
    calculateTime,
  } = useScheduling();
  const { products } = useProducts();
  const { taxes, load : loadTaxes, loaded : taxesLoaded } = useTaxes();
  const { adjustments, calculateProductPrice } = useAdjustments();

  const {
    createLineItem,
    retrieveLineItems,
    retrieveLineItem,
    updateLineItem,
    deleteLineItem,
    bulkCreateLineItems,
    bulkUpdateLineItems,
    bulkDeleteLineItems,
    createFulfilment,
    updateFulfilment,
    deleteFulfilment,
    bulkCreateFulfilments,
    bulkUpdateFulfilments,
    bulkDeleteFulfilments,
    addAdjustmentToFulfilment,
    removeAdjustmentFromFulfilment,
    retrieveOrders,
    retrieveOrder,
    addAdjustmentToOrder,
    removeAdjustmentFromOrder,
    createHold,
    retrieveHolds,
    retrieveHold,
    deleteHold,
    claimGuestCode,
    payOrders,
  } = useOrdersAPI();

  const [draftOrders, setDraftOrders] = useState<DraftOrder[]>([]);

  const {
    data : allLineItems,
    dispatch : dispatchLineItems,
    lastUpdated: lineItemsLastUpdated,
  } = useData<LineItem>({ storageKey : 'lineItems' });

  const {
    data : allOrders,
    dispatch : dispatchOrders,
    lastUpdated: ordersLastUpdated,
  } = useData<Order>({ storageKey : 'orders' });

  const {
    data : allHolds,
    dispatch : dispatchHolds,
    lastUpdated: holdsLastUpdated,
  } = useData<Hold>({
    storageKey : 'holds',
    serializer : serializeHold,
    deserializer : deserializeHold,
  });

  const lineItemsStale = lineItemsLastUpdated !== undefined &&
    (new Date().getTime() - lineItemsLastUpdated.getTime()) > MAX_AGE;

  const cacheLineItems = useCallback((
    lineItems : LineItemIndex | LineItem | null,
  ) => {
    if (lineItems === null) return null;
    const index = isLineItem(lineItems)
      ? (lineItems.id ? { [lineItems.id] : lineItems } : {})
      : lineItems;

    dispatchLineItems({
      type : actionTypes.add,
      data : index,
    });
    return index;
  }, [dispatchLineItems]);

  const cacheOrders = useCallback((orders : OrderIndex | Order | null) => {
    if (orders === null) return null;
    const index = isOrder(orders)
      ? (orders.id ? { [orders.id] : orders } : {})
      : orders;

    dispatchOrders({
      type : actionTypes.add,
      data : index,
    });
    return index;
  }, [dispatchOrders]);

  const [lineItems, setLineItems] = useState<LineItemIndex | null>(
    allLineItems
      ? filterIndex(allLineItems, undefined, { dropDeleted : true })
      : null
  );
  const [orders, setOrders] = useState<OrderIndex | null>(
    allOrders
      ? filterIndex(allOrders, undefined, { dropDeleted : true })
      : null
  );
  const [holds, setHolds] = useState<HoldIndex | null>(
    allHolds
      ? filterIndex(allHolds, undefined, { dropDeleted : true })
      : null
  );

  const parseLineItems = useCallback((response : LineItemReturn) => {
    if (response.orders) {
      dispatchOrders({ data : response.orders, type : actionTypes.add });
    }
    return {
      ...(response.lineItem
        && { [response.lineItem.id ?? NaN] : response.lineItem }),
      ...response.lineItems,
    };
  }, [dispatchOrders]);

  const parseFulfilments = useCallback((
    response : FulfilmentReturn | FulfilmentsReturn,
  ) => {
    function isFulfilmentReturn(ret : any) : ret is FulfilmentReturn {
      return ret.fulfilment !== undefined;
    }
    if (isFulfilmentReturn(response)) {
      if (!response.order?.id) return null;
      return { [response.order.id] : response.order };
    }
    return response.orders;
  }, []);

  const queryRecentLineItems = useCallback(() => {
    return retrieveLineItems({ since : lineItemsLastUpdated });
  }, [retrieveLineItems, lineItemsLastUpdated]);

  const newLineItem = useCache({
    process : createLineItem,
    parser : parseLineItems,
    filter : (response : LineItemReturn) => response.lineItem ?? null,
    dispatch : dispatchLineItems,
  });
  const reloadLineItems = useCache({
    process : retrieveLineItems,
    dispatch : dispatchLineItems,
    refresh : true,
    isLoader : true,
  });
  const refreshLineItems = useCache({
    process : queryRecentLineItems,
    dispatch : dispatchLineItems,
    update : true,
    isLoader : true,
  });
  const refreshLineItem = useCache({
    process : retrieveLineItem,
    dispatch : dispatchLineItems,
    isLoader : true,
    dropNull : true,
  });
  const getLineItems = useCache({
    process : retrieveLineItems,
    dispatch : dispatchLineItems,
    data : lineItems,
    stale : lineItemsStale,
    refresh : true,
    isLoader : true,
  });
  const getLineItem = useCache({
    process : retrieveLineItem,
    dispatch : dispatchLineItems,
    data : lineItems,
    stale : lineItemsStale,
    isLoader : true,
    dropNull : true,
  });
  const amendLineItem = useCache({
    process : updateLineItem,
    parser : parseLineItems,
    filter : (response : LineItemReturn) => response.lineItem ?? null,
    dispatch : dispatchLineItems,
  });
  const removeLineItem = useCache({
    process : deleteLineItem,
    parser : parseLineItems,
    filter : (response : LineItemReturn) => response.lineItem ?? null,
    dispatch : dispatchLineItems,
  });

  const newBulkLineItems = useCache({
    process : bulkCreateLineItems,
    parser : parseLineItems,
    filter : (response : LineItemReturn) => response.lineItems ?? null,
    dispatch : dispatchLineItems,
  });
  const amendBulkLineItems = useCache({
    process : bulkUpdateLineItems,
    parser : parseLineItems,
    filter : (response : LineItemReturn) => response.lineItems ?? null,
    dispatch : dispatchLineItems,
  });
  const removeBulkLineItems = useCache({
    process : bulkDeleteLineItems,
    parser : parseLineItems,
    filter : (response : LineItemReturn) => response.lineItems ?? null,
    dispatch : dispatchLineItems,
  });

  const ordersStale = ordersLastUpdated !== undefined &&
    (new Date().getTime() - ordersLastUpdated.getTime()) > MAX_AGE;

  const queryRecentOrders = useCallback(() => {
    return retrieveOrders({ since : ordersLastUpdated });
  }, [retrieveOrders, ordersLastUpdated]);

  const reloadOrders = useCache({
    process : retrieveOrders,
    dispatch : dispatchOrders,
    refresh : true,
    isLoader : true,
  });
  const refreshOrders = useCache({
    process : queryRecentOrders,
    dispatch : dispatchOrders,
    update : true,
    isLoader : true,
  });
  const refreshOrder = useCache({
    process : retrieveOrder,
    dispatch : dispatchOrders,
    isLoader : true,
  });
  const getOrders = useCache({
    process : queryRecentOrders,
    dispatch : dispatchOrders,
    data : orders,
    stale : ordersStale,
    refresh : true,
    isLoader : true,
  });
  const getOrder = useCache({
    process : retrieveOrder,
    dispatch : dispatchOrders,
    data : orders,
    stale : ordersStale,
    isLoader : true,
  });

  const addOrderAdjustment = useCache({
    process : addAdjustmentToOrder,
    dispatch : dispatchOrders,
  });
  const removeOrderAdjustment = useCache({
    process : removeAdjustmentFromOrder,
    dispatch : dispatchOrders,
  });

  const newFulfilment = useCache({
    process : createFulfilment,
    parser : parseFulfilments,
    dispatch : dispatchOrders,
  });
  const amendFulfilment = useCache({
    process : updateFulfilment,
    parser : parseFulfilments,
    dispatch : dispatchOrders,
  });
  const removeFulfilment = useCache({
    process : deleteFulfilment,
    parser : parseFulfilments,
    dispatch : dispatchOrders,
  });

  const bulkNewFulfilments = useCache({
    process : bulkCreateFulfilments,
    parser : parseFulfilments,
    dispatch : dispatchOrders,
  });
  const bulkAmendFulfilments = useCache({
    process : bulkUpdateFulfilments,
    parser : parseFulfilments,
    dispatch : dispatchOrders,
  });
  const bulkRemoveFulfilments = useCache({
    process : bulkDeleteFulfilments,
    parser : parseFulfilments,
    dispatch : dispatchOrders,
  });

  const addFulfilmentAdjustment = useCache({
    process : addAdjustmentToFulfilment,
    dispatch : dispatchOrders,
  });
  const removeFulfilmentAdjustment = useCache({
    process : removeAdjustmentFromFulfilment,
    dispatch : dispatchOrders,
  });

  const claimGuestItems = useCache({
    process : claimGuestCode,
    dispatch : dispatchLineItems,
  });

  const processOrders = useCache({
    process : payOrders,
    dispatch : dispatchOrders,
  });

  const holdsStale = holdsLastUpdated !== undefined &&
    (new Date().getTime() - holdsLastUpdated.getTime()) > MAX_AGE;

  const newHold = useCache({
    process : createHold,
    parser : ({ hold } : { hold : Hold }) => ({ [hold.id ?? NaN] : hold }),
    dispatch : dispatchHolds,
  });
  const refreshHolds = useCache({
    process : retrieveHolds,
    dispatch : dispatchHolds,
    refresh : true,
    isLoader : true,
  });
  const refreshHold = useCache({
    process : retrieveHold,
    dispatch : dispatchHolds,
    isLoader : true,
  });
  const getHolds = useCache({
    process : retrieveHolds,
    dispatch : dispatchHolds,
    data : holds,
    stale : holdsStale,
    refresh : true,
    isLoader : true,
  });
  const getHold = useCache({
    process : retrieveHold,
    dispatch : dispatchHolds,
    data : holds,
    stale : holdsStale,
    isLoader : true,
  });
  const removeHold = useCache({
    process : deleteHold,
    parser : ({ hold } : { hold : Hold }) => ({ [hold.id ?? NaN] : hold }),
    dispatch : dispatchHolds,
  });

  const { loaded: lineItemsLoaded, load : loadLineItems } = useLoad({
    data : allLineItems,
    loader : refreshLineItems,
  });
  const { loaded: ordersLoaded, load : loadOrders } = useLoad({
    data : orders,
    loader : refreshOrders,
  });
  const { load : loadHolds } = useLoad({
    data : allHolds,
    loader : refreshHolds,
  });

  const load = useCallback(() => {
    loadCustomers();
    loadServices();
    loadRoutes();
    loadSchedules();
    loadTaxes();
    loadLineItems();
    loadOrders();
    loadHolds();
  }, [
    loadCustomers,
    loadServices,
    loadRoutes,
    loadSchedules,
    loadTaxes,
    loadLineItems,
    loadOrders,
    loadHolds,
  ]);

  const [addresses, dispatchAddresses] = useReducer(
    (state : DataIndex<Address>, newAddresses : DataIndex<Address>) => ({
      ...state,
      ...newAddresses,
    }),
    {}
  );

  const findService = useCallback(({
    address,
    serviceChannel,
    location,
    timeSlot,
  } : {
    address : Address | null;
    serviceChannel : ServiceChannel;
    location : Location | null;
    timeSlot : TimeSlot;
  }) => {
    return listRecords(services).find(service => {
      const serviceId = service.id;
      if (!serviceId) return false;
      if (!serviceChannel.id) return false;
      const timeSlotId = timeSlot.id;
      if (!timeSlotId) return false;

      if (address) {
        const addressRoutes = retrieveAddressRoutesSync(address);
        if (!addressRoutes) return false;
        if (!addressRoutes.some(
          route => route.serviceIds?.includes(serviceId)
        )) return false;
      }
      if (serviceChannel.id !== service.serviceChannelId) return false;
      if (location && (location.id !== service.locationId)) return false;
      if (!listRecords(schedules).some(schedule => (
        schedule.serviceIds?.includes(serviceId)
          && Object.keys(schedule.timeSlots).includes(timeSlotId.toString())
      ))) return false;

      return true;
    }) ?? null;
  }, [services, retrieveAddressRoutesSync, schedules]);

  const validateOrder = useCallback((order : DraftOrder) => {
    if (
      !order.paid
      && order.customer
      && !getCustomerCreditCards(order.customer).length
    ) {
      order.errors.push('noPayment');
    }

    const itemIds = Object.keys(order.lineItems).map(Number);
    if (itemIds.length === 0) order.errors.push('emptyOrder');

    if (!order.order) return;
    if (Object.values(order.order.fulfilments).some(
      fulfilment => fulfilment.lineItemId
        && !itemIds.includes(fulfilment.lineItemId)
    )) order.errors.push('orphanedFulfilment');
  }, [getCustomerCreditCards]);

  const buildTotals = useCallback((order : Order) => {
    const newSubtotals : Subtotal[] = [];

    if (order) {
      const subtotalAmount = Object.values(order.fulfilments).reduce(
        (acc, fulfilment) => {
          const base = (fulfilment.unitPrice.amount * (
            fulfilment.fulfilledQty ?? fulfilment.requestedQty
          ));
          return acc + Object.values(fulfilment.appliedAdjustments)
            .filter((adj) => adj.applied)
            .reduce((adjAcc, adj) => adjAcc + ((
              adj.currency?.amount
                ? adj.currency.amount
                : (fulfilment.unitPrice.amount * (adj.factor ?? 0))
            ) * adj.count), base);
        },
        0,
      );
      const subtotal = {
        key : 'subtotal',
        total : {
          amount : Math.round(subtotalAmount),
          currencyCode : 'CAD',
          increment : 0.01,
          calculatedValue : subtotalAmount * 0.01,
        }
      }
      newSubtotals.push(subtotal);

      Object.values(order.appliedTaxes).forEach((tax) => {
        const appliedAdjustment = order.appliedAdjustments[tax.adjustmentId];
        if (!appliedAdjustment || !appliedAdjustment.applied) return;

        const existing = newSubtotals.find((sub) => sub.taxId === tax.taxId);

        const taxed = multiplyCurrency(appliedAdjustment.currency?.amount
          ? appliedAdjustment.currency
          : multiplyCurrency(
            subtotal.total,
            appliedAdjustment.factor ?? 0,
          ), appliedAdjustment.count);

        if (existing) {
          existing.total = addCurrency(
            existing.total,
            multiplyCurrency(taxed, tax.rate)
          );
          return;
        }

        newSubtotals.push({
          key : `tax-${tax.taxId}`,
          total : multiplyCurrency(taxed, tax.rate),
          taxId : tax.taxId,
        });
      });

      Object.values(order.fulfilments).forEach((fulfilment) => {
        Object.keys(fulfilment.taxes).forEach((taxId) => {
          const tax = taxes?.[Number(taxId)];
          const subtotal = newSubtotals.find(
            (sub) => sub.taxId === Number(taxId)
          );

          const base = (fulfilment.unitPrice.amount * (
            fulfilment.fulfilledQty ?? fulfilment.requestedQty
          ));
          const taxable = Object.values(fulfilment.appliedAdjustments)
            .filter((adj) => adj.applied)
            .reduce((adjAcc, adj) => adjAcc + ((
              adj.currency?.amount
                ? adj.currency.amount
                : (fulfilment.unitPrice.amount * (adj.factor ?? 0))
            ) * adj.count), base);

          const rate = fulfilment.taxes[Number(taxId)];

          if (subtotal) {
            subtotal.total.amount += taxable * rate;
            subtotal.total.calculatedValue += (taxable * rate) * 0.01;
            return;
          }

          newSubtotals.push({
            key : `tax-${tax?.id}`,
            total : {
              amount : taxable * rate,
              currencyCode : 'CAD',
              increment : 0.01,
              calculatedValue : (taxable * rate) * 0.01,
            },
            taxId : Number(taxId),
          });
        });
      })

      Object.values(order?.appliedAdjustments).forEach((adj) => {
        if (!adj.applied) return;
        const existing = newSubtotals.find(
          (sub) => adj.adjustmentId
            ? (sub.adjustmentId === adj.adjustmentId)
            : (sub.label === adj.name)
        );

        const absolute = multiplyCurrency(adj.currency?.amount
          ? adj.currency
          : multiplyCurrency(
            subtotal.total,
            adj.factor ?? 0,
          ), adj.count);

        if (existing) {
          existing.total = addCurrency(existing.total, absolute)
          if (adj.id) {
            existing.appliedAdjustmentIds = [
              ...(existing.appliedAdjustmentIds || []),
              adj.id,
            ]
          }
          return;
        }

        const adjustment = adj.adjustmentId
          ? adjustments?.[adj.adjustmentId]
          : null;
        newSubtotals.push({
          key : `adjustment-${adj.adjustmentId}`,
          label : adj.adjustmentId ? adjustment?.name : adj.name,
          total : absolute,
          adjustmentId : adj.adjustmentId,
          appliedAdjustmentIds : adj.id ? [adj.id] : undefined,
        });
      })

      const total = newSubtotals.reduce(
        (acc, sub) => acc + sub.total.amount,
        0,
      );
      newSubtotals.push({
        key : 'total',
        total : {
          amount : Math.round(total),
          currencyCode : 'CAD',
          increment : 0.01,
          calculatedValue : total * 0.01,
        },
      });

      if (Object.values(order.payments).length > 0) {
        const paid = Object.values(order.payments).reduce(
          (acc, payment) => acc + payment.currency.amount,
          0,
        );
        newSubtotals.push({
          key : 'paid',
          total : {
            amount : Math.round(paid),
            currencyCode : 'CAD',
            increment : 0.01,
            calculatedValue : paid * 0.01,
          },
        });

        const balance = Math.round(total - paid);
        newSubtotals.push({
          key : 'balance',
          total : {
            amount : Math.round(balance),
            currencyCode : 'CAD',
            increment : 0.01,
            calculatedValue : balance * 0.01,
          },
        });
      }
    }

    newSubtotals.sort((a, b) => {
      const weigh = (subtotal : Subtotal) => {
        switch (subtotal.key) {
          case 'subtotal':
            return -1;
          case 'total':
            return 2;
          case 'paid':
            return 3;
          case 'balance':
            return 4;
          default:
            if (subtotal.taxId) return 1;
            return 0;
        }
      }
      return weigh(a) - weigh(b);
    });
    return newSubtotals;
  }, [adjustments, taxes]);

  const buildDraftOrder = useCallback(({
    order,
    lineItem,
    customerId,
    serviceChannelId,
    addressId,
    locationId,
    timeSlotId,
    timeSlotIteration,
    timeSlotDivision,
    guestCode,
  } : {
    order? : Order;
    lineItem? : LineItem,
    customerId? : number | null,
    serviceChannelId? : number | null,
    locationId? : number | null,
    addressId? : number | null,
    timeSlotId? : number | null,
    timeSlotIteration? : number;
    timeSlotDivision? : number;
    guestCode? : string;
  }) : DraftOrder => {
    customerId = customerId
      ?? order?.customerId
      ?? lineItem?.customerId
      ?? null;
    serviceChannelId = serviceChannelId
      ?? order?.serviceChannelId
      ?? lineItem?.serviceChannelId
      ?? null;
    serviceChannelId = serviceChannelId
      ?? order?.serviceChannelId
      ?? lineItem?.serviceChannelId
      ?? null;
    locationId = locationId
      ?? order?.locationId
      ?? lineItem?.locationId
      ?? null;
    addressId = addressId
      ?? order?.addressId
      ?? lineItem?.addressId
      ?? null;
    timeSlotId = timeSlotId
      ?? order?.timeSlotId
      ?? lineItem?.timeSlotId
      ?? null;

    timeSlotIteration = timeSlotIteration
      ?? order?.timeSlotIteration
      ?? lineItem?.timeSlotIteration
      ?? 0;
    timeSlotDivision = timeSlotDivision
      ?? order?.timeSlotDivision
      ?? lineItem?.timeSlotDivision
      ?? 0;

    guestCode = customerId
      ? ''
      : guestCode ?? lineItem?.guestCode ?? '';

    const customer = (customerId !== null)
      ? customers?.[customerId] ?? null
      : null;
    const serviceChannel = (serviceChannelId !== null)
      ? serviceChannels?.[serviceChannelId] ?? null
      : null;
    const location = (locationId !== null)
      ? locations?.[locationId] ?? null
      : null;
    const address = (addressId !== null)
      ? ((customer?.addresses && customer.addresses[addressId])
        ? customer.addresses[addressId]
        : addresses?.[addressId]) ?? {
          id : addressId,
          description : 'Unknown Address',
          street : '',
          city : '',
          postal : '',
        }
      : null;
    const timeSlot = (timeSlotId !== null)
      ? timeSlots?.[timeSlotId] ?? null
      : null;

    order = order ?? listRecords(orders).find(order => (
      order.customerId === customerId
        && order.serviceChannelId === serviceChannelId
        && order.locationId === locationId
        && order.addressId === addressId
        && order.timeSlotId === timeSlotId
        && order.timeSlotIteration === timeSlotIteration
        && order.timeSlotDivision === timeSlotDivision
    ));

    const complete = !!((address || location)
      && customer
      && serviceChannel
      && timeSlot);
    const service = (
      services
        && order
        && (order.serviceId !== null)
        && services[order.serviceId]
    ) ? services[order.serviceId]
      : (complete ? findService({
        address,
        serviceChannel,
        location,
        timeSlot,
      }) : null);

    const orderErrors : OrderError[] = [];
    if (complete && !service) orderErrors.push('noService');

    const totals = order ? buildTotals(order) : [];

    return {
      address,
      customer,
      serviceChannel,
      location,
      timeSlot,
      timeSlotIteration : timeSlotIteration,
      timeSlotDivision : timeSlotDivision,
      guestCode,
      time : timeSlot ? calculateTime(
        timeSlot,
        timeSlotIteration,
        timeSlotDivision,
      ) : null,
      service,
      complete,
      paid : order?.payments && !!Object.keys(order?.payments).length,
      status : order?.fulfilments
        ? Object.values(order.fulfilments).reduce(
          (status, fulfilment) => {
            if (status === 'fulfilled' || fulfilment.status === 'fulfilled')
              return 'fulfilled';
            if (status === 'ready' || fulfilment.status === 'ready')
              return 'ready';
            if (status === 'inProgress' || fulfilment.status === 'inProgress')
              return 'inProgress';
            if (status === 'cancelled' || fulfilment.status === 'cancelled')
              return 'cancelled';
            if (status === 'confirmed' || fulfilment.status === 'confirmed')
              return 'confirmed';
            if (status === 'pending' || fulfilment.status === 'pending')
              return 'pending';
            return status;
          },
          'pending' as FulfilmentStatus,
        )
        : undefined,
      order,
      lineItems : lineItem?.id ? { [lineItem.id] : lineItem } : {},
      totals,
      errors : orderErrors,
    };
  }, [
    orders,
    customers,
    serviceChannels,
    services,
    locations,
    timeSlots,
    addresses,
    buildTotals,
    calculateTime,
    findService,
  ]);

  const createOrderFromLineItem = useCallback(async (lineItem : LineItem) => {
    return buildDraftOrder({ lineItem });
  }, [buildDraftOrder]);

  const findOrphanedOrders = useCallback((built : DraftOrder[]) => {
    return listRecords(orders).filter(
      order => !built.some(draft => draft.order?.id === order.id)
    );
  }, [orders]);

  const buildOrphanedOrders = useCallback(async (built : DraftOrder[]) => {
    return Promise.all(findOrphanedOrders(built).map(
      order => buildDraftOrder({ order })
    ));
  }, [buildDraftOrder, findOrphanedOrders]);

  const buildOrders = useCallback(async (items = lineItems) => {
    if (!items
      || !customers
      || !serviceChannels
      || !locations
      || !timeSlots) return null;
    const drafts : DraftOrder[] = [];
    for (const lineItem of Object.values(items)) {
      if (!lineItem?.id) continue;

      let exists = false;
      for (const order of drafts) {
        if (
          ((lineItem.addressId === null && order.address === null)
            || (lineItem.addressId === order.address?.id))
          && ((
            lineItem.customerId === null
              && order.customer === null
              && (
                (!lineItem.guestCode && !order.guestCode)
                  || (lineItem.guestCode === order.guestCode)
          )) || (lineItem.customerId === order.customer?.id))
          && ((
            lineItem.serviceChannelId === null
              && order.serviceChannel === null
          ) || (lineItem.serviceChannelId === order.serviceChannel?.id))
          && ((lineItem.locationId === null && order.location === null)
            || (lineItem.locationId === order.location?.id))
          && ((lineItem.timeSlotId === null && order.timeSlot === null)
            || (lineItem.timeSlotId === order.timeSlot?.id))
          && lineItem.timeSlotIteration === order.timeSlotIteration
          && lineItem.timeSlotDivision === order.timeSlotDivision
        ) {
          order.lineItems[lineItem.id] = lineItem;
          exists = true;
          continue;
        }
      }
      if (exists) continue;

      drafts.push(await createOrderFromLineItem(lineItem));
    }

    drafts.push(...(await buildOrphanedOrders(drafts)));

    for (const draft of drafts) {
      validateOrder(draft);
    }

    return drafts;
  }, [
    lineItems,
    customers,
    serviceChannels,
    locations,
    timeSlots,
    createOrderFromLineItem,
    buildOrphanedOrders,
    validateOrder,
  ]);

  const calculateFulfilmentPrice = useCallback((
    f : Fulfilment,
    options : {
      quantity? : number | null,
      adjustments? : Adjustment[],
    } = {},
  ) => {
    const price = options.adjustments
      ? f.price
      : Object.values(f.appliedAdjustments).reduce(
        (price, adjustment) => {
          const change = adjustment.currency?.amount
            ? adjustment.currency
            : multiplyCurrency(f.unitPrice, adjustment.factor ?? 0);
          return addCurrency(
            price,
            multiplyCurrency(change, adjustment.count)
          );
        },
        f.price,
      );

    const qty = f.fulfilledQty ?? f.requestedQty;
    return (
      typeof options?.quantity === 'number'
        && (options.quantity !== qty)
    ) ? (qty ? multiplyCurrency(price, (options.quantity)/qty) : price)
      : price;
  }, []);

  const calculateLinePrice = useCallback((
    line : LineItem | {
      id? : number,
      productId : number,
      quantity : number,
      price? : Currency,
    },
    order : DraftOrder | null = null,
    options : {
      quantity? : number | null,
      adjustments? : Adjustment[],
    } = {},
  ) => {
    const lineItemId = line.id;
    const fulfilment = order?.order?.fulfilments
      ? Object.values(order?.order.fulfilments).find((fulfilment) => (
        fulfilment.lineItemId === lineItemId
          && fulfilment.requestedProductId === line.productId
      )) ?? null
      : null;

    if (fulfilment) return calculateFulfilmentPrice(fulfilment, options);
    if (line.price) return multiplyCurrency(
      line.price,
      options.quantity ?? line.quantity,
    )

    const product = products?.[line.productId];
    if (!product) return null;

    const qty = (typeof options?.quantity === 'number')
      ? options.quantity
      : line.quantity;
    return calculateProductPrice(product, {
      quantity : qty,
      adjustments : options?.adjustments,
    });
  }, [products, calculateFulfilmentPrice, calculateProductPrice]);

  useEffect(() => {
    setLineItems(allLineItems
      ? filterIndex(allLineItems, undefined, { dropDeleted : true })
      : null);
  }, [allLineItems]);

  useEffect(() => {
    setOrders(allOrders
      ? filterIndex(allOrders, undefined, { dropDeleted : true })
      : null);
  }, [allOrders]);

  useEffect(() => {
    setHolds(allHolds
      ? filterIndex(allHolds, undefined, { dropDeleted : true })
      : null);
  }, [allHolds]);

  useEffect(() => {
    const addressIds = listRecords(lineItems).reduce((ids, lineItem) => {
      if (lineItem.addressId && !ids.includes(lineItem.addressId))
        ids.push(lineItem.addressId);
      return ids;
    }, [] as number[]);
    const missingAddresses = addressIds.filter(id => !addresses[id]);
    if (!missingAddresses.length) return;

    retrieveAddressesBulk(missingAddresses).then((add) => {
      if (add) dispatchAddresses(add);
    });
  }, [lineItems, addresses, retrieveAddressesBulk]);

  useEffect(() => {
    buildOrders().then(drafts => { if (drafts) setDraftOrders(drafts); }) },
    [buildOrders],
  );

  const context = {
    lineItems,
    holds,
    orders : draftOrders,
    orderAddresses : addresses,
    loaded : lineItemsLoaded
      && ordersLoaded
      && customerLoaded
      && servicesLoaded
      && routesLoaded
      && schedulesLoaded
      && taxesLoaded,
    cacheLineItems,
    cacheOrders,
    dispatchOrderAddresses : dispatchAddresses,
    load,
    createLineItem : newLineItem,
    reloadLineItems,
    refreshLineItems,
    refreshLineItem,
    retrieveLineItems : getLineItems,
    retrieveLineItem : getLineItem,
    updateLineItem : amendLineItem,
    deleteLineItem : removeLineItem,
    bulkCreateLineItems : newBulkLineItems,
    bulkUpdateLineItems : amendBulkLineItems,
    bulkDeleteLineItems : removeBulkLineItems,
    reloadOrders,
    refreshOrders,
    refreshOrder,
    retrieveOrders : getOrders,
    retrieveOrder : getOrder,
    addAdjustmentToOrder : addOrderAdjustment,
    removeAdjustmentFromOrder : removeOrderAdjustment,
    createFulfilment : newFulfilment,
    updateFulfilment : amendFulfilment,
    deleteFulfilment : removeFulfilment,
    bulkCreateFulfilments : bulkNewFulfilments,
    bulkUpdateFulfilments : bulkAmendFulfilments,
    bulkDeleteFulfilments : bulkRemoveFulfilments,
    addAdjustmentToFulfilment : addFulfilmentAdjustment,
    removeAdjustmentFromFulfilment : removeFulfilmentAdjustment,
    createHold : newHold,
    refreshHolds,
    refreshHold,
    retrieveHolds : getHolds,
    retrieveHold : getHold,
    deleteHold : removeHold,
    payOrders : processOrders,
    claimGuestItems,
    createOrderFromLineItem,
    buildOrders,
    buildOrphanedOrders,
    validateOrder,
    calculateFulfilmentPrice,
    calculateLinePrice,
  }

  return (
    <OrderContext.Provider value={context}>
      { children }
    </OrderContext.Provider>
  );
}

export default OrderContext;
