import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import Preface from '#materials/Preface';

interface Style { theme? : Theme; }
interface OrderSectionViewStyle extends Style { split? : boolean; }

const OrderSectionView = styled.div<OrderSectionViewStyle>`
  display: flex;
  margin: 0 0 2rem;
  padding: ${(props) => props.split
     ? props.theme.layout.spacing.small
     : props.theme.layout.spacing.medium} 0 0;
  align-items: center;
  justify-content: space-between;

  border-top:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.border};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &:first-of-type {
    border: 0;
    padding: 0;
  }

  &:last-of-type {
    margin: 0;
  }
`;

const OrderSectionLabel = styled.div<Style>`
  flex: 0 0 15rem;
  margin: 0 ${(props) => props.theme.layout.spacing.small} 0;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    flex: 0 0 auto;
    margin: 0 0 ${(props) => props.theme.layout.spacing.xxsmall};
  }

  span {
    margin: 0;
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
  }
`;

interface OrderSectionContentStyle extends Style {
  noTitle? : boolean;
}

const OrderSectionContent = styled.div<OrderSectionContentStyle>`
  width: 100%;
  flex: 1;

  p {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
    line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
    margin: ${(props) => props.theme.layout.spacing.xxsmall} 0 0;

    &:first-of-type {
      margin: 0;
      font-size: ${(props) => props.noTitle
        ? props.theme.typography.fonts.default.sizes.small
        : props.theme.typography.fonts.default.sizes.medium};
    }
  }
`;

interface OrderSectionProps {
  label? : string;
  noTitle? : boolean;
  children : React.ReactNode;
}

function OrderSection({
  label,
  noTitle = false,
  children,
} : OrderSectionProps) {

  return (
    <OrderSectionView split={label !== undefined}>
      { label && (
        <OrderSectionLabel>
          <Preface size="small">
            { label }
          </Preface>
        </OrderSectionLabel>
      ) }
      <OrderSectionContent noTitle={noTitle}>{ children }</OrderSectionContent>
    </OrderSectionView>
  );
}

export default OrderSection;
