import { useContext } from 'react';

import AreaContext from '#mrktbox/clerk/context/AreaContext';

import useAddressesAPI from '#mrktbox/clerk/hooks/api/useAddressesAPI';

function useAddresses() {
  const context = useContext(AreaContext);

  const { searchAddresses } = useAddressesAPI();

  return {
    ...context,
    loadAreas : context.load,
    areasLoaded : context.loaded,
    searchAddresses,
  };
}

export default useAddresses;
