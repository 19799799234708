import { useCallback } from 'react';

import { Image, Category } from '#mrktbox/clerk/types';

import {
  createCategory as createCategoryAPI,
  retrieveCategories as retrieveCategoriesAPI,
  retrieveCategory as retrieveCategoryAPI,
  updateCategory as updateCategoryAPI,
  deleteCategory as deleteCategoryAPI,
  addSubcategoryToCategory as addSubcategoryToCategoryAPI,
  removeSubcategoryFromCategory as removeSubcategoryFromCategoryAPI,
  createCategoryImageUpload as createCategoryImageUploadAPI,
  pullCategoryImage as pullCategoryImageAPI,
  removeImageFromCategory as removeImageFromCategoryAPI,
} from '#mrktbox/clerk/api/mrktbox/categories';

import useAPI from '#mrktbox/clerk/hooks/api/useAPI';

function useCategoriesAPI() {
  const { processRequest } = useAPI();

  const createCategory = useCallback(async (category : Category) => {
    return await processRequest(
      createCategoryAPI,
      { category },
    )
  }, [processRequest]);

  const retrieveCategories = useCallback(async () => {
    return await processRequest(
      retrieveCategoriesAPI,
      {},
    )
  }, [processRequest]);

  const retrieveCategory = useCallback(async (categoryId : number) => {
    return await processRequest(
      retrieveCategoryAPI,
      { categoryId },
    )
  }, [processRequest]);

  const updateCategory = useCallback(async (category : Category) => {
    return await processRequest(
      updateCategoryAPI,
      { category }
    )
  }, [processRequest]);

  const deleteCategory = useCallback(async (category : Category) => {
    return await processRequest(
      deleteCategoryAPI,
      { category }
    )
  }, [processRequest]);

  const addSubcategoryToCategory = useCallback(
    async (
      category : Category,
      subcategory : Category,
      before? : Category | null,
    ) => {
      return await processRequest(
        addSubcategoryToCategoryAPI,
        { subcategory, category, before },
      )
    },
    [processRequest],
  );

  const removeSubcategoryFromCategory = useCallback(
    async (category : Category, subcategory : Category) => {
      return await processRequest(
        removeSubcategoryFromCategoryAPI,
        { subcategory, category },
      )
    },
    [processRequest],
  );

  const createCategoryImageUpload = useCallback(async (category : Category) => {
    return await processRequest(
      createCategoryImageUploadAPI,
      { category },
    )
  }, [processRequest]);

  const pullCategoryImage = useCallback(
    async (category : Category, image : Image) => {
      return await processRequest(
        pullCategoryImageAPI,
        { category, image },
      )
    },
    [processRequest],
  );

  const removeImageFromCategory = useCallback(
    async (category : Category, image : Image) => {
      return await processRequest(
        removeImageFromCategoryAPI,
        { category, image },
      )
    },
    [processRequest],
  );

  return {
    createCategory,
    retrieveCategories,
    retrieveCategory,
    updateCategory,
    deleteCategory,
    addSubcategoryToCategory,
    removeSubcategoryFromCategory,
    createCategoryImageUpload,
    pullCategoryImage,
    removeImageFromCategory,
  };
}

export default useCategoriesAPI;
