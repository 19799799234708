import { timeScales, roundDateTime } from '#mrktbox/utils';

export function calculateDate(datetime : Date) {
  return roundDateTime(datetime, timeScales.day);
}

export function calculateDates(datetimes : Date[]) {
  const dates = [] as Date[];
  datetimes.forEach(datetime => {
    const date = calculateDate(datetime);
    if (!dates.some((d) => d.getTime() === date.getTime())) dates.push(date);
  });

  dates.sort((a, b) => a.getTime() - b.getTime());
  return dates;
}
