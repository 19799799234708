import styled from '@emotion/styled';

import { Theme } from '#types';

interface ContainerStyle {
  theme? : Theme;
}

const Container = styled.div<ContainerStyle>`
  width: 100%;
  margin: 0 auto;
  max-width: 139rem;
  padding: 0 ${(props) => props.theme.layout.spacing.medium};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    padding: 0 ${(props) => props.theme.layout.spacing.small};
  }
`;

export default Container;
