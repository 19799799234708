import React, { Component, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/react'

import ErrorReport from '#pages/ErrorReport';

import packageJson from '../package.json'

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
  eventId?: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
      errorInfo: undefined,
      eventId: undefined,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error, errorInfo: undefined, eventId: undefined };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ errorInfo });
    Sentry.withScope((scope) => {
      const extras = { version: packageJson.version, ...errorInfo }
      scope.setExtras(extras)
      // TODO: add user info
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    const { hasError, error, errorInfo, eventId } = this.state
    return hasError ? (
      <ErrorReport error={error} errorInfo={errorInfo} eventId={eventId} />
    ) : (
      this.props.children
    )
  }
}

export default ErrorBoundary;
