import { useCallback } from 'react';

import {
  retrieveCounts as retrieveCountsAPI,
  retrieveCount as retrieveCountAPI,
} from '#mrktbox/clerk/api/mrktbox/inventory';

import useProcessRequest from '#mrktbox/clerk/hooks/api/useProcessRequest';

function useInventoryAPI() {
  const processRequest = useProcessRequest();

  const retrieveCounts = useCallback(async (since? : Date) => {
    return await processRequest(
      retrieveCountsAPI,
      { since },
    );
  }, [processRequest]);

  const retrieveCount = useCallback(async (countId : number) => {
    return await processRequest(
      retrieveCountAPI,
      { countId },
    );
  }, [processRequest]);

  return {
    retrieveCounts,
    retrieveCount,
  };
}

export default useInventoryAPI;
