import { useCallback, useContext } from 'react';

import {
  Customer,
  ContactInfo,
  CustomerUser,
} from '#mrktbox/clerk/types';

import CustomerContext from '#mrktbox/clerk/context/CustomersContext';

import useUsers from '#mrktbox/clerk/hooks/useUsers';
import useCustomersAPI from '#mrktbox/clerk/hooks/api/useCustomersAPI';

function defaultContactInfo() : ContactInfo {
  return {
    name : '',
    nickname : '',
    email : '',
    phone : '',
    emailNotifications : false,
    smsNotifications : false,
  };
}

function useCustomers() {
  const {
    customers,
    loaded,
    load,
    refreshCustomers,
    refreshCustomer,
    createCustomer,
    retrieveCustomers,
    retrieveCustomer,
    deleteCustomer,
    createCustomerIntegration,
    syncCustomerIntegration,
    deleteCustomerIntegration,
    pullCustomerIntegration,
    pushCustomerIntegration,
    importCustomer,
    searchCustomers,
    regenerateCustomers,
    createContactInfo,
    updateContactInfo,
    deleteContactInfo,
    addAddressToCustomer,
    removeAddressFromCustomer,
  } = useContext(CustomerContext);
  const { token } = useUsers();
  const { retrieveExternalCustomers } = useCustomersAPI();

  const newCustomer = useCallback(
    async (
      customer : Customer,
      { contactInfo, user } : {
        contactInfo? : ContactInfo,
        user? : CustomerUser,
      },
    ) => {
      const [
        newCustomer,
        results,
      ] = await createCustomer(customer, { contactInfo, user });
      return { customer : newCustomer, taken : results.invalidUser };
    },
    [createCustomer],
  );

  const renewCustomers = useCallback(async () => {
    if (!token) return null;
    return await refreshCustomers();
  }, [refreshCustomers, token]);

  const getCustomers = useCallback(async () => {
    if (!token) return null;
    return await retrieveCustomers();
  }, [retrieveCustomers, token]);

  const preload = useCallback(() => {
    if (!token) return;
    load();
  }, [load, token]);

  return {
    customers,
    loaded,
    load : preload,
    defaultContactInfo,
    refreshCustomers : renewCustomers,
    refreshCustomer,
    createCustomer : newCustomer,
    retrieveCustomers : getCustomers,
    retrieveCustomer,
    deleteCustomer,
    retrieveExternalCustomers,
    createCustomerIntegration,
    syncCustomerIntegration,
    deleteCustomerIntegration,
    pullCustomerIntegration,
    pushCustomerIntegration,
    importCustomer,
    searchCustomers,
    regenerateCustomers,
    regenerateCustomerIndex : regenerateCustomers,
    createContactInfo,
    updateContactInfo,
    deleteContactInfo,
    addAddressToCustomer,
    removeAddressFromCustomer,
  }
}

export default useCustomers;
