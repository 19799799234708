import { Address, isAddress } from './addresses';
import { Image, ImageUpload, isImage, isImageUpload } from './images';

export interface LocationImageUpload extends ImageUpload {
  locationId : number;
}

export interface Location {
  id? : number;
  name : string;
  description? : string;
  images? : { [key : number] : Image };
  serviceIds? : number[];
  addressId : number | null;
  address? : Address | null;
  integrations? : {
    [id : number] : LocationIntegration;
  };
}

export interface LocationIntegration {
  id? : number;
  locationId : number;
  externalId : string;
  integrationId : number;
  data : {
    [field : string] : string | number | boolean | null;
  };
}

export interface ExternalLocation {
  externalId : string;
  integrationId : number;
  name : string;
  data : object;
}

export interface ServiceChannel {
  id? : number;
  name : string;
  active : boolean;
  visible : boolean;
  requireCheckout : boolean;
  description : string;
  serviceIds? : number[];
}

export interface Service {
  id? : number;
  name : string;
  serviceChannelId : number | null;
  locationId : number | null;
  leadTime : number | null;
  cutoffTime : number | null;
}

export function isService(service : any): service is Service {
  return (
    typeof service === 'object' &&
    typeof service.id === 'number' &&
    typeof service.name === 'string' &&
    (service.serviceChannelId === null ||
      typeof service.serviceChannelId === 'number') &&
    (service.locationId === null ||
      typeof service.locationId === 'number') &&
    (service.leadTime === null || typeof service.leadTime === 'number') &&
    (service.cutoffTime === null || typeof service.cutoffTime === 'number')
  )
}

export function isServiceChannel(
  serviceChannel : any
): serviceChannel is ServiceChannel {
  return (
    typeof serviceChannel === 'object' &&
    typeof serviceChannel.id === 'number' &&
    typeof serviceChannel.name === 'string' &&
    typeof serviceChannel.active === 'boolean' &&
    typeof serviceChannel.visible === 'boolean' &&
    typeof serviceChannel.requireCheckout === 'boolean' &&
    typeof serviceChannel.description === 'string' &&
    Array.isArray(serviceChannel.serviceIds) &&
    serviceChannel.serviceIds.every((id : any) => typeof id === 'number')
  )
}

export function isLocation(
  location : any
): location is Location {
  return (
    typeof location === 'object' &&
    typeof location.id === 'number' &&
    typeof location.name === 'string' &&
    (location.description === undefined ||
      typeof location.description === 'string') &&
    (location.images === undefined ||
      Object.keys(location.images).every((key : any) => (
        typeof key === 'string' &&
        !isNaN(Number(key)) &&
        isImage(location.images[key])
      ))) &&
    Array.isArray(location.serviceIds) &&
    location.serviceIds.every((id : any) => typeof id === 'number') &&
    (!location.address || isAddress(location.address)) &&
    (location.addressId === null ||
      typeof location.addressId === 'number') &&
    (location.integrations === undefined ||
      Object.keys(location.integrations).every((key : any) => (
        typeof key === 'string' &&
        !isNaN(Number(key)) &&
        isLocationIntegration(location.integrations[key])
      ))
    )
  )
}

export function isLocationIntegration(
  locationIntegration : any,
) : locationIntegration is LocationIntegration {
  return (
    typeof locationIntegration === 'object' &&
    typeof locationIntegration.id === 'number' &&
    typeof locationIntegration.locationId === 'number' &&
    typeof locationIntegration.externalId === 'string' &&
    typeof locationIntegration.integrationId === 'number' &&
    typeof locationIntegration.data === 'object' &&
    Object.keys(locationIntegration.data).every(
      key => typeof key === 'string'
    ) &&
    Object.values(locationIntegration.data).every(
      value => typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean' ||
        value === null
    )
  )
}

export function isExternalLocation(
  externalLocation : any
): externalLocation is ExternalLocation {
  return (
    typeof externalLocation === 'object' &&
    typeof externalLocation.externalId === 'string' &&
    typeof externalLocation.integrationId === 'number' &&
    typeof externalLocation.name === 'string' &&
    typeof externalLocation.data === 'object'
  )
}

export function isLocationImageUpload(
  locationImageUpload : any
): locationImageUpload is LocationImageUpload {
  return (
    typeof locationImageUpload === 'object' &&
    typeof locationImageUpload.locationId === 'number' &&
    isImageUpload(locationImageUpload)
  )
}
