import { useCallback } from 'react';

import {
  Assembly,
  Collection,
  Selection,
  Product,
  Tag,
  LineItem,
} from '#mrktbox/clerk/types';

import {
  retrieveCustomProducts as retrieveCustomProductsAPI,
  createAssembly as createAssemblyAPI,
  retrieveAssemblies as retrieveAssembliesAPI,
  retrieveAssembly as retrieveAssemblyAPI,
  updateAssembly as updateAssemblyAPI,
  deleteAssembly as deleteAssemblyAPI,
  addAssemblyToProduct as addAssemblyToProductAPI,
  removeAssemblyFromProduct as removeAssemblyFromProductAPI,
  createCollection as createCollectionAPI,
  retrieveCollections as retrieveCollectionsAPI,
  retrieveCollection as retrieveCollectionAPI,
  updateCollection as updateCollectionAPI,
  deleteCollection as deleteCollectionAPI,
  addCollectionDefault as addCollectionDefaultAPI,
  removeCollectionDefault as removeCollectionDefaultAPI,
  addTagToCollection as addTagToCollectionAPI,
  removeTagFromCollection as removeTagFromCollectionAPI,
  addCollectionToAssembly as addCollectionToAssemblyAPI,
  removeCollectionFromAssembly as removeCollectionFromAssemblyAPI,
  createSelection as createSelectionAPI,
  retrieveSelections as retrieveSelectionsAPI,
  retrieveSelection as retrieveSelectionAPI,
  updateSelection as updateSelectionAPI,
  deleteSelection as deleteSelectionAPI,
  bulkCreateSelections as bulkCreateSelectionsAPI,
  bulkRetrieveSelections as bulkRetrieveSelectionsAPI,
  bulkUpdateSelections as bulkUpdateSelectionsAPI,
  bulkDeleteSelections as bulkDeleteSelectionsAPI,
} from '#mrktbox/clerk/api/mrktbox/options';

import useProcessRequest from '#mrktbox/clerk/hooks/api/useProcessRequest';

function useOptionsAPI() {
  const processRequest = useProcessRequest();

  const retrieveCustomProducts = useCallback(async () => {
    return await processRequest(
      retrieveCustomProductsAPI,
      {},
    )
  }, [processRequest]);

  const createAssembly = useCallback(async (assembly : Assembly) => {
    return await processRequest(
      createAssemblyAPI,
      { assembly },
    )
  }, [processRequest]);

  const retrieveAssemblies = useCallback(async () => {
    return await processRequest(
      retrieveAssembliesAPI,
      {},
    )
  }, [processRequest]);

  const retrieveAssembly = useCallback(async (assemblyId : number) => {
    return await processRequest(
      retrieveAssemblyAPI,
      { assemblyId },
    )
  }, [processRequest]);

  const updateAssembly = useCallback(async (assembly : Assembly) => {
    return await processRequest(
      updateAssemblyAPI,
      { assembly }
    )
  }, [processRequest]);

  const deleteAssembly = useCallback(async (assembly : Assembly) => {
    return await processRequest(
      deleteAssemblyAPI,
      { assembly }
    )
  }, [processRequest]);

  const addAssemblyToProduct = useCallback(async (
    product : Product,
    assembly : Assembly,
    options? : { before? : Assembly },
  ) => {
    return await processRequest(
      addAssemblyToProductAPI,
      { product, assembly, before : options?.before }
    )
  }, [processRequest]);

  const removeAssemblyFromProduct = useCallback(
    async (product : Product, assembly : Assembly) => {
      return await processRequest(
        removeAssemblyFromProductAPI,
        { product, assembly }
      )
    }, [processRequest],
  );

  const createCollection = useCallback(async (collection : Collection) => {
    return await processRequest(
      createCollectionAPI,
      { collection },
    )
  }, [processRequest]);

  const retrieveCollections = useCallback(async () => {
    return await processRequest(
      retrieveCollectionsAPI,
      {},
    )
  }, [processRequest]);

  const retrieveCollection = useCallback(async (collectionId : number) => {
    return await processRequest(
      retrieveCollectionAPI,
      { collectionId },
    )
  }, [processRequest]);

  const updateCollection = useCallback(async (collection : Collection) => {
    return await processRequest(
      updateCollectionAPI,
      { collection }
    )
  }, [processRequest]);

  const deleteCollection = useCallback(async (collection : Collection) => {
    return await processRequest(
      deleteCollectionAPI,
      { collection }
    )
  }, [processRequest]);

  const addCollectionDefault = useCallback(async (
    collection : Collection,
    product : Product,
    quantity : number = 1,
  ) => {
    return await processRequest(
      addCollectionDefaultAPI,
      { collection, product, quantity }
    )
  }, [processRequest]);

  const removeCollectionDefault = useCallback(async (
    collection : Collection,
    product : Product,
  ) => {
    return await processRequest(
      removeCollectionDefaultAPI,
      { collection, product }
    )
  }, [processRequest]);

  const addTagToCollection = useCallback(
    async (collection : Collection, tag : Tag) => {
      return await processRequest(
        addTagToCollectionAPI,
        { collection, tag }
      )
    }, [processRequest],
  );

  const removeTagFromCollection = useCallback(
    async (collection : Collection, tag : Tag) => {
      return await processRequest(
        removeTagFromCollectionAPI,
        { collection, tag }
      )
    }, [processRequest],
  );

  const addCollectionToAssembly = useCallback(
    async (assembly : Assembly, collection : Collection) => {
      return await processRequest(
        addCollectionToAssemblyAPI,
        { assembly, collection }
      )
    }, [processRequest],
  );

  const removeCollectionFromAssembly = useCallback(
    async (assembly : Assembly, collection : Collection) => {
      return await processRequest(
        removeCollectionFromAssemblyAPI,
        { assembly, collection }
      )
    }, [processRequest],
  );

  const createSelection = useCallback(async (selection : Selection) => {
    return await processRequest(
      createSelectionAPI,
      { selection },
    )
  }, [processRequest]);

  const retrieveSelections = useCallback(async (
    options? : { since? : Date },
  ) => {
    return await processRequest(
      retrieveSelectionsAPI,
      { since : options?.since },
    )
  }, [processRequest]);

  const retrieveSelection = useCallback(async (selectionId : number) => {
    return await processRequest(
      retrieveSelectionAPI,
      { selectionId },
    )
  }, [processRequest]);

  const updateSelection = useCallback(async (selection : Selection) => {
    return await processRequest(
      updateSelectionAPI,
      { selection }
    )
  }, [processRequest]);

  const deleteSelection = useCallback(async (selection : Selection) => {
    return await processRequest(
      deleteSelectionAPI,
      { selection }
    )
  }, [processRequest]);

  const bulkCreateSelections = useCallback(async (
    selections : Selection[],
    lineItems? : LineItem[],
  ) => {
    return await processRequest(
      bulkCreateSelectionsAPI,
      { selections, lineItems },
    )
  }, [processRequest]);

  const bulkRetrieveSelections = useCallback(
    async (selectionIds : number[]) => {
      return await processRequest(
        bulkRetrieveSelectionsAPI,
        { selectionIds },
      )
    },
    [processRequest],
  );

  const bulkUpdateSelections = useCallback(
    async (selections : Selection[], options? : {
        lineItems? : LineItem[],
        deleteSelections? : Selection[],
      }) => {
      return await processRequest(
        bulkUpdateSelectionsAPI,
        {
          lineItems : options?.lineItems,
          selections,
          deleteSelections : options?.deleteSelections,
        },
      )
    },
    [processRequest],
  );

  const bulkDeleteSelections = useCallback(
    async (selections : Selection[]) => {
      return await processRequest(
        bulkDeleteSelectionsAPI,
        { selections },
      )
    },
    [processRequest],
  );

  return {
    retrieveCustomProducts,
    createAssembly,
    retrieveAssemblies,
    retrieveAssembly,
    updateAssembly,
    deleteAssembly,
    addAssemblyToProduct,
    removeAssemblyFromProduct,
    createCollection,
    retrieveCollections,
    retrieveCollection,
    updateCollection,
    deleteCollection,
    addCollectionDefault,
    removeCollectionDefault,
    addTagToCollection,
    removeTagFromCollection,
    addCollectionToAssembly,
    removeCollectionFromAssembly,
    createSelection,
    retrieveSelections,
    retrieveSelection,
    updateSelection,
    deleteSelection,
    bulkCreateSelections,
    bulkRetrieveSelections,
    bulkUpdateSelections,
    bulkDeleteSelections,
  };
}

export default useOptionsAPI;
