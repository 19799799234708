import { useCallback, useEffect, useReducer, useState } from 'react';

import {
  ActionType,
  DataReducer,
  DataIndex,
  DataStore,
} from '#mrktbox/clerk/types';
import { actionTypes } from '#mrktbox/clerk/utils/data';

export { actionTypes };
export type { ActionType };

function generateStore<RT>({
   data
} : {
  data? : DataIndex<RT> | null;
}) : DataStore<RT> {
  return {
    data : data ?? {},
    timestamp : new Date(),
    storageKey : '',
  };
}

function getDataStore<RT>({
  storageKey,
  deserializer,
} : {
  storageKey : string,
  deserializer? : (data : any) => RT | null,
}) : DataStore<RT> | null {
  let store : string | null = null;

  try { store = localStorage.getItem(storageKey); }
  catch { }

  if (store === null) return null;

  const { data, timestamp } = JSON.parse(store);
  return {
    data : deserializer
      ? Object.entries(data).reduce(
        (acc : DataIndex<RT>, [key, value] : [string, any]) => {
          return { ...acc, [key] : deserializer(value) };
        },
        {},
      )
      : data,
    timestamp : new Date(timestamp),
    storageKey,
  };
}

function setDataStore<RT>({
  store,
  serializer,
} : {
  store : DataStore<RT>,
  serializer? : (data : RT) => any,
}) {
  if (!store.storageKey) return;

  const serialized = {
    timestamp : store.timestamp,
    data : serializer
      ? Object.entries(store.data).reduce((acc, [key, value]) => {
        const serialized = value ? serializer(value) : null;
        if (serialized === null) return acc;
        return { ...acc, [key] : serialized };
      }, {})
      : store.data,
  };

  try { localStorage.setItem(store.storageKey, JSON.stringify(serialized)); }
  catch { }
}

function reduceDataStore<RT>(
  state : DataStore<RT> | null,
  action : { data : DataIndex<RT>, type? : ActionType },
) {
  let result = {
    ...state?.data,
    ...action.data,
  }
  switch (action.type) {
    case actionTypes.set:
      result = { ...action.data };
      break;
    case actionTypes.remove:
      if (state === null) break;
      result = Object.entries(state).reduce((acc, [key, value]) => {
        if (key in action.data) return acc;
        else return { ...acc, [key] : value };
      }, {});
      break;
  }

  const resetTimestamp = action.type === actionTypes.set
    || action.type === actionTypes.update
    || !state?.timestamp
    || !state?.data;
  const timestamp = resetTimestamp
    ? new Date()
    : new Date(state?.timestamp);

  return {
    data : result,
    timestamp,
    storageKey : state?.storageKey ?? '',
  };
}

interface useDataProps<RT> {
  initData? : DataIndex<RT> | null;
  storageKey? : string;
  serializer? : (data : RT) => any;
  deserializer? : (data : any) => RT | null;
}

function useStorage<RT>({
  initData,
  storageKey,
  serializer,
  deserializer,
} : useDataProps<RT> = {} ) {
  const [loaded, setLoaded] = useState(false);

  const initStore = (!loaded && storageKey)
    ? getDataStore<RT>({ storageKey, deserializer })
    : null;
  if (initStore) setLoaded(true);

  const [dataStore, dispatchData] = useReducer<DataReducer<RT>>(
    reduceDataStore<RT>,
    initStore
      ?? (initData ? generateStore<RT>({ data : initData }) : null),
  );

  const clearCache = useCallback(() => {
    dispatchData({ data : {}, type : actionTypes.set });
  }, []);

  useEffect(() => {
    if (dataStore === null) return;
    setDataStore({
      store : { ...dataStore, storageKey : storageKey ?? '' },
      serializer,
    });
  }, [dataStore, serializer]);

  return {
    data : dataStore?.data ?? null,
    dispatch : dispatchData,
    lastUpdated : dataStore?.timestamp,
    clearCache,
  };
}

export default useStorage;
