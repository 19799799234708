import React from 'react';
import styled from '@emotion/styled';

import { Product } from '#mrktbox/types';

import { Theme } from '#types';

import ProductImage from '#components/products/ProductImage';

interface Style { theme? : Theme; }

const ProductImageView = styled.div<Style>`
  display: none;
  position: relative;
  width: 100%;
  height: 40%;
  min-height: 24rem;
  z-index: 10;
  padding: 0;

  div {
    height: 40%;
    min-height: 24rem;
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    display: block;
  }
`;

interface ProductItemImageProps {
  product : Product | null;
  imageRef?: React.RefObject<HTMLDivElement>;
}

function ProductItemImage({
  product,
  imageRef,
} : ProductItemImageProps) {

  return (
    <ProductImageView ref={imageRef} >
      <ProductImage product={product} variant="full" />
    </ProductImageView>
  );
}

export default ProductItemImage;
