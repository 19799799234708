import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { useAuth, useNavigation } from '#mrktbox';

import { Theme } from '#types';

import useConfig from '#hooks/useConfig';

import useModal from '#hooks/useModal';
import useSidebar from '#hooks/useSidebar';

import {
  CreditCard,
  Info,
  LogOut,
  MapPin,
  PlusCircle,
  ShoppingBag,
  UserCircle,
} from '#materials/icons';

import Login from '#components/auth/Login';

interface Style {
  theme? : Theme;
}

const NavView = styled.nav<Style>`
  width: 28rem;
  max-width: 100%;
  height: 100%;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-color: ${(props) => props.theme.palette.background.fill};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    width: ${(props) => props.theme.view.breakpoints.mobile};
  }
`;

const NavContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const NavItems = styled.nav<Style>`
  width: 100%;
  padding: ${(props) => props.theme.layout.spacing.small} 0;
`;

const NavItem = styled.button<Style>`
  display: flex;
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  justify-content: flex-start;
  align-items: center;

  text-align: left;
  line-height: 0;

  color: ${(props) => props.theme.palette.background.text.primary};

  &:hover {
    color: ${(props) => props.theme.palette.background.text.hover};
    }
  }
`;

const NavIcon = styled.span<Style>`
  display: block;
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 ${(props) => props.theme.layout.spacing.small} 0 0;
  flex-shrink: 0;
  line-height: 0;
`;

const NavTitle = styled.span<Style>`
  display: block;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
`;

function Nav() {
  const { content } = useConfig();
  const { navigate } = useNavigation();
  const { openModal } = useModal();
  const { closeSidebar } = useSidebar();
  const { checkAuth, logout } = useAuth();

  const closeGo = useCallback((evt : any, path : string) => {
    evt.target.blur();
    evt.preventDefault();
    evt.stopPropagation();
    closeSidebar();
    if (path.startsWith('http')) {
      window.open(path, '_blank');
      return;
    }

    navigate(path);
  }, [navigate, closeSidebar])

  const handleLogin = useCallback(() => {
    openModal(<Login />);
    closeSidebar();
  }, [openModal, closeSidebar]);

  const handleLogout = useCallback(() => {
    navigate('/', );
    closeSidebar();
    logout();
  }, [logout, closeSidebar, navigate]);

  const isAuthenticated = checkAuth({ redirect: null });

  const navButtons = isAuthenticated ? [
    {
      icon: <UserCircle />,
      title: 'Account',
      path: '/profile/',
    },
    {
      icon: <ShoppingBag />,
      title: 'Orders',
      path: '/orders/',
    },
    {
      icon: <MapPin />,
      title: 'Addresses',
      path: '/addresses/',
    },
    {
      icon: <CreditCard />,
      title: 'Credit Cards',
      path: '/credit-cards',
    },
  ] : [
    {
      icon: <PlusCircle />,
      title: 'Sign Up',
      path: '/signup/',
    },
  ];

  if (content.links.help) {
    navButtons.push({
      icon: <Info />,
      title: 'Help',
      path: content.links.help,
    });
  }

  return (
    <NavView>
      <NavContainer>
        <NavItems>
          { navButtons.map((i) => (
            <NavItem key={i.path} onClick={(evt) => closeGo(evt, i.path)}>
              <NavIcon>{i.icon}</NavIcon>
              <NavTitle>{i.title}</NavTitle>
            </NavItem>
          )) }
        </NavItems>
          { isAuthenticated
            ? <NavItem onClick={handleLogout}>
                <NavIcon><LogOut /></NavIcon>
                <NavTitle>Sign Out</NavTitle>
              </NavItem>
            : <NavItem onClick={handleLogin}>
                <NavIcon><UserCircle /></NavIcon>
                <NavTitle>Log In</NavTitle>
              </NavItem>
          }
      </NavContainer>
    </NavView>
  );
}

export default Nav;
