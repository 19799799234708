import { Address, isAddress, Area, isArea } from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

const ADDRESSES_PATH = 'geolocation/addresses/';
const ADDRESS_SEARCH_PATH = `${ADDRESSES_PATH}search/`;
const AREAS_PATH = 'geolocation/areas/';

interface AddressProps {
  address : Address;
}

export function parseAddress(address : any) : Address {
  if (!isAddress(address))
      throw new TypeError('Address is not an address');

  return address;
}

export function parseAddresses(addresses : any) : { [id : number] : Address } {
  const parsedAddresses = {} as { [id : number] : Address };
  for (const addressId in addresses) {
    if (typeof addressId !== 'string')
      throw new TypeError('Address info id is not a string');

    const id = parseInt(addressId);
    if (isNaN(id))
      throw new TypeError('Address id is not a number');

    if (!isAddress(addresses[id]))
      throw new TypeError('Address is not an address');

    parsedAddresses[id] = parseAddress(addresses[id]);
  }
  return parsedAddresses;
}

export function parseArea(area : any) : Area {
  if (!isArea(area))
    throw new TypeError('Area is not an area');

  return area;
}

export function parseAreas(areas : any) : { [id : number] : Area } {
  const parsedAreas = {} as { [id : number] : Area };
  for (const areaId in areas) {
    if (typeof areaId !== 'string')
      throw new TypeError('Area info id is not a string');

    const id = parseInt(areaId);
    if (isNaN(id)) throw new TypeError('Area id is not a number');

    parsedAreas[id] = parseArea(areas[id]);
  }
  return parsedAreas;
}

export async function createAddress({ address } : AddressProps) {
  const response = await request(
    getUrl(ADDRESSES_PATH),
    methods.post,
    {
      address : {
        street : address.street,
        city : address.city,
        postal : address.postal,
      }
    }
  );

  try {
    return parseAddress(response.address);
  } catch {
    throw new DeserializationError(
      'Could not deserialize address',
      response,
    );
  }
}

export async function retrieveAddressesBulk({
  addressIds,
} : {
  addressIds : number[];
}, options? : RequestOptions) : Promise<{ [id : number] : Address }> {
  if (!addressIds.length) return {};
  if (addressIds.length > 500) {
    return {
      ...await retrieveAddressesBulk({ addressIds: addressIds.slice(0, 500) }),
      ...await retrieveAddressesBulk({ addressIds: addressIds.slice(500) }),
    }
  }

  const response = await request(
    getUrl(
      `${ADDRESSES_PATH}bulk/`,
      addressIds.map((id) => ['id', id.toString()]),
    ),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseAddresses(response.addresses);
  } catch {
    throw new DeserializationError(
      'Could not deserialize address list',
      response,
    );
  }
}

export async function retrieveAddress(
  { addressId } : { addressId : number },
  options? : RequestOptions,
) {
  const response = await request(
    `${getUrl(ADDRESSES_PATH)}${addressId}`,
    methods.get,
    undefined,
    options,
  );

  try {
    return parseAddress(response.address);
  } catch {
    throw new DeserializationError(
      'Could not deserialize address',
      response,
    );
  }
}

export async function searchAddresses({ query } : { query : string }) {
  const response = await request(
    getUrl(
      ADDRESS_SEARCH_PATH,
      { query },
    ),
    methods.get,
  );

  try {
    return parseAddresses(response.searchResults);
  } catch {
    throw new DeserializationError(
      'Could not deserialize address list',
      response,
    );
  }
}

export async function deleteAddress({ address } : AddressProps) {
  const response = await request(
    `${getUrl(ADDRESSES_PATH)}${address.id}`,
    methods.delete,
  );

  try {
    return parseAddress(response.address);
  } catch {
    throw new DeserializationError(
      'Could not deserialize address',
      response,
    );
  }
}

export async function createArea({ area } : { area : Area }) {
  const response = await request(
    getUrl(AREAS_PATH),
    methods.post,
    { area }
  );

  try {
    return parseArea(response.area);
  } catch {
    throw new DeserializationError(
      'Could not deserialize area',
      response,
    );
  }
}

export async function retrieveAreas() {
  const response = await request(
    getUrl(AREAS_PATH),
    methods.get,
  );

  try {
    return parseAreas(response.areas);
  } catch {
    throw new DeserializationError(
      'Could not deserialize area list',
      response,
    );
  }
}

export async function retrieveArea({ areaId } : { areaId : number }) {
  const response = await request(
    `${getUrl(AREAS_PATH)}${areaId}`,
    methods.get,
  );

  try {
    return parseArea(response.area);
  } catch {
    throw new DeserializationError(
      'Could not deserialize area',
      response,
    );
  }
}

export async function updateArea ({ area } : { area : Area }) {
  const response = await request(
    `${getUrl(AREAS_PATH)}${area.id}`,
    methods.put,
    { area }
  );

  try {
    return parseArea(response.area);
  } catch {
    throw new DeserializationError(
      'Could not deserialize area',
      response,
    );
  }
}

export async function deleteArea({ area } : { area : Area }) {
  const response = await request(
    `${getUrl(AREAS_PATH)}${area.id}`,
    methods.delete,
  );

  try {
    return parseArea(response.area);
  } catch {
    throw new DeserializationError(
      'Could not deserialize area',
      response,
    );
  }
}
