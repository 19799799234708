import { Address, isAddress } from './addresses';

export interface ContactInfo {
  id? : number;
  name : string;
  nickname : string;
  email : string;
  phone? : string;
  customerId? : number;
  emailNotifications : boolean;
  smsNotifications : boolean;
}

export interface CustomerUser {
  id : number;
  username : string;
}

export interface Customer {
  id? : number;
  defaultName? : string;
  defaultEmail? : string;
  contactInfo : {
    [id : number] : ContactInfo;
  };
  addresses? : {
    [id : number] : Address;
  };
  integrations? : {
    [id : number] : CustomerIntegration;
  }
  users? : {
    [id : number] : CustomerUser;
  }
}

export interface CustomerIntegration {
  id? : number;
  customerId : number;
  externalId : string;
  integrationId : number;
  name : string;
  email : string;
  phone : string;
  data : {
    [field : string] : string | number | boolean | null;
  };
}

export interface ExternalCustomer {
  externalId : string;
  integrationId : number;
  name : string | null;
  email : string | null;
  phone : string | null;
}

export function isContactInfo(contactInfo : any) : contactInfo is ContactInfo {
  return (
    typeof contactInfo.name === 'string' &&
    typeof contactInfo.nickname === 'string' &&
    typeof contactInfo.email === 'string' &&
    typeof contactInfo.emailNotifications === 'boolean' &&
    typeof contactInfo.smsNotifications === 'boolean' &&
    (contactInfo.phone === undefined ||
      typeof contactInfo.phone === 'string') &&
    (contactInfo.customerId === undefined ||
      typeof contactInfo.customerId === 'number')
  );
}

export function isExternalCustomer(
  externalCustomer : any
) : externalCustomer is ExternalCustomer {
  return (
    typeof externalCustomer === 'object' &&
    typeof externalCustomer.externalId === 'string' &&
    typeof externalCustomer.integrationId === 'number' &&
    (externalCustomer.name === null ||
      typeof externalCustomer.name === 'string') &&
    (externalCustomer.email === null ||
      typeof externalCustomer.email === 'string') &&
    (externalCustomer.phone === null ||
      typeof externalCustomer.phone === 'string')
  );
}

export function isCustomerIntegration(
  customerIntegration : any
) : customerIntegration is CustomerIntegration {
  return (
    (customerIntegration.id === null ||
      typeof customerIntegration.id === 'number') &&
    (customerIntegration.customerId === null ||
      typeof customerIntegration.customerId === 'number') &&
    typeof customerIntegration.externalId === 'string' &&
    typeof customerIntegration.integrationId === 'number' &&
    typeof customerIntegration.name === 'string' &&
    typeof customerIntegration.email === 'string' &&
    typeof customerIntegration.phone === 'string' &&
    typeof customerIntegration.data === 'object' &&
    Object.keys(customerIntegration.data).every(
      key => typeof key === 'string'
    ) &&
    Object.values(customerIntegration.data).every(
      value => typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean' ||
        value === null
    )
  );
}

export function isCustomer(customer : any) : customer is Customer {

  return (
    (customer.id === undefined ||
      typeof customer.id === 'number') &&
    (customer.defaultName === undefined ||
      typeof customer.defaultName === 'string') &&
    typeof customer.contactInfo === 'object' &&
    Object.keys(customer.contactInfo).every(key => (
      typeof key === 'string' &&
      !isNaN(parseInt(key))
    )) &&
    Object.values(customer.contactInfo).every(
      value => isContactInfo(value)
    ) &&
    typeof customer.addresses === 'object' &&
    Object.keys(customer.addresses).every(key => (
      typeof key === 'string' &&
      !isNaN(parseInt(key))
    )) &&
    Object.values(customer.addresses).every(
      value => isAddress(value)
    ) &&
    (customer.integrations === undefined || (
      typeof customer.integrations === 'object' &&
      Object.keys(customer.integrations).every(key => (
        typeof key === 'string' &&
        !isNaN(parseInt(key))
      )) &&
      Object.values(customer.integrations).every(
        value => isCustomerIntegration(value))
    )),
    (customer.users === undefined || (
      typeof customer.users === 'object' &&
      Object.keys(customer.users).every(key => (
        typeof key === 'string' &&
        !isNaN(parseInt(key))
      )) &&
      Object.values(customer.users).every(
        (value : any) => (
          typeof value === 'object' &&
          typeof value?.id === 'number' &&
          typeof value?.username === 'string'
        )
      )
    ))
  );
}

export function isCustomerUser(user : any) : user is CustomerUser {
  return (
    (user !== undefined || typeof user.id === 'number') &&
    typeof user.username === 'string'
  );
}
