import styled from '@emotion/styled';

import { Theme } from '#types';

import Preface from '#materials/Preface';
import Headline from '#components/page/Headline';

interface Style { theme? : Theme; }

const SidebarHeaderView = styled.div<Style>`
  width: 100%;
  padding: ${(props) => props.theme.layout.spacing.small};

  color: ${(props) => props.theme.palette.background.text.primary};
  background-color: ${(props) => props.theme.palette.background.fill};

  p {
    line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};

    span {
      padding: 0;
    }
  }

  p + p {
    margin: ${(props) => props.theme.layout.spacing.xsmall} 0 0;
  }

  div {
    margin: ${(props) => props.theme.layout.spacing.small} auto 0;

    p {
      color: ${(props) => props.theme.palette.background.text.alert};
    }
  }
`;

const SidebarHeaderTitle = styled(Headline)`
  margin: 0 0 ${(props) => props.theme.layout.spacing.xsmall} -0;
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.xlarge};
`;

interface SidebarHeaderProps {
  title : string;
  preface? : string;
  children : React.ReactNode;
}

function SidebarHeader({ title, preface, children } : SidebarHeaderProps) {
  return (
    <SidebarHeaderView>
      { preface && (<Preface>{ preface }</Preface>) }
      <SidebarHeaderTitle as="h2">
        { title }
      </SidebarHeaderTitle>
      { children }
    </SidebarHeaderView>
  );
}

export default SidebarHeader;
