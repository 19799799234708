import { SearchIndex, SearchEntry } from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

function parseEntry(entry : any) : SearchEntry {
  const tokens = [] as string[];
  const scores = {} as { [id : string | number] : number };

  for (const token of entry.tokens) {
    if (typeof token !== 'string')
      throw new TypeError('Token is not a string');
    tokens.push(token);
  }

  for (const id in entry.scores) {
    if (typeof id !== 'string' && typeof id !== 'number')
      throw new TypeError('Score ID is not a string or number');
    if (typeof entry.scores[id] !== 'number') {
      throw new TypeError('Score is not a number');
    }
    scores[id] = entry.scores[id];
  }

  return { tokens, scores };
}

function parseIndex(index : any) : SearchIndex {
  const parsedEntries = [] as SearchEntry[];
  for (const entry of index) {
    parsedEntries.push(parseEntry(entry));
  }
  return { entries : parsedEntries };
}

interface IndexProps {
  url : string;
}

export async function retrieveIndex(
  { url } : IndexProps,
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(url),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseIndex(response.searchIndex);
  } catch (err) {
    console.error(err);
    throw new DeserializationError(
      'Could not deserialize search index',
      response,
    );
  }
}

export async function regenerateIndex(
  { url } : IndexProps,
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(url),
    methods.post,
    {},
    options,
  );

  return !!response;
}
