import React, { createContext, useCallback } from 'react';

import {
  Address,
  ContactInfo,
  Customer,
  CustomerUser,
  CustomerIntegration,
  ExternalCustomer,
  Integration,
} from '#mrktbox/clerk/types';

import useData, {
  DataIndex,
  useLoad,
  useChange,
  useChangeWithReturn,
  useRefreshIndex,
  useRefresh,
  useRetrieveIndex,
  useRetrieve,
  useDelete,
  useRelate,
} from '#mrktbox/clerk/hooks/useData';
import useSearch, {  SearchResults } from '#mrktbox/clerk/hooks/useSearch';
import useCustomersAPI from '#mrktbox/clerk/hooks/api/useCustomersAPI';

export type CustomerIndex = DataIndex<Customer>;

const CUSTOMER_SERACH_URL = 'customers/index/';
const INDEX_PERMISSIONS = ['read_all_customers'];
const MAX_AGE = 1000 * 60 * 60;

const CustomersContext = createContext({
  customers : null as DataIndex<Customer> | null,
  loaded : false,
  load : () => {},
  refreshCustomers : async () => null as CustomerIndex | null,
  refreshCustomer : async (id : number) => null as Customer | null,
  createCustomer : async (
    customer : Customer,
    { contactInfo, user } : {
      contactInfo? : ContactInfo,
      user? : CustomerUser,
    },
  ) => [
    null, { invalidUser : null }
  ] as [Customer, { invalidUser : boolean | null }]
    | [null, { invalidUser : boolean | null }],
  retrieveCustomers : async () => null as CustomerIndex | null,
  retrieveCustomer : async (id : number) => null as Customer | null,
  deleteCustomer : async (customer : Customer) => null as boolean | null,
  importCustomer : async (
    integration : Integration,
    externalCustomer : ExternalCustomer,
  ) => null as Customer | null,
  createCustomerIntegration : async (
    customer : Customer,
    externalCustomer : ExternalCustomer,
  ) => null as Customer | null,
  deleteCustomerIntegration : async (
    customerIntegration : CustomerIntegration,
  ) => null as Customer | null,
  syncCustomerIntegration : async (
    customerIntegration : CustomerIntegration,
  ) => null as Customer | null,
  pushCustomerIntegration : async (
    customerIntegration : CustomerIntegration,
  ) => null as Customer | null,
  pullCustomerIntegration : async (
    customerIntegration : CustomerIntegration,
  ) => null as Customer | null,
  searchCustomers : (query : string) => null as SearchResults<Customer> | null,
  regenerateCustomers : async () => null as boolean | null,
  createContactInfo : async (
    customer : Customer,
    contactInfo : ContactInfo,
  ) => null as ContactInfo | null,
  updateContactInfo :
    async (contactInfo : ContactInfo) => null as ContactInfo | null,
  deleteContactInfo :
    async (contactInfo : ContactInfo) => null as boolean | null,
  addAddressToCustomer : async (
    customer : Customer,
    address : Address,
  ) => null as boolean | null,
  removeAddressFromCustomer : async (
    customer : Customer,
    address : Address,
  ) => null as boolean | null,
});

interface CustomersProviderProps {
  children : React.ReactNode;
}

export function CustomersProvider({
  children,
} : CustomersProviderProps) {
  const {
    createCustomer,
    retrieveCustomers,
    retrieveCustomer,
    deleteCustomer,
    importCustomer,
    createCustomerIntegration,
    deleteCustomerIntegration,
    syncCustomerIntegration,
    pushCustomerIntegration,
    pullCustomerIntegration,
    createContactInfo,
    updateContactInfo,
    deleteContactInfo,
    addAddressToCustomer,
    removeAddressFromCustomer,
  } = useCustomersAPI();

  const {
    data : customers,
    dispatch : dispatchCustomers,
    lastUpdated,
  } = useData<Customer>({ storageKey : 'customers' });

  const newCustomer = useChangeWithReturn({
    dispatch : dispatchCustomers,
    change : createCustomer,
  });
  const refreshCustomers = useRefreshIndex({
    dispatch : dispatchCustomers,
    retrieve : retrieveCustomers,
  });
  const refreshCustomer = useRefresh({
    dispatch : dispatchCustomers,
    retrieve : retrieveCustomer,
  });
  const getCustomers = useRetrieveIndex({
    data : customers,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshCustomers,
  });
  const getCustomer = useRetrieve({
    data : customers,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshCustomer,
  });
  const removeCustomer = useDelete({
    dispatch : dispatchCustomers,
    delete : deleteCustomer,
  });

  const dispatchContactCustomer = useCallback(
    (action : { data : DataIndex<ContactInfo> }) => {
      if (!action.data) return;
      Object.values(action.data).forEach((contactInfo) => {
        if (contactInfo?.customerId) refreshCustomer(contactInfo.customerId);
      });
    },
    [refreshCustomer],
  );

  const dispatchContactInfo = useCallback(
    (action : { data : DataIndex<ContactInfo> }) => {
      if (!action.data) return;
      Object.values(action.data).forEach((contactInfo) => {
        if (contactInfo?.customerId) refreshCustomer(contactInfo.customerId);
      });
    },
    [refreshCustomer],
  );

  const newContactInfo = useRelate({
    dispatch : dispatchContactInfo,
    relate : createContactInfo,
  });
  const amendContactInfo = useChange({
    dispatch : dispatchContactCustomer,
    change : updateContactInfo,
  });
  const removeContactInfo = useDelete({
    dispatch : dispatchContactCustomer,
    delete : deleteContactInfo,
  });

  const addAddress = useRelate({
    dispatch : dispatchCustomers,
    relate : addAddressToCustomer,
    callback : refreshCustomers,
  });
  const removeAddress = useRelate({
    dispatch : dispatchCustomers,
    relate : removeAddressFromCustomer,
    callback : refreshCustomers,
  });

  const loadCustomer = useRelate({
    dispatch : dispatchCustomers,
    relate : importCustomer,
  });
  const newCustomerIntegration = useRelate({
    dispatch : dispatchCustomers,
    relate : createCustomerIntegration,
  });
  const removeCustomerIntegration = useChange({
    dispatch : dispatchCustomers,
    change : deleteCustomerIntegration,
  });
  const refreshCustomerIntegration = useChange({
    dispatch : dispatchCustomers,
    change : syncCustomerIntegration,
  });
  const exportCustomerIntegration = useChange({
    dispatch : dispatchCustomers,
    change : pushCustomerIntegration,
  });
  const importCustomerIntegration = useChange({
    dispatch : dispatchCustomers,
    change : pullCustomerIntegration,
  });

  const {
    search : searchCustomers,
    regenerate : regenerateCustomers,
  } = useSearch<Customer>({
    url : CUSTOMER_SERACH_URL,
    records : customers,
    requirePermissions : INDEX_PERMISSIONS,
  });

  const { loaded, load } = useLoad({
    data : customers,
    loader : refreshCustomers,
  });

  const context = {
    customers,
    loaded,
    load,
    refreshCustomers,
    refreshCustomer,
    createCustomer : newCustomer,
    retrieveCustomers : getCustomers,
    retrieveCustomer : getCustomer,
    deleteCustomer : removeCustomer,
    importCustomer : loadCustomer,
    createCustomerIntegration : newCustomerIntegration,
    deleteCustomerIntegration : removeCustomerIntegration,
    syncCustomerIntegration : refreshCustomerIntegration,
    pushCustomerIntegration : exportCustomerIntegration,
    pullCustomerIntegration : importCustomerIntegration,
    searchCustomers,
    regenerateCustomers,
    createContactInfo : newContactInfo,
    updateContactInfo : amendContactInfo,
    deleteContactInfo : removeContactInfo,
    addAddressToCustomer : addAddress,
    removeAddressFromCustomer : removeAddress,
  }

  return (
    <CustomersContext.Provider value={context}>
      { children }
    </CustomersContext.Provider>
  );
}

export default CustomersContext;
