import React, { useCallback, useState } from 'react'

import {
  isCustomer,
  isContactInfo,
  isCustomerUser,
} from '#mrktbox/types';

import { useForm, useCustomers, FormProvider } from '#mrktbox';

import useLogin from '#hooks/useLogin';

import Registration, {
  RegistrationData,
} from '#components/profile/RegistrationForm';

function validateCustomerInfo(customer : RegistrationData) {
  if (customer.name && customer.email) return null;
  return {
    ...(!customer.name &&
      { name : 'Name required' }),
    ...(!customer.email &&
      { email : 'Email required' }),
  };
}

function RegistrationFormControl() {
  const { state } = useForm<RegistrationData>();
  const { createCustomer } = useCustomers();
  const { login } = useLogin();

  const [error, setError] = useState('');

  const handleSave = useCallback(async () => {
    if (!state) return;

    const customer = { contactInfo : {} };
    const contactInfo = {
      name : state.name,
      nickname : state.nickname || state.name,
      email : state.email,
      phone : state.phone,
      emailNotifications : state.emailNotifications,
      smsNotifications : state.smsNotifications,
    };
    const user = { username : state.email, password : state.password };
    if (
      !isCustomer(customer) ||
      !isContactInfo(contactInfo) ||
      !isCustomerUser(user)
    ) {
      return;
    }

    const { customer : newCustomer, taken } = await createCustomer(
      customer,
      { contactInfo, user }
    );

    if (newCustomer) {
      await login(state.email ?? '', state.password ?? '');
    } else {
      setError(taken
        ? 'Email already registered. Please login or reset your password'
        : 'Something went wrong. Please try again or contact us for help');
    }
  }, [state, createCustomer, login]);

  return (
    <Registration onSubmit={handleSave} error={error} />
  );
}

function RegistrationForm() {
  const { defaultContactInfo } = useCustomers();

  const [validators] = useState([validateCustomerInfo]);
  const [init] = useState<RegistrationData>(defaultContactInfo());

  return (
    <FormProvider<RegistrationData>
      init={init}
      validators={validators}
    >
      <RegistrationFormControl />
    </FormProvider>
  );
}

export default RegistrationForm;
