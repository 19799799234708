import React, { createContext } from 'react';

import { Service, RevenueCentre } from '#mrktbox/clerk/types';

import useData, {
  DataIndex,
  useLoad,
  useRefreshIndex,
  useRefresh,
  useRetrieveIndex,
  useRetrieve,
  useChange,
  useDelete,
  useRelate,
} from '#mrktbox/clerk/hooks/useData';
import usePaymentsAPI from '#mrktbox/clerk/hooks/api/usePaymentsAPI';

export type RevenueCentreIndex = DataIndex<RevenueCentre>;

const MAX_AGE = 1000 * 60 * 60;

const RevenueCentreContext = createContext({
  revenueCentres: null as DataIndex<RevenueCentre> | null,
  loaded: false,
  load: () => {},
  refreshRevenueCentres : async () => null as RevenueCentreIndex | null,
  refreshRevenueCentre : async (id : number) => null as RevenueCentre | null,
  retrieveRevenueCentres : async () => null as RevenueCentreIndex | null,
  retrieveRevenueCentre : async (id : number) => null as RevenueCentre | null,
  createRevenueCentre :
    async (revenueCentre : RevenueCentre) => null as RevenueCentre | null,
  updateRevenueCentre :
    async (revenueCentre : RevenueCentre) => null as RevenueCentre | null,
  deleteRevenueCentre :
    async (revenueCentre : RevenueCentre) => null as boolean | null,
  addServiceToRevenueCentre : async (
    revenueCentre : RevenueCentre,
    service : Service
  ) => null as boolean | null,
  removeServiceFromRevenueCentre : async (
    revenueCentre : RevenueCentre,
    service : Service
  ) => null as boolean | null,
});

interface RevenueCentreProviderProps {
  children : React.ReactNode;
}

export function RevenueCentreProvider({
  children,
} : RevenueCentreProviderProps) {
  const {
    createRevenueCentre,
    retrieveRevenueCentres,
    retrieveRevenueCentre,
    updateRevenueCentre,
    deleteRevenueCentre,
    addServiceToRevenueCentre,
    removeServiceFromRevenueCentre,
  } = usePaymentsAPI();

  const {
    data : revenueCentres,
    dispatch : dispatchRevenueCentres,
    lastUpdated,
  } = useData<RevenueCentre>({ storageKey : 'revenueCentres' });

  const newRevenueCentre = useChange({
    dispatch : dispatchRevenueCentres,
    change : createRevenueCentre,
  });
  const refreshRevenueCentres = useRefreshIndex({
    dispatch : dispatchRevenueCentres,
    retrieve : retrieveRevenueCentres,
  });
  const refreshRevenueCentre = useRefresh({
    dispatch : dispatchRevenueCentres,
    retrieve : retrieveRevenueCentre,
  });
  const getRevenueCentres = useRetrieveIndex({
    data : revenueCentres,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshRevenueCentres,
  });
  const getRevenueCentre = useRetrieve({
    data : revenueCentres,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshRevenueCentre,
  });
  const amendRevenueCentre = useChange({
    dispatch : dispatchRevenueCentres,
    change : updateRevenueCentre,
  });
  const removeRevenueCentre = useDelete({
    dispatch : dispatchRevenueCentres,
    delete : deleteRevenueCentre,
  });
  const addService = useRelate({
    dispatch : dispatchRevenueCentres,
    relate : addServiceToRevenueCentre,
    callback : refreshRevenueCentres,
  });
  const removeService = useRelate({
    dispatch : dispatchRevenueCentres,
    relate : removeServiceFromRevenueCentre,
    callback : refreshRevenueCentres,
  });

  const { loaded, load } = useLoad({
    data : revenueCentres,
    loader : refreshRevenueCentres,
  });

  const context = {
    revenueCentres,
    loaded,
    load,
    createRevenueCentre : newRevenueCentre,
    refreshRevenueCentres,
    refreshRevenueCentre,
    retrieveRevenueCentres : getRevenueCentres,
    retrieveRevenueCentre : getRevenueCentre,
    updateRevenueCentre : amendRevenueCentre,
    deleteRevenueCentre : removeRevenueCentre,
    addServiceToRevenueCentre : addService,
    removeServiceFromRevenueCentre : removeService,
  }

  return (
    <RevenueCentreContext.Provider value={context}>
      { children }
    </RevenueCentreContext.Provider>
  );
}

export default RevenueCentreContext;
