import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { useCards } from '#mrktbox';

import { Theme } from '#types';

import { ThemeProvider } from '#context/ThemeContext';

import Row from '#materials/Row';
import Box from '#materials/Box';
import CreditCards from '#materials/icons/CreditCards';

import { CreditCard } from '#mrktbox/types';

import ButtonLink from '#materials/ButtonLink';

interface Style { theme? : Theme; }

const CreditCardBox = styled(Box)<Style>`
  margin: 0 0 ${(props) => props.theme.layout.spacing.small};
`;

const CardIcon = styled.div<Style>`
  @media (max-width: 300px) {
    display: none;
  }
`

const CardNumber = styled.p<Style>`
  white-space: nowrap;
  font-family: ${(props) => props.theme.typography.fonts.mono.family};
`;

const CardNumberPrefix = styled.span<Style>`
  @media (max-width: 250px) {
    display: none;
  }
`;

interface CreditCardDetailsProps {
  creditCards : CreditCard[];
  buttonTitle? : string;
}

function CreditCardDetails({ creditCards } : CreditCardDetailsProps) {
  const { deleteCreditCard } = useCards();

  const handleDeleteCreditCard = useCallback(
    (creditCard : CreditCard) => async () => {
      await deleteCreditCard(creditCard);
    },
    [deleteCreditCard],
  );

  return !!creditCards && (
    creditCards.map((creditCard) => {
      let iconKey : 'amex' | 'mastercard' | 'visa' | undefined;
      switch (creditCard.brand) {
        case 'AMERICAN_EXPRESS':
          iconKey = 'amex';
          break;
        case 'VISA':
          iconKey = 'visa';
          break;
        case 'MASTERCARD':
          iconKey = 'mastercard';
          break;
      }

      return (
        <ThemeProvider themeKey='card' key={creditCard.id}>
          <CreditCardBox>
            <Row
              key={`credit-card-${creditCard.id}`}
              icon={(
                <CardIcon>
                  <CreditCards brand={iconKey} />
                </CardIcon>
              )}
              content={(
                <>
                  <p className="title">{creditCard.brand}</p>
                  <CardNumber>
                    <CardNumberPrefix>**** **** ***</CardNumberPrefix>
                    * {creditCard.last4}
                  </CardNumber>
                  <p>
                    <ButtonLink
                      onClick={handleDeleteCreditCard(creditCard)}
                    >
                      remove
                    </ButtonLink>
                  </p>
                </>
              )}
            />
          </CreditCardBox>
        </ThemeProvider>
      );
    })
  );
}

export default CreditCardDetails;
