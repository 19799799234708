import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Category, isCategory } from '#mrktbox/types';

import { Theme } from '#types';

import Container from '#materials/Container';

import CategoryItem from '#components/categories/CategoryItem';

interface Style { theme? : Theme; }
interface CategoriesListStyle extends Style {
  hasTop? : boolean;
}

const CategoriesListView = styled.div<CategoriesListStyle>`
  ${(props) => !props.hasTop
    && `margin-top: ${props.theme.layout.spacing.medium};`};
`;

const CategoriesListTitle = styled.h1<Style>`
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
  margin-bottom: ${(props) => props.theme.layout.spacing.small};

  color: ${(props) => props.theme.palette.background.text.primary};
  font-family: ${(props) => props.theme.typography.fonts.brand.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.xlarge};
  font-weight:
    ${(props) => props.theme.typography.fonts.default.weights.regular};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.large};
  }
`;

export const CategoryListCategories = styled.div<Style>`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${(props) => props.theme.layout.spacing.xsmall};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    gap: 1rem;
  }
`;

interface CategoryCard {
  name : string;
  href? : string;
  onClick? : () => void;
  image? : string;
  icon? : React.ReactElement<{ size : number }>;
}
interface CategoryImageCard extends CategoryCard { image : string; }
interface CategoryIconCard extends CategoryCard {
  icon : React.ReactElement<{ size : number }>;
}

export type ListItem = Category | CategoryImageCard | CategoryIconCard;
interface CategoriesListProps {
  categories : ListItem[];
  title? : string;
  onClick? : (category : Category) => void;
}

function CategoriesList({
  categories,
  title,
  onClick,
} : CategoriesListProps) {
  const handleClick = useCallback((category : Category) => () => {
    if (onClick) onClick(category);
  }, [onClick]);

  return (
    <>
      <Container>
        <CategoriesListView id="full-menu" hasTop={!title}>
          { title && (<CategoriesListTitle>
              { title }
            </CategoriesListTitle>
          ) }
          <CategoryListCategories>
            { categories.map((card, index) => {
              if (isCategory(card)) {
                return (
                  <CategoryItem
                    key={card.name}
                    category={card}
                    onClick={handleClick(card)}
                  />
                )
              }
              if (card.image) {
                return (
                <CategoryItem
                  key={card.name}
                  name={card.name}
                  image={card.image}
                  href={card.href}
                  onClick={card.onClick}
                />
              )}
              return (
                <CategoryItem
                  key={card.name}
                  name={card.name}
                  icon={card.icon}
                  href={card.href}
                  onClick={card.onClick}
                />
              )
            }) }
          </CategoryListCategories>
        </CategoriesListView>
      </Container>
    </>
  );
}

export default CategoriesList;
