import React from 'react'
import styled from '@emotion/styled'

import { Theme } from '#types'

import { X } from '#materials/icons'

interface Style { theme? : Theme; }

const ButtonClearView = styled.button<Style>`
  display: inline-flex;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.palette.background.text.primary};

  border:
    0.1rem
    solid
    ${(props) => props.theme.palette.background.text.primary};
    opacity: 1;
  border-radius: 50%;

  &:hover {
    color: ${(props) => props.theme.palette.background.text.hover};
    border-color: ${(props) => props.theme.palette.background.text.hover};
  }

  * {
    position: relative;
  }
`;

interface ClearButtonProps {
  ariaLabel? : string;
  onClick? : () => void;
  disabled? : boolean;
}

function ClearButton ({
  ariaLabel,
  onClick,
  disabled,
} : ClearButtonProps) {
  const handleClick = (evt : React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (!disabled && onClick) onClick();
  };

  return (
    <ButtonClearView
      type="button"
      aria-label={ariaLabel}
      onClick={handleClick}
      disabled={disabled}
    >
      <X />
    </ButtonClearView>
  );
}

export default ClearButton;
