import React from 'react';
import styled from '@emotion/styled';

import { Link } from '#mrktbox';

import { Theme } from '#types';

import { ThemeProvider } from '#context/ThemeContext';

import useConfig from '#hooks/useConfig';

import Container from '#materials/Container';

interface Style {
  theme? : Theme;
}

const FooterView = styled.footer<Style>`
  position: relative;
  width: 100%;
  z-index: 1;

  color: ${(props) => props.theme.palette.background.text.primary};
  background-color: ${(props) => props.theme.palette.background.fill};

  border-top:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.border};
`;

const FooterContainer = styled.div<Style>`
  display: flex;
  min-height: 28rem;
  padding: ${(props) => props.theme.layout.spacing.large} 0
    ${(props) => props.theme.layout.spacing.medium};
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    min-height: 32rem;
  }
`;

const FooterContent = styled.div<Style>`
  display: flex;
  width: min-content;
  margin: 0 0 ${(props) => props.theme.layout.spacing.medium};
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

const FooterLogo = styled.a<Style>`
  display: inline-block;
  width: min-content;
  margin: 0
    ${(props) => props.theme.layout.spacing.small}
    ${(props) => props.theme.layout.spacing.medium}
    0;
  overflow: hidden;

  color: ${(props) => props.theme.palette.background.text.primary};

  font-family: ${(props) => props.theme.typography.fonts.brand.family};
  font-size: ${(props) => props.theme.typography.fonts.brand.sizes.xxxlarge};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.palette.background.text.hover};
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    margin-bottom: ${(props) => props.theme.layout.spacing.small};
  }

  img {
    display: inline-block;
    width: auto;
    height: 100%;
    pointer-events: none;
  }
`;

const FooterLinks = styled.ul<Style>`
  display: flex;
  width: 100%;
  max-width: calc(100vw - ${(props) => props.theme.layout.spacing.large});
  margin:
    ${(props) => props.theme.layout.spacing.xsmall}
    0
    ${(props) => props.theme.layout.spacing.small};
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: ${(props) => props.theme.layout.spacing.small};
  overflow: hidden;

  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};

  li {
    display: block;

    &:last-of-type {
      margin-right: 0;
    }

    a {
      display: inline-block;
      max-width: calc(100vw - ${(props) => props.theme.layout.spacing.large});
      white-space: nowrap;
      overflow: hidden;

      color: ${(props) => props.theme.palette.background.text.primary};

      text-overflow: ellipsis;
      text-decoration: none;

      &:hover {
        color: ${(props) => props.theme.palette.background.text.hover};
      }
    }
  }
`;

const CCorpLogo = styled.div<Style>`
  max-width: 14rem;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    text-align: left;
  }

  img {
    height: 9rem;
    margin-bottom: 1rem;
  }
`;

const Footer = ({ hasRouter = true }) => {
  const { brand, content } = useConfig();

  const brandName = brand.name;
  const home = content.links.home;
  const about = content.links.about;
  const team = content.links.team;
  const blog = content.links.blog;
  const giftCards = content.links.giftCards;
  const terms = content.links.terms;
  const privacy = content.links.privacy;
  const pricing = content.links.pricing;
  const help = content.links.help;

  const logo = content.logos.footerRight.src;
  const logoLink = content.logos.footerRight.link;
  const logoAlt = content.logos.footerRight.alt;

  return (
    <ThemeProvider themeKey='footer'>
      <FooterView role="contentinfo">
        <Container>
          <FooterContainer>
            <FooterContent >
              <FooterLogo
                href={home}
                rel="noopener noreferrer"
              >
                { brandName }
              </FooterLogo>
              { hasRouter && (
                <nav>
                  <FooterLinks>
                    { about && (
                      <li>
                        <Link to={about}>About Us</Link>
                      </li>
                    ) }
                    { team && (
                      <li>
                        <Link to={team}>Our Team</Link>
                      </li>
                    ) }
                    { blog && (
                      <li>
                        <Link to={blog}>Blog</Link>
                      </li>
                    ) }
                    { giftCards && (
                      <li>
                        <Link to={giftCards}>Gift Cards</Link>
                      </li>
                    ) }
                    { help && (
                      <li>
                        <Link to={help}>Help</Link>
                      </li>
                    ) }
                  </FooterLinks>
                  <FooterLinks>
                    { terms && (
                      <li>
                        <Link to={terms}>Terms & Conditions</Link>
                      </li>
                    ) }
                    { privacy && (
                      <li>
                        <Link to={privacy}>Privacy</Link>
                      </li>
                    ) }
                    { pricing && (
                      <li>
                        <Link to={pricing}>Pricing Policy</Link>
                      </li>
                    ) }
                  </FooterLinks>
                </nav>
              ) }
            </FooterContent>
            { logo && (
              <CCorpLogo>
                <a href={logoLink ?? '#'}>
                  <img src={logo} alt={logoAlt}/>
                </a>
              </CCorpLogo>
            ) }
          </FooterContainer>
        </Container>
      </FooterView>
    </ThemeProvider>
  );
}

export default Footer;
