import styled from '@emotion/styled'
import { Theme } from '#types';

interface Style {
  theme? : Theme;
}

const PageContainer = styled.div<Style>`
  label: PageContainer;
  width: 100%;
  max-width: 139rem;
  margin: ${(props) => props.theme.layout.spacing.large} auto;
  padding: 0 ${(props) => props.theme.layout.spacing.medium};
  color: ${(props) => props.theme.palette.background.text.primary};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.spacing.medium} auto;
    padding: 0 ${(props) => props.theme.layout.spacing.small};
  }
`;

export default PageContainer;
