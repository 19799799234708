
export interface Broadcast {
  id? : number;
  message : string;
  link : string;
  href : string;
  active : boolean;
}

export function isBroadcast(broadcast : any) : broadcast is Broadcast {
  return (
    typeof broadcast === 'object'
      && (broadcast.id === undefined || typeof broadcast.id === 'number')
      && typeof broadcast.message === 'string'
      && typeof broadcast.link === 'string'
      && typeof broadcast.href === 'string'
      && typeof broadcast.active === 'boolean'
  );
}
