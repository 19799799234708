import React from 'react';
import { Routes, Route } from '#mrktbox';

import CatalogueLayout from '#layouts/Catalogue';
import OrderingLayout from '#layouts/Ordering';
import ProfileLayout from '#layouts/Profile';

import AddressPage from '#pages/Addresses';
import CatalogueIndex from '#pages/Catalogue';
import CatalogueSearch from '#pages/CatalogueSearch';
import CategoryProducts from '#pages/CategoryProducts';
import CreditCardPage from '#pages/CreditCards';
import LocationsPage from '#pages/Locations';
import OrderPage from '#pages/Order';
import OrderTypesPage from '#pages/OrderTypes';
import PasswordReset from '#pages/PasswordReset';
import ProductPage from '#pages/Product';
import ProfilePage from '#pages/Profile';
import SignUpPage from '#pages/SignUp';

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={(<CatalogueLayout />)}>
        <Route path="/" element={(<CatalogueIndex />)} />
        <Route path="/category/:categoryId?" element={(<CategoryProducts />)} />
        <Route path="/product/:productId?" element={(<ProductPage/>)} />
        <Route path="/search/" element={(<CatalogueSearch />)} />
      </Route>
      <Route element={(<OrderingLayout />)}>
        <Route path="/locations/" element={(<LocationsPage />)} />
        <Route path="/order-types/" element={(<OrderTypesPage />)} />
      </Route>
      <Route element={(<ProfileLayout />)}>
        <Route path="/addresses/" element={(<AddressPage />)} />
        <Route path="/credit-cards/" element={(<CreditCardPage />)} />
        <Route path="/orders/" element={(<OrderPage />)} />
        <Route path="/orders/:orderId" element={(<OrderPage />)} />
        <Route path="/reset-password/" element={(<PasswordReset />)} />
        <Route path="/profile/" element={(<ProfilePage />)} />
        <Route path="/signup/" element={(<SignUpPage />)} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
