
export interface Tag {
  id? : number;
  name : string;
  productIds : number[];
  availableChannelIds : number[];
}

export function isTag(tag : any) : tag is Tag {
  return (
    (tag.id === undefined || typeof tag.id === 'number') &&
    typeof tag.name === 'string' &&
    Array.isArray(tag.productIds) &&
    tag.productIds.every((id : any) => typeof id === 'number') &&
    Array.isArray(tag.availableChannelIds) &&
    tag.availableChannelIds.every((id : any) => typeof id === 'number')
  );
}
