import React, { createContext, useCallback, useState, useEffect } from 'react';

import { Count } from '#mrktbox/clerk/types';

import useData, {
  DataIndex,
  useLoad,
} from '#mrktbox/clerk/hooks/useData';
import useCache from '#mrktbox/clerk/hooks/useDataCache';
import useInventoryAPI from '#mrktbox/clerk/hooks/api/useInventoryAPI';

import { filterIndex } from '#mrktbox/clerk/utils/data';

export type CountIndex = DataIndex<Count>;

const MAX_AGE = 1000 * 60 * 5;

const InventoryContext = createContext({
  counts : null as CountIndex | null,
  loaded : false,
  load : () => {},
  refreshCounts : async () => null as CountIndex | null,
  refreshCount : async (id : number) => null as Count | null,
  retrieveCounts : async () => null as CountIndex | null,
  retrieveCount : async (id : number) => null as Count | null,
});

interface InventoryProviderProps {
  children : React.ReactNode;
}

export function InventoryProvider({ children } : InventoryProviderProps) {
  const {
    retrieveCounts,
    retrieveCount,
  } = useInventoryAPI();

  const {
    data : allCounts,
    dispatch : dispatchCounts,
    lastUpdated,
  } = useData<Count>({ storageKey : 'inventoryCounts' });

  const [counts, setCounts] = useState<CountIndex | null>(null);

  const stale = lastUpdated !== undefined
    && (new Date().getTime() - lastUpdated.getTime()) > MAX_AGE;

  const adjustCounts = useCallback(
    (args : any) => ({ since : lastUpdated, ...args }),
    [lastUpdated],
  );

  const refreshCounts = useCache({
    process : retrieveCounts,
    dispatch : dispatchCounts,
    adjustment : adjustCounts,
    refresh : true,
    isLoader : true,
  })
  const refreshCount = useCache({
    process : retrieveCount,
    dispatch : dispatchCounts,
    refresh : true,
    isLoader : true,
    dropNull : true,
  });
  const getCounts = useCache({
    process : retrieveCounts,
    dispatch : dispatchCounts,
    adjustment : adjustCounts,
    data : allCounts,
    stale,
    refresh : true,
    isLoader : true,
  });
  const getCount = useCache({
    process : retrieveCount,
    dispatch : dispatchCounts,
    data : allCounts,
    stale,
    isLoader : true,
    dropNull : true,
  });

  const { loaded, load } = useLoad({
    data : counts,
    timeout : MAX_AGE,
    loader : refreshCounts,
  });

  useEffect(() => {
    if (!allCounts) setCounts(null);
    else setCounts(filterIndex(allCounts, undefined, { dropDeleted : true }));
  }, [allCounts]);

  const context = {
    counts,
    loaded,
    load,
    refreshCounts,
    refreshCount,
    retrieveCounts : getCounts,
    retrieveCount : getCount,
  };

  return (
    <InventoryContext.Provider value={context}>
      {children}
    </InventoryContext.Provider>
  );
}


export default InventoryContext;
