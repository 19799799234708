import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import SkipLink from '#materials/SkipLink';

interface Style {
  theme? : Theme;
}

interface HeaderViewStyle extends Style {
  stuck : boolean;
  border? : boolean;
  maxWidth : string;
}

const HeaderContainer = styled.nav``;

const HeaderView = styled.div<HeaderViewStyle>`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: ${(props) => props.theme.view.nav.height.default};
  max-width: ${(props) => props.maxWidth};
  z-index: 50;
  padding: 0 ${(props) => props.theme.layout.spacing.medium};
  justify-content: space-between;
  align-items: center;

  background-color: ${(props) => props.theme.palette.background.fill};
  box-shadow: ${(props) => props.stuck
    ? props.theme.layout.boxShadow.outer.map((bs) => (`
      ${bs.x} ${bs.y} ${bs.blur} ${bs.spread} ${bs.colour}
    `)).join(', ') : 'none'
  };

  border: 0;
  border-style: solid;
  border-bottom-width: ${(props) => props.theme.layout.border.width};
  border-color: ${(props) => props.border
    ? props.theme.palette.border
    : 'transparent'};

  transition: box-shadow 0.2s ease-in-out;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    height: ${(props) => props.theme.view.nav.height.mobile};
    padding: 0 ${(props) => props.theme.layout.spacing.xsmall};
  }
`;

const HeaderTitle = styled.div<Style>`
  display: flex;
  width: 0;
  margin: 1rem;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;

  > span {
    display: block;
    max-width: 26rem;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: ${(props) => props.theme.palette.background.text.primary};
  }

  @media (max-width: 280px) {
    display: none;
  }
`;

const HeaderNav = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
`;

interface HeaderProps {
  left? : JSX.Element;
  title? : string | JSX.Element;
  right? : JSX.Element;
  border? : boolean;
  maxWidth? : string;
  style? : React.CSSProperties;
}

const Header = ({
  left,
  title,
  right,
  border,
  maxWidth = '100%',
  style,
} : HeaderProps) => {
  const header = useRef<HTMLElement>(null);
  const [stuck, setStuck] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (header.current) {
        setStuck(header.current.getBoundingClientRect().top < 0)
      }
    };

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    };
  }, [])

  return (
    <HeaderContainer
      ref={header}
      role="navigation"
      aria-label="Primary Navigation"
    >
      <HeaderView
        stuck={stuck}
        border={border}
        maxWidth={maxWidth}
        style={style}
      >
        <SkipLink />
        <HeaderNav>{ left }</HeaderNav>
        { title && (
          <HeaderTitle>
            <span>{ title }</span>
          </HeaderTitle>
        ) }
        <HeaderNav>{ right }</HeaderNav>
      </HeaderView>
    </HeaderContainer>
  )
}

export default Header
