
export interface User {
  id : number;
  username : string;
  permissions : string[];
}

export interface Auth extends User {
  token : string;
}

export function isUser(user : any) : user is User {
  return (
    typeof user === 'object' &&
    typeof user.id === 'number' &&
    typeof user.username === 'string' &&
    Array.isArray(user.permissions) &&
    user.permissions.every((permission : any) => typeof permission === 'string')
  );
}
