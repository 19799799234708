import { Broadcast, isBroadcast } from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

const BROADCASTS_PATH = 'notifications/broadcasts/';

function parseBroadcast(broadcast : any) : Broadcast {
  if (!isBroadcast(broadcast))
    throw new TypeError('Broadcast is not a Broadcast Object')
  return broadcast;
}

function parseBroadcasts(broadcasts : any) {
  const parsedBroadcasts : { [id : number] : Broadcast } = {};
  for (const broadcastId in broadcasts) {
    if (typeof broadcastId !== 'string')
      throw new TypeError('Broadcast id is not a string');

    const id = parseInt(broadcastId);
    if (isNaN(id)) throw new TypeError('Broadcast id is not a number');

    parsedBroadcasts[id] = parseBroadcast(broadcasts[id]);
  }

  return parsedBroadcasts;
}

export async function retrieveBroadcasts(
  input : {},
  options? : RequestOptions,
) : Promise<{ [id : number] : Broadcast }> {
  const response = await request(
    getUrl(BROADCASTS_PATH),
    methods.GET,
    undefined,
    options,
  );

  try {
    return parseBroadcasts(response.broadcasts);
  } catch {
    throw new DeserializationError(
      'Could not deserialize broadcasts',
      response,
    );
  }
}

export async function retrieveBroadcast(
  { broadcastId } : { broadcastId : number },
  options? : RequestOptions,
) : Promise<Broadcast> {
  const response = await request(
    getUrl(BROADCASTS_PATH + broadcastId),
    methods.GET,
    undefined,
    options,
  );

  try {
    return parseBroadcast(response.broadcast);
  } catch {
    throw new DeserializationError(
      'Could not deserialize broadcast',
      response,
    );
  }
}
