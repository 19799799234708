import React from 'react';

interface HamburgerProps {
  size?: number;
  strokeWidth?: number;
}

function Hamburger({ size, strokeWidth } : HamburgerProps) {

  return (
    <svg
      width={size}
      viewBox="0 0 20 11"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth || 2}
      strokeLinecap="square"
      overflow="visible"
    >
      <line x1="0.5" y1="0.5" x2="19.5" y2="0.5" />
      <line x1="0.5" y1="5.5" x2="19.5" y2="5.5" />
      <line x1="0.5" y1="10.5" x2="19.5" y2="10.5" />
    </svg>
  );
}

export default Hamburger;
