import { Tag, isTag } from './tags';

export interface Tax extends Tag {
  rate : number;
  integrations? : { [id : number] : TaxIntegration };
}

export interface TaxIntegration {
  id? : number;
  taxId : number;
  externalId : string;
  integrationId : number;
  data : {
    [field : string] : string | number | boolean | null;
  };
}

export interface ExternalTax {
  externalId : string;
  integrationId : number;
  name : string;
  rate : number;
}

export function isTax(tax : Tax) : tax is Tax {
  return (
    typeof tax === 'object' &&
    typeof tax.rate === 'number' &&
    (tax.integrations === undefined || (
      typeof tax.integrations === 'object' &&
      Object.keys(tax.integrations).every(key => (
        typeof key === 'string' &&
        !isNaN(parseInt(key)) &&
        isTaxIntegration(tax.integrations?.[parseInt(key)])
      )))) &&
    isTag(tax)
  )
}

export function isTaxIntegration(
  taxIntegration : any
) : taxIntegration is TaxIntegration {
  return (
    typeof taxIntegration === 'object' &&
    (taxIntegration.id === null ||
      typeof taxIntegration.id === 'number') &&
    typeof taxIntegration.taxId === 'number' &&
    typeof taxIntegration.externalId === 'string' &&
    typeof taxIntegration.integrationId === 'number' &&
    typeof taxIntegration.data === 'object' &&
    Object.keys(taxIntegration.data).every(
      key => typeof key === 'string'
    )
  );
}

export function isExternalTax(tax : any) : tax is ExternalTax {
  return (
    typeof tax === 'object' &&
    typeof tax.externalId === 'string' &&
    typeof tax.integrationId === 'number' &&
    typeof tax.name === 'string' &&
    typeof tax.rate === 'number'
  );
}
