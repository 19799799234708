import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import useMap from '#hooks/useMap';

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
`;

function Map() {
  const mapRef = useRef(null);
  const { makeMap } = useMap();

  useEffect(() => { makeMap(mapRef); }, [makeMap]);

  return (
    <MapContainer ref={mapRef} />
  );
}

export default Map;
