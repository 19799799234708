import React from 'react';
import styled from '@emotion/styled';

import { Link } from '#mrktbox';

import { Theme } from '#types';

import Heading from '#materials/Heading';
import { ArrowRightLong, ChevronRight } from '#materials/icons';

interface SeeMoreLinkStyle {
  theme? : Theme;
}

const SeeMoreLinkView = styled.div<SeeMoreLinkStyle>`
  flex-grow: 0;

  a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
    color: ${(props) => props.theme.palette.background.text.primary};

    &:hover,
    &:active {
      color: ${(props) => props.theme.palette.background.text.hover};
    }
  }
`;

const SeeMoreLinkText = styled(Heading)<SeeMoreLinkStyle>`
  display: block;

  color: ${(props) => props.theme.palette.background.text.primary};

  font-size: ${(props) => props.theme.typography.fonts.default.sizes.large};
  line-height: 1;

  transition: all 0.150s ease;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  }

  a:hover &,
  a:active & {
    color: ${(props) => props.theme.palette.background.text.hover};
  }
`;

const SeeMoreLinkArrow = styled.div<SeeMoreLinkStyle>`
  display: flex;
  margin: 0 0 0 1.5rem;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    display: none;
  }
`;

const SeeMoreLinkChevron = styled.span<SeeMoreLinkStyle>`
  display: none;
  width: 1.4rem;
  height: 1.4rem;
  margin: 0.1rem -0.5rem 0 0;
  color: ${(props) => props.theme.palette.background.text.primary};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    display: block;
  }
`;

interface SeeMoreLinkProps {
  text : string,
  to : string,
}

function SeeMoreLink({ text, to } : SeeMoreLinkProps) {
  return (
    <SeeMoreLinkView>
      <Link to={to}>
        <SeeMoreLinkText>{text}</SeeMoreLinkText>
        <SeeMoreLinkArrow>
          <ArrowRightLong />
        </SeeMoreLinkArrow>
        <SeeMoreLinkChevron>
          <ChevronRight />
        </SeeMoreLinkChevron>
      </Link>
    </SeeMoreLinkView>
  );
}

export default SeeMoreLink
