import styled from '@emotion/styled';

import { Theme } from '#types';

interface ProductsViewProps {
  theme? : Theme;
}

const ProductsView = styled.div<ProductsViewProps>`
  display: grid;
  padding: 0;
  align-items: space-between;
  justify-content: center;
  gap: ${(props) => props.theme.layout.spacing.medium};
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1350px) { grid-template-columns: repeat(3, 1fr); }

  @media (max-width: ${(props) => props.theme.view.breakpoints.narrow}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    gap: ${(props) => props.theme.layout.spacing.small};
  }

  @media (max-width: 350px) {
    grid-template-columns: 1fr;
  }

`;

export default ProductsView;
