import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }
interface CountViewStyle extends Style {
  color? : keyof Theme['palette']['notifications'];
  size? : keyof Theme['typography']['fonts']['default']['sizes'];
}

const CountView = styled.div<CountViewStyle>`
  display: flex;
  width: ${(props) => `calc(1.5
    * ${props.theme.typography.fonts.default.sizes[props.size ?? 'medium']})`};
  height: ${(props) => `calc(1.5
    * ${props.theme.typography.fonts.default.sizes[props.size ?? 'medium']})`};
  align-items: center;
  justify-content: center;

  color: ${(props) =>
    props.theme.palette.notifications[props.color || 'default'].text};
  background-color: ${(props) =>
    props.theme.palette.notifications[props.color || 'default'].fill};

  border-radius: 50%;
`;

interface CountCountStyle extends Style {
  fontSize? : keyof Theme['typography']['fonts']['default']['sizes'];
}

const CountCount = styled.span<CountCountStyle>`
  display: block;
  line-height: 0;
  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) =>
    props.theme.typography.fonts.default.sizes[props.fontSize || 'medium']};
  font-weight: ${(props) =>
    props.theme.typography.fonts.default.weights.regular};
  -webkit-font-smoothing: antialiased;
`;

interface CountProps {
  count : number;
  colour? : keyof Theme['palette']['notifications'];
  size? : keyof Theme['typography']['fonts']['default']['sizes'];
}

function Count({
  count,
  colour,
  size = 'medium',
} : CountProps) {
  return (
    <CountView
      color={colour}
      size={size}
    >
      <CountCount fontSize={size}>{count}</CountCount>
    </CountView>
  );
}

export default Count;
