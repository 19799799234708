import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Address, Location } from '#mrktbox/types';
import { useAddresses } from '#mrktbox';
import { formatDateTime, formats } from '#mrktbox/utils';

import { Theme } from '#types';

import { ThemeProvider } from '#context/ThemeContext';

import useConfig from '#hooks/useConfig';
import useMap from '#hooks/useMap';
import useRequests from '#hooks/useRequests';

import { BgImage } from '#materials/BackgroundImage';
import Heading from '#materials/Heading';
import Box from '#materials/Box';
import ButtonStyled from '#materials/ButtonStyled';
import { Check, MapPin } from '#materials/icons';

import LocationAction from '#components/orders/LocationAction';

import { scrollToId } from '#utils/scroll';
import { remToPx } from '#utils/style';

interface Style { theme? : Theme; }

interface LocationViewStyle extends Style {
  isMenu? : boolean;
  isActive? : boolean;
}

interface LocationContentStyle extends LocationViewStyle {
  showImage? : boolean;
}

const LocationView = styled(Box)<LocationViewStyle>`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 15rem;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    padding: 1.5rem;
    min-height: 10rem;
  }
`;

const LocationImage = styled(BgImage)<Style>`
  position: absolute;
  width: 24rem;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${(props) => props.theme.palette.background.fill};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    width: 18rem;
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    display: none;
  }
`;

const LocationContent = styled.div<LocationContentStyle>`
  padding: 0 ${(props) => (props.showImage ? `24rem` : '0')} 0 0;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    padding-right: 0 ${(props) => (props.showImage ? `24rem` : '0')} 0 0;
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    padding: 0;
  }

  > div {
    padding: ${(props) => props.theme.layout.spacing.small};

    @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
      padding: 0;
    }
  }
`;

const LocationHeader = styled.div<Style>`
  display: flex;
  margin-bottom: ${(props) => props.theme.layout.spacing.xsmall};
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  & > * {
    display: block;
  }
`;

const LocationTitle = styled(Heading)`
  font-size: ${(props) => props.theme.typography.fonts.title.sizes.xlarge};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.title.sizes.medium};
  }
`;

const LocationDescription = styled(Heading)`
  display: block;
  margin: ${(props) => props.theme.layout.spacing.xsmall} 0 0;
  white-space: pre-line;
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
  }
`;

const LocationCheckmark = styled.div<Style>`
  width: ${(props) => props.theme.typography.fonts.title.sizes.xlarge};
  height: ${(props) => props.theme.typography.fonts.title.sizes.xlarge};
  margin: 0 0 0 ${(props) => props.theme.layout.spacing.xsmall};
  padding: ${(props) => props.theme.layout.spacing.xxsmall};

  color: ${(props) => props.theme.palette.notifications.success.text};
  background-color:
    ${(props) => props.theme.palette.notifications.success.fill};

  border-radius: 50%;

  line-height: 1;
`;

const LocationFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LocationFlexContent = styled.div`
  flex: 1 1 auto;
`;

const LocationActions = styled.div<Style>`
  a,
  button {
    display: block;
    width: 100%;
    text-align: left;
    text-decoration: none;
  }
`;

const LocationButtons = styled.div<Style>`
  margin-top: ${(props) => props.theme.layout.spacing.small};

  button {
    margin-bottom: 0;
  }
`;

interface LocationCardProps {
  location : Location;
  showImage? : boolean;
  onSelect? : () => void;
  disabled? : boolean;
}

function LocationCard({
  location,
  showImage,
  disabled = false,
  onSelect,
} : LocationCardProps) {
  const { content, theme } = useConfig();
  const { currentOrder, proposeTime } = useRequests();
  const { retrieveAddress } = useAddresses();
  const { setPin, removePin } = useMap();

  const [address, setAddress] = useState<Address | null>(null);
  const [imageUrl, setImageUrl] = useState('');
  const [pinned, setPinned] = useState<string | null>(null);

  const retrieve = useCallback(async () => {
    if (!location.addressId) {
      setAddress(null);
      return;
    }

    const address = await retrieveAddress(location.addressId);
    setAddress(address);
  }, [location.addressId, retrieveAddress]);

  useEffect(() => { retrieve(); }, [retrieve]);

  useEffect(() => {
    if (pinned || !address || !address.latitude || !address.longitude) return;
    const pin = setPin(
      address.latitude,
      address.longitude,
      {
        onClick : location.id
          ? (() => scrollToId(
            `location-${location.id}`,
            { offset : remToPx(theme.view.nav.height.default) * 1.2 },
          ))
          : undefined
      }
    );

    if (!pin) return;
    setPinned(pin.key);
  }, [location, pinned, address, setPin, removePin, theme]);

  useEffect(() => { return () => {
    if (!pinned) return;
    removePin(pinned);
  } }, [pinned, removePin]);

  useEffect(() => {
    const image = Object.values(location.images ?? {})[0];
    if (!image || !Object.keys(image.variants).length) return;

    if ('card' in image.variants) setImageUrl(image.variants.card);
    else setImageUrl(Object.values(image.variants)[0]);
  }, [location]);

  const proposedTime = proposeTime({
    location,
    starting : currentOrder?.time,
  });
  const nextAvailable = proposedTime?.time
    ? formatDateTime(proposedTime.time, formats.easy)
    : '';

  const isActive = currentOrder?.location?.id === location.id;

  const bgImage = imageUrl || content.images.defaults.location;
  const bgStyle = bgImage ? { backgroundImage: `url(${bgImage}` } : {};

  return (
    <ThemeProvider themeKey="card">
      <LocationView isActive={isActive}>
        { showImage && (
          <LocationImage style={bgStyle}>&nbsp;</LocationImage>
        ) }
        <LocationContent showImage={showImage}>
          <div>
            <LocationFlex>
              <LocationFlexContent>
                <LocationHeader>
                  <LocationTitle as="p" className="title">
                    { location.name }
                  </LocationTitle>
                  { isActive && (
                    <LocationCheckmark>
                      <Check />
                    </LocationCheckmark>
                  ) }
                </LocationHeader>
                <LocationActions>
                  <LocationAction
                    icon={(<MapPin />)}
                    text={(<span>{ address?.description }</span>)}
                  />
                  { nextAvailable && (
                    <LocationDescription>
                      { (currentOrder?.time
                        ? 'Reschedule for '
                        : 'Next available pickup '
                      ) + nextAvailable }
                    </LocationDescription>
                  ) }
                  { location.description && (
                    <LocationDescription>
                      { location.description }
                    </LocationDescription>
                  ) }
                </LocationActions>
              </LocationFlexContent>
            </LocationFlex>
            <LocationButtons>
              <ButtonStyled
                onClick={onSelect}
                disabled={disabled || !proposedTime.selection}
                size="small"
                colour="primary"
              >
                { proposedTime.selection
                  ? 'Order From Here'
                  : 'No Times Available'
                }
              </ButtonStyled>
            </LocationButtons>
          </div>
        </LocationContent>
      </LocationView>
    </ThemeProvider>
  );
}

export default LocationCard;
