import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Product, Category } from '#mrktbox/types';

import { Theme } from '#types';

import useCatalogue from '#hooks/useCatalogue';

import Container from '#materials/Container';

import CategoryHeader from '#components/categories/CategoryHeader';
import ProductGrid from '#components/products/ProductGrid';
import ProductCard from '#components/products/ProductCard';

interface Style {
  theme? : Theme;
}

export const CategoriesView = styled(Container)<Style>`
  margin: ${(props) => props.theme.layout.spacing.medium} auto;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.spacing.small} 0;
  }
`;

interface CategoriesProps {
  category : Category;
  products? : Product[];
  isChild : boolean;
  currentCategory? : Category;
}

function Categories({ category, isChild, currentCategory } : CategoriesProps) {
  const { setLast, getCategorySlug, getCategoryProducts } = useCatalogue();

  const handleClick = useCallback(() => {
    setLast(`/category/${getCategorySlug(currentCategory ?? category)}`);
  }, [category, currentCategory, setLast, getCategorySlug])

  return (
    <CategoriesView>
      <CategoryHeader
        title={category.name}
        subtitle={category.description}
        id={getCategorySlug(category)}
        isChild={isChild}
      />
      { category.productIds.length > 0 &&
        <ProductGrid>
          { getCategoryProducts(category)?.map((product, index) => (
            <ProductCard
              product={product}
              onClick={handleClick}
              key={`${product.id}-${index}`}
            />
          ))}
        </ProductGrid>
      }
    </CategoriesView>
  );
}

export default Categories;
