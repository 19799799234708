import styled from '@emotion/styled';

import { Theme } from '#types';

import Body from '#components/page/Body';
import Headline from '#components/page/Headline';

interface Style { theme? : Theme; }
interface PageTitleViewStyle extends Style {
  fullWidth? : boolean;
  textAlign? : 'left' | 'center' | 'right';
}

const PageTitleView = styled.div<PageTitleViewStyle>`
  width: 100%;
  max-width: ${(props) => props.fullWidth ? '100%' : '64rem'};
  margin: 0 auto ${(props) => props.theme.layout.spacing.large};
  overflow: hidden;
  opacity: 0;

  text-align: ${(props) => props.textAlign ?? 'center'};

  animation: slide-up 0.25s ease-in-out 0.125s forwards;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    margin: 0 auto ${(props) => props.theme.layout.spacing.medium};
    text-align: left;
  }
`;

const PageTitleTitle = styled(Headline)`
  margin: 0;
  font-size: ${(props) => props.theme.typography.fonts.brand.sizes.xxlarge};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.typography.fonts.brand.sizes.xlarge};
  }
`;

const PageTitleSubtitle = styled(Body)`
  margin: ${(props) => props.theme.layout.spacing.xsmall} 0 0;
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.spacing.xxsmall} 0 0;
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  }
`;

interface PageTitleProps {
  title : string;
  subtitle : string;
  textAlign? : 'left' | 'center' | 'right';
  fullWidth? : boolean;
  children? : React.ReactNode;
  style? : React.CSSProperties;
}

function PageTitle({
  title,
  subtitle,
  textAlign = 'center',
  fullWidth = false,
  children,
  style,
} : PageTitleProps) {
  return (
    <PageTitleView textAlign={textAlign} fullWidth={fullWidth} style={style}>
    { title && (
        <PageTitleTitle as="h1">{ title }</PageTitleTitle>
      ) }
      { subtitle && (
        <PageTitleSubtitle as="p">{ subtitle }</PageTitleSubtitle>
      ) }
      { children }
    </PageTitleView>
  );
}

export default PageTitle;
