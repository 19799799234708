import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import { ThemeProvider } from '#context/ThemeContext';

import Box from '#materials/Box';
import Heading from '#materials/Heading';
import Preface from '#materials/Preface';

interface Style { theme? : Theme; }

const CardView = styled(Box)<Style>`
  position: relative;
  height: 100%;
  padding: ${(props) => props.theme.layout.spacing.xsmall};
`;

const CardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.div`
  flex-grow: 0;
`;

const CardPreface = styled.p<Style>`
  span {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
  }
`;

const CardTitle = styled(Heading)`
  margin: ${(props) => props.theme.layout.spacing.xxsmall} 0;
`;

const CardSubtitle = styled.p<Style>`
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
`;

const CardContent = styled.div<Style>`
  margin: ${(props) => props.theme.layout.spacing.xsmall} 0;
  flex-grow: 1;

  > p {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
    line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
  }
`;

const CardFooter = styled.div<Style>`
  flex-grow: 0;

  > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    gap: ${(props) => props.theme.layout.spacing.xsmall};

    button {
      margin:
        0
        ${(props) => props.theme.layout.spacing.xxsmall}
        ${(props) => props.theme.layout.spacing.xxsmall}
        0;
      &:last-child {
        margin: 0;
      }
    }
  }
`;

interface CardProps {
  tag? : React.ReactNode;
  preface? : React.ReactNode;
  title? : React.ReactNode;
  subtitle? : React.ReactNode;
  content? : React.ReactNode;
  footer? : React.ReactNode;
  style? : React.CSSProperties;
}

function Card({
  tag,
  preface,
  title,
  subtitle,
  content,
  footer,
  style,
} : CardProps) {

  return (
    <ThemeProvider themeKey="card">
      <CardView style={style}>
        { tag }
        <CardContainer>
          <CardHeader>
            { preface && (
              <CardPreface>
                <Preface>{preface}</Preface>
              </CardPreface>
            ) }
            { title && (
              <CardTitle as="p" className="title">
                { title }
              </CardTitle>
            ) }
            { subtitle && <CardSubtitle>{ subtitle }</CardSubtitle> }
          </CardHeader>
          <CardContent>{ content }</CardContent>
          <CardFooter>
            <div>{ footer }</div>
          </CardFooter>
        </CardContainer>
      </CardView>
    </ThemeProvider>
  );
}

export default Card;
