import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }
interface ResponsiveStyle extends Style { responsive? : boolean; }

const OverlayMask = styled.div<Style>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;

  background-color: ${(props) => props.theme.palette.overlay};
`;

const OverlayView = styled.div<ResponsiveStyle>`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: ${(props) => props.theme.layout.spacing.xsmall};

  overflow-y: auto;
  overflow-x: hidden;

  * {
    font-family: ${(props) => props.theme.typography.fonts.default.family};
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  }

  button {
    width: 80%;

    @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
      width: 90%;
    }
  }
`;

const Instructions = styled.div<ResponsiveStyle>`
  width: 100%;
  margin:
    ${(props) => props.theme.layout.spacing.xsmall}
    ${(props) => props.theme.layout.spacing.xsmall}
    0;
  padding: ${(props) => props.theme.layout.spacing.xsmall};

  color: ${(props) => props.theme.palette.background.text.primary};
  background-color: ${(props) => props.theme.palette.background.fill};

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.large};


  span {
    white-space: pre-wrap;
  }
`;

interface ButtonOverlayProps {
  text : string;
  children? : React.ReactNode;
}

function ButtonOverlay({
  text,
  children,
} : ButtonOverlayProps) {
  return (
    <>
      <OverlayMask />
      <OverlayView>
        <Instructions>
          <span>{ text }</span>
        </Instructions>
        { children }
      </OverlayView>
    </>
  );
}

export default ButtonOverlay;
