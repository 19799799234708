
export function scrollToId(
  id : string,
  options? : {
    offset? : number,
    view? : | Element,
  }
) {
  if (typeof document === 'undefined' || typeof window === 'undefined') {
    return null;
  }

  const element = document.querySelector(`#${id}`);
  if (!element) return null;

  const viewContainer = options?.view ?? window;
  const scrollY = options?.view
    ? options.view.scrollTop
    : window.scrollY;

  const y = element.getBoundingClientRect().top
    + scrollY
    - (options?.offset ?? 0);

  viewContainer.scrollTo({top: y, behavior: 'smooth'});
  return (element.id !== id) ? (element as HTMLElement) : null;
}
