import styled from '@emotion/styled';

import { Theme } from '#types';

interface BodyStyle {
  theme? : Theme;
  size? : keyof Theme['typography']['fonts']['default']['sizes'];
}

const Body = styled.span<BodyStyle>`
  color: ${(props) => props.theme.palette.background.text.primary};
  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.size
    ? props.theme.typography.fonts.default.sizes[props.size]
    : props.theme.typography.fonts.default.sizes.medium};
  font-weight: ${(props) => props.theme.typography.fonts.default.weights.regular};
  letter-spacing: ${(props) =>
    props.theme.typography.fonts.default.letterSpacing};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
  -webkit-font-smoothing: antialiased;
`;

export default Body;
