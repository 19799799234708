import { useContext } from 'react';

import ModalContext from '#context/ModalContext';

function useModal() {
  const {
    open,
    modal,
    openModal,
    closeModal,
  } = useContext(ModalContext);

  return {
    open,
    modal,
    openModal,
    closeModal,
  };
}

export default useModal;
