import { useCallback, useContext } from 'react';

import {
  useNavigation,
  useProducts,
  useTags,
  useTaxes,
  useSuppliers,
  useCategories,
  useInventory,
  useAdjustments,
  useOptions,
  useNotes,
} from '#mrktbox';
import { listRecords } from '#mrktbox/utils';
import {
  Currency,
  ServiceChannel,
  Product,
  Category,
  Assembly,
  Collection,
  Adjustment,
  LineItem,
  DraftCustomOrder,
  isProductLoaded,
} from '#mrktbox/types';

import CatalogueContext from "#context/CatalogueContext";

import useConfig from '#hooks/useConfig';
import useRequests from '#hooks/useRequests';

import { remToPx } from '#utils/style';
import { slug } from '#utils/format';

function useCatalogue() {
  const {
    loaded,
    last,
    products,
    categories,
    setLast,
    isProductStocked,
    isProductAvailable,
    getFeaturedCategory,
    getDealsCategory,
    filterProducts,
    filterCategories,
  } = useContext(CatalogueContext);

  const { isRouteActive } = useNavigation();
  const { content, theme } = useConfig();
  const {
    products : allProducts,
    load : loadProducts,
    retrieveProductsBulk,
  } = useProducts();
  const {
    categories : allCategories,
    loaded : categoriesLoaded,
    load : loadCategories,
    retrieveCategorySubcategories,
  } = useCategories();
  const {
    load : loadAdjustments,
    getAdjustmentConditions,
    calculateProductPrice,
    calculateAdjustmentRelative,
  } = useAdjustments();
  const { tags, load : loadTags } = useTags();
  const { taxes, load : loadTaxes } = useTaxes();
  const {
    suppliers,
    load : loadSuppliers
  } = useSuppliers();
  const { load : loadInventory } = useInventory();
  const {
    assemblies,
    collections,
    load : loadCollection,
    getProductAssemblies,
    getCollection,
    getCollectionProducts,
    getCollectionDefaults,
    getAssemblyProducts,
    getAssemblyCounts,
    validateSelection,
  } = useOptions();
  const { loadProductNotes } = useNotes();

  const {
    time,
    getLineItems,
  } = useRequests();

  const getProductSlug = useCallback((product : Product) => {
    return slug(product.name);
  }, []);

  const getProductTags = useCallback((product : Product) => {
    const productId = product.id;
    if (!productId) return [];
    if (!tags || !taxes || !suppliers || !categories || !collections) return [];
    return listRecords(tags).filter((tag) => tag.productIds.includes(productId))
      .filter((tag) => !(
        !tag.id
          || tag.id in taxes
          || tag.id in suppliers
          || tag.id in collections
      ));
  }, [tags, suppliers, categories, taxes, collections]);

  const getProductImage = useCallback((
    product : Product | null,
    key? : string,
  ) => {
    const image = product?.images ? listRecords(product.images)[0] : null;
    if (!image) return content.images.defaults.product;

    if (key && image.variants[key]) return image.variants[key];
    if (image.variants['public']) return image.variants['public'];
    return listRecords(image.variants)[0];
  }, [content]);

  const validateSelected = useCallback((
    product : Product,
    selections : { [id : number] : Product[] },
  ) => {
    return getProductAssemblies(product).every((a) => {
      const selected = a.id ? (selections[a.id] ?? []) : [];

      const { min, max } = getAssemblyCounts(a, time ?? new Date());
      if (min && selected.length < min) return false;
      if (max && selected.length > max) return false;

      const applicableProducts = getAssemblyProducts(a, time ?? new Date());
      return selected.every(
        (s) => applicableProducts.some((p) => p.id === s.id)
      );
    });
  }, [getProductAssemblies, getAssemblyCounts, getAssemblyProducts, time]);

  const isCollectionManditory = useCallback((
    collection : Collection,
  ) => {
    return collection
      && (collection.productIds.length === 0
        || (collection.min > 0
          && collection.min === collection.max
          && collection.productIds.length === 1));
  }, []);

  const isAssemblyManditory = useCallback((
    assembly : Assembly,
    date : Date,
  ) => {
    const collection = getCollection(assembly, date);
    if (!collection) return false;
    return isCollectionManditory(collection);
  }, [getCollection, isCollectionManditory]);

  const getCurrentSelections = useCallback((
    product : Product,
    order? : DraftCustomOrder | null,
    lineItem? : LineItem | null,
  ) => {
    if (!allProducts) return {};

    if (lineItem && order) {
      return Object.values(order.selections)
        .filter(s => s.lineItemId === lineItem.id
          && validateSelection(
            product,
            s,
            order.time ?? time ?? new Date()
          ).valid
        )
        .reduce((acc, s) => {
          const product = allProducts[s.productId];
          if (!product) return acc;

          if (!acc[s.assemblyId]) acc[s.assemblyId] = [];
          for (let q = 0; q < s.quantity; q++) {
            acc[s.assemblyId].push(product);
          }
          return acc;
        }, {} as { [id : number] : Product[] })
    }

    const assemblies = getProductAssemblies(product);
    const selections = {} as { [id : number] : Product[] };
    for (const assembly of assemblies) {
      const assemblyId = assembly.id;
      if (!assemblyId) continue;
      const collection = getCollection(
        assembly,
        order?.time ?? time ?? new Date(),
      );
      if (!collection) continue;

      const products = getCollectionProducts(collection);
      if (products.length && isCollectionManditory(collection)) {
        selections[assemblyId] = [];
        for (let i = 0; i < collection.min; i++) {
          if (!isProductStocked(
            products[0],
            undefined,
            { checkOptions : false },
          )) continue;
          selections[assemblyId].push(products[0]);
        }
        continue;
      }

      const defaults = getCollectionDefaults(collection);
      if (!defaults.length) continue;
      selections[assemblyId] = [];
      for (const def of defaults) {
        if (
          !isProductStocked(
            def.product,
            undefined,
            { checkOptions : false },
          )
            || !products.some((p) => p.id === def.product.id)
        ) continue;

        for (let i = 0; i < def.quantity; i++) {
          selections[assemblyId].push(def.product);
        }
      }
    }

    return selections;
  }, [
    allProducts,
    time,
    isProductStocked,
    getProductAssemblies,
    getCollection,
    getCollectionProducts,
    getCollectionDefaults,
    isCollectionManditory,
    validateSelection,
  ]);

  const isCustomisationRequired = useCallback((product : Product) => {
    const defaultSelections = getCurrentSelections(product);
    return !validateSelected(product, defaultSelections);
  }, [getCurrentSelections, validateSelected]);

  const formatAdjustment = useCallback((
    adjustment : Adjustment,
    product : Product,
  ) => {
    const productId = product.id;
    if (!productId) return '';
    const conditions = getAdjustmentConditions(adjustment);
    if (!conditions.length) return '';

    const condition = conditions.find((c) => c.productIds.includes(productId));
    if (!condition) return '';

    const bulk = condition.count > 1;
    const conditionText = (bulk ? `Buy ${condition.count}, ` : '')
      + (calculateAdjustmentRelative(adjustment, product) > 0
        ? 'additional fee of'
        : 'save');

    return conditionText;
  }, [getAdjustmentConditions, calculateAdjustmentRelative]);

  const calculatePrice = useCallback(({
    product,
    adjustments,
    selections,
    quantity,
    relative,
  } : {
    product : Product;
    adjustments? : Adjustment[];
    selections? : { [id : number] : Product[]; };
    quantity? : number;
    relative? : boolean;
  }) : Currency => {
    const selectionPrice = selections ? Object.entries(selections)?.reduce(
      (acc, [a, selections]) => {
        const assembly = assemblies && assemblies[a.toString()];
        if (!assembly || assembly.complimentary) return acc;

        return acc + selections.reduce((p, s) => (p + s.price.amount), 0);
      },
      0,
    ) : 0;

    const adjustedPrice = calculateProductPrice(product, {
      quantity,
      adjustments,
    });

    let amount = !relative
      ? (adjustedPrice.amount + selectionPrice)
      : selectionPrice;

    return {
      ...product.price,
      amount : amount,
      calculatedValue : amount * product.price.increment,
    }
  }, [assemblies, calculateProductPrice]);

  const checkChannelAvailability = useCallback((channel : ServiceChannel) => {
    if (!allProducts) return true;
    const orderProducts = getLineItems()
        .map((item) => allProducts[item.productId])
        .filter((p) => p) as Product[];

    const availableProducts = filterProducts(
      orderProducts,
      { serviceChannels : [channel] },
    );
    return orderProducts.length === availableProducts.length;
  }, [allProducts, filterProducts, getLineItems]);

  const getCategorySlug = useCallback((category : Category) => {
    return slug(category.name);
  }, []);

  const currentCategory = useCallback(() => {
    return listRecords(categories).find(
      (category) => category && (
        isRouteActive(`/category/${category.id}`)
          || isRouteActive(`/category/${getCategorySlug(category)}`)
      ),
    ) ?? null;
  }, [categories, getCategorySlug, isRouteActive]);

  const getRootCategories = useCallback(() => {
    const main = listRecords(allCategories).find(
      (category) => category.name.toLowerCase() === 'main navigation',
    );
    if (main) return filterCategories(retrieveCategorySubcategories(main));

    return listRecords(categories).reduce(
      (acc, category) => {
        if (!listRecords(categories).some(
          (c) => category.id && c && c.subcategoryIds.includes(category.id),
        )) acc.push(category);
        return acc;
      },
      [] as Category[],
    ).sort((a, b) => {
      if (a.name === 'Featured') return -1;
      if (b.name === 'Featured') return 1;
      return 0;
    });
  }, [
    categories,
    filterCategories,
    allCategories,
    retrieveCategorySubcategories,
  ]);

  const getSubcategories = useCallback((category : Category) => {
    return filterCategories(retrieveCategorySubcategories(category));
  }, [retrieveCategorySubcategories, filterCategories]);

  const getCategoryProducts = useCallback((category : Category | null) => {
    return (category && products)
      ? category.productIds.map((id) => products[id])
        .filter((p) => p) as Product[]
      : [];
  }, [products]);

  const getCategoryImage = useCallback((category : Category, key? : string) => {
    const image = category.images ? listRecords(category.images)[0] : null;
    if (!image) return content.images.defaults.category;

    if (key && image.variants[key]) return image.variants[key];
    return listRecords(image.variants)[0];
  }, [content]);

  const scrollToCategory = useCallback((category : Category) => {
    if (typeof document === 'undefined' ||
      typeof window === 'undefined') return;

    const element = document.querySelector<HTMLElement>(
      `#${getCategorySlug(category)}`
    );
    if (!element) return;

    const offset = remToPx(theme.view.nav.height.default) * 1.2;
    const y = element.getBoundingClientRect().top + window.scrollY - offset;
    window.scrollTo({top: y, behavior: 'smooth'});

    return element;
  }, [getCategorySlug, theme]);

  const loadCategory = useCallback((category : Category) => {
    const productIds = category.subcategoryIds.reduce(
      (acc : number[], subcategoryId) => {
        const subcategory = allCategories?.[subcategoryId];
        if (!subcategory) return acc;
        return [...acc, ...subcategory.productIds];
      },
      category.productIds,
    )
    retrieveProductsBulk(productIds);
  }, [allCategories, retrieveProductsBulk]);

  const loadOptions = useCallback((product : Product) => {
    const assemblies = getProductAssemblies(product);
    const productIds : number[] = [];
    assemblies.forEach((assembly) => {
      const products = getAssemblyProducts(assembly, time ?? new Date());
      products.forEach((p) => {
        if (!p.id || productIds.includes(p.id)) return;
        productIds.push(p.id);
      })
    });

    retrieveProductsBulk(productIds);
  }, [getProductAssemblies, getAssemblyProducts, retrieveProductsBulk, time]);

  const load = useCallback(async () => {
    loadCategories();
    const featured = getFeaturedCategory();
    if (featured) loadCategory(featured);

    if (!categoriesLoaded) return;
    if (featured && getCategoryProducts(featured).length) {
      if (!getCategoryProducts(featured).some(isProductLoaded)) return;
    }

    loadProducts();
    loadTags();
    loadSuppliers();
    loadTaxes();
    loadInventory();
    loadCollection();
    loadAdjustments();
    loadProductNotes();
  }, [
    categoriesLoaded,
    getCategoryProducts,
    getFeaturedCategory,
    loadCategory,
    loadProducts,
    loadCategories,
    loadTags,
    loadSuppliers,
    loadTaxes,
    loadInventory,
    loadCollection,
    loadAdjustments,
    loadProductNotes,
  ]);

  return {
    products,
    allProducts,
    categories,
    allCategories,
    last,
    loaded,
    load,
    loadCatalogue : load,
    loadCategory,
    loadOptions,
    setLast,
    isProductLoaded,
    isProductAvailable,
    isProductStocked,
    filterProducts,
    getProductSlug,
    getProductTags,
    getProductImage,
    isCustomisationRequired,
    getCurrentSelections,
    isAssemblyManditory,
    validateSelected,
    formatAdjustment,
    calculatePrice,
    currentCategory,
    filterCategories,
    getFeaturedCategory,
    getDealsCategory,
    getRootCategories,
    getSubcategories,
    getCategoryProducts,
    getCategorySlug,
    getCategoryImage,
    scrollToCategory,
    checkChannelAvailability,
  };
}

export default useCatalogue;
