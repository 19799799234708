import React, { createContext, useCallback, useEffect, useState } from 'react';

import { Auth, User } from '#mrktbox/clerk/types';

import useData, { actionTypes } from '#mrktbox/clerk/hooks/useData';

const AuthContext = createContext({
  user : null as Auth | null,
  token : '' as string,
  dispatchUser : (user : User, token : string) => {},
  clear : () => {},
  exists : false
});

interface AuthProviderProps {
  children : React.ReactNode;
}

export function AuthProvider({ children } : AuthProviderProps) {
  const { data, dispatch, clearCache } = useData<Auth>({ storageKey : 'auth' });
  const [userId, setUserId] = useState<number | null>(null);
  const [user, setUser] = useState<Auth | null>(
    (!!data && !!Object.keys(data).length)
      ? Object.values(data)[0]
      : null
  );

  const dispatchUser = useCallback((user : User, token : string) => {
    setUserId(user.id);
    setUser({
      ...user,
      token : token,
    });
    dispatch({
      data : { [user.id] : {
        ...user,
        token : token,
      } },
      type : actionTypes.set,
    });
  }, [dispatch]);

  const clear = useCallback(() => {
    if (user) clearCache();
  }, [user, clearCache]);

  useEffect(() => {
    if (!data) return;

    if (!Object.keys(data).length) {
      setUserId(null);
      setUser(null);
      return;
    }

    if (!userId || !(userId in data)) {
      const userId = parseInt(Object.keys(data)[0]);
      if (isNaN(userId)) return;

      setUserId(userId);
      setUser(data[userId]);
    } else {
      setUser(data[userId]);
    }
  }, [data, userId, setUserId, setUser]);

  const context = {
    user,
    token : user?.token || '',
    dispatchUser,
    clear,
    exists : true,
  }

  return (
    <AuthContext.Provider value={context}>
      { children }
    </AuthContext.Provider>
  );
}

export default AuthContext;
