import { useCallback } from 'react';

import {
  retrieveBroadcasts as retrieveBroadcastsAPI,
  retrieveBroadcast as retrieveBroadcastAPI,
} from '#mrktbox/clerk/api/mrktbox/notifications';

import useProcessRequest from '#mrktbox/clerk/hooks/api/useProcessRequest';

function useNotificationsAPI() {
  const processRequest = useProcessRequest();

  const retrieveBroadcasts = useCallback(async () => {
    return await processRequest(
      retrieveBroadcastsAPI,
      {},
    )
  }, [processRequest]);

  const retrieveBroadcast = useCallback(async (broadcastId : number) => {
    return await processRequest(
      retrieveBroadcastAPI,
      { broadcastId },
    )
  }, [processRequest]);

  return {
    retrieveBroadcasts,
    retrieveBroadcast,
  };
}

export default useNotificationsAPI;
