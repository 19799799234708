import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled'

import { Product } from '#mrktbox/types';

import { Theme } from '#types';

import useRequests from '#hooks/useRequests';

import Transition, { TransitionGroup } from '#materials/Transition';
import { ChevronDown, ChevronUp, Refresh } from '#materials/icons';
import ButtonStyled from '#materials/ButtonStyled';

import ProductIngredients from '#components/products/ProductIngredients';
import ProductNote from '#components/products/ProductNote';

import { capitalize } from '#utils/format';

interface Style {
  theme? : Theme;
}

const ProductAccordionView = styled.div<Style>`
  padding: ${(props) => props.theme.layout.spacing.small};
`;

const ProductAccordionContainer = styled.div<Style>`
  border-bottom:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.border};
`;

const ProductAccordionRow = styled.button<Style>`
  display: flex;
  width: 100%;
  height: ${(props) =>
    `calc(${props.theme.typography.fonts.default.sizes.medium} * 3)`};
  align-items: center;
  justify-content: space-between;

  color: ${(props) => props.theme.palette.background.text.primary};

  border-top:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.border};

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    height: ${(props) =>
      `calc(${props.theme.typography.fonts.default.sizes.small} * 3)`};
  }
`;

interface ProductAccordionRowButtonProps {
  name: string;
  open: string;
  setOpen: any;
  children: any;
}

function ProductAccordionRowButton({
  name,
  open,
  setOpen,
  children,
} : ProductAccordionRowButtonProps){
  const isOpen = name === open;
  const onClick = () => { setOpen(isOpen ? undefined : name) }
  return (
    <ProductAccordionRow onClick={onClick}>
      { children }
    </ProductAccordionRow>
  );
}

const ProductAccordionLabel = styled.div<Style>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${(props) => props.theme.layout.spacing.xsmall};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  }

  span {
    display: block;
  }

  svg {
    position: relative;

    @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
      top: -0.1rem;
    }
  }
`;

const ProductAccordionToggleView = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ProductAccordionToggleIcon = styled.div<Style>`
  width: ${(props) => props.theme.typography.fonts.default.sizes.large};
  padding: 0 0 0 ${(props) => props.theme.layout.spacing.xxsmall};
  margin: 0.2rem 0 0 0;
`;

const ProductAccordionButtonList = styled.div<Style>`
  display: flex;
  padding: ${(props) => props.theme.layout.spacing.small} 0;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.layout.spacing.xxsmall};

  button {
    width: 28rem;
    max-width: 90%;
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    padding:
      ${(props) => props.theme.layout.spacing.xxsmall}
      0
      ${(props) => props.theme.layout.spacing.small};
  }
`;

interface ProductAccordionToggleProps {
  isOpen: boolean;
  children?: any;
}

function ProductAccordionToggle({
  isOpen,
  children,
} : ProductAccordionToggleProps){
  return (
    <ProductAccordionToggleView>
      { children }
      <ProductAccordionToggleIcon>
        { isOpen ? <ChevronUp /> : <ChevronDown /> }
      </ProductAccordionToggleIcon>
    </ProductAccordionToggleView>
  )
}

const ProductAccordionSectionView = styled.div`
  margin: 0 0 0;
`;

interface ProductAccordionSectionProps {
  isOpen : boolean;
  style? : any;
  children : React.ReactNode;
}

function ProductAccordionSection({
  isOpen = false,
  style,
  children,
} : ProductAccordionSectionProps){
  return (
    <TransitionGroup component={undefined}>
      { isOpen
        ? (
          <Transition
            classNames="reveal"
            timeout={{ enter : 250, exit : 250 }}
          >
            <ProductAccordionSectionView style={style}>
              { children }
            </ProductAccordionSectionView>
          </Transition>
        )
        : undefined
      }
    </TransitionGroup>
  )
}

interface ProductAccordionProps {
  product : Product,
  period? : number,
  setPeriod? : (period: number) => void,
  note?: string;
  setNote?: (note : string) => void;
}

function ProductAccordion({
  product,
  note,
  period = 0,
  setNote,
  setPeriod,
} : ProductAccordionProps){
  const { formatRecurrence } = useRequests();
  const [open, setOpen] = useState<string>('');

  const handlePeriod = useCallback((i : number) => {
    setPeriod?.(i);
    setOpen('');
  }, [setPeriod]);

  const ingredients = product.ingredientsList;

  return (
    <ProductAccordionView>
      <ProductAccordionContainer>
        { !!(ingredients && ingredients.length) && (
          <>
            <ProductAccordionRowButton
              name="INGREDIENTS"
              open={open}
              setOpen={setOpen}
            >
              <ProductAccordionLabel>Ingredients</ProductAccordionLabel>
              <ProductAccordionToggle isOpen={open === 'INGREDIENTS'} />
            </ProductAccordionRowButton>
            <ProductAccordionSection isOpen={open === 'INGREDIENTS'}>
              <ProductIngredients ingredients={ingredients} />
            </ProductAccordionSection>
          </>
        )}
        { (note !== undefined) && (
          <>
            <ProductAccordionRowButton
              name="NOTE"
              open={open}
              setOpen={setOpen}
            >
              <ProductAccordionLabel>Note</ProductAccordionLabel>
              <ProductAccordionToggle isOpen={open === 'NOTE'} />
            </ProductAccordionRowButton>
            <ProductAccordionSection isOpen={open === 'NOTE'}>
              <ProductNote note={note} setNote={setNote} />
            </ProductAccordionSection>
          </>
        ) }
        { (!!period || setPeriod) && (
          <>
            <ProductAccordionRowButton
              name="PERIOD"
              open={open}
              setOpen={setOpen}
            >
              <ProductAccordionLabel>
                { !!period && <Refresh size={12}/> }
                { !period
                  ? 'Subscribe'
                  : capitalize(formatRecurrence(period))
                }
              </ProductAccordionLabel>
              <ProductAccordionToggle isOpen={open === 'PERIOD'} />
            </ProductAccordionRowButton>
            <ProductAccordionSection isOpen={open === 'PERIOD'}>
              <ProductAccordionButtonList>
                <ButtonStyled
                  onClick={() => handlePeriod(0)}
                  disabled={period === 0}
                  size='small'
                >
                  One-Time
                </ButtonStyled>
                { [1, 2, 3, 4].map((i) => (
                  <ButtonStyled
                    key={i}
                    onClick={() => handlePeriod(i)}
                    disabled={period === i}
                    size='small'
                  >
                    { capitalize(formatRecurrence(i)) }
                  </ButtonStyled>
                )) }
              </ProductAccordionButtonList>
            </ProductAccordionSection>
          </>
        ) }
      </ProductAccordionContainer>
    </ProductAccordionView>
  );
}

export default ProductAccordion;
