import styled from '@emotion/styled'
import { Theme } from '#types';

interface HeadingStyle{
  theme? : Theme;
  size? : keyof Theme['typography']['fonts']['default']['sizes'];
};

const Heading = styled.span<HeadingStyle>`
  color: ${(props) => props.theme.palette.background.text.primary};
  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.size
    ? props.theme.typography.fonts.default.sizes[props.size]
    : 'inherit'};
  font-weight: ${(props) =>
    props.theme.typography.fonts.default.weights.regular};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.typography.fonts.default.letterSpacing};
  -webkit-font-smoothing: 'antialiased';
`;

export default Heading;
