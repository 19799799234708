import { useCallback } from 'react';

import { Note, ProductNote } from '#mrktbox/clerk/types';

import {
  createNote as createNoteAPI,
  retrieveNotes as retrieveNotesAPI,
  retrieveNote as retrieveNoteAPI,
  updateNote as updateNoteAPI,
  deleteNote as deleteNoteAPI,
  createProductNote as createProductNoteAPI,
  retrieveProductNotes as retrieveProductNotesAPI,
  retrieveProductNote as retrieveProductNoteAPI,
  updateProductNote as updateProductNoteAPI,
  deleteProductNote as deleteProductNoteAPI,
} from '#mrktbox/clerk/api/mrktbox/notes';

import useProcessRequest from '#mrktbox/clerk/hooks/api/useProcessRequest';

function useNotesAPI() {
  const processRequest = useProcessRequest();

  const createNote = useCallback(
    async (
      note : Note,
    ) => {
    return await processRequest(
      createNoteAPI,
      { note },
    )
  }, [processRequest]);

  const retrieveNotes = useCallback(async () => {
    return await processRequest(
      retrieveNotesAPI,
      {},
    )
  }, [processRequest]);

  const retrieveNote = useCallback(async (noteId : number) => {
    return await processRequest(
      retrieveNoteAPI,
      { noteId },
    )
  }, [processRequest]);

  const updateNote = useCallback(
    async (
      note : Note,
    ) => {
    return await processRequest(
      updateNoteAPI,
      { note },
    )
  }, [processRequest]);

  const deleteNote = useCallback(async (note : Note) => {
    return await processRequest(
      deleteNoteAPI,
      { note },
    )
  }, [processRequest]);

  const createProductNote = useCallback(
    async (
      productNote : ProductNote,
    ) => {
    return await processRequest(
      createProductNoteAPI,
      { productNote },
    )
  }, [processRequest]);

  const retrieveProductNotes = useCallback(async () => {
    return await processRequest(
      retrieveProductNotesAPI,
      {},
    )
  }, [processRequest]);

  const retrieveProductNote = useCallback(async (productNoteId : number) => {
    return await processRequest(
      retrieveProductNoteAPI,
      { productNoteId },
    )
  }, [processRequest]);

  const updateProductNote = useCallback(
    async (
      productNote : ProductNote,
    ) => {
    return await processRequest(
      updateProductNoteAPI,
      { productNote },
    )
  }, [processRequest]);

  const deleteProductNote = useCallback(async (productNote : ProductNote) => {
    return await processRequest(
      deleteProductNoteAPI,
      { productNote },
    )
  }, [processRequest]);

  return {
    createNote,
    retrieveNotes,
    retrieveNote,
    updateNote,
    deleteNote,
    createProductNote,
    retrieveProductNotes,
    retrieveProductNote,
    updateProductNote,
    deleteProductNote,
  };
}

export default useNotesAPI;
