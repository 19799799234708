import { useCallback, useContext } from 'react';

import {
  Channel,
  Integration,
  isChannel,
  isIntegration,
} from '#mrktbox/clerk/types';

import IntegrationContext from '#mrktbox/clerk/context/IntegrationContext';

import useUsers from '#mrktbox/clerk/hooks/useUsers';

import { listRecords } from '#mrktbox/clerk/utils/data';

export const READ_PERMISSION = ['read_all_integrations'];

const default_check_props = {
  redirect : null,
} as {
  redirect : string | null,
}

function useIntegrations() {
  const { checkAuth } = useUsers();
  const {
    integrations,
    channels,
    engines,
    addIntegrationToChannel,
    removeIntegrationFromChannel,
    ...context
  } = useContext(IntegrationContext);

  const getIntegration = useCallback(({
    engineName,
    channelName,
  } : { engineName? : string, channelName? : string }) => {
    const engine = engineName
      ? listRecords(engines).find(e => e.name === engineName)
      : undefined;
    if (engineName && !engine) return null;

    const channel = channelName
      ? listRecords(channels).find(c => c.name === channelName)
      : undefined;
    if (channelName && !channel) return null;

    return listRecords(integrations).find(i => (
      (!engine || i.engineId === engine.id) &&
      (!channel || (channel.id && i.channelIds.includes(channel.id)))
    ));
  }, [integrations, channels, engines]);

  const addChannel = useCallback(async (
    integration : Channel | Integration,
    channel : Channel | Integration,
  ) => {
    if (isIntegration(integration) && isChannel(channel)) {
      return await addIntegrationToChannel(integration, channel);
    } else if (isChannel(integration) && isIntegration(channel)) {
      // DEPR: (channel, integration) => boolean
      return await addIntegrationToChannel(channel, integration);
    } else {
      throw new Error('Invalid argument combination');
    }
  }, [addIntegrationToChannel]);

  const removeChannel = useCallback(async (
    integration : Channel | Integration,
    channel : Channel | Integration,
  ) => {
    if (isIntegration(integration) && isChannel(channel)) {
      return await removeIntegrationFromChannel(integration, channel);
    } else if (isChannel(integration) && isIntegration(channel)) {
      // DEPR: (channel, integration) => boolean
      return await removeIntegrationFromChannel(channel, integration);
    } else {
      throw new Error('Invalid argument combination');
    }
  }, [removeIntegrationFromChannel]);

  // DERP: use checkAuth directly from useAuth to enable redirects
  const checkIntegrationPermissions = useCallback(
    ({ redirect } = default_check_props) => {
      return checkAuth({
        permissions : READ_PERMISSION,
      });
    },
    [checkAuth],
  );

  return {
    integrations,
    channels,
    engines,
    addIntegrationToChannel : addChannel,
    removeIntegrationFromChannel : removeChannel,
    getIntegration,
    checkIntegrationPermissions,
    ...context,
  }
}

export default useIntegrations;
