import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { Partial } from '#mrktbox/clerk/types';

export interface FormState {
  [key : string | number] : any;
};

export function formReducer<RT extends FormState>(
  state : RT | undefined,
  action : Partial<RT> | undefined,
) : RT | undefined {
  if (state === undefined) return undefined;
  return {
    ...state,
    ...(Object.fromEntries(
      Object.entries(action ?? {})
        .filter(([_, value]) => value !== undefined)
    )),
  }
};

function reducer<RT extends FormState>(
  state : RT,
  action : Partial<RT>,
) : RT {
  return formReducer(state, action) as RT;
}

export interface FormContextState<T extends FormState> {
  state? : T;
  dispatch : React.Dispatch<Partial<T>>;
  reset : () => void;
  onChange? : (newState : T) => void;
  key : string;
  setKey : (key : string) => void;
  editing : boolean;
  setEditing : (editing : boolean) => void;
  valid : boolean;
  setValid : (valid : boolean) => void;
  validation : boolean;
  errors : { [key : string] : string[] };
  exists : boolean;
};

const FormContext = createContext<FormContextState<any>>({
  dispatch : () => {},
  reset : () => {},
  onChange : () => {},
  key : '',
  setKey : () => {},
  editing : false,
  setEditing : () => {},
  valid : true,
  setValid : () => {},
  validation : true,
  errors : {},
  exists : false,
});

interface FormProviderProps<T extends FormState> {
  init : T;
  onChange? : (newState : T) => void;
  formKey? : string;
  editing? : boolean;
  editingInit? : boolean;
  valid? : boolean;
  validInit? : boolean;
  validators? : ((state : T) => { [key : string] : string } | null)[];
  children : React.ReactNode;
}

export function FormProvider<T extends FormState>({
  init,
  onChange,
  formKey,
  editing : edit,
  editingInit,
  valid : validated,
  validInit,
  validators,
  children,
} : FormProviderProps<T>) {
  const [state, dispatch] = useReducer(
    reducer<T>,
    init,
  );
  const [key, setKey] = useState(formKey ?? '');
  const [editing, setEditing] = useState(edit ?? editingInit ?? true);
  const [valid, setValid] = useState(validated ?? validInit ?? true);
  const [validation, setValidation] = useState(true);
  const [errors, setErrors] = useState<{ [key : string] : string[] }>({});

  const reset = useCallback(() => {
    if (formKey === undefined) setKey(key === '' ? '_' : '');
  }, [formKey, key, setKey]);

  useEffect(() => {
    if (formKey !== key) {
      dispatch(init);
      setKey(formKey ?? '');
    }
  }, [formKey, key, init, dispatch]);

  useEffect(() => {
    if (edit === undefined) return;
    if ((edit !== editing) && !edit) dispatch(init);
    setEditing(edit);
  }, [edit, editing, init, setEditing, dispatch]);

  useEffect(() => {
    let stateValid = undefined;
    if (validators) {
      stateValid = true;
      const validatorErrors = validators.reduce((acc, validator, i) => {
        const newErrors = validator(state);
        if (newErrors) {
          stateValid = false;
          return Object.entries(newErrors).reduce((a, [k, v]) => {
            if (acc[k]) a[k] = acc[k].concat(v);
            else a[k] = [v];
            return a;
          }, acc);
        }
        return acc;
      }, {} as { [key : string] : string[] });
      setErrors(validatorErrors);
    }
    if (stateValid !== undefined && stateValid !== validation) {
      setValidation(stateValid);
    }

    if (validated ?? stateValid === undefined) return;
    setValid(validated ?? stateValid);
  }, [
    init,
    validated,
    validators,
    state,
    valid,
    validation,
    setValid,
    dispatch,
  ]);

  useEffect(() => {
    if (onChange) onChange(state);
  }, [state, onChange]);

  const context = {
    state,
    dispatch : dispatch  as React.Dispatch<Partial<FormState>>,
    reset,
    onChange : onChange as (newState : FormState) => void,
    key,
    setKey,
    editing,
    setEditing,
    valid,
    setValid,
    validation,
    errors,
    exists : true,
  }

  return (
    <FormContext.Provider value={context}>
      { children }
    </FormContext.Provider>
  );
}

export default FormContext;
