import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useSidebar from '#hooks/useSidebar';

import { X } from '#materials/icons';

interface Style { theme? : Theme; }

const SidebarCloseButton = styled.button<Style>`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 140;
  color: ${(props) => props.theme.palette.background.text.primary};

  &:hover,
  &:active {
    color: ${(props) => props.theme.palette.background.text.hover};
  }

  &:disabled {
    color: ${(props) => props.theme.palette.background.text.primary};
    opacity: 0.75;
  }
`;

function SidebarClose() {
  const { closeSidebar } = useSidebar();

  return (
    <SidebarCloseButton
      onClick={closeSidebar}
      aria-label="Close cart & return to current page"
    >
      <X size={20} />
    </SidebarCloseButton>
  );
}

export default SidebarClose;
