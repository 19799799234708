import styled from '@emotion/styled';

import { Theme } from '#types';

interface HeadlineStyle {
  theme? : Theme;
  size? : keyof Theme['typography']['fonts']['default']['sizes'];
}

const Headline = styled.span<HeadlineStyle>`
  color: ${(props) => props.theme.palette.background.text.primary};
  font-family: ${(props) => props.theme.typography.fonts.title.family};
  font-size: ${(props) => props.size
    ? props.theme.typography.fonts.title.sizes[props.size]
    : props.theme.typography.fonts.title.sizes.large};
  font-weight: ${(props) => props.theme.typography.fonts.title.weights.regular};
  letter-spacing: ${(props) =>
    props.theme.typography.fonts.title.letterSpacing};
  line-height: ${(props) => props.theme.typography.fonts.title.lineHeight};
  -webkit-font-smoothing: antialiased;
`;

export default Headline;
