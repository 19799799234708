import React, { createContext } from 'react';

import { ServiceChannel } from '#mrktbox/clerk/types';

import useData, {
  DataIndex,
  useLoad,
  useRefreshIndex,
  useRefresh,
  useRetrieveIndex,
  useRetrieve,
  useChange,
  useDelete,
} from '#mrktbox/clerk/hooks/useData';
import useServicesAPI from '#mrktbox/clerk/hooks/api/useServicesAPI';

export type ServiceChannelIndex = DataIndex<ServiceChannel>;

const MAX_AGE = 1000 * 60 * 60;

const ServiceChannelContext = createContext({
  serviceChannels: null as DataIndex<ServiceChannel> | null,
  loaded: false,
  load: () => {},
  refreshServiceChannels : async () => null as ServiceChannelIndex | null,
  refreshServiceChannel : async (id : number) => null as ServiceChannel | null,
  retrieveServiceChannels : async () => null as ServiceChannelIndex | null,
  retrieveServiceChannel : async (id : number) => null as ServiceChannel | null,
  createServiceChannel :
    async (serviceChannel : ServiceChannel) => null as ServiceChannel | null,
  updateServiceChannel :
    async (serviceChannel : ServiceChannel) => null as ServiceChannel | null,
  deleteServiceChannel :
    async (serviceChannel : ServiceChannel) => null as boolean | null,
});

interface ServiceChannelProviderProps {
  children : React.ReactNode;
}

export function ServiceChannelProvider({
  children,
} : ServiceChannelProviderProps) {
  const {
    createServiceChannel,
    retrieveServiceChannels,
    retrieveServiceChannel,
    updateServiceChannel,
    deleteServiceChannel,
  } = useServicesAPI();

  const {
    data : serviceChannels,
    dispatch : dispatchServiceChannels,
    lastUpdated,
  } = useData<ServiceChannel>({ storageKey : 'serviceChannels' });

  const newServiceChannel = useChange({
    dispatch : dispatchServiceChannels,
    change : createServiceChannel,
  });
  const refreshServiceChannels = useRefreshIndex({
    dispatch : dispatchServiceChannels,
    retrieve : retrieveServiceChannels,
  });
  const refreshServiceChannel = useRefresh({
    dispatch : dispatchServiceChannels,
    retrieve : retrieveServiceChannel,
  });
  const getServiceChannels = useRetrieveIndex({
    data : serviceChannels,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshServiceChannels,
  });
  const getServiceChannel = useRetrieve({
    data : serviceChannels,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshServiceChannel,
  });
  const amendServiceChannel = useChange({
    dispatch : dispatchServiceChannels,
    change : updateServiceChannel,
  });
  const removeServiceChannel = useDelete({
    dispatch : dispatchServiceChannels,
    delete : deleteServiceChannel,
  });

  const { loaded, load } = useLoad({
    data : serviceChannels,
    loader : refreshServiceChannels,
  });

  const context = {
    serviceChannels,
    loaded,
    load,
    createServiceChannel : newServiceChannel,
    refreshServiceChannels,
    refreshServiceChannel,
    retrieveServiceChannels : getServiceChannels,
    retrieveServiceChannel : getServiceChannel,
    updateServiceChannel : amendServiceChannel,
    deleteServiceChannel : removeServiceChannel,
  };

  return (
    <ServiceChannelContext.Provider value={context}>
      { children }
    </ServiceChannelContext.Provider>
  );
}

export default ServiceChannelContext;
