import React from 'react';
import styled from '@emotion/styled';

import IconButton from '#materials/IconButton';
import { Hamburger } from '#materials/icons';

const Button = styled(IconButton)`
  margin: 0;
  color: ${(props) => props.theme.palette.background.text.primary};

  &:hover {
    color: ${(props) => props.theme.palette.background.text.hover};
  }

  transition: color .2s ease;
`;

interface NavMenuProps {
  onClick? : () => void;
}

function NavMenu({ onClick } : NavMenuProps) {
  return (
    <Button onClick={onClick}>
      <Hamburger size={20} />
    </Button>
  );
}

export default NavMenu;
