import styled from '@emotion/styled';

import { Theme } from '#types';

import Text from '#materials/Text';
import Heading from '#materials/Heading';
import { Check, PlusCircle, XCircle } from '#materials/icons';

interface Style { theme? : Theme; }
interface ViewStyle extends Style {
  isApplied? : boolean;
}

const CheckoutButtonView = styled.button<ViewStyle>`
  display: flex;
  position: relative;
  width: 100%;
  margin: 1rem 0 0;
  align-items: center;
  justify-content: space-between;

  background-color: ${(props) => props.theme.palette.background.fill};

  border-style: solid;
  border:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.isApplied
      ? props.theme.palette.notifications.success.fill
      : props.theme.palette.border};
  border-radius: ${(props) => props.theme.layout.border.radius};

  &:focus, &:active {
    background-color: ${(props) => props.theme.palette.background.fill};
    border-color: ${(props) => props.theme.palette.background.text.hover};
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.background.fill};
    border-color: ${(props) => props.isApplied
      ? props.theme.palette.background.text.alert
      : props.theme.palette.background.text.hover};
  }

  &:disabled {
    background-color: ${(props) => props.theme.palette.background.fill};
    border-color: ${(props) => props.theme.palette.border};
    opacity: 0.75;
  }
`;

const CheckoutButtonCheckmark = styled.div<Style>`
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  z-index: 2;
  padding: ${(props) => props.theme.layout.spacing.xxsmall};

  background-color:
    ${(props) => props.theme.palette.notifications.success.fill};

  border-radius: 50%;

  svg {
    display: block;
    height: ${(props) => props.theme.typography.fonts.default.sizes.small};
    width: ${(props) => props.theme.typography.fonts.default.sizes.small};
    color: ${(props) => props.theme.palette.notifications.success.text};
  }
`;

const CheckoutButtonInfo = styled.div<Style>`
  display: flex;
  padding:
      ${(props) => props.theme.layout.spacing.small}
      0
      ${(props) => props.theme.layout.spacing.small}
      ${(props) => props.theme.layout.spacing.small};
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    padding:
        ${(props) => props.theme.layout.spacing.xsmall}
        0
        ${(props) => props.theme.layout.spacing.xsmall}
        ${(props) => props.theme.layout.spacing.xsmall};
  }
`;

const CheckoutButtonIcon = styled.div<ViewStyle>`
  display: flex;
  margin: 0 ${(props) => props.theme.layout.spacing.small} 0 0;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.palette.background.text.primary};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    margin: 0 ${(props) => props.theme.layout.spacing.xsmall} 0 0;
  }

  @media (max-width: 340px) {
    display: none;
  }
`;

const CheckoutButtonText = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > span {
    display: block;
    text-align: left;
  }
`;

const CheckoutButtonTitle = styled(Heading)<ViewStyle>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.palette.background.text.primary};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  transition: all 0.150s ease;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  }
`;

const CheckoutButtonSubtitle = styled(Text)<ViewStyle>`
  white-space: nowrap;
  margin: ${(props) => props.theme.layout.spacing.xxsmall} 0 0;
  white-space: nowrap;

  color: ${(props) => props.theme.palette.background.text.primary};
  font-size: ${(props) => props.theme.typography.fonts.mono.sizes.small};
  font-family: ${(props) => props.theme.typography.fonts.mono.family};

  transition: all 0.150s ease;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.mono.sizes.xsmall};
  }
`;

const CheckoutButtonSubtitlePrefix = styled(CheckoutButtonSubtitle)<ViewStyle>`
  display: inline;

  @media (max-width: 280px) {
    display: none;
  }
`;

interface ButtonStyle extends Style {
  disabled? : boolean;
}

const CheckoutButtonAction = styled.div<ButtonStyle>`
  display: flex;
  width: auto;
  height: 100%;
  padding: 0 ${(props) => props.theme.layout.spacing.small} 0 0;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  opacity: ${(props) => (props.disabled ? '0.75' : '1')};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    padding: 0 ${(props) => props.theme.layout.spacing.xsmall} 0 0;
  }
`;

const CheckoutButtonActionIcon = styled.div<ViewStyle>`
  width: 1.8rem;
  height: 1.8rem;
  color: ${(props) => props.theme.palette.background.text.primary};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    width: 1.6rem;
    height: 1.6rem;
  }

  transition: all 0.150s ease;

  button:enabled:focus & {
    color: ${(props) => props.theme.palette.background.text.hover};
  }

  button:enabled:hover & {
    color: ${(props) => props.isApplied
      ? props.theme.palette.background.text.alert
      : props.theme.palette.background.text.hover};
    };
  }

  &:disabled {
    color: ${(props) => props.theme.palette.background.text.primary};
    opacity: 0.75;
  }
`;

const CheckoutButtonApply = styled(Text)`
  display: block;
  margin: 0 0 0 ${(props) => props.theme.layout.spacing.xxsmall};

  color: ${(props) => props.theme.palette.background.text.primary};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};

  transition: all 0.150s ease;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
  }

  svg {
    transition: all 0.150s ease;
  }

  button:enabled:focus & {
    color: ${(props) => props.theme.palette.background.text.hover};
  }

  button:enabled:hover & {
    color: ${(props) => props.theme.palette.background.text.hover};
  }
`;

const CheckoutButtonRemove = styled(CheckoutButtonApply)`
  button:enabled:focus & {
    color: ${(props) => props.theme.palette.background.text.hover};
  }

  button:enabled:hover & {
    color: ${(props) => props.theme.palette.background.text.alert};
  }
`;

interface CheckoutButtonProps {
  icon? : React.ReactNode;
  title? : string;
  subtitle? : string;
  subtitlePrefix? : string;
  finePrint? : string;
  onPress? : () => void;
  isApplied? : boolean;
  disabled? : boolean;
  applyText? : string;
}

function CheckoutButton({
  icon,
  title,
  subtitle,
  subtitlePrefix,
  finePrint,
  onPress,
  isApplied,
  disabled,
  applyText = 'Apply',
} : CheckoutButtonProps) {

  return (
    <CheckoutButtonView
      onClick={onPress}
      disabled={disabled}
      isApplied={isApplied}
    >
      { isApplied ? (
        <CheckoutButtonCheckmark>
          <Check />
        </CheckoutButtonCheckmark>
      ) : null }
      <CheckoutButtonInfo>
        { icon && (
          <CheckoutButtonIcon isApplied={isApplied}>
            { icon }
          </CheckoutButtonIcon>
        ) }
        <CheckoutButtonText>
          <CheckoutButtonTitle isApplied={isApplied}>
            { title }
          </CheckoutButtonTitle>
          { subtitle && (
            <CheckoutButtonSubtitle isApplied={isApplied}>
              <CheckoutButtonSubtitlePrefix isApplied={isApplied}>
                { subtitlePrefix }
              </CheckoutButtonSubtitlePrefix>
              { subtitle }
            </CheckoutButtonSubtitle>
          ) }
          { finePrint && (
            <CheckoutButtonSubtitle isApplied={isApplied}>
              { finePrint }
            </CheckoutButtonSubtitle>
          ) }
        </CheckoutButtonText>
      </CheckoutButtonInfo>
      <CheckoutButtonAction disabled={disabled}>
        { isApplied ? (
          <>
            { disabled ? (
              <CheckoutButtonApply>Applied</CheckoutButtonApply>
            ) : (
              <>
                <CheckoutButtonActionIcon isApplied={isApplied}>
                  <XCircle />
                </CheckoutButtonActionIcon>
                <CheckoutButtonRemove>Remove</CheckoutButtonRemove>
              </>
            ) }
          </>
        ) : (
          <>
            <CheckoutButtonActionIcon>
              <PlusCircle />
            </CheckoutButtonActionIcon>
            <CheckoutButtonApply>{ applyText }</CheckoutButtonApply>
          </>
        )}
      </CheckoutButtonAction>
    </CheckoutButtonView>
  );
}

export default CheckoutButton;
