import React from 'react';
import styled from '@emotion/styled';

import { ProjectedOrder } from '@mrktbox/clerk/types';

import { Theme } from '#types';

import OrderCard from '#components/orders/OrderCard';

interface Style { theme? : Theme; }

const OrdersListView = styled.div<Style>`
  display: flex;
  margin:
    ${(props) => props.theme.layout.spacing.xsmall}
    -${(props) => props.theme.layout.spacing.xsmall};
  flex-wrap: wrap;
  justify-content: center;
  opacity: 0;

  animation: slide-up 0.25s ease-in-out 0.25s forwards;
`;

const OrdersListItem = styled.div<Style>`
  width: 36rem;
  max-width: 100%;
  margin: 0 0 ${(props) => props.theme.layout.spacing.small};
  padding: 0 ${(props) => props.theme.layout.spacing.xsmall};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    width: 100%;
  }
`;

interface OrdersListProps {
  orders : ProjectedOrder[];
  pageCount? : number,
}

function OrdersList({
  orders,
  pageCount = 0,
} : OrdersListProps) {

  return (
    <>
      <OrdersListView>
        { orders.slice(0, pageCount || orders.length).map((order, index) => {
          return (
            <OrdersListItem key={order.order?.id || `-${index}`}>
              <OrderCard order={order} />
            </OrdersListItem>
          )
        }) }
      </OrdersListView>
    </>
  );
}

export default OrdersList;
