import React, { useCallback, useMemo, useRef } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import useFocus from '#hooks/useFocus';
import useModal from '#hooks/useModal';
import useRequests from '#hooks/useRequests';

import Transition, { TransitionGroup } from '#materials/Transition';
import Preface from '#materials/Preface';
import TimeButton from '#materials/TimeButton';
import FulfilmentButton from '#materials/FulfilmentButton';
import ServiceButton from '#materials/ServiceButton';

import OrderTime from '#components/orders/OrderTime';

import { shortenAddress } from '#utils/format';

interface Style { theme? : Theme; }
interface DropdownViewStyle extends Style { show? : boolean; }

const OptionsDropdownView = styled.div<DropdownViewStyle>`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  position: fixed;
  top: ${(props) => props.theme.view.nav.height.mobile};
  left: 0;
  right: 0;
  z-index: 40;
  padding: ${(props) => props.theme.layout.spacing.small};
  padding-top:
    calc(${(props) => props.theme.view.nav.height.default}
      - ${(props) => props.theme.view.nav.height.mobile}
      + ${(props) => props.theme.layout.spacing.small});
  opacity: ${(props) => (props.show ? 1 : 0)};
  overflow-y: hidden;

  background-color: ${(props) => props.theme.palette.background.fill};

  transform: translateY(${(props) => (props.show ? '0' : '-100%')});
  transition: all 0.125s ease;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    padding: ${(props) => props.theme.layout.spacing.small};
    top: ${(props) => props.theme.view.nav.height.mobile};
  }
`;

const OptionsDropdownOverlay = styled.div<Style>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
  background-color: ${(props) => props.theme.palette.overlay};
`;

const OptionsDropdownContainer = styled.div<Style>`
  width: 100%;
  max-width: 48rem;
  margin: 0 auto;
`;

const OptionsDropdownRow = styled.div<Style>`
  display: flex;
  padding: 1rem 0;
  align-items: center;
  justify-content: space-between;

  border-top:
    solid
    ${(props) => props.theme.layout.border.width}
    ${(props) => props.theme.palette.border};

  &:first-of-type {
    border: 0;
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  & > span {
    display: block;
    margin: 0;
  }
`;

interface OrderOptionsDropdownProps {
  show : boolean;
  setShow : (show : boolean) => void;
}

function OrderOptionsDropdown({
  show,
  setShow,
} : OrderOptionsDropdownProps) {
  const { openModal } = useModal();
  const {
    serviceChannel,
    address,
    location,
    time,
    waiting,
    getLineItems,
    canUpdateOrder,
    canUpdateItem,
  } = useRequests();

  const dropdown = useRef<HTMLDivElement>(null);

  const selectTime = useCallback(() => {
    openModal(<OrderTime />);
  }, [openModal]);

  const close = useCallback(() => { setShow(false); }, [setShow]);

  const { takeFocus, returnFocus } = useFocus({
    container: dropdown,
    onEscape: close,
  });

  const canUpdate = useMemo(() => (
    canUpdateOrder()
      && getLineItems().every((item) => canUpdateItem(item))
  ), [getLineItems, canUpdateOrder, canUpdateItem]);

  return (
    <>
      <OptionsDropdownView ref={dropdown} show={show}>
        <OptionsDropdownContainer>
          <>
            <OptionsDropdownRow>
              <Preface size="xsmall">Order Type</Preface>
              <ServiceButton
                serviceChannel={serviceChannel}
                fallbackName="Select"
                disabled={waiting || !canUpdate}
                href="/order-types/"
                fullButton={true}
              />
            </OptionsDropdownRow>
            { serviceChannel && (
              <OptionsDropdownRow>
                <Preface size="xsmall">Location</Preface>
                <FulfilmentButton
                  name={location?.name ||
                    (address?.description
                      ? shortenAddress(address.description)
                      : '') ||
                    'Select'
                  }
                  href='/locations/'
                  disabled={waiting || !canUpdate}
                  fullButton={true} />
              </OptionsDropdownRow>
            ) }
            { (address || location) && (
              <OptionsDropdownRow>
                <Preface size="xsmall">Order Time</Preface>
                <TimeButton
                  datetime={time}
                  fallback={'Select'}
                  onClick={selectTime}
                  disabled={waiting || !canUpdate}
                  fullButton={true}
                />
              </OptionsDropdownRow>
            ) }
          </>
        </OptionsDropdownContainer>
      </OptionsDropdownView>
      <TransitionGroup component={null}>
        { show ? (
          <Transition
            key="mobile-menu-overlay"
            classNames="overlay"
            timeout={250}
            onEntered={takeFocus}
            onExited={returnFocus}
          >
            <OptionsDropdownOverlay onClick={close} />
          </Transition>
        ) : null}
      </TransitionGroup>
    </>
  );
}

export default OrderOptionsDropdown;
