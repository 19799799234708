import React, { createContext, useCallback } from 'react';

import { Adjustment, isAdjustment, Condition, Tax } from '#mrktbox/clerk/types';

import useData, {
  actionTypes,
  DataIndex,
  useLoad,
} from '#mrktbox/clerk/hooks/useData';
import useCache from '#mrktbox/clerk/hooks/useDataCache';
import useAdjustmentsAPI from '#mrktbox/clerk/hooks/api/useAdjustmentsAPI';

export type AdjustmentIndex = DataIndex<Adjustment>;

const MAX_AGE = 1000 * 60 * 60;

const AdjustmentContext = createContext({
  adjustments: null as DataIndex<Adjustment> | null,
  loaded: false,
  load: () => {},
  cacheAdjustments: <T extends Adjustment>(
    adjustment: DataIndex<T> | T | null
  ) => null as DataIndex<T> | null,
  createAdjustment: async (adjustment: Adjustment) => null as Adjustment | null,
  refreshAdjustment: async (id: number) => null as Adjustment | null,
  refreshAdjustments: async () => null as AdjustmentIndex | null,
  retrieveAdjustment: async (id: number) => null as Adjustment | null,
  retrieveAdjustments: async () => null as AdjustmentIndex | null,
  updateAdjustment: async (adjustment: Adjustment) => null as Adjustment | null,
  deleteAdjustment: async (adjustment: Adjustment) => null as Adjustment | null,
  addConditionToAdjustment: async (
    adjustment: Adjustment,
    condition: Condition,
  ) => null as Adjustment | null,
  removeConditionFromAdjustment: async (
    adjustment: Adjustment,
    condition: Condition,
  ) => null as Adjustment | null,
  addTaxToAdjustment: async (
    adjustment: Adjustment,
    tax: Tax,
  ) => null as Adjustment | null,
  removeTaxFromAdjustment: async (
    adjustment: Adjustment,
    tax: Tax,
  ) => null as Adjustment | null,
});

interface AdjustmentProviderProps {
  children: React.ReactNode;
}

export function AdjustmentProvider({ children } : AdjustmentProviderProps) {

  const {
    createAdjustment,
    retrieveAdjustments,
    retrieveAdjustment,
    updateAdjustment,
    deleteAdjustment,
    addConditionToAdjustment,
    removeConditionFromAdjustment,
    addTaxToAdjustment,
    removeTaxFromAdjustment
  } = useAdjustmentsAPI();

  const {
    data : adjustments,
    dispatch : dispatchAdjustments,
    lastUpdated,
  } = useData<Adjustment>({ storageKey : 'adjustments' });

  const cacheAdjustments = useCallback(
    <T extends Adjustment>(adjustments : DataIndex<T> | T | null) => {
      if (!adjustments) return null;
      const index = isAdjustment(adjustments)
        ? (adjustments.id ? { [adjustments.id] : adjustments } : {})
        : adjustments;

      dispatchAdjustments({
        type : actionTypes.add,
        data : index,
      });
      return index;
    },
    [dispatchAdjustments],
  );

  const stale = lastUpdated !== undefined
    && (new Date().getTime() - lastUpdated.getTime()) > MAX_AGE;

  const newAdjustment = useCache({
    process : createAdjustment,
    dispatch : dispatchAdjustments,
  });
  const refreshAdjustments = useCache({
    process : retrieveAdjustments,
    dispatch : dispatchAdjustments,
    refresh : true,
    isLoader : true,
  });
  const refreshAdjustment = useCache({
    process : retrieveAdjustment,
    dispatch : dispatchAdjustments,
    refresh : true,
    dropNull : true,
  });
  const getAdjustments = useCache({
    process : retrieveAdjustments,
    dispatch : dispatchAdjustments,
    data : adjustments,
    stale,
    refresh : true,
    isLoader : true,
  });
  const getAdjustment = useCache({
    process : retrieveAdjustment,
    dispatch : dispatchAdjustments,
    data : adjustments,
    stale,
    isLoader : true,
    dropNull : true,
  });
  const amendAdjustment = useCache({
    process : updateAdjustment,
    dispatch : dispatchAdjustments,
  });
  const removeAdjustment = useCache({
    process : deleteAdjustment,
    dispatch : dispatchAdjustments,
    drop : true,
  });

  const addCondition = useCache({
    process : addConditionToAdjustment,
    dispatch : dispatchAdjustments,
  });
  const removeCondition = useCache({
    process : removeConditionFromAdjustment,
    dispatch : dispatchAdjustments,
  });

  const addTax = useCache({
    process : addTaxToAdjustment,
    dispatch : dispatchAdjustments,
  })

  const removeTax = useCache({
    process : removeTaxFromAdjustment,
    dispatch : dispatchAdjustments,
  })

  const { loaded, load } = useLoad({
    data : adjustments,
    loader : refreshAdjustments,
  });

  const context = {
    adjustments,
    loaded,
    load,
    cacheAdjustments,
    createAdjustment : newAdjustment,
    refreshAdjustments,
    refreshAdjustment,
    retrieveAdjustments : getAdjustments,
    retrieveAdjustment : getAdjustment,
    updateAdjustment : amendAdjustment,
    deleteAdjustment : removeAdjustment,
    addConditionToAdjustment : addCondition,
    removeConditionFromAdjustment : removeCondition,
    addTaxToAdjustment : addTax,
    removeTaxFromAdjustment : removeTax,
  };

  return (
    <AdjustmentContext.Provider value={context}>
      {children}
    </AdjustmentContext.Provider>
  );
}

export default AdjustmentContext;
