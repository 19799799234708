import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }
interface SelectViewStyle extends Style {
  hasError? : boolean;
  showLabel? : boolean;
}

const SelectOnlyView = styled.span<Style>`
  position: relative;
  display: block;
  flex-grow: 1;
`;

const SelectView = styled.select<SelectViewStyle>`
  position: relative;
  width: 100%;
  z-index: 5;
  padding:
    ${(props) => props.theme.layout.spacing.small}
    ${(props) => props.theme.layout.spacing.xxsmall}
    ${(props) => props.theme.layout.spacing.xsmall};

  color: ${(props) => props.theme.palette.background.text.primary};
  background-color: ${(props) => props.theme.palette.background.fill};

  border: none;
  border-bottom:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.hasError
      ? props.theme.palette.background.text.alert
      : props.theme.palette.border};

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  font-weight:
    ${(props) => props.theme.typography.fonts.default.weights.regular};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
  letter-spacing:
    ${(props) => props.theme.typography.fonts.default.letterSpacing};
  -webkit-font-smoothing: antialiased;

  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  transition: all 0.150s ease;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
  }

  &:active,
  &:focus,
  &:hover {
    color: ${(props) => props.theme.palette.background.text.primary};
    background-color: ${(props) => props.theme.palette.background.fill};
    border-color: ${(props) => props.theme.palette.background.text.hover};
    outline: none;
  }

  &:disabled {
    cursor: default;
    opacity: 1;
    color: ${(props) => props.theme.palette.background.text.primary};
    border-color: ${(props) => props.theme.palette.border};
    background-color: ${(props) => props.theme.palette.background.fill};
  }
`;

const SelectArrowView = styled.span<Style>`
  display: block;
  position: absolute;
  height: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  bottom: ${(props) => props.theme.layout.spacing.xsmall};
  right: ${(props) => props.theme.layout.spacing.xxsmall};
  z-index: 6;
  padding: 0 0.2rem;

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    height: ${(props) => props.theme.typography.fonts.default.sizes.small};
  }
`;

const SelectArrowContainer = styled.span`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const SelectArrow = styled.span<Style>`
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  margin-top: -0.6rem;

  border-bottom:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.background.text.primary};
  border-right:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.background.text.primary};

    transform: scale(1) rotate(45deg);
`

interface SelectProps<T> {
  id? : string;
  label? : string;
  showLabel? : boolean;
  value : T;
  options : T[];
  onChange? : (value : T) => void;
  generateOption : (option : T) => {
    value : T;
    key? : string | number;
    name : string;
    disabled? : boolean;
  };
  disabled? : boolean;
}

function Select<T>({
  id,
  label,
  showLabel,
  value,
  options : opts,
  onChange,
  generateOption,
  disabled,
} : SelectProps<T>) {
  const [inputOption, setInputOption] = useState(generateOption(value));
  const [options, setOptions] = useState(opts.map(generateOption));

  const handleChange = useCallback(
    (e : React.ChangeEvent<HTMLSelectElement>) => {
      const val = e.target.value;
      const option = options.find((o) => o.key === val);

      if (option) {
        setInputOption(option);
        if (onChange) {
          onChange(option.value);
        }
      }
    },
    [onChange, options]
  );

  useEffect(() => {
    setInputOption(generateOption(value));
  }, [value, generateOption]);

  useEffect(() => {
    setOptions(opts.map(generateOption));
  }, [opts, generateOption]);

  return (
    <SelectOnlyView>
      <SelectView
        aria-label={label}
        id={id}
        value={inputOption.key}
        onChange={handleChange}
        disabled={disabled}
        showLabel={!!label && showLabel}
      >
        {options ? (
          options.map((option, index) => (
            <option
              key={`${option.value}-${index}`}
              value={option.key}
              disabled={option.disabled || false}
            >
              {option.name}
            </option>
          ))
        ) : (
          <option>No Options Available</option>
        )}
      </SelectView>
      <SelectArrowView>
        <SelectArrowContainer>
          <SelectArrow />
        </SelectArrowContainer>
      </SelectArrowView>
    </SelectOnlyView>
  );
}

export default Select;
