import React, { createContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import useConfig from '#hooks/useConfig';

const ChatContext = createContext({
  bottom: 20,
  setBottom: (bottom : number) => {}
});

interface ChatProviderProps {
  children : React.ReactNode;
}

export function ChatProvider({ children } : ChatProviderProps) {
  const { settings } = useConfig();

  const [initialized, setInitialized] = useState(false);
  const [bottom, setBottom] = useState(20);

  useEffect(() => {
    const gorgiasScriptId = settings.chat.script.id;
    const gorgiasScript = settings.chat.script.src;

    if (gorgiasScriptId && gorgiasScript) {
      const script = document.createElement('script');
      script.id = gorgiasScriptId;
      script.src = gorgiasScript;
      script.async = true;
      document.body.appendChild(script);
    }
    return () => {
      const script = !!gorgiasScriptId
        && document.getElementById(gorgiasScriptId);
      if (script) script.remove();
    }
  }, [settings]);

  useEffect(() => {
    if (initialized) return;
    const init = setInterval(() => {
      const button = document.getElementById('chat-button');
      const window = document.getElementById('chat-window');
      const campaign = document.getElementById('chat-campaign');
      if (button) button.style.zIndex = `15`;
      if (window) window.style.zIndex = `16`;
      if (campaign) campaign.style.zIndex = `16`;

      if (!button) return;
      setInitialized(true);
    }, 1000);
    return () => clearInterval(init);
  }, [initialized]);

  useEffect(() => {
    const gorgiasScriptId = process.env.REACT_APP_GORGIAS_SCRIPT_ID;
    const gorgiasScript = process.env.REACT_APP_GORGIAS_SCRIPT;

    if (gorgiasScriptId && gorgiasScript) {
      const windowBottom = isMobile ? 20 : bottom;
      const button = document.getElementById('chat-button');
      const window = document.getElementById('chat-window');
      const campaign = document.getElementById('chat-campaign');
      if (button) {
        button.style.bottom = `${bottom}px`;
        button.style.transition = 'bottom 0.5s';
      }
      if (window) {
        window.style.bottom = `${windowBottom + 70}px`;
        window.style.height = `calc(100% - ${160 + windowBottom}px)`;
        window.style.transition = 'bottom 0.5s, height 0.5s';
      }
      if (campaign) {
        campaign.style.bottom = `${windowBottom + 70}px`;
        campaign.style.height = `calc(100% - ${160 + windowBottom}px)`;
        campaign.style.transition = 'bottom 0.5s, height 0.5s';
      }
    }
  });

  const context = {
    bottom : bottom ?? 20,
    setBottom
  };

  return (
    <ChatContext.Provider value={context}>
      { children }
    </ChatContext.Provider>
  );
}

export default ChatContext;
