import { useContext, useMemo } from 'react';

import ConfigContext from '#context/ConfigContext';
import ThemeContext from '#context/ThemeContext';

function useConfig() {
  const { config } = useContext(ConfigContext);
  const { theme } = useContext(ThemeContext);

  return useMemo(() => ({ ...config, theme }), [config, theme]);
}

export default useConfig;
