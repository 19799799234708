import React, { useCallback } from 'react';

import { useNotifications } from '#mrktbox';

import { ThemeProvider } from '#context/ThemeContext';

import Banner from '#materials/Banner';

function HeaderBanner() {
  const { nextBroadcast, acknowledgeBroadcast } = useNotifications();

  const onClose = useCallback(() => {
    if (!nextBroadcast) return;
    acknowledgeBroadcast(nextBroadcast);
  }, [acknowledgeBroadcast, nextBroadcast]);

  return (
    <ThemeProvider themeKey={'banner'} >
      { nextBroadcast && (
        <Banner
          link={nextBroadcast.link}
          href={nextBroadcast.href}
          onClose={onClose}
        >
          Welcome to our new website! Need help scheduling your order?
        </Banner>
      ) }
    </ThemeProvider>
  );
}

export default HeaderBanner;
