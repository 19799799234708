import styled from '@emotion/styled';

import { Theme } from '#types';

const Box = styled.div<{ theme? : Theme }>`
  color: ${(props) => props.theme.palette.background.text.primary};
  background-color: ${(props) => props.theme.palette.background.fill};
  border:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.theme.palette.border};
  border-radius: ${(props) => props.theme.layout.border.radius};
  box-shadow: ${(props) => props.theme.layout.boxShadow.outer.map((bs) => (`
    ${bs.x} ${bs.y} ${bs.blur} ${bs.spread} ${bs.colour}
  `)).join(', ')};
`;

export default Box;
