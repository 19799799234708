import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { Product, LineItem } from '#mrktbox/types';
import { useOptions, useSubscriptions, useNotes } from '#mrktbox';

import useCatalogue from '#hooks/useCatalogue';
import useRequests from '#hooks/useRequests';

import { Theme } from '#types';

import Body from '#materials/Body';
import ProductHeader from '#components/products/ProductHeader';
import ProductFooter from '#components/products/ProductFooter';
import ProductTags from '#components/products/ProductTags';
import ProductAccordion from '#components/products/ProductAccordion';
import ProductItemImage from '#components/products/ProductItemImage';
import ProductOptions from '#components/products/ProductOptions';

interface Style {
  theme? : Theme;
}

const ProductView = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
`;

const ProductContent = styled.div<Style>`
  flex: 1 1 auto;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.palette.background.fill};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ProductContentView = styled.div<Style>``;

const ProductDetails = styled.div<Style>`
  padding: 0 ${(props) => props.theme.layout.spacing.small};
`;

const ProductDesc = styled(Body)<Style>`
  margin: 1rem 0 0;
  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  }
`;

interface ProductItemProps {
  product : Product;
  quantity : number;
  lineItem? : LineItem | null;
  setQuantity : (quantity: number) => void;
}

function ProductItem({
  product,
  quantity,
  lineItem,
  setQuantity,
} : ProductItemProps){
  const productContainerRef = useRef<HTMLDivElement>(null);
  const productContentRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);

  const { isProductCustomisable } = useOptions();
  const { findLineItemSubscription } = useSubscriptions();
  const { getLineItemNotes, canProductHaveNote } = useNotes();
  const { getProductTags, getCurrentSelections } = useCatalogue();
  const {
    time,
    currentOrder,
    evaluateReccurence,
    canSubscribe,
  } = useRequests();

  const [note, setNote] = useState(lineItem
    ? getLineItemNotes(lineItem)[0]?.content ?? ''
    : ''
  );

  const determinePeriod = useCallback(() => {
    const subcription = (lineItem && currentOrder)
      ? (findLineItemSubscription(lineItem, currentOrder))
      : null;
    if (!subcription) return 0;
    return evaluateReccurence(
      subcription,
      { iteration : currentOrder?.timeSlotIteration }
    );
  }, [lineItem, currentOrder, evaluateReccurence, findLineItemSubscription]);

  const getSelections = useCallback(() => {
    return getCurrentSelections(product, currentOrder, lineItem);
  }, [product, currentOrder, lineItem, getCurrentSelections]);

  const [tags, setTags] = useState(getProductTags(product));
  const [lineItemId, setLineItemId] = useState(lineItem?.id ?? null);
  const [selections, setSelections] = useState(getSelections());
  const [period, setPeriod] = useState(determinePeriod());

  const [compact, setCompact] = useState(false);
  const [optionsTop, setOptionsTop] = useState(0);
  const [footerShadow, setFooterShadow] = useState(false);

  useEffect(() => {
    if (lineItem?.id !== lineItemId) {
      setLineItemId(lineItem?.id ?? null);
      setSelections(getSelections());
      setPeriod(determinePeriod());
    }
  }, [lineItem, lineItemId, getSelections, determinePeriod]);

  useEffect(() => {
    setTags(getProductTags(product));
  }, [product, getProductTags]);

  useEffect(() => {
    const container = productContainerRef.current;
    const content = productContentRef.current;
    const header = headerRef.current;
    const img = imageRef.current;
    if (!container || !content || !header || !img) return;

    const handleScroll = () => {
      const headerbottom = (img.offsetTop + img.offsetHeight + 20);
      if (container.scrollTop > headerbottom) setCompact(true);
      else if (container.scrollTop === 0
        || container.scrollTop < headerbottom + 16) setCompact(false);
      setOptionsTop(header.offsetHeight - 1);

      const continerHeight = container.clientHeight;
      const contentHeight = content.clientHeight;
      if (contentHeight <= continerHeight) {
        setFooterShadow(false);
        return;
      }

      console.log({
        scrollTop: container.scrollTop,
        continerHeight,
        contentHeight,
        offset : container.scrollTop + continerHeight,
      });
      setFooterShadow(container.scrollTop + continerHeight < contentHeight);
    }

    productContainerRef.current.addEventListener('scroll', handleScroll);
    return () => { container.removeEventListener('scroll', handleScroll); }
  }, [productContainerRef, headerRef, imageRef]);

  return (
    <ProductView>
      <ProductContent id="product-content" ref={productContainerRef} >
        <ProductContentView ref={productContentRef}>
          <ProductItemImage imageRef={imageRef} product={product} />
          <ProductHeader
            product={product}
            selections={selections}
            compact={compact}
            headerRef={headerRef}
          />
          <ProductDetails>
            <ProductTags tags={tags} />
            <ProductDesc as="p">{ product.description }</ProductDesc>
          </ProductDetails>
          <ProductAccordion
            product={product}
            note={canProductHaveNote(product) ? note : undefined}
            setNote={setNote}
            period={period}
            setPeriod={canSubscribe() ? setPeriod : undefined}
          />
          { isProductCustomisable(product, time ?? new Date()) && (
            <ProductOptions
              product={product}
              selections={selections}
              setSelections={setSelections}
              scrollOffset={optionsTop}
              scrollRef={productContainerRef}
            />
          ) }
        </ProductContentView>
      </ProductContent>
      <ProductFooter
        product={product}
        selections={selections}
        lineItem={lineItem}
        quantity={quantity}
        setQuantity={setQuantity}
        note={note}
        period={period}
        shadow={footerShadow}
      />
    </ProductView>
  );
}

export default ProductItem;
