import { useCallback, useContext } from 'react';

import ProductContext from '#mrktbox/clerk/context/ProductsContext';

import useProductsAPI from '#mrktbox/clerk/hooks/api/useProductsAPI';

function useProducts() {
  const {
    products,
    loaded,
    load,
    refreshProduct,
    refreshProductsBulk,
    refreshProducts,
    createProduct,
    retrieveProduct,
    retrieveProductsBulk,
    retrieveProducts,
    updateProduct,
    deleteProduct,
    importProduct,
    createProductIntegration,
    deleteProductIntegration,
    syncProductIntegration,
    pushProductIntegration,
    pullProductIntegration,
    addServiceChannelToProduct,
    removeServiceChannelFromProduct,
    createProductImageUpload,
    pullProductImage,
    removeImageFromProduct,
    searchProducts,
    regenerateProductIndex,
  } = useContext(ProductContext);

  const { retrieveExternalProducts } = useProductsAPI();

  const refProduct = useCallback(async (
    productId : number,
    options? : { withIntegrations? : boolean } | boolean,
  ) => {
    if (typeof options === 'boolean') {
      options = { withIntegrations : options };
    }
    return await refreshProduct(productId, options);
  }, [refreshProduct]);

  const retProduct = useCallback(async (
    productId : number,
    options? : { withIntegrations? : boolean } | boolean,
  ) => {
    if (typeof options === 'boolean') {
      options = { withIntegrations : options };
    }
    return await retrieveProduct(productId, options);
  }, [retrieveProduct]);

  return {
    products,
    loaded,
    load,
    refreshProduct : refProduct,
    refreshProductsBulk,
    refreshProducts,
    createProduct,
    retrieveProduct : retProduct,
    retrieveProductsBulk,
    retrieveProducts,
    updateProduct,
    deleteProduct,
    retrieveExternalProducts,
    importProduct,
    createProductIntegration,
    deleteProductIntegration,
    syncProductIntegration,
    pushProductIntegration,
    pullProductIntegration,
    addServiceChannelToProduct,
    removeServiceChannelFromProduct,
    searchProducts,
    regenerateProductIndex,
    createProductImageUpload,
    newProductImageUpload : createProductImageUpload,
    pullProductImage,
    importProductImage : pullProductImage,
    removeImageFromProduct,
    dropImageFromProduct : removeImageFromProduct,
  };
}

export default useProducts;
