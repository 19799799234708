import styled from '@emotion/styled'

import { Currency } from '#mrktbox/types';
import { formatCurrency } from '#mrktbox/utils';

import { Theme } from '#types';

import Heading from '#materials/Heading';

interface Style {
  theme? : Theme;
}

const ProductPriceCalsView = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

interface ProductPriceProps extends Style {
  size : keyof Theme['typography']['fonts']['default']['sizes'];
  font? : keyof Theme['typography']['fonts'];
  fade? : boolean;
  strike? : boolean;
}

const ProductPrice = styled(Heading)<ProductPriceProps>`
  color: inherit;

  white-space: nowrap;
  white-space-collapse: preserve;
  font-family: ${(props) =>
    props.theme.typography.fonts[props.font || 'default'].family};
  font-size: ${(props) =>
    props.theme.typography.fonts[props.font || 'default'].sizes[props.size]};
  ${(props) => props.strike && 'text-decoration: line-through;'}

  ${(props) => props.fade && `
    animation: fade-in 0.1s ease-in-out 0s forwards;
  `}
`;

interface ProductPriceCalsProps {
  price : Currency;
  adjustment? : Currency;
  cals? : string | number;
  absolute? : boolean;
  size: keyof Theme['typography']['fonts']['default']['sizes'];
  variant? : 'default' | 'strike' | 'deal';
  children? : any;
  style? : any;
}

function ProductPriceCals({
  price,
  adjustment,
  absolute = false,
  size = 'small',
  variant = 'default',
  children,
  style,
}: ProductPriceCalsProps){
  if (absolute && price && price?.amount < 0) {
    price = {
      ...price,
      amount : -price.amount,
      calculatedValue : -price.calculatedValue,
    };
  }

  const currencyFormatted = (price ? formatCurrency(price) : undefined)

  return (
    <ProductPriceCalsView style={style}>
      { price && (
        <ProductPrice
          size={size}
          font={variant === 'deal' ? 'brand' : 'default'}
          strike={variant === 'strike'}
        >
          { currencyFormatted }
        </ProductPrice>
      ) }
      { !!adjustment?.amount && (
        <ProductPrice size={size} fade>
          { (adjustment.amount >= 0 ? ' + ' : ' - ')
            + formatCurrency(adjustment) }
        </ProductPrice>
      ) }
      { children }
    </ProductPriceCalsView>
  )
}

export default ProductPriceCals;
