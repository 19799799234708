import { Supplier, isSupplier } from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

const SUPPLIERS_PATH = 'suppliers/';

function parseSupplier(supplier : any) {
  if (!isSupplier(supplier))
    throw new TypeError('Supplier is not a supplier');
  return supplier;
}

function parseSuppliers(suppliers : any) {
  const parsedSuppliers : { [id : number] : Supplier } = {};
  for (const supplierId in suppliers) {
    if (typeof supplierId !== 'string')
      throw new TypeError('Supplier id is not a string');

    const id = parseInt(supplierId);
    if (isNaN(id)) throw new TypeError('Supplier id is not a number');

    parsedSuppliers[id] = parseSupplier(suppliers[id]);
  }

  return parsedSuppliers;
}

export async function createSupplier(
  { supplier } : { supplier : Supplier },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(SUPPLIERS_PATH),
    methods.post,
    { supplier },
    options,
  );

  try {
    return parseSupplier(response.supplier);
  } catch {
    throw new DeserializationError(
      'Could not deserialize supplier',
      response,
    );
  }
}

export async function retrieveSuppliers(
  input : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(SUPPLIERS_PATH),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseSuppliers(response.suppliers);
  } catch {
    throw new DeserializationError(
      'Could not deserialize suppliers',
      response,
    );
  }
}

export async function retrieveSupplier(
  { supplierId } : { supplierId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SUPPLIERS_PATH}${supplierId}`),
    methods.get,
    undefined,
  );

  try {
    return parseSupplier(response.supplier);
  } catch {
    throw new DeserializationError(
      'Could not deserialize supplier',
      response,
    );
  }
}

export async function updateSupplier(
  { supplier } : { supplier : Supplier },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SUPPLIERS_PATH}${supplier.id}`),
    methods.put,
    { supplier },
    options,
  );

  try {
    return parseSupplier(response.supplier);
  } catch {
    throw new DeserializationError(
      'Could not deserialize supplier',
      response,
    );
  }
}

export async function deleteSupplier(
  { supplier } : { supplier : Supplier },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${SUPPLIERS_PATH}${supplier.id}`),
    methods.delete,
  );

  return !!response;
}
