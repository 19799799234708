import React, { createContext } from 'react';

import { Service } from '#mrktbox/clerk/types';

import useData, {
  DataIndex,
  useLoad,
  useRefreshIndex,
  useRefresh,
  useRetrieveIndex,
  useRetrieve,
  useChange,
  useDelete,
} from '#mrktbox/clerk/hooks/useData';
import useServicesAPI from '#mrktbox/clerk/hooks/api/useServicesAPI';

export type ServiceIndex = DataIndex<Service>;

const MAX_AGE = 1000 * 60 * 60;

const ServiceContext = createContext({
  services: null as DataIndex<Service> | null,
  loaded: false,
  load: () => {},
  refreshServices : async () => null as ServiceIndex | null,
  refreshService : async (id : number) => null as Service | null,
  retrieveServices : async () => null as ServiceIndex | null,
  retrieveService : async (id : number) => null as Service | null,
  createService : async (service : Service) => null as Service | null,
  updateService : async (service : Service) => null as Service | null,
  deleteService : async (service : Service) => null as boolean | null,
});

interface ServiceProviderProps {
  children : React.ReactNode;
}

export function ServiceProvider({
  children,
} : ServiceProviderProps) {
  const {
    createService,
    retrieveServices,
    retrieveService,
    updateService,
    deleteService,
  } = useServicesAPI();

  const {
    data : services,
    dispatch : dispatchServices,
    lastUpdated,
  } = useData<Service>({ storageKey : 'services' });

  const newService = useChange({
    dispatch : dispatchServices,
    change : createService,
  });
  const refreshServices = useRefreshIndex({
    dispatch : dispatchServices,
    retrieve : retrieveServices,
  });
  const refreshService = useRefresh({
    dispatch : dispatchServices,
    retrieve : retrieveService,
  });
  const getServices = useRetrieveIndex({
    data : services,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshServices,
  });
  const getService = useRetrieve({
    data : services,
    timestamp : lastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshService,
  });
  const amendService = useChange({
    dispatch : dispatchServices,
    change : updateService,
  });
  const removeService = useDelete({
    dispatch : dispatchServices,
    delete : deleteService,
  });

  const { loaded, load } = useLoad({
    data : services,
    loader : refreshServices,
  });

  const context = {
    services,
    loaded,
    load,
    createService : newService,
    refreshServices,
    refreshService,
    retrieveServices : getServices,
    retrieveService : getService,
    updateService : amendService,
    deleteService : removeService,
  }

  return (
    <ServiceContext.Provider value={context}>
      { children }
    </ServiceContext.Provider>
  );
}

export default ServiceContext;
