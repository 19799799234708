import * as JsSearch from 'js-search';

import {
  SearchIdentifier,
  SearchIndex,
  TokenMap,
  JsIndex,
  Search,
  isSearchIdentifier,
} from '#mrktbox/clerk/types';

export function buildSearch(index : SearchIndex) {
  const search = new JsSearch.Search('token') as unknown as Search;

  const jsIndex : JsIndex = {};
  const documents = {} as {
    [id : SearchIdentifier ] : { id : SearchIdentifier };
  };

  index.entries.forEach(entry => {
    const map = {} as TokenMap;
    let tokenCount = 0;

    Object.keys(entry.scores).forEach(id => {
      // build placholder documents with uid field
      if (!(id in documents)) documents[id] = { id };
      const record = documents[id];

      map[id] = {
        $document : record,
        $numTokenOccurrences : entry.scores[id]
      }
      tokenCount += entry.scores[id];
    })

    entry.tokens.forEach(token => {
      jsIndex[token] = {
        $numDocumentOccurrences : Object.keys(entry.scores).length,
        $totalNumOccurrences : tokenCount,
        $uidMap : map
      };
    })
  });

  search._documents = Object.values(documents);
  search._searchIndex._uidFieldName = 'id';
  search._searchIndex._tokenMap = jsIndex;
  return search;
}

export function querySearch(search : Search, query : string) {
  const results = search.search(query).map((result : any) => result?.id);

  const safeResults = [] as SearchIdentifier[];
  results.forEach(result => {
    if (isSearchIdentifier(result))
      safeResults.push(result);
  })

  return safeResults;
}
