import React from 'react';

import { PaymentToken, Subtotal } from '#mrktbox/types';

import SquareForm from '#components/creditCards/SquareForm';

interface CreditCardFormProps {
  total? : Subtotal,
  onSaved? : (token : PaymentToken) => boolean | Promise<boolean>,
  onCancel? : () => void,
}

function CreditCardForm(props : CreditCardFormProps) {

  return (
    <SquareForm {...props} />
  );
}

export default CreditCardForm;
