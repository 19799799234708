import React, { useCallback } from 'react';

import useSidebar from '#hooks/useSidebar';
import useRequests from '#hooks/useRequests';

import BackButton from '#materials/BackButton';
import NavButton from '#materials/NavButton';

import Header from '#components/header/Header';
import Nav from '#components/nav/Nav';

interface ProfileHeaderProps {
  maxWidth? : string;
}

function ProfileHeader({ maxWidth } : ProfileHeaderProps) {
  const { openSidebar } = useSidebar();
  const { currentOrder } = useRequests();

  const handleNav = useCallback(() => {
    openSidebar(<Nav />);
  }, [openSidebar]);

  const back = (
    window.location.pathname.includes('/orders/')
      && !(window.location.pathname === '/orders/'
        && window.location.search.length === 0)
      && (!currentOrder?.order?.id
        || !window.location.pathname.includes(
          `/orders/${currentOrder?.order?.id}`
        ))
  ) ? '/orders/' : '/';

  return (
    <Header
      maxWidth={maxWidth}
      left={<BackButton path={back} />}
      right={<NavButton onClick={handleNav} />}
    />
  );
}

export default ProfileHeader;
