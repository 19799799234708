import { Tag, isTag, Product, ServiceChannel } from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

const SERVICE_CHANNELS_PATH = 'service-channels/';
const PRODUCTS_PATH = 'products/'
const TAGS_PATH = 'tags/';

function parseTag(tag : any) {
  if (!isTag(tag))
    throw new TypeError('Tag is not a tag');
  return tag;
}

function parseTags(tags : any) {
  const parsedTags : { [id : number] : Tag } = {};
  for (const tagId in tags) {
    if (typeof tagId !== 'string')
      throw new TypeError('Tag id is not a string');

    const id = parseInt(tagId);
    if (isNaN(id)) throw new TypeError('Tag id is not a number');

    parsedTags[id] = parseTag(tags[id]);
  }

  return parsedTags;
}

export async function createTag(
  { tag } : {tag : Tag},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(TAGS_PATH),
    methods.post,
    { tag },
    options,
  );

  try {
    return parseTag(response.tag);
  } catch {
    throw new DeserializationError(
      'Could not deserialize tag',
      response,
    );
  }
}

export async function retrieveTags(
  input : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(TAGS_PATH),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseTags(response.tags);
  } catch {
    throw new DeserializationError(
      'Could not deserialize tags',
      response,
    );
  }
}

export async function retrieveTag(
  { tagId } : { tagId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAGS_PATH}${tagId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseTag(response.tag);
  } catch {
    throw new DeserializationError(
      'Could not deserialize tag',
      response,
    );
  }
}

export async function updateTag(
  { tag } : { tag : Tag },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAGS_PATH}${tag.id}`),
    methods.put,
    { tag },
    options,
  );

  try {
    return parseTag(response.tag);
  } catch {
    throw new DeserializationError(
      'Could not deserialize tag',
      response,
    );
  }
}

export async function deleteTag(
  { tag } : { tag : Tag },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAGS_PATH}${tag.id}`),
    methods.delete,
    undefined,
    options,
  );

  return !!response?.success;
}

export async function addProductToTag({
  product,
  tag,
  before,
} : { product : Product, tag : Tag, before? : Product },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAGS_PATH}${tag.id}/${PRODUCTS_PATH}${product.id}`),
    methods.post,
    before?.id ? { before : before.id } : {},
    options,
  );

  return !!response;
}

export async function removeProductFromTag(
  { product, tag } : { product : Product, tag : Tag },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAGS_PATH}${tag.id}/${PRODUCTS_PATH}${product.id}`),
    methods.delete,
    undefined,
    options,
  );

  return !!response;
}

export async function addServiceChannelToTag(
  { tag, serviceChannel } : { tag : Tag, serviceChannel : ServiceChannel },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAGS_PATH}${tag.id}/${SERVICE_CHANNELS_PATH}` +
      `${serviceChannel.id}`),
    methods.post,
    {},
    options,
  );

  return !!response;
}

export async function removeServiceChannelFromTag(
  { tag, serviceChannel } : { tag : Tag, serviceChannel : ServiceChannel },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${TAGS_PATH}${tag.id}/${SERVICE_CHANNELS_PATH}` +
      `${serviceChannel.id}`),
    methods.delete,
    undefined,
    options,
  );

  return !!response;
}
