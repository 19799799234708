import styled from '@emotion/styled'

import { Theme } from '#types';

interface Style { theme? : Theme; }

const CardItem = styled.div<Style>`
  label: CardMenuItem;
  border-style: solid;

  background-colour: ${(props) => props.theme.palette.background.fill};

  border-width: ${(props) => props.theme.layout.border.width};
  border-colour: ${(props) => props.theme.palette.border};
  border-radius: ${(props) => props.theme.layout.border.radius};
  box-shadow: ${(props) => props.theme.layout.boxShadow.outer.map((bs) => (`
    ${bs.x} ${bs.y} ${bs.blur} ${bs.spread} ${bs.colour}
  `)).join(', ')};
`;

export default CardItem;
