import styled from '@emotion/styled'

import { Theme } from '#types'

interface IconButtonStyle {
  theme? : Theme;
  margin? : string;
  filled? : boolean;
}

const IconButton = styled.button<IconButtonStyle>`
  display: flex;
  width: ${(props) => props.theme.layout.spacing.medium};
  height: ${(props) => props.theme.layout.spacing.medium};
  margin: ${(props) => props.theme.layout.spacing.xxsmall};
  padding: ${(props) => props.filled
      ? props.theme.layout.spacing.xsmall
      : 0};
  align-items: center;
  justify-content: center;

  color: ${(props) => props.color || (props.filled
    ? props.theme.palette.accent.primary.text
    : props.theme.palette.background.text.primary)};
  background-color: ${(props) => props.filled
    ? props.theme.palette.accent.primary.fill
    : props.theme.palette.background.fill};

  border:
    ${(props) => props.theme.layout.border.width}
    solid
    ${(props) => props.filled
      ? p => p.theme.palette.accent.primary.fill
      : 'transparent'};
  border-radius: 50%;

  cursor: pointer;
  transition: all 0.150s ease;

  &:hover {
    color: ${(props) => props.color || (props.filled
      ? props.theme.palette.background.fill
      : props.theme.palette.background.text.hover)};
    background-color: ${(props) => props.filled
      ? props.theme.palette.background.text.hover
      : props.theme.palette.background.fill};
    border:
      ${(props) => props.theme.layout.border.width}
      solid
      ${(props) => props.filled
        ? props.theme.palette.background.fill
        : 'transparent'};
  }
`;

export default IconButton;
