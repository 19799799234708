import { useContext, useCallback } from 'react';

import { Category } from '#mrktbox/clerk/types';
import CategoryContext from '#mrktbox/clerk/context/CategoryContext';
import { listRecords } from '#mrktbox/clerk/utils';

function useCategories() {
  const {
    categories,
    loaded,
    load,
    refreshCategories,
    refreshCategory,
    createCategory,
    retrieveCategories,
    retrieveCategory,
    updateCategory,
    deleteCategory,
    addSubcategoryToCategory,
    removeSubcategoryFromCategory,
    createCategoryImageUpload,
    pullCategoryImage,
    removeImageFromCategory,
  } = useContext(CategoryContext);

  const retrieveCategorySubcategories = useCallback((category : Category) => {
    return category.subcategoryIds.reduce((acc, subcategoryId) => {
      const subcategory = categories
        ? listRecords(categories).find(c => c?.id === subcategoryId)
        : undefined;
      if (subcategory) acc.push(subcategory);
      return acc;
    }, [] as Category[]);
  }, [categories]);

  return {
    categories,
    loaded,
    load,
    refreshCategories,
    refreshCategory,
    createCategory,
    retrieveCategories,
    retrieveCategory,
    updateCategory,
    deleteCategory,
    retrieveCategorySubcategories,
    addSubcategoryToCategory,
    removeSubcategoryFromCategory,
    createCategoryImageUpload,
    pullCategoryImage,
    removeImageFromCategory,
  };
}

export default useCategories;
