import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style {
  theme? : Theme;
}

export const FormWrapper = styled('div')<Style>`
  width: 100%;
  max-width: 60rem;
  margin: 0 auto ${(props) => props.theme.layout.spacing.large};
  padding: ${(props) => props.theme.layout.spacing.medium};
  overflow: hidden;

  color: ${(props) => props.theme.palette.background.text.primary};
  background-color: ${(props) => props.theme.palette.background.fill};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    margin: 0 auto ${(props) => props.theme.layout.spacing.medium};
    padding: ${(props) => props.theme.layout.spacing.small};
  }
`;

interface FormSubmitStyle extends Style { compact? : boolean }

export const FormSubmit = styled('div')<FormSubmitStyle>`
  display: flex;
  width: fit-content;
  max-width: 100%;
  ${(props) => props.compact
    ? 'margin: 0 auto;'
    : `margin: ${props.theme.layout.spacing.medium} auto 0;`
  }
  text-align: center;

  button + button {
    margin: 0 0 0 ${(props) => props.theme.layout.spacing.xsmall};
  }
`;

const InputView = styled.div<Style>`
  width: 100%;
  margin: ${(props) => props.theme.layout.spacing.small} 0 0;

  button + button {
    margin-left: ${(props) => props.theme.layout.spacing.xsmall};
  }
`;

interface FormProps {
  error? : React.ReactNode;
  buttons? : React.ReactNode;
  compact? : boolean;
  onSubmit? : () => void;
  children? : React.ReactNode;
}

function Form({
  error,
  buttons,
  compact = false,
  onSubmit,
  children,
} : FormProps) {
  const handleSubmit = useCallback((e : React.FormEvent<HTMLFormElement>) => {
    if (onSubmit) onSubmit();
    e.preventDefault();
  }, [onSubmit]);

  return (
    <form onSubmit={handleSubmit}>
      { error }
      <InputView>
        { children }
      </InputView>
      <FormSubmit compact={compact} >
        { buttons }
      </FormSubmit>
    </form>
  );
}

export default Form;
