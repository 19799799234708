import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface SkipLinkViewStyle {
  theme? : Theme;
}

const SkipLinkView = styled.a<SkipLinkViewStyle>`
  display: inline-block;
  position: absolute;
  bottom: 100%;
  left: ${(props) => props.theme.layout.spacing.xsmall};
  z-index: 300;
  margin: 0;
  padding:
    ${(props) => props.theme.layout.spacing.xsmall}
    ${(props) => props.theme.layout.spacing.small};

  margin:
    ${(props) => props.theme.layout.spacing.xsmall}
    0 0
    ${(props) => props.theme.layout.spacing.xsmall};

  color: ${(props) => props.theme.palette.accent.primary.text};
  background-color: ${(props) => props.theme.palette.accent.primary.fill};

  border-style: solid;
  border-width: ${(props) => props.theme.layout.border.width};
  border-radius: ${(props) => props.theme.layout.border.radius};
  border-color: ${(props) => props.theme.palette.border};

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  font-weight: ${(props) =>
    props.theme.typography.fonts.default.weights.regular};
  line-height: 1;
  letter-spacing: ${(props) =>
    props.theme.typography.fonts.default.letterSpacing};
  text-align: center;
  -webkit-font-smoothing: antialiased;

  &:focus {
    bottom: auto;
    top: calc(${(props) => props.theme.view.nav.height.default} +
      ${(props) => props.theme.layout.spacing.xsmall});

    color: ${(props) => props.theme.palette.accent.primary.text};

    @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
      top: calc(${(props) => props.theme.view.nav.height.mobile} +
        ${(props) => props.theme.layout.spacing.xsmall});
    }
  }
`

const SkipLink = ({ id = 'main' }) => {
  const mainId = id.includes('#') ? id : `#${id}`

  return (
    <SkipLinkView href={mainId}>
      Skip to Main Content
    </SkipLinkView>
  );
}

export default SkipLink;
