import React from 'react';

import TextArea from '#materials/TextArea';

interface ProductNoteProps {
  note : string;
  setNote? : (note : string) => void;
}

function ProductNote({ note, setNote } : ProductNoteProps) {
  return (
    <TextArea
      value={note}
      placeholder="Add a note"
      onChange={setNote}
      disabled={!setNote}
    />
  );
}

export default ProductNote;
