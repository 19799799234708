import React, {
  createContext,
  useContext,
  useMemo,
} from 'react';
import { ThemeProvider as EmotionProvider } from '@emotion/react';

import ConfigContext from '#context/ConfigContext';

import {
  Theme,
  ThemeKey,
  ThemePalettes,
  BrandPalette,
  BrandTheme,
} from '#types';

import defaults from '../defaults.json';

function generateTheme(config : typeof defaults, themeKey : ThemeKey) : Theme {
  const brandTheme = config.brand.themes[themeKey] as BrandTheme;
  const brandPalette = config.brand.palette as BrandPalette;
  const themePalettes = config.theme.palettes as ThemePalettes;
  const paletteKey = themePalettes[brandTheme.palette];

  return {
    layout : {
      border : config.theme.spacing.border,
      boxShadow : {
        outer : brandTheme.shadow
          ? config.theme.spacing.boxShadow.map((shadow) => ({
            ...shadow,
            colour : config.brand.palette.shadow,
          })) : [],
      },
      spacing : config.theme.spacing.sizes,
    },
    palette : {
      accent : {
        primary : {
          fill : brandPalette[paletteKey.accent].primary.fill,
          text : brandPalette[paletteKey.accent].primary.text,
        },
        secondary : {
          fill : brandPalette[paletteKey.accent].secondary?.fill
            ?? 'transparent',
          text : brandPalette[paletteKey.accent].secondary?.text
            ?? brandPalette[paletteKey.accent].primary.fill,
        },
        tertiary : {
          fill : brandPalette[paletteKey.accent].tertiary?.fill
            ?? brandPalette[paletteKey.accent].primary.fill,
          text : brandPalette[paletteKey.accent].tertiary?.text
            ??brandPalette[paletteKey.accent].primary.text,
        },
        alert : {
          fill : brandPalette.notifications.alert.fill,
          text : brandPalette.notifications.alert.text,
        },
      },
      background : {
        fill : brandPalette[paletteKey.background].fill,
        text : {
          primary : brandPalette[paletteKey.background].text.primary,
          secondary : brandPalette[paletteKey.background].text.secondary
            ?? brandPalette[paletteKey.background].text.primary,
          alert : brandPalette[paletteKey.background].text.alert
            ?? brandPalette.notifications.alert.text,
          hover : brandPalette[paletteKey.background].text.hover
            ?? brandPalette[paletteKey.background].text.primary,
        }
      },
      border : paletteKey.border
        ? brandPalette[paletteKey.border].fill
        : 'transparent',
      notifications : {
        default : {
          fill : brandPalette.notifications.default.fill,
          text : brandPalette.notifications.default.text,
        },
        alert : {
          fill : brandPalette.notifications.alert.fill,
          text : brandPalette.notifications.alert.text,
        },
        success : {
          fill : brandPalette.notifications.success.fill,
          text : brandPalette.notifications.success.text,
        },
      },
      overlay : brandPalette.shadow,
    },
    typography : {
      fonts : {
        default : {
          ...config.theme.typography,
          ...config.brand.fonts.default,
        },
        brand : {
          ...config.theme.typography,
          ...config.brand.fonts.brand,
        },
        mono : {
          ...config.theme.typography,
          ...config.brand.fonts.mono,
        },
        title : {
          ...config.theme.typography,
          ...config.brand.fonts.title,
        }
      },
    },
    view : config.theme.view,
  };
}

const ThemeContext = createContext({
  theme : generateTheme(defaults, 'app'),
  themeKey : 'app' as ThemeKey,
});

interface ThemeProviderProps {
  themeKey?: ThemeKey;
  children: React.ReactNode;
};

export function ThemeProvider ({
  themeKey = 'app',
  children,
} : ThemeProviderProps) {
  const { config  } = useContext(ConfigContext);

  const contextalTheme = useMemo<Theme>(
    () => generateTheme(config, themeKey),
    [themeKey, config],
  );
  const context = useMemo(
    () => ({
      theme : contextalTheme,
      themeKey,
    }),
    [themeKey, contextalTheme],
  );

  return (
    <ThemeContext.Provider value={context}>
      <EmotionProvider theme={contextalTheme}>
        { children }
      </EmotionProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
