import { useCallback } from 'react';

import { TimeSlot, Schedule, Service, Window } from '#mrktbox/clerk/types';

import {
  createTimeSlot as createTimeSlotAPI,
  retrieveTimeSlots as retrieveTimeSlotsAPI,
  retrieveTimeSlot as retrieveTimeSlotAPI,
  updateTimeSlot as updateTimeSlotAPI,
  deleteTimeSlot as deleteTimeSlotAPI,
  createWindow as createWindowAPI,
  addTimeSlotToSchedule as addTimeSlotToScheduleAPI,
  removeTimeSlotFromSchedule as removeTimeSlotFromScheduleAPI,
  createSchedule as createScheduleAPI,
  retrieveSchedules as retrieveSchedulesAPI,
  retrieveSchedule as retrieveScheduleAPI,
  updateSchedule as updateScheduleAPI,
  deleteSchedule as deleteScheduleAPI,
  addScheduleToService as addScheduleToServiceAPI,
  removeScheduleFromService as removeScheduleFromServiceAPI,
} from '#mrktbox/clerk/api/mrktbox/timeslots';
import useAPI from '#mrktbox/clerk/hooks/api/useAPI';

function useTimeSlotsAPI() {
  const { processRequest } = useAPI();

  const createTimeSlot = useCallback(
    async (timeSlot : TimeSlot) => {
      return await processRequest(
        createTimeSlotAPI,
        { timeSlot },
      )
    },
    [processRequest],
  )

  const retrieveTimeSlots = useCallback(async () => {
    return await processRequest(
      retrieveTimeSlotsAPI,
      {},
    )
  }, [processRequest])

  const retrieveTimeSlot = useCallback(async (timeSlotId : number) => {
    return await processRequest(
      retrieveTimeSlotAPI,
      { timeSlotId },
    )
  }, [processRequest])

  const updateTimeSlot = useCallback(async (timeSlot : TimeSlot) => {
    return await processRequest(
      updateTimeSlotAPI,
      { timeSlot },
    )
  }, [processRequest])

  const deleteTimeSlot = useCallback(async (timeSlot : TimeSlot) => {
    return await processRequest(
      deleteTimeSlotAPI,
      { timeSlot },
    )
  }, [processRequest])

  const createWindow = useCallback(async (window : Window) => {
    return await processRequest(
      createWindowAPI,
      { window },
    )
  }, [processRequest])

  const addTimeSlotToSchedule = useCallback(
    async ( schedule : Schedule, timeSlot : TimeSlot ) => {
      return await processRequest(
        addTimeSlotToScheduleAPI,
        { schedule, timeSlot },
      )
    }, [processRequest])

  const removeTimeSlotFromSchedule = useCallback(
    async ( schedule : Schedule, timeSlot : TimeSlot ) => {
      return await processRequest(
        removeTimeSlotFromScheduleAPI,
        { schedule, timeSlot },
      )
    }, [processRequest])

  const createSchedule = useCallback(
    async (schedule : Schedule) => {
      return await processRequest(
        createScheduleAPI,
        { schedule },
      )
    },
    [processRequest],
  )

  const retrieveSchedules = useCallback(async () => {
    return await processRequest(
      retrieveSchedulesAPI,
      {},
    )
  }, [processRequest])

  const retrieveSchedule = useCallback(async (scheduleId : number) => {
    return await processRequest(
      retrieveScheduleAPI,
      { scheduleId },
    )
  }, [processRequest])

  const updateSchedule = useCallback(async (schedule : Schedule) => {
    return await processRequest(
      updateScheduleAPI,
      { schedule },
    )
  }, [processRequest])

  const deleteSchedule = useCallback(async (schedule : Schedule) => {
    return await processRequest(
      deleteScheduleAPI,
      { schedule },
    )
  }, [processRequest])

  const addScheduleToService = useCallback(
    async (schedule : Schedule, service : Service) => {
      return await processRequest(
        addScheduleToServiceAPI,
        { service, schedule },
      )
    }, [processRequest])

  const removeScheduleFromService = useCallback(
    async (schedule : Schedule, service : Service) => {
      return await processRequest(
        removeScheduleFromServiceAPI,
        { service, schedule },
      )
    }, [processRequest])

  return {
    createTimeSlot,
    retrieveTimeSlots,
    retrieveTimeSlot,
    updateTimeSlot,
    deleteTimeSlot,
    createWindow,
    addTimeSlotToSchedule,
    removeTimeSlotFromSchedule,
    createSchedule,
    retrieveSchedules,
    retrieveSchedule,
    updateSchedule,
    deleteSchedule,
    addScheduleToService,
    removeScheduleFromService,
  }
}

export default useTimeSlotsAPI;
