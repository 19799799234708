import React, { createContext, useCallback, useEffect, useState } from 'react';

import {
  DataIndex,
  ServiceChannel,
  Category,
  Product,
  Service,
  Location,
  DraftOrder,
  isProductLoaded,
} from '@mrktbox/clerk/types';
import {
  useProducts,
  useTags,
  useCategories,
  useOptions,
  useInventory,
} from '#mrktbox';
import { listRecords, indexRecords } from '#mrktbox/utils';

import useRequests from '#hooks/useRequests';

const CatalogueContext = createContext({
  loaded : false as boolean,
  last : '/',
  products : {} as DataIndex<Product>,
  categories : {} as DataIndex<Category>,
  setLast : (last : string) => {},
  isProductStocked : (
    product : Product | number,
    alternatives? : {
      locations? : Location[];
      services? : Service[];
      time? : Date;
    },
    options? : { checkOptions? : boolean; },
  ) => false as boolean,
  isProductAvailable : (
    product : Product | number,
    order? : DraftOrder | null,
  ) => false as boolean,
  getFeaturedCategory : () => null as Category | null,
  getDealsCategory : () => null as Category | null,
  filterProducts : (
    products : Product[],
    by? : { serviceChannels? : ServiceChannel[] },
  ) => [] as Product[],
  filterCategories : (categories : Category[]) => [] as Category[],
});

interface CatalogueProviderProps {
  children : React.ReactNode;
}

export function CatalogueProvider({ children } : CatalogueProviderProps) {
  const { products : allProducts, loaded : productsLoaded } = useProducts();
  const { isProductAvailable, loaded : tagsLoaded } = useTags();
  const {
    categories : allCategories,
    loaded : categoriesLoaded,
  } = useCategories();
  const {
    getProductAssemblies,
    getCollection,
    getCollectionProducts,
  } = useOptions();
  const { counts } = useInventory();
  const {
    serviceChannel,
    availableServiceChannels,
    availableLocations,
    availableServices,
    allLocations,
    location,
    address,
    time,
  } = useRequests();

  const filterProducts = useCallback((
    products : Product[],
    by? : { serviceChannels? : ServiceChannel[] }
  ) => {
    products = products || listRecords(allProducts);
    const channels = by?.serviceChannels
      ? by.serviceChannels
      : serviceChannel
        ? [serviceChannel]
        : Object.values(availableServiceChannels);

    return products.filter((product) => isProductAvailable(product, channels));
  }, [
    serviceChannel,
    availableServiceChannels,
    allProducts,
    isProductAvailable,
  ]);

  const filterCategories = useCallback((categories : Category[]) => {
    categories = categories || listRecords(allCategories);
    const channels = serviceChannel
      ? [serviceChannel]
      : Object.values(availableServiceChannels);

    return categories.filter((category) => (
      channels.some((channel) => (
        channel.id
          && channel.visible
          && category.availableChannelIds.includes(channel.id)
      ))
    ));
  }, [serviceChannel, availableServiceChannels, allCategories]);

  const filterProductIndex = useCallback(() => {
    return indexRecords(filterProducts(listRecords(allProducts)));
  }, [filterProducts, allProducts]);
  const filterCategoryIndex = useCallback(() => {
    return indexRecords(filterCategories(listRecords(allCategories)));
  }, [filterCategories, allCategories]);

  const [last, setLast] = useState('/');
  const [loaded, setLoaded] = useState(false);
  const [products, setProducts] = useState(filterProductIndex());
  const [categories, setCategories] = useState(filterCategoryIndex());

  const isProductStocked = useCallback((
    product : Product | number,
    alternatives? : {
      locations? : Location[];
      services? : Service[];
      time? : Date;
    },
    options? : { checkOptions? : boolean; },
  ) => {
    if (!counts) return true;
    const checkOptions = options?.checkOptions ?? true;

    const alternativeLocations = alternatives?.locations
      ? alternatives.locations
      : alternatives?.services
        ? listRecords(allLocations).filter(
          (l) => alternatives.services?.some((s) => s.locationId === l.id),
        ) : null;
    const serviceLocations = allLocations
      ? availableServices.reduce((acc, s) => {
        const loc = s.locationId ? allLocations[s.locationId] : null;
        if (loc) acc.push(loc);
        return acc;
      }, [] as Location[])
      : [];
    const locations = alternativeLocations
      ? alternativeLocations
        : location
          ? [location]
          : address
            ? serviceLocations
            : Object.values(availableLocations).concat(serviceLocations);

    const allCounts = listRecords(counts);
    const productRecord = typeof product === 'number'
      ? (allProducts?.[product] || null)
      : product;
    const productId = typeof product === 'number' ? product : product.id;

    if (checkOptions) {
      const assemblies = productRecord
        ? getProductAssemblies(productRecord)
        : [];
      for (const assembly of assemblies) {
        const collection = getCollection(
          assembly,
          alternatives?.time ?? time ?? new Date(),
        );
        if (!collection || collection.min < 1) continue;

        const collectionProducts = getCollectionProducts(collection);
        if (!collectionProducts.some(
          (p) => isProductStocked(p, alternatives, { checkOptions: true }),
        )) return false;
      }
    }

    return locations.some((l) => {
      const count = allCounts.find((c) => (
        productId
          && c.productId === productId
          && c.locationId === l.id
      ));
      return (!count || count.current > 0);
    });
  }, [
    counts,
    location,
    address,
    availableLocations,
    availableServices,
    allLocations,
    allProducts,
    time,
    getProductAssemblies,
    getCollection,
    getCollectionProducts,
  ]);

  const isAvailable = useCallback((
    product : Product | number,
    order? : DraftOrder | null,
  ) => {
    if (order) {
      const channel = order.serviceChannel
      const prod = (typeof product === 'number')
        ? allProducts?.[product]
        : product;
      if (!prod || !channel) return true;
      return isProductAvailable(prod, channel);
    }

    const productId = typeof product === 'number' ? product : product.id;
    return !productId || productId in products;
  }, [products, allProducts, isProductAvailable]);

  const getFeaturedCategory = useCallback(() => {
    return allCategories ? Object.values(allCategories).find(
      (category) => category?.name.toLowerCase() === 'featured',
    ) ?? null : null;
  }, [allCategories]);

  const getDealsCategory = useCallback(() => {
    return allCategories ? Object.values(allCategories).find(
      (category) => category?.name.toLowerCase() === 'deals',
    ) ?? null : null;
  }, [allCategories]);

  useEffect(() => { setProducts(filterProductIndex()); }, [filterProductIndex]);
  useEffect(() => {
    setCategories(filterCategoryIndex());
  }, [filterCategoryIndex]);

  useEffect(() => {
    if (loaded) return;
    if (
      !tagsLoaded
        || !categoriesLoaded
        || !productsLoaded
        || !allProducts
    ) return;

    const featured = getFeaturedCategory();
    if (featured) {
      const featuredProducts = featured.productIds.map((id) => allProducts[id])
        .filter(p => p) as Product[];
      setLoaded(featuredProducts.every(isProductLoaded));
    }
  }, [
    loaded,
    tagsLoaded,
    categoriesLoaded,
    productsLoaded,
    allProducts,
    getFeaturedCategory,
  ]);

  const context = {
    loaded,
    last,
    products,
    categories,
    setLast,
    isProductStocked,
    isProductAvailable : isAvailable,
    getFeaturedCategory,
    getDealsCategory,
    filterProducts,
    filterCategories,
  }

  return (
    <CatalogueContext.Provider value={context}>
      { children }
    </CatalogueContext.Provider>
  );
}

export default CatalogueContext;
