import React, { useCallback, useState } from 'react';

import { ContactInfo } from '#mrktbox/types';
import { FormProvider, useFormContext, useCustomers, useNotes } from '#mrktbox';

import ProfileForm from '#components/profile/ProfileForm';

interface ProfileFormProps {
  contactInfo : ContactInfo;
}

function ProfileControl({ contactInfo : fallback } : ProfileFormProps) {
  const { updateContactInfo } = useCustomers();
  const {
    createNote,
    updateNote,
    getCustomerNotes,
    generateDefaultNote,
  } = useNotes();

  const { state : contactInfo, setEditing } = useFormContext<ContactInfo>();

  const getNote = useCallback(() => {
    if (!contactInfo?.customerId) return null;
    return getCustomerNotes(contactInfo.customerId)[0] ?? null;
  }, [contactInfo, getCustomerNotes]);

  const [note, setNote] = useState(getNote()?.content ?? '');
  const [error, setError] = useState('');

  const handleSave = useCallback(async () => {
    if (!contactInfo?.customerId) return;
    const contact = await updateContactInfo({
      ...contactInfo,
      nickname : contactInfo.nickname || contactInfo.name,
    });

    if (!contact) {
      setError(
        'Something went wrong. Please try again, or contact us for help.'
      );
      return;
    }

    const currentNote = getNote();
    if (note === (currentNote?.content ?? '')) {
      setEditing(false);
      setError('');
      return;
    }

    const newNote = currentNote
      ? await updateNote({
        ...currentNote,
        content: note,
      })
      : await createNote({
        ...generateDefaultNote({ customerId : contactInfo.customerId }),
        content: note,
      });

    if (!newNote) {
      setError(
        'Something went wrong. Please try again, or contact us for help.'
      );
      return;
    }

    setNote(newNote.content);
    setEditing(false);
    setError('');
  }, [
    contactInfo,
    note,
    setEditing,
    getNote,
    updateContactInfo,
    createNote,
    updateNote,
    generateDefaultNote,
  ]);

  const handleCancel = useCallback(
    () => setNote(getNote()?.content ?? ''),
    [getNote],
  );

  return (
    <ProfileForm
      contactInfo={fallback}
      onSubmit={handleSave}
      onCancel={handleCancel}
      note={note}
      setNote={setNote}
      error={error}
    />
  );
}

function Profile({ contactInfo } : ProfileFormProps) {

  return (
    <FormProvider init={contactInfo} editingInit={false}>
      <ProfileControl contactInfo={contactInfo} />
    </FormProvider>
  );
}

export default Profile;
