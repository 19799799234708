import React from 'react';
import styled from '@emotion/styled';

import { Assembly } from '#mrktbox/types';
import { useOptions } from '#mrktbox';

import { Theme } from '#types';

import useRequests from '#hooks/useRequests';

import Heading from '#materials/Heading';

interface Style { theme? : Theme; }

const ProductOptionHeaderView = styled.div<Style>`
  display: flex;
  margin: 0 0 ${(props) => props.theme.layout.spacing.xsmall};
  align-items: flex-end;
  justify-content: space-between;
`;

const ProductOptionHeaderName = styled(Heading)<Style>`
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.palette.background.text.primary};
  font-family: ${(props) => props.theme.typography.fonts.brand.family};
  font-size: ${(props) => props.theme.typography.fonts.brand.sizes.large};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.typography.fonts.brand.sizes.medium};
  }
`;

interface NameQuantityStyle extends Style {
  belowMin? : boolean;
  belowIncluded? : boolean;
}

const ProductOptionHeaderNameQuantity = styled.span<NameQuantityStyle>`
  padding: 0 0 0 ${(props) => props.theme.layout.spacing.xxsmall};
  ${(props) =>
    props.belowMin
      ? `color: ${props.theme.palette.background.text.alert};`
      : `color: ${props.theme.palette.background.text.primary};`}
  }
`;

interface ProductOptionHeaderProps {
  assembly : Assembly;
  count : number;
}

function ProductOptionHeader({
  assembly,
  count,
} : ProductOptionHeaderProps) {
  const { getAssemblyCounts } = useOptions();
  const { time } = useRequests();

  const { min, max } = getAssemblyCounts(assembly, time ?? new Date());

  return (
    <ProductOptionHeaderView>
      <div>
        <ProductOptionHeaderName as="p">
          { assembly.name }
          <ProductOptionHeaderNameQuantity
            belowMin={!!min && count < min}
          >
            { '(' + count }
            {max ? `/${max}` : ' selected'}
            {(min && (count < min)) ? `, ${min} required)` : ')'}
          </ProductOptionHeaderNameQuantity>
        </ProductOptionHeaderName>
      </div>
    </ProductOptionHeaderView>
  );
}

export default ProductOptionHeader;
