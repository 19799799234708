import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import FormError from '#materials/FormError';

interface Style {
  theme? : Theme;
}

interface LabelViewStyle extends Style{
  hasIcon? : boolean;
  hasError? : boolean;
  hidePlaceholder? : boolean;
  isTextarea? : boolean;
}

interface LabelIconStyle extends Style {
  hasValue? : boolean;
  disabled? : boolean;
}

interface LabelTextStyle extends Style {
  hasValue? : boolean;
  hasError? : boolean;
  hasIcon? : boolean;
  isTextarea? : boolean;
}

const LabelView = styled('label')<LabelViewStyle>`
  display: block;
  position: relative;
  width: 100%;
  margin: 0 0 ${(props) => props.isTextarea
    ? props.theme.layout.spacing.xsmall
    : props.theme.layout.spacing.medium};
  white-space: nowrap;

  color: ${(props) => props.theme.palette.background.text.primary};

  input {
    ${(props) => props.hasIcon
      ? `padding-left: calc(
        ${props.theme.typography.fonts.default.sizes.medium} +
        (2 * ${props.theme.layout.spacing.xsmall}));`
      : ''}
  }
`;

const LabelIconView = styled.span<LabelIconStyle>`
  display: flex;
  position: absolute;
  width: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  height: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  top: ${(props) => props.theme.layout.spacing.small};
  left: ${(props) => props.theme.layout.spacing.xsmall};
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.palette.background.text.primary};
  line-height: 0;
`;

const LabelIcon = styled.span<Style>`
  display: block;
  width: ${(props) => props.theme.typography.fonts.default.sizes.medium};
  height: ${(props) => props.theme.typography.fonts.default.sizes.medium};
`;

const LabelText = styled.span<LabelTextStyle>`
  position: absolute;
  max-width: 100%;
  top: ${(props) => props.isTextarea
    ? `-${props.theme.layout.spacing.xsmall}`
    : '0'};
  left: ${(props) => props.isTextarea
    ? props.theme.layout.spacing.xsmall
    : '0'};
  padding:
    ${(props) => props.theme.layout.spacing.small}
    0
    ${(props) => props.theme.layout.spacing.xsmall};
    ${(props) => props.hasIcon
      ? props.theme.layout.spacing.medium
      : '0'};
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${(props) =>
    props.hasError
      ? props.theme.palette.background.text.alert
      : props.theme.palette.background.text.primary};

  border-style: solid;
  border-color: transparent;
  border-width: ${(props) => props.theme.layout.border.width};

  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  font-family: ${(props) => props.theme.typography.fonts.default.family};
  line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.typography.fonts.default.letterSpacing};
  -webkit-font-smoothing: antialiased;

  transition: all 0.2s cubic-bezier(0.17, 0.67, 0.12, 1);

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};
  }

  input:focus + &,
  textarea:focus + &,
  select:focus + & {
    cursor: default;

    transform: translate(
      ${(props) => props.isTextarea
        ? `-${props.theme.layout.spacing.xsmall}`
        : '0'},
      ${(props) => props.isTextarea
        ? `-${props.theme.layout.spacing.medium}`
        : `-${props.theme.layout.spacing.small}`}
    );
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.xsmall};

    @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
      ${(props) => `
        font-size: ${props.theme.typography.fonts.default.sizes.xxsmall};
      `}
    }
  }

  ${(props) =>
    props.hasValue
      ? `cursor: default;
        transform: translate(
          ${props.isTextarea ? `-${props.theme.layout.spacing.xsmall}` : '0'},
          ${props.isTextarea
            ? `-${props.theme.layout.spacing.medium}`
            : `-${props.theme.layout.spacing.small}`}
        );
        font-size: ${props.theme.typography.fonts.default.sizes.xsmall};
      ` : ''}
`;

const LabelRequired = styled.span<Style>`
  color: ${(props) => props.theme.palette.background.text.alert};
`;

interface LabelProps {
  htmlFor? : string;
  icon? : React.ReactNode;
  text? : React.ReactNode;
  required? : boolean;
  value? : string | number | boolean;
  errMsg? : string;
  showLabel? : boolean;
  disabled? : boolean;
  isTextarea? : boolean;
  style? : React.CSSProperties;
  className? : string;
  children? : React.ReactNode;
}

function Label({
  htmlFor,
  icon,
  text,
  required,
  value,
  errMsg,
  showLabel = true,
  disabled = false,
  isTextarea = false,
  className = undefined,
  children,
} : LabelProps) {
  return (
    <>
      <LabelView
        htmlFor={htmlFor}
        className={className}
        hidePlaceholder={!!text}
        hasError={!!errMsg}
        hasIcon={!!icon}
        isTextarea={isTextarea}
      >
        {icon && (
          <LabelIconView hasValue={!!value} disabled={disabled}>
            <LabelIcon>{icon}</LabelIcon>
          </LabelIconView>
        )}
        {children}
        {showLabel && (
          <LabelText
            hasValue={!!value}
            hasError={!!errMsg}
            hasIcon={!!icon}
            isTextarea={isTextarea}
          >
            {text}
            {required ? <LabelRequired>&nbsp;*</LabelRequired> : null}
          </LabelText>
        )}
        <FormError errMsg={errMsg} />
      </LabelView>
    </>
  );
}

export default Label;
