import React from 'react';
import styled from '@emotion/styled';

import { Product } from '#mrktbox/types';

import { Theme } from '#types';
import useCatalogue from '#hooks/useCatalogue';

import { LoadedImage } from '#materials/BackgroundImage';

interface Style { theme? : Theme; }
interface ViewStyle extends Style { fade? : boolean; }

const BackgroundImageView = styled.div<ViewStyle>`
  position: relative;
  height: 100%;
  flex-grow: 1;
  background-color: ${(props) => props.theme.palette.background.fill};
  opacity: ${(props) => props.fade ? 0.5 : 1};
  overflow: hidden;
  border-radius: ${(props) => props.theme.layout.border.radius};
`;

interface BackgroundImageProps {
  product : Product | null;
  variant? : string;
  fade? : boolean;
  children? : React.ReactNode;
}

function BackgroundImage({
  product,
  variant,
  fade,
  children,
} : BackgroundImageProps) {
  const { isProductLoaded, getProductImage } = useCatalogue();

  const imageUrl = getProductImage(product, variant);

  return (
    <BackgroundImageView fade={fade}>
      { isProductLoaded(product) && (
        <LoadedImage imageUrl={imageUrl} />
      ) }
      { children }
    </BackgroundImageView>
  );
}

export default BackgroundImage;
