import { Image, ImageUpload, isImage, isImageUpload } from './images';
import { Currency, isCurrency } from './payments';

export interface Product {
  id? : number;
  name : string;
  sku : string;
  summary? : string;
  description? : string;
  ingredientsList? : string[];
  price : Currency;
  images? : { [id : number] : Image };
  availableChannelIds? : number[];
  integrations? : {
    [id : number] : ProductIntegration;
  };
}

export interface ProductIntegration {
  id? : number;
  productId : number;
  externalId : string;
  integrationId : number;
  data : {
    [field : string] : string | number | boolean | null;
  };
}

export interface ExternalProduct {
  externalId : string;
  integrationId : number;
  name : string;
  sku : string;
}

export interface ProductImageUpload extends ImageUpload {
  productId : number;
}

export function isProductImageUpload(
  upload : any,
) : upload is ProductImageUpload {
  return (
    typeof upload.productId === 'number' &&
    isImageUpload(upload)
  );
}

export function isExternalProduct(externalProduct : any) : externalProduct is ExternalProduct {
  return (
    typeof externalProduct === 'object' &&
    typeof externalProduct.externalId === 'string' &&
    typeof externalProduct.integrationId === 'number' &&
    typeof externalProduct.name === 'string' &&
    typeof externalProduct.sku === 'string'
  );
}

export function isProductIntegration(
  productIntegration : any
) : productIntegration is ProductIntegration {
  return (
    typeof productIntegration === 'object' &&
    (productIntegration.id === null ||
      typeof productIntegration.id === 'number') &&
    typeof productIntegration.productId === 'number' &&
    typeof productIntegration.externalId === 'string' &&
    typeof productIntegration.integrationId === 'number' &&
    typeof productIntegration.data === 'object' &&
    Object.keys(productIntegration.data).every(
      key => typeof key === 'string'
    ) &&
    Object.values(productIntegration.data).every(
      value => typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean' ||
        value === null
    )
  );
}

export function isProduct(product : any) : product is Product {
  if (!product) return false;
  return (
    typeof product === 'object' &&
    (product.id === undefined || typeof product.id === 'number') &&
    typeof product.name === 'string' &&
    typeof product.sku === 'string' &&
    (product.summary === undefined || typeof product.summary === 'string') &&
    (product.description === undefined ||
      typeof product.description === 'string') &&
    (product.ingredientsList === undefined ||
      (Array.isArray(product.ingredientsList) &&
      product.ingredientsList.every(
        (ingredient : any) => typeof ingredient === 'string'
      ))
    ) &&
    isCurrency(product.price) &&
    ((product.images) === undefined || (
      typeof product.images === 'object' &&
      Object.keys(product.images).every(key => (
        typeof key === 'string' &&
        !isNaN(parseInt(key)) &&
        isImage(product.images[key])
      ))
    )) &&
    (product.availableChannelIds === undefined ||
      (Array.isArray(product.availableChannelIds) &&
      product.availableChannelIds.every((id : any) => typeof id === 'number'))
    ) &&
    (product.integrations === undefined || (
      typeof product.integrations === 'object' &&
      Object.keys(product.integrations).every(key => (
        typeof key === 'string' &&
        !isNaN(parseInt(key)) &&
        isProductIntegration(product.integrations[key])
      ))
    ))
  );
}

export function isProductLoaded(product : any) : product is Product {
  return (
    isProduct(product) &&
    product.images !== undefined
  );
}
