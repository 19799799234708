import { useCallback } from 'react';

import { RevenueCentre, Service } from '#mrktbox/clerk/types';

import {
  createRevenueCentre as createRevenueCentreAPI,
  retrieveRevenueCentres as retrieveRevenueCentresAPI,
  retrieveRevenueCentre as retrieveRevenueCentreAPI,
  updateRevenueCentre as updateRevenueCentreAPI,
  deleteRevenueCentre as deleteRevenueCentreAPI,
  addServiceToRevenueCentre as addServiceToRevenueCentreAPI,
  removeServiceFromRevenueCentre as removeServiceFromRevenueCentreAPI,
} from '#mrktbox/clerk/api/mrktbox/payments';

import useAPI from '#mrktbox/clerk/hooks/api/useAPI';

function usePaymentsAPI() {
  const { processRequest } = useAPI();

  const createRevenueCentre = useCallback(
    async (revenueCentre : RevenueCentre) => {
      return await processRequest(
        createRevenueCentreAPI,
        { revenueCentre },
      )
    }, [processRequest])

  const retrieveRevenueCentres = useCallback(async () => {
    return await processRequest(
      retrieveRevenueCentresAPI,
      {},
    )
  }, [processRequest]);

  const retrieveRevenueCentre = useCallback(
    async (revenueCentreId : number) => {
      return await processRequest(
        retrieveRevenueCentreAPI,
        { revenueCentreId },
      )
    },
    [processRequest],
  );

  const updateRevenueCentre = useCallback(
    async (revenueCentre : RevenueCentre) => {
      return await processRequest(
        updateRevenueCentreAPI,
        { revenueCentre },
      )
    },
    [processRequest],
  );

  const deleteRevenueCentre = useCallback(
    async (revenueCentre : RevenueCentre) => {
      return await processRequest(
        deleteRevenueCentreAPI,
        { revenueCentre },
      )
    },
    [processRequest],
  );

  const addServiceToRevenueCentre = useCallback(
    async (revenueCentre : RevenueCentre, service : Service) => {
      return await processRequest(
        addServiceToRevenueCentreAPI,
        { revenueCentre, service },
      )
    },
    [processRequest],
  );

  const removeServiceFromRevenueCentre = useCallback(
    async (revenueCentre : RevenueCentre, service : Service) => {
      return await processRequest(
        removeServiceFromRevenueCentreAPI,
        { revenueCentre, service },
      )
    },
    [processRequest],
  );

  return {
    createRevenueCentre,
    retrieveRevenueCentres,
    retrieveRevenueCentre,
    updateRevenueCentre,
    deleteRevenueCentre,
    addServiceToRevenueCentre,
    removeServiceFromRevenueCentre,
  };
}

export default usePaymentsAPI;
