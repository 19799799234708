import React, { useCallback, useState } from 'react';

import Input from '#materials/Input';
import Form from '#materials/Form';
import FormError from '#materials/FormError';
import ButtonLink from '#materials/ButtonLink';
import ButtonStyled from '#materials/ButtonStyled';

interface LoginFormProps {
  email : string;
  password : string;
  setEmail : (email : string) => void;
  setPassword : (password : string) => void;
  onSubmit? : () => void;
  onReset? : () => void;
  error? : string;
}

function LoginForm({
  email,
  password,
  setEmail,
  setPassword,
  onSubmit,
  onReset,
  error = ''
} : LoginFormProps) {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(() => {
    if (!onSubmit) return;
    setSubmitting(true);
    onSubmit();
    setSubmitting(false);
  }, [onSubmit]);

  return (
    <Form
      onSubmit={handleSubmit}
      error={(
        <FormError errMsg={error}/>
      )}
      buttons={(
        <ButtonStyled
          type="submit"
          disabled={submitting}
        >
          Login
        </ButtonStyled>
      )}
    >
      <Input
        label="Email"
        name="email"
        type="email"
        value={email}
        onChange={setEmail}
        required={true}
      />
      <Input
        label="Password"
        name="password"
        type="password"
        value={password}
        onChange={setPassword}
        required={true}
      />
      <ButtonLink onClick={onReset}>Reset password</ButtonLink>
    </Form>
  );
}

export default LoginForm;
