import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style { theme? : Theme; }

const SidebarFooterView = styled.div<Style>`
  width: 100%;
  height: fit-content;
  margin: ${(props) => props.theme.layout.spacing.medium} 0 0;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.palette.background.fill};

  @media (max-width: ${(props) => props.theme.view.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.spacing.small} 0 0;
  }

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.spacing.xsmall} 0 0;
  }
`;

const SidebarButtons = styled.div<Style>`
  display: flex;
  height: fit-content;
  width: calc(100% - (${(props) => props.theme.layout.spacing.small} * 2));
  margin : 0 auto 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.layout.spacing.xsmall};

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    width: calc(100% - (${(props) => props.theme.layout.spacing.xsmall} * 2));
    gap: ${(props) => props.theme.layout.spacing.xxsmall};
  }

  button, a {
    width: 100%;
    padding: 0;
  }
`;

interface SidebarFooterProps {
  children? : React.ReactNode;
}

function SidebarFooter({ children } : SidebarFooterProps) {
  return (
    <SidebarFooterView>
      <SidebarButtons>
        { children }
      </SidebarButtons>
    </SidebarFooterView>
  );
}

export default SidebarFooter;
