import {
  Integration,
  Image,
  Product,
  ProductIntegration,
  ExternalProduct,
  ServiceChannel,
  isImage,
  isProduct,
  isProductImageUpload,
  isExternalProduct,
} from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { RequestOptions, getUrl, request } from '#mrktbox/clerk/api/mrktbox';
import { formats, formatDateTime } from '#mrktbox/clerk/utils/date';

const PRODUCTS_PATH = 'products/';
const EXTERNAL_PRODUCTS_PATH = `${PRODUCTS_PATH}external/`;
const PRODUCT_INTEGRATIONS_PATH = `${PRODUCTS_PATH}integrations/`;
const IMPORT_PATH = `${PRODUCTS_PATH}import/`;
const SERVICE_CHANNELS_PATH = 'service-channels/';

function parseProduct(product : any) : Product {
  if (!isProduct(product))
    throw new TypeError('Product is not a product')
  return product;
}

function parseProducts(products : any) {
  const parsedProducts : { [id : number] : Product } = {};
  for (const productId in products) {
    if (typeof productId !== 'string')
      throw new TypeError('Product id is not a string');

    const id = parseInt(productId);
    if (isNaN(id)) throw new TypeError('Product id is not a number');

    parsedProducts[id] = parseProduct(products[id]);
  }

  return parsedProducts;
}

function parseIntegrationProducts(
  productIntegrations : any,
  integrationId : number,
) {
  const parsedExternalProducts : { [id : string] : ExternalProduct; } = {};
  for (const externalId in productIntegrations) {
    if (typeof externalId !== 'string')
      throw new TypeError('External product id is not a string');

    const productIntegration = productIntegrations[externalId];

    productIntegration.externalId = externalId;
    productIntegration.integrationId = integrationId;

    if (!isExternalProduct(productIntegration))
      throw new TypeError('Product integration is not a product integration');

    parsedExternalProducts[externalId] = productIntegration;
  }
  return parsedExternalProducts;
}

function parseExternalProducts(externalProducts : any) {
  const parsedIntegrationProducts : {
    [integrationId : number] : { [id : string] : ExternalProduct };
  } = {};
  for (const integrationId in externalProducts) {
    if (typeof integrationId !== 'string')
      throw new TypeError('Integration id is not a string');

    const id = parseInt(integrationId);
    if (isNaN(id)) throw new TypeError('Integration id is not a number');

    parsedIntegrationProducts[id] = parseIntegrationProducts(
      externalProducts[id],
      id,
    );
  }
  return parsedIntegrationProducts;
}

function parseProductImageUpload(productImageUpload : any) {
  if (!isProductImageUpload(productImageUpload))
    throw new TypeError('Product image upload is not a product image upload');

  return productImageUpload;
}

function parseProductImage(productImage : any) {
  if (!isImage(productImage))
    throw new TypeError('Product image is not an image');

  return productImage;
}

export async function createProduct(
  { product } : {product : Product},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(PRODUCTS_PATH),
    methods.post,
    { product },
    options,
  );

  try {
    return parseProduct(response.product);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product',
      response,
    );
  }
}

export async function retrieveProducts(input : {
  since? : Date,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(
      PRODUCTS_PATH,
      input.since
        ? { since : formatDateTime(input.since, formats.iso) }
        : undefined,
    ),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseProducts(response.products);
  } catch {
    throw new DeserializationError(
      'Could not parse product list',
      response,
    );
  }
}

export async function retrieveProductsBulk(input : {
  productIds : number[],
}, options? : RequestOptions) {
  const response = await request(
    getUrl(
      `${PRODUCTS_PATH}bulk/`,
      input.productIds.map(id => ['id', id.toString()]),
    ),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseProducts(response.products);
  } catch {
    throw new DeserializationError(
      'Could not parse product list',
      response,
    );
  }
}

export async function retrieveProduct({
  productId,
  withIntegrations = false,
} : {
  productId : number,
  withIntegrations? : boolean
}, options? : RequestOptions ) {
  const response = await request(
    getUrl(
      `${PRODUCTS_PATH}${productId}`,
      { withIntegrations : withIntegrations ? 'true' : 'false' },
    ),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseProduct(response.product);
  } catch {
    throw new DeserializationError(
      'Could not parse product',
      response,
    );
  }
}

export async function updateProduct(
  { product } : { product : Product },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${PRODUCTS_PATH}${product.id}`),
    methods.put,
    { product },
    options,
  );
  try {
    return parseProduct(response.product);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product',
      response,
    );
  }
}

export async function deleteProduct(
  { product } : { product : Product },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${PRODUCTS_PATH}${product.id}`),
    methods.delete,
    undefined,
    options,
  );

  return !!response?.success;
}

export async function retrieveExternalProducts(
  input? : { query? : string },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(EXTERNAL_PRODUCTS_PATH, { query : input?.query ?? '' }),
    methods.get,
    undefined,
    options,
  );
  try {
    return parseExternalProducts(response.externalProducts);
  } catch {
    throw new DeserializationError(
      'Could not parse external products',
      response,
    );
  }
}

export async function importProduct({
  integration,
  externalProduct,
} : {
  integration : Integration,
  externalProduct : ExternalProduct,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${IMPORT_PATH}${integration.id}/${externalProduct.externalId}`),
    methods.post,
    undefined,
    options,
  );

  try {
    return parseProduct(response.product);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product',
      response,
    );
  }
}

export async function createProductIntegration({
  product,
  externalProduct,
} : {
  product : Product,
  externalProduct : ExternalProduct,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${PRODUCTS_PATH}${product.id}/integrations/` +
      `${externalProduct.integrationId}/${externalProduct.externalId}`),
    methods.post,
    undefined,
    options,
  );

  try {
    return parseProduct(response.product);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product',
      response,
    );
  }
}

export async function deleteProductIntegration(
  { productIntegration } : { productIntegration : ProductIntegration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${PRODUCT_INTEGRATIONS_PATH}${productIntegration.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseProduct(response.product);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product',
      response,
    );
  }
}

export async function syncProductIntegration(
  { productIntegration } : { productIntegration : ProductIntegration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${PRODUCT_INTEGRATIONS_PATH}${productIntegration.id}/sync/`),
    methods.post,
    undefined,
    options,
  );

  try {
    return parseProduct(response.product);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product',
      response,
    );
  }
}

export async function pushProductIntegration(
  { productIntegration } : { productIntegration : ProductIntegration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${PRODUCT_INTEGRATIONS_PATH}${productIntegration.id}/push/`),
    methods.post,
    undefined,
    options,
  );

  try {
    return parseProduct(response.product);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product',
      response,
    );
  }
}

export async function pullProductIntegration(
  { productIntegration } : { productIntegration : ProductIntegration },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${PRODUCT_INTEGRATIONS_PATH}${productIntegration.id}/pull/`),
    methods.post,
    undefined,
    options,
  );

  try {
    return parseProduct(response.product);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product',
      response,
    );
  }
}

export async function createProductImageUpload(
  { product } : { product : Product },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${PRODUCTS_PATH}${product.id}/images/upload/`),
    methods.post,
    undefined,
    options,
  );

  try {
    return parseProductImageUpload(response.productImageUpload);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product image upload',
      response,
    );
  }
}

export async function pullProductImage({
  product,
  image,
} : {
  product : Product,
  image : Image,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${PRODUCTS_PATH}${product.id}/images/${image.id}/pull/`),
    methods.post,
    undefined,
    options,
  );

  try {
    return parseProductImage(response.image);
  }
  catch {
    throw new DeserializationError(
      'Could not deserialize product image',
      response,
    );
  }
}

export async function removeImageFromProduct({
  product,
  image,
} : {
  product : Product,
  image : Image,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${PRODUCTS_PATH}${product.id}/images/${image.id}`),
    methods.delete,
    undefined,
    options,
  );

  return !!response;
}

export async function addServiceChannelToProduct({
  product,
  serviceChannel,
} : {
  product : Product,
  serviceChannel : ServiceChannel,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${PRODUCTS_PATH}${product.id}/${SERVICE_CHANNELS_PATH}` +
      `${serviceChannel.id}`),
    methods.post,
    undefined,
    options,
  );

  return !!response;
}

export async function removeServiceChannelFromProduct({
  product,
  serviceChannel,
} : {
  product : Product,
  serviceChannel : ServiceChannel,
}, options? : RequestOptions) {
  const response = await request(
    getUrl(`${PRODUCTS_PATH}${product.id}/${SERVICE_CHANNELS_PATH}` +
      `${serviceChannel.id}`),
    methods.delete,
    undefined,
    options,
  );

  return !!response;
}
