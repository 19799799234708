import { useCallback } from 'react';

import {
  retrieveIndex as retrieveIndexAPI,
  regenerateIndex as regenerateIndexAPI,
} from '#mrktbox/clerk/api/mrktbox/search';

import useAPI from '#mrktbox/clerk/hooks/api/useAPI';

function useSearchAPI() {
  const { processRequest } = useAPI();

  const retrieveIndex = useCallback(
    async (url : string) => {
      return await processRequest(
        retrieveIndexAPI,
        { url },
      )
    },
    [processRequest],
  )

  const regenerateIndex = useCallback(
    async (url : string) => {
      return await processRequest(
        regenerateIndexAPI,
        { url },
      )
    },
    [processRequest],
  )

  return {
    retrieveIndex,
    regenerateIndex,
  };
}

export default useSearchAPI;
