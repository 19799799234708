import * as turf from '@turf/turf';
import { featureCollection } from '@turf/helpers'

export function pointInPolygon(
  point: [number, number],
  polygon: [number, number][],
): boolean {
  const pt = featureCollection([turf.point(point)]);
  const poly = featureCollection([turf.polygon([polygon])]);
  return turf.pointsWithinPolygon(pt, poly).features.length > 0;
}