import React from 'react';

import styled from '@emotion/styled';

import { Theme } from '#types';

interface Style {
  theme? : Theme;
}

interface RowViewProps extends Style{
  hasBox? : boolean;
}

const RowView = styled.div<RowViewProps>`
  display: flex;
  max-width: 100%;
  margin: 0;
  padding: ${(props) => props.theme.layout.spacing.small};
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  gap: ${(props) => props.theme.layout.spacing.small};
`;

const RowIcon = styled.div<Style>`
  flex: 0 0 auto;
`;

const RowContent = styled.div<Style>`
  display: flex;
  padding: 0;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RowText = styled.div<Style>`
  flex: 1;
  width: 100%;

  p {
    margin: ${(props) => props.theme.layout.spacing.xxsmall} 0 0;
    color: ${(props) => props.theme.palette.background.text};
    font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
    line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};

    &:first-of-type {
      margin: 0;
      font-size:
        ${(props) => props.theme.typography.fonts.default.sizes.medium};
    }
  }
`;

const RowActions = styled.div<Style>`
  flex-shrink: 0;

  @media (max-width: ${(props) => props.theme.view.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.spacing.xsmall} 0 0;
  }
`;

interface RowProps {
  icon? : React.ReactNode;
  content? : React.ReactNode;
  actions? : React.ReactNode;
  style? : React.CSSProperties;
}

function Row({ icon, content, actions, style } : RowProps) {
  return (
    <RowView style={style}>
      { icon && <RowIcon>{ icon }</RowIcon> }
      <RowContent>
        <RowText>{content}</RowText>
        { actions && <RowActions>{ actions }</RowActions> }
      </RowContent>
    </RowView>
  );
}

export default Row;
