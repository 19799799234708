import React from 'react';
import { Helmet } from 'react-helmet-async';

import Content from '#components/page/Content';
import Main from '#components/page/Main';
import ScreenreaderTitle from '#components/page/ScreenreaderTitle';

import ProductSearch from '#slices/ProductSearch';

function Search() {
  return (
    <>
      <Helmet>
        <title>Search</title>
      </Helmet>
      <Content>
        <Main>
          <ScreenreaderTitle>Search</ScreenreaderTitle>
          <ProductSearch />
        </Main>
      </Content>
    </>
  )
};

export default Search;
