import React, { createContext, useCallback } from 'react';

import { Channel, Config, Engine, Integration } from '#mrktbox/clerk/types';

import useData, {
  DataIndex,
  useLoad,
  useChange,
  useRefreshIndex,
  useRefresh,
  useRetrieveIndex,
  useRetrieve,
  useDelete,
  useRelate,
} from '#mrktbox/clerk/hooks/useData';
import useIntegrationsAPI from '#mrktbox/clerk/hooks/api/useIntegrationsAPI';

export type IntegrationIndex = DataIndex<Integration>;
export type ChannelIndex = DataIndex<Channel>;
export type EngineIndex = DataIndex<Engine>;

const MAX_AGE = 1000 * 60 * 60;

const IntegrationContext = createContext({
  engines : {} as EngineIndex | null,
  channels : {} as ChannelIndex | null,
  integrations : {} as IntegrationIndex | null,
  loaded : false,
  load : () => {},
  refreshEngines : async () => null as DataIndex<Engine> | null,
  retrieveEngines : async () => null as DataIndex<Engine> | null,
  refreshChannels : async () => null as DataIndex<Channel> | null,
  refreshChannel : async (id : number) => null as Channel | null,
  retrieveChannels : async () => null as DataIndex<Channel> | null,
  retrieveChannel : async (id : number) => null as Channel | null,
  updateChannel : async (channel : Channel) => null as Channel | null,
  refreshIntegrations : async () => null as DataIndex<Integration> | null,
  refreshIntegration : async (id : number) => null as Integration | null,
  retrieveIntegrations : async () => null as IntegrationIndex | null,
  retrieveIntegration : async (id : number) => null as Integration | null,
  createIntegration :
    async (integration : Integration) => null as Integration | null,
  deleteIntegration :
    async (integration : Integration) => null as boolean | null,
  addIntegrationToChannel :
    async (integration : Integration, channel : Channel) =>
      null as boolean | null,
  removeIntegrationFromChannel :
    async (integration : Integration, channel : Channel) =>
      null as boolean | null,
  createConfig :
    async (integration : Integration, config : Config) => null as Config | null,
  updateConfig : async (config : Config) => null as Config | null,
  deleteConfig : async (config : Config) => null as boolean | null,
});

interface IntegrationProviderProps {
  children : React.ReactNode;
}

export function IntegrationProvider({
  children,
} : IntegrationProviderProps) {
  const {
    createIntegration,
    retrieveIntegrations,
    retrieveIntegration,
    deleteIntegration,
    retrieveChannels,
    retrieveChannel,
    updateChannel,
    addIntegrationToChannel,
    removeIntegrationFromChannel,
    retrieveEngines,
    createConfig,
    updateConfig,
    deleteConfig,
  } = useIntegrationsAPI();

  const {
    data : engines,
    dispatch : dispatchEngines,
    lastUpdated : enginesLastUpdated,
  } = useData<Engine>({ storageKey : 'engines' });

  const refreshEngines = useRefreshIndex({
    dispatch : dispatchEngines,
    retrieve : retrieveEngines,
  });
  const getEngines = useRetrieveIndex({
    data : engines,
    timestamp : enginesLastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshEngines,
  });

  const {
    data : channels,
    dispatch : dispatchChannels,
    lastUpdated : channelsLastUpdated,
  } = useData<Channel>({ storageKey : 'channels' });

  const refreshChannels = useRefreshIndex({
    dispatch : dispatchChannels,
    retrieve : retrieveChannels,
  });
  const refreshChannel = useRefresh({
    dispatch : dispatchChannels,
    retrieve : retrieveChannel,
  });
  const getChannels = useRetrieveIndex({
    data : channels,
    timestamp : channelsLastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshChannels,
  });
  const getChannel = useRetrieve({
    data : channels,
    timestamp : channelsLastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshChannel,
  });
  const updateChannelData = useChange({
    dispatch : dispatchChannels,
    change : updateChannel,
  });

  const {
    data : integrations,
    dispatch : dispatchIntegrations,
    lastUpdated : integrationsLastUpdated,
  } = useData<Integration>({ storageKey : 'integrations' });


  const newIntegration = useChange({
    dispatch : dispatchIntegrations,
    change : createIntegration,
  });
  const refreshIntegrations = useRefreshIndex({
    dispatch : dispatchIntegrations,
    retrieve : retrieveIntegrations,
  });
  const refreshIntegration = useRefresh({
    dispatch : dispatchIntegrations,
    retrieve : retrieveIntegration,
  });
  const getIntegrations = useRetrieveIndex({
    data : integrations,
    timestamp : integrationsLastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshIntegrations,
  });
  const getIntegration = useRetrieve({
    data : integrations,
    timestamp : integrationsLastUpdated,
    maxAge : MAX_AGE,
    refresh : refreshIntegration,
  });
  const removeIntegration = useDelete({
    dispatch : dispatchIntegrations,
    delete : deleteIntegration,
  });

  const addIntegration = useRelate({
    dispatch : dispatchIntegrations,
    relate : addIntegrationToChannel,
    callback : refreshIntegrations,
  });
  const removeIntegrationFrom = useRelate({
    dispatch : dispatchIntegrations,
    relate : removeIntegrationFromChannel,
    callback : refreshIntegrations,
  });

  const dispatchConfig = useCallback(
    (action : { data : DataIndex<Config> }) => {
      Object.values(action.data).forEach(config => {
        if (!config?.integrationId) return;
        refreshIntegration(config?.integrationId);
      }
    )},
    [refreshIntegration],
  );

  const newConfig = useRelate({
    dispatch : dispatchConfig,
    relate : createConfig,
  });
  const amendConfig = useChange({
    dispatch : dispatchConfig,
    change : updateConfig,
  });
  const removeConfig = useDelete({
    dispatch : dispatchConfig,
    delete : deleteConfig,
  });

  const { loaded : enginesLoaded, load : loadEngines } = useLoad({
    data : engines,
    loader : refreshEngines,
  });
  const { loaded : channelsLoaded, load : loadChannels } = useLoad({
    data : channels,
    loader : refreshChannels,
  });
  const { loaded : integrationsLoaded, load : loadIntegrations } = useLoad({
    data : integrations,
    loader : refreshIntegrations,
  });

  const load = useCallback(() => {
    loadEngines();
    loadChannels();
    loadIntegrations();
  }, [loadEngines, loadChannels, loadIntegrations]);

  const context = {
    engines,
    channels,
    integrations,
    loaded : enginesLoaded && channelsLoaded && integrationsLoaded,
    load,
    refreshEngines,
    retrieveEngines : getEngines,
    refreshChannels,
    refreshChannel,
    retrieveChannels : getChannels,
    retrieveChannel : getChannel,
    updateChannel : updateChannelData,
    refreshIntegrations,
    refreshIntegration,
    retrieveIntegrations : getIntegrations,
    retrieveIntegration : getIntegration,
    createIntegration : newIntegration,
    deleteIntegration : removeIntegration,
    addIntegrationToChannel : addIntegration,
    removeIntegrationFromChannel : removeIntegrationFrom,
    createConfig : newConfig,
    updateConfig : amendConfig,
    deleteConfig : removeConfig,
  };

  return (
    <IntegrationContext.Provider value={context}>
      { children }
    </IntegrationContext.Provider>
  );
}

export default IntegrationContext;
