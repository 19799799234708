import React from 'react';

import { formatDateTime, formats } from '#mrktbox/utils';

interface OrderRequestedAtProps {
  time : Date;
}

function OrderRequestedAt({ time } : OrderRequestedAtProps) {
   return (
    <p>{ formatDateTime(time, formats.short) }</p>
  );
}

export default OrderRequestedAt;
