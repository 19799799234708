import {
  Note,
  isNote,
  ProductNote,
  isProductNote,
} from '#mrktbox/clerk/types';

import { DeserializationError, methods } from '#mrktbox/clerk/api';
import { getUrl, request, RequestOptions } from '#mrktbox/clerk/api/mrktbox';

import { currentGuestCode } from '#mrktbox/clerk/api/mrktbox/orders';

const NOTES_PATH = 'notes/';
const PRODUCT_NOTES_PATH = 'notes/products/';

function generateNotesPath(guestCode? : string | null) {
  if (!guestCode) return NOTES_PATH;
  return `${NOTES_PATH}${guestCode}/`;
}

function parseNote(note : any) : Note {
  if (!isNote(note))
    throw new TypeError('Note is not a Note Object')
  return note;
}

function parseNotes(notes : any) {
  const parsedNotes : { [id : number] : Note } = {};
  for (const noteId in notes) {
    if (typeof noteId !== 'string')
      throw new TypeError('Note id is not a string');

    const id = parseInt(noteId);
    if (isNaN(id)) throw new TypeError('Note id is not a number');

    parsedNotes[id] = parseNote(notes[id]);
  }

  return parsedNotes;
}

function parseProductNote(productNote : any) : ProductNote {
  if (!isProductNote)
    throw new TypeError('ProductNote is not a ProductNote Object')
  return productNote;
}

function parseProductNotes(productNotes : any) {
  const parsedProductNotes : { [id : number] : ProductNote } = {};
  for (const productNoteId in productNotes) {
    if (typeof productNoteId !== 'string')
      throw new TypeError('ProductNote id is not a string');

    const id = parseInt(productNoteId);
    if (isNaN(id)) throw new TypeError('ProductNote id is not a number');

    parsedProductNotes[id] = parseProductNote(productNotes[id]);
  }

  return parsedProductNotes;
}

export async function createNote(
  { note } : { note : Note },
  options? : RequestOptions,
) {
  const guestCode = currentGuestCode();
  const response = await request(
    getUrl(generateNotesPath(guestCode)),
    methods.post,
    { note },
    options,
  );

  try {
    return parseNote(response.note);
  } catch {
    throw new DeserializationError(
      'Could not deserialize note',
      response,
    );
  }
}

export async function retrieveNotes(
  input : {},
  options? : RequestOptions,
) {
  const guestCode = currentGuestCode();
  const response = await request(
    getUrl(generateNotesPath(guestCode)),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseNotes(response.notes);
  } catch {
    throw new DeserializationError(
      'Could not deserialize notes',
      response,
    );
  }
}

export async function retrieveNote(
  { noteId } : { noteId : number },
  options? : RequestOptions,
) {
  const guestCode = currentGuestCode();
  const response = await request(
    getUrl(`${generateNotesPath(guestCode)}${noteId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseNote(response.note);
  } catch {
    throw new DeserializationError(
      'Could not deserialize note',
      response,
    );
  }
}

export async function updateNote(
  { note } : { note : Note},
  options? : RequestOptions,
) {
  const guestCode = currentGuestCode();
  const response = await request(
    getUrl(`${generateNotesPath(guestCode)}${note.id}`),
    methods.put,
    { note },
    options,
  );

  try {
    return parseNote(response.note);
  } catch {
    throw new DeserializationError(
      'Could not deserialize note',
      response,
    );
  }
}

export async function deleteNote(
  { note } : { note : Note },
  options? : RequestOptions,
) {
  const guestCode = currentGuestCode();
  const response = await request(
    getUrl(`${generateNotesPath(guestCode)}${note.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseNote(response.note);
  } catch {
    throw new DeserializationError(
      'Could not deserialize note',
      response,
    );
  }
}

export async function createProductNote({
  productNote,
} : {
  productNote : ProductNote,
},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${PRODUCT_NOTES_PATH}`),
    methods.post,
    { productNote },
    options,
  );

  try {
    return parseProductNote(response.productNote);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product note',
      response,
    );
  }
}

export async function retrieveProductNotes(
  input : {},
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(PRODUCT_NOTES_PATH),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseProductNotes(response.productNotes);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product notes',
      response,
    );
  }
}

export async function retrieveProductNote(
  { productNoteId } : { productNoteId : number },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${PRODUCT_NOTES_PATH}${productNoteId}`),
    methods.get,
    undefined,
    options,
  );

  try {
    return parseProductNote(response.productNote);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product note',
      response,
    );
  }
}

export async function updateProductNote(
  { productNote } : { productNote : ProductNote},
  options? : RequestOptions,
) {
  const response = await request(
    `${getUrl(PRODUCT_NOTES_PATH)}${productNote.id}`,
    methods.put,
    { productNote },
    options,
  );

  try {
    return parseProductNote(response.productNote);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product note',
      response,
    );
  }
}


export async function deleteProductNote(
  { productNote } : { productNote : ProductNote },
  options? : RequestOptions,
) {
  const response = await request(
    getUrl(`${PRODUCT_NOTES_PATH}${productNote.id}`),
    methods.delete,
    undefined,
    options,
  );

  try {
    return parseProductNote(response.productNote);
  } catch {
    throw new DeserializationError(
      'Could not deserialize product note',
      response,
    );
  }
}
