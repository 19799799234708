
import { Tag, isTag } from './tags';

export interface Supplier extends Tag {}

export function isSupplier(supplier : Supplier) : supplier is Supplier {
  return (
    isTag(supplier)
  )
}
