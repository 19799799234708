import React from 'react';
import styled from '@emotion/styled';

import { Theme } from '#types';

import Transition, { TransitionGroup } from '#materials/Transition';

interface Style { theme? : Theme; }
interface WrapperStyle extends Style { show : boolean; }

const FormErrorWrapper = styled.div<WrapperStyle>`
  height: ${(props) => props.show ? '60px' : 0};
  transition: height 0.25s ease;
`;

const FormErrorView = styled.span<Style>`
  outline: 0;
  display: inline-block;
  width: calc(100% + (2 * ${(props) => props.theme.layout.border.radius}));
  margin:
    ${(props) => props.theme.layout.spacing.xsmall}
    -${(props) => props.theme.layout.border.radius}
    0;
  padding: ${(props) => props.theme.layout.spacing.xsmall};
  white-space: nowrap;

  color: ${(props) => props.theme.palette.accent.alert.text};
  background-color: ${(props) => props.theme.palette.accent.alert.fill};

  border-radius: ${(props) => props.theme.layout.border.radius};

  font-family: ${(props) => props.theme.typography.fonts.default.family};
  font-size: ${(props) => props.theme.typography.fonts.default.sizes.small};
  font-weight:
    ${(props) => props.theme.typography.fonts.default.weights.regular};
    line-height: ${(props) => props.theme.typography.fonts.default.lineHeight};
  letter-spacing:
    ${(props) => props.theme.typography.fonts.default.letterSpacing};
  -webkit-font-smoothing: antialiased;

  p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface FormErrorProps {
  errMsg? : string;
  style? : Object;
}

const FormError = ({ errMsg, style } : FormErrorProps) => {
  return (
    <FormErrorWrapper show={!!errMsg}>
      <TransitionGroup component={undefined}>
        { errMsg
          ?
          (
            <Transition
              key="form-error"
              classNames="reveal"
              timeout={{ enter: 250, exit: 250 }}
            >
              <FormErrorView style={style}>
                <p>{ errMsg }</p>
              </FormErrorView>
            </Transition>
          )
          : undefined
        }
      </TransitionGroup>
    </FormErrorWrapper>
  )
}

export default FormError;
