import { useCallback, useContext } from 'react';

import { Address, Service, Route } from '#mrktbox/clerk/types';

import RouteContext from '#mrktbox/clerk/context/RouteContext';

import useAddresses from '#mrktbox/clerk/hooks/useAddresses';

import { listRecords } from '#mrktbox/clerk/utils/data';
import { pointInPolygon } from '#mrktbox/clerk/utils/geometry';

function useRoutes() {
  const {
    routes,
    loaded: routesLoaded,
    load : loadRoutes,
    refreshRoutes,
    refreshRoute,
    retrieveRoutes,
    retrieveRoute,
    createRoute,
    updateRoute,
    deleteRoute,
    addAreaToRoute,
    removeAreaFromRoute,
    addServiceToRoute,
    removeServiceFromRoute,
  } = useContext(RouteContext);
  const { areas, areasLoaded, loadAreas, retrieveArea } = useAddresses();

  // DEPR: use retrieveAddressRoutesSync instead to avoid unnecessary deferals
  const retrieveAddressRoutes = useCallback(async (address : Address) => {
    if (!address.latitude || !address.longitude) return null;
    const allRoutes = await retrieveRoutes();
    if (!allRoutes) return null;

    const addressRoutes : Route[] = [];
    for (const route of Object.values(allRoutes)) {
      if (!route?.areaIds) continue;
      for (const areaId of route.areaIds) {
        const area = await retrieveArea(areaId);
        if (!area) continue;
        const inRoute = pointInPolygon(
          [address.latitude, address.longitude],
          area.vertices,
        );
        if (inRoute) {
          addressRoutes.push(route);
          break;
        }
      }
    }
    return addressRoutes;
  }, [retrieveRoutes, retrieveArea]);

  // TODO: check, refresh if routes and areas are not loaded
  const retrieveAddressRoutesSync = useCallback((address : Address) => {
    if (!address.latitude || !address.longitude) return null;
    if (!routes || !areas) return null;

    const addressRoutes : Route[] = [];
    for (const route of Object.values(routes)) {
      if (!route?.areaIds) continue;
      for (const areaId of route.areaIds) {
        const area = areas[areaId];
        if (!area) continue;
        const inRoute = pointInPolygon(
          [address.latitude, address.longitude],
          area.vertices,
        );
        if (inRoute) {
          addressRoutes.push(route);
          break;
        }
      }
    }
    return addressRoutes;
  }, [routes, areas]);

  const getServiceRoutes = useCallback(
    (services : Service[] | Service | null) => {
      if (!services) return [];
      if (!routes) return [];

      const serviceArray = Array.isArray(services) ? services : [services];
      return listRecords(routes).filter((route) => {
        return serviceArray && serviceArray.some(s => (
          s.id && route.serviceIds?.includes(s.id)
        ));
      });
    },
    [routes],
  );

  const isServiceDelivery = useCallback(
    (services : Service[] | Service | null) => {
      return getServiceRoutes(services).length > 0;
    },
    [getServiceRoutes],
  );

  const load = useCallback(() => {
    loadRoutes();
    loadAreas();
  }, [loadRoutes, loadAreas]);

  return {
    routes,
    loaded : routesLoaded && areasLoaded,
    load,
    createRoute,
    refreshRoutes,
    refreshRoute,
    retrieveRoutes: retrieveRoutes,
    retrieveRoute,
    updateRoute,
    deleteRoute,
    addAreaToRoute,
    removeAreaFromRoute,
    addServiceToRoute,
    removeServiceFromRoute,
    retrieveAddressRoutes,
    retrieveAddressRoutesSync,
    getServiceRoutes,
    isServiceDelivery,
  };
}

export default useRoutes;
