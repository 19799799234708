
export function capitalize(str : string) {
  const tokens = str.split(' ');
  return tokens.map((token) => {
    return token.charAt(0).toUpperCase() + token.slice(1);
  }).join(' ');
}

export function slug(str : string) {
  return str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

export function shortenAddress(address : string) {
  const tokens = address.split(',');
  const short = (tokens[0] && tokens[0]?.length > 4)
    ? tokens[0]
    : tokens.slice(0, 2).join(',');
  return short.slice(0, 30);
}
